import React,{useEffect, useState, useRef} from 'react';
import axios from 'axios';
import urls from '../../utils/url';
import { getReadableTime , isJson} from '../../utils/converter';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import IntrapartumGraph from '../viewFiles/intrapartumData/IntrapartumGraph';
import * as htmlToImage from 'html-to-image';
import jsPDF from "jspdf"
import '../partograph/partograph.scss';
import Cookie from 'js-cookie';
import Loading from '../common/loading';
import Reload from '../common/Reload';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const PartographLayout = (props) => {

  const { patientID, openPartograph, setOpenPartograph, handleClickOpen, handleClosePartograph} = props;

  const partographRef = useRef(null);
  const cookie = Cookie.get('access_token')
  const [ loading, setLoading] = useState(false);
  const [ error, setError] = useState(false);
  const [ patientData, setPatientData] = useState();

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=basic_detail,events,place_details,admission_note&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPatientData(json_data.response[0])
            setLoading(false) 
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err);
          setError(true)
          setPatientData()
          setLoading(false)
        }
      ) 
   }, [])
   
  const handleDownload = () => {
    const closeButton = document.querySelector('.close-button');
    const printButton = document.querySelector('.print-partograph');
    const partograph = document.getElementById('partograph-container');
    let originalWidth;

    if (partograph) {
        // Store the original width of the partograph
        originalWidth = partograph.style.width;
        partograph.style.width = '1100px';
    }
    closeButton.style.display = 'none';
    printButton.style.display = 'none';

    // Give time for the layout changes to take effect
    setTimeout(() => {
        htmlToImage.toCanvas(partograph)
            .then((canvas) => {
                const dataUrl = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');    // 'p' for portrait mode
                pdf.addImage(dataUrl, 'PNG', 0, 0, 200, 290);
                window.open(pdf.output('bloburl'), '_self');

                partograph.style.width = originalWidth;
                closeButton.style.display = 'block';
                printButton.style.display = 'inline-flex';
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
                partograph.style.width = originalWidth;
                closeButton.style.display = 'block';
                printButton.style.display = 'inline-flex';
            });
    }, 1000); // 2 seconds delay to allow layout updates
}


  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
        e.preventDefault();
        alert('Please click on Print Partogaph button to print the Partograph');
      }
    };
    
  useEffect(() => {
    const handleKeyDownEvent = (e) => {
      handleKeyDown(e);
    };

    window.addEventListener('keydown', handleKeyDownEvent);

    return () => {
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, []);

  return ( 

    <>
      {
      loading ? <div className='loading-div'><Loading/></div> 
      : error ? <div className='reload-div'><Reload/></div>
      :
      <>
        <Dialog fullScreen open={openPartograph} onClose={handleClosePartograph} TransitionComponent={Transition} >
          <div style={{paddingTop:`2rem`,display:`flex`, flexDirection:`column`}} > 
          
            <img src="/back_arrow.svg" alt = "close" onClick={handleClosePartograph} style={{paddingLeft:`1rem`, width:`120px`, height:`30px`, cursor:`pointer`}} className='close-button'/>

            <div className='print-partograph' onClick={handleDownload}>
              <button className='print-partograph-button'>Print Partograph</button> 
            </div>

            <div ref={partographRef} id="partograph-container">
                <h3 style={{textAlign: `center`, textTransform:`capitalize`, fontWeight:`bold`}}> {patientData?.place_details[patientData?.basic_detail?.registration_place_id].name} -  SIMPLIFIED PARTOGRAPH </h3>

                <div className='partograph-info-container'>
                    <div className='partograph-info-section'>
                        <p className='text1'>Patient's Name</p>
                        <p className='text2 breakableText'>{patientData?.basic_detail?.name ? patientData?.basic_detail?.name: "- -"} </p>
                    </div>

                    <div className='partograph-info-section'>
                        <p className='text1'>GPAL</p>
                        {patientData?.admission_note && patientData.admission_note.gravida !== null && patientData.admission_note.para !== null && patientData.admission_note.abortion !== null && patientData.admission_note.living_children !== null ?
                            <p className='text2 breakableText'>
                                G{<><sub>{patientData.admission_note.gravida}</sub></>}
                                P{<><sub>{patientData.admission_note.para}</sub></>}
                                A{<><sub>{patientData.admission_note.abortion}</sub></>}
                                L{<><sub>{patientData.admission_note.living_children}</sub></>}
                            </p>
                            :
                            '-'
                        }
                    </div>

                    <div className='partograph-info-section'>
                        <p className='text1'>IPD Registration Number</p>
                        <p className='text2 breakableText'>{patientData?.basic_detail?.ipd_registration_number ? patientData?.basic_detail?.ipd_registration_number : "- -"}</p>
                    </div>

                    <div className='partograph-info-section'>
                        <p className='text1'>Admission Time</p>
                        <p className='text2 breakableText'>{patientData?.basic_detail?.register_time ? getReadableTime(patientData?.basic_detail?.register_time) : "- -"}</p>
                    </div>
                        
                    <div className='partograph-info-section'>
                        <p className='text1'>Membrane Rupture Time</p>
                        <p className='text2 breakableText'>{ (patientData?.events.find(obj => obj.event === "membrane_ruptured") !== undefined) ? getReadableTime(patientData.events.find(obj => obj.event === "membrane_ruptured").event_time) : '--' }</p> 
                    </div> 

                    <div className='partograph-info-section'>
                        <p className='text1'>OPD Registration Number</p>
                        <p className='text2 breakableText'>{patientData?.basic_detail?.opd_registration_number ? patientData?.basic_detail?.opd_registration_number: "- -"}</p>
                    </div>
                </div>
              
                <div className='view-partograph' style={{width:`95%`, margin:`0px auto`, marginTop:`2rem`}}>
                  <IntrapartumGraph patientID={patientID}/>
                </div>
                
            </div>
          </div>
        </Dialog>
      </>
      }
    </>
  )
}

export default PartographLayout