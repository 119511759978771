import React from 'react'
import '../common/common.scss'

const BackButton = ({theme}) => {
  console.log(theme)

  return (
    <div className='back-button'>
        <img src="/back.png" alt="back icon" className='back-icon'/>
        <p className={theme === "light-theme" ? "back-light" : "back-dark"}>Back</p>
    </div>
  )
}

export default BackButton
