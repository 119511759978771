import { configureStore } from "@reduxjs/toolkit";
import keyarDataReducer from "./features/central_monitor/keyarData";
import basicDetailReducer from './features/central_monitor/basic_detail'
import liveOneSecKeyarDataReducer from './features/central_monitor/liveOneSecKeyarData'
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";
import configurationReducer from './features/login/appConfiguration'
import placeDetailReducer from "./features/login/placeDetail";
import patientDetailReducer from "./features/details/patientDetail";


export const store = configureStore({
    reducer: {
        keyarData: keyarDataReducer,
        liveBasicDetail: basicDetailReducer,
        liveOneSecKeyarData: liveOneSecKeyarDataReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware())
})

initMessageListener(store);

