import React from 'react';
import axios from 'axios';
import urls from '../../../utils/url';
import '../common.scss';
import { useState, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { getPostPartumData, isJson, convertChildCode, getReadableTime, TooltipComponent,charLimit, limitStr } from '../../../utils/converter'
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import NoData from '../../common/NoData';
import Cookie from 'js-cookie';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)' 
}));

const parseDateTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  // Format the date as "dd MMM yyyy"
  const day = dateTime.toLocaleString('en-US', { day: '2-digit' });
  const month = dateTime.toLocaleString('en-US', { month: 'short' });
  const year = dateTime.toLocaleString('en-US', { year: 'numeric' });

  const date = `${day}-${month}-${year}`;
  // Format the time as "hh:mm a"
  const time = dateTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  return { date, time };
}

const PostpartumData = (props) => {

  const cookie = Cookie.get('access_token')
  const { patientID } = props;
  const [ expandedTables, setExpandedTables ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ activeText, setActiveText ] = useState('Mother');
  const [ postpartumData, setPostpartumData ] = useState({});
  const [ postpartumParams, setPostpartumParams ] = useState([]);
  const [ childDetails, setChildDetails ] = useState([])
  const [ patientStatus, setPatientStatus ] = useState(" ")
  const [ activeChildId, setActiveChildID ] = useState()
 

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=post_partum_data,basic_detail,delivery_detail&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPostpartumData(json_data.response[0].post_partum_data);
            console.log(json_data.response[0].post_partum_data)
            //console.log(json_data.response[0].post_partum_data)
            setChildDetails(json_data.response[0].delivery_detail?.child_details)
           // console.log(json_data.response[0].delivery_detail)
            const current_status = json_data.response[0]?.basic_detail?.current_status;
            setPatientStatus(current_status)
            setLoading(false)
            
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err)
          setError(true)
          setPostpartumData()
          setLoading(false)
        }
      ) 
  }, [])

  const handleToggle = (text) => {
    setActiveText(text);
  };

  useEffect(() => {
    if(Object.keys(postpartumData).length > 0) {
      //console.log(postpartumData)
       const postpartum_details =  getPostPartumData(postpartumData)
       setPostpartumParams([postpartum_details])
       
   }
  }, [postpartumData])

  const handleExpandClick = (index) => {
    if (expandedTables.includes(index)) {
      setExpandedTables(expandedTables.filter(item => item !== index)); // Remove index if already expanded
    } else {
      setExpandedTables([...expandedTables, index]); // Add index to the array if not expanded
    }
  };

  const handleChildId = (id) => {
    setActiveChildID(id)
  }

  return (
    <>
    {
      loading ? (<div className='loading-div'><Loading/></div>) 
      : error ? (<div className='reload-div'><Reload/></div>) 
      : 
      ( patientStatus !== 'intra_partum_process' ? 

        <>
          <div className = 'toggle-header info-section'>
            
            <div className='toggle-button'>
              <p className={activeText === 'Mother' ? 'active-toggle-text' : 'inactive-toggle-text'} onClick={() => handleToggle('Mother')}> Mother </p>
              <hr className='vertical-line'/>
              {
                childDetails && childDetails.length > 0 && childDetails.map((child, index) => (
                  <> 
                      <p  className={activeText === `Child ${index + 1}` ? 'active-toggle-text' : 'inactive-toggle-text'} onClick={() => {handleToggle(`Child ${index + 1}`); handleChildId(child.id) }}>
                        {`Child ${index + 1}`}
                      </p>
                      {
                        index !== (childDetails?.child_details?.length ?? 0) - 1 && <hr className='vertical-line'/>
                      }
                    </>

                ))
              }
            </div>
            
          </div>

         <div className='info-section'>
              
            { activeText === 'Mother' ?
                ( postpartumParams.length > 0 && postpartumParams[0]['mother'].data_times.length > 0 ? (
                    postpartumParams[0]['mother'].data_times.map((timestamp, index) => {
                      const { date, time } = parseDateTime(timestamp);
                      const isExpanded = expandedTables.includes(index);
            
                      return (

                        <div key={index} style={{marginTop:`2rem`, width:`90%`, marginBottom:`2rem`}}>

                          <div className={`${'postpartum-table-header'} ${isExpanded ? 'active-table' : 'table'}`}>
                            <p className="header-text">Data at</p>
                            <p className="header-text">{date}</p>
                            <p className="header-text">{time}</p>
                            <ExpandMore expand={isExpanded} onClick={() => handleExpandClick(index)} aria-expanded={isExpanded} aria-label="show more">
                              <img src="/down_arrow.png" alt="arrow" style={{ width: '50%', borderRadius: '100%' }} />
                            </ExpandMore>
                          </div>
            
                          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <table style={{ width: '100%', borderSpacing: '0px',  marginLeft:`3rem`, paddingRight:`3rem`,  }}>
                              <tbody>
                                {
                                  postpartumParams[0]['mother'].data_values.map((row, ind) => (
                                    <tr key={ind} className="table-layout postpartum-table">
                                      <td key={0} className="table-text">{row[0]}</td>
                                      <td key={ind + 1} className="table-text">{row[index + 1]}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </Collapse>
                        </div>

                      );
                    })) 
                : 
                  (
                    <div> <NoData/> </div>
                  )
                ) 
                : 
                null
            }
      
            {
              activeText.startsWith('Child') ? 
              (
                <>
                  {
                    postpartumParams.length > 0 && postpartumParams[0]['child'].length > 0 && postpartumParams[0]['child'].map((childData, childIndex) => (
                      activeText === `Child ${childIndex + 1}` && (
                        <div key={childIndex} style={{marginTop:`1rem`, width:`90%`}}>
                          {
                            childData.data_times.map((timestamp, index) => {
                              const { date, time } = parseDateTime(timestamp);
                              const isExpanded = expandedTables.includes(index);
                              return (
                                <div key={index}>
                                  <div className={`${'postpartum-table-header'} ${isExpanded ? 'active-table' : 'table'}`}>
                                    <p className="header-text">Data at</p>
                                    <p className="header-text">{date}</p>
                                    <p className="header-text">{time}</p>
                                    <ExpandMore expand={isExpanded} onClick={() => handleExpandClick(index)} aria-expanded={isExpanded} aria-label="show more">
                                        <img src="/down_arrow.png" alt="arrow" style={{ width: '50%', borderRadius: '100%' }} />
                                    </ExpandMore>
                                  </div>

                                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                    <table style={{ width: '100%', borderSpacing: '0px', marginLeft:`3rem`, paddingRight:`3rem`, paddingBottom:`2rem`}}>
                                      <tbody>
                                        {
                                          childData.data_values.map((row, ind) => (
                                            <tr key={ind} className="table-layout postpartum-table">
                                              <td key={0} className="table-text">{row[0]}</td>
                                              <td key={ind + 1} className="table-text">{row[index + 1]}</td>
                                            </tr>
                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </Collapse>
                                </div>
                              );
                            })
                          }
                        </div>
                      )
                    ))
                  } 

                   {
                      activeChildId && childDetails.filter(child => child.id === activeChildId).map((obj, index) => (
                       <div key={index} >
                            <div className='info-header'>
                                <p className='heading'> Child Details</p>
                            </div>

                            <div className='info-container'>
                              <div>
                                  <p className='text1'>Status</p>
                                  <p className='text2'> {obj.child_status ? (obj.child_status === "live birth" ? "Alive" : obj.child_status) : "--"}</p>
                              </div>

                              <div>
                                  <p className='text1'>Gender</p>
                                  <p className='text2'>{obj.gender ? obj.gender : "--" }</p>
                              </div>

                              <div>
                                  <p className='text1'>Weight(Kgs)</p>
                                  <p className='text2'>{obj.weight ? obj.weight : "--" }</p>
                              </div>


                              { obj.child_status === "live birth" || obj.child_status === 'dead' ?
                                <div>
                                    <p className='text1'>Delivery Time</p>
                                    <p className='text2'>{obj.delivery_time ? getReadableTime(obj.delivery_time) : "--"}</p>
                                </div>
                                : <></>
                              }

                              {/* If child is alive then death parameters will not display */}

                              { 
                                obj.child_status !== "live birth" ? (
                                  <>
                                    <div>
                                        <p className='text1'>
                                          {
                                            obj.child_status === 'dead' ? "Death Time" : 
                                            obj.child_status === 'abortion' ? "Time of Abortion" : 
                                            obj.child_status === 'macreated still birth' ? "Time of Macerated Still Birth" : 
                                            obj.child_status === 'fresh still birth' ? "Time of Fresh Still Birth" : null
                                          }
                                        </p>
                                        <p className='text2'>{obj.child_death_time ? getReadableTime(obj.child_death_time) : "--"}</p>
                                    </div>

                                    <div>
                                        <p className='text1'>
                                            {
                                              obj.child_status === 'dead' ? "Death Cause" : 
                                              obj.child_status === 'abortion' ? "Reason for Abortion" : 
                                              obj.child_status === 'macreated still birth' ? "Reason for Macerated Still Birth" : 
                                              obj.child_status === 'fresh still birth' ? "Reason for Fresh Still Birth" : null
                                            }
                                        </p>
                                        {
                                          obj.child_death_cause && obj.child_death_cause.length > charLimit ?
                                              <TooltipComponent title={`${convertChildCode(obj.child_death_cause)}`} content={limitStr(convertChildCode(obj.child_death_cause),charLimit)} />
                                              :
                                              <p className='text2'>{obj.child_death_cause ? convertChildCode(obj.child_death_cause) : "--"}</p>
                                        }
                                    </div>

                                    <div>
                                        <p className='text1'>
                                            {
                                            obj.child_status === 'dead' ? "Other Death Cause" : 
                                            obj.child_status === 'abortion' ? "Other Reason for Abortion" : 
                                            obj.child_status === 'macreated still birth' ? "Other Reason for Macerated Still Birth" : 
                                            obj.child_status === 'fresh still birth' ? "Other Reason for Fresh Still Birth" : null
                                            }
                                        </p>
                                        {
                                          obj.child_death_cause_other && obj.child_death_cause_other.length > charLimit ?
                                              <TooltipComponent title={`${obj.child_death_cause_other}`} content={limitStr(obj.child_death_cause_other)} />
                                              :
                                              <p className='text2'>{obj.child_death_cause_other ? obj.child_death_cause_other : "--"}</p>
                                        }
                                    </div>
                                  </>
                                )
                                : null
                              }
                          </div>
                        </div>
                      ))
                   }
                </>
              )
              : 
              null
            }

         </div>

        </>

        : <div className='no-data-div'> <NoData/> </div>
      ) 
    }
   </>

  )
}

export default PostpartumData