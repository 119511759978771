import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    configuration: []
}

const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        addConfiguration: ( state, action) => {
            state.configuration = action.payload
        }}
})

export const {addConfiguration} = configurationSlice.actions;
export default configurationSlice.reducer