export const medicalData = {
  "timeline": {
    "All": 'All',
    "Admitted": "registered",
    "Membrane Ruptured": "membrane_ruptured",
    "Discharged": "completed",
    "Delivered": "delivered",
    "Referred (Before Delivery)": "referred_before_delivery",
    "Referred (After Delivery)": "referred_after_delivery",
    "Referral Received": "referral_accepted",
    "Referral Cancelled": "referral_cancelled"
},
"child_gender": {
    "All": "All",
    "Male": "male",
    "Female": "female",
    "Other": "other"
},
"delivery_type": {
    "All": "All",
    "Normal": "normal",
    "C-section": "c-section",
    "Vaccum": "vaccum",
    "Forceps": "forceps",
},
"cervix_dilatation": {
    "All": "All",
    "7": "7",
    "8": "8",
    "9": "9",
    "10": "10"
},
"baby_birth_weight": {
    "All": "All",
    "More than 2.5": "1",
    "1.50 - 2.49": "2",
    "1.0 - 1.49": "3"
},
"child_status": {
    "All": "All",
    "Live Birth": "live birth",
    "Macreated Still Birth": "macreated still birth",
    "Fresh Still Birth": "fresh still birth",
    "Abortion": "abortion",
    "Dead": "dead"
},
"mother_status": {
    "All": "All",
    "Alive": "alive",
    "Dead": "dead"
},
"keyar_data": {
    "All": "All",
    "Available": "yes",
    "Not Available": "no"
}
}

export const dataEntries = {
    "cervix_diameter": "Cervix Diameter",
    "bp": "Blood Pressure (SBP/DBP mmHg)",
    "pulse": "Pulse (BPM)",
    "fetal_heart_rate": "Fetal Heart Rate (BPM)",
    "head_descent": "Head Descent",
    "amniotic_fluid": "Amniotic Fluid",
    "moulding": "Moulding",
    "contraction": "Contractions (Count / Duration)",
    "oxytocin": "Oxytocin (Conc. U/L / Drops/Min)",
    "drugs": "Drugs",
    "temperature": "Temperature (oF)",
    "bishop_score": "Bishop Score",
    "DELIVERY_COMPLICATION" : "Delivery Complication",
    "Placenta_Complication" : "Placenta Complication"
}