import React from 'react'
import '../Cards/DelayedCard.scss'
import Tooltip,{ tooltipClasses }  from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#353535',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      width: 200,
      textAlign: 'center'
    },
  }));

const DelayedCard = ({keyar, fhr2critical, fhrcritical, mhrcritical, uccritical}) => {
  return (
    <div>
      <div className='delay-div'>
        <LightTooltip title='The data on the tablet monitoring the patient might not be synced. Please click on "Sync Data" option on the tablet.' placement="right-start"> 
            <img src="../info.png" alt="info" className='info'/>
        </LightTooltip>
        
        <div className='warning-div'>
            <img src="../warning.png" alt="warning" className='warning'/>
            <p className='delayed-message'>Data loading is delayed by more than 1 min</p>
        </div>
      </div>
    <div className='data-div'>
        <div className='fhr-fhr2-mhr-div'>
            <div className={fhrcritical === true ? 'critical' : 'normal'}>
                <p className={fhrcritical === true ? 'critical-number' : 'normal-number'}>
                { keyar.fhr.length == 0 || keyar.fhr[0][1] === '0.0' || keyar.fhr[0][1] == '' || keyar.fhr[0][1] == undefined ? '--' :  Math.floor(keyar.fhr[0][1])}
                </p>
                <p className={fhrcritical === true ? 'critical-text' : 'normal-text-1'}>
                    FHR 1
                </p>
            </div>
            <div className={fhr2critical === true ? 'critical' : 'normal'}>
                <p className={fhr2critical === true ? 'critical-number' : 'normal-number'}>
                { keyar.fhr2.length == 0 || keyar.fhr2[0][1] === '0.0' || keyar.fhr2[0][1] == '' || keyar.fhr2[0][1] == undefined ? '--' : Math.floor(keyar.fhr2[0][1])} 
                </p>
                <p className={fhr2critical === true ? 'critical-text' : 'normal-text-1'}>
                    FHR 2
                </p>
            </div>
            <div className={mhrcritical === true ? 'critical' : 'normal'}>
                <p className={mhrcritical === true ? 'critical-number' : 'normal-number'}>
                { keyar.mhr.length == 0 || keyar.mhr[0][1] === '0.0' || keyar.mhr[0][1] == '' || keyar.mhr[0][1] == undefined ? '--' : Math.floor(keyar.mhr[0][1])} 
                </p>
                <p className={mhrcritical === true ? 'critical-text' : 'normal-text-1'}>
                    MHR
                </p>
            </div>
            
        </div>
        <div className='uc-div'>
            
            <div className='uc'>
                <p className='uc-duration-number'>
                { keyar?.realtimeContractions?.duration == undefined || keyar?.realtimeContractions?.duration == '' || keyar?.realtimeContractions?.duration == 0 ? '--' : keyar?.realtimeContractions?.duration}
                </p>
                <p className='uc-text'>
                    Avg. UC Duration
                </p>
            </div>

            <div className='uc'>
                <p className='uc-number'>
                { keyar.uc.length == 0 || keyar.uc[0][1] === '0.0' || keyar.uc[0][1] == '' || keyar.uc[0][1] == undefined ? '--' : Math.floor(keyar.uc[0][1])}
                </p>
                <p className='uc-text'>
                    Avg. UC Count
                </p>
            </div>
        </div>
    </div>
    </div>
  )
}

export default DelayedCard
