import React from 'react';
import { useState, useEffect } from 'react';
import '../common.scss';
import axios from 'axios';
import urls from '../../../utils/url';
import { getReadableTime, limitStr, charLimit, isJson, convertChildCode, TooltipComponent } from '../../../utils/converter';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import Cookie from 'js-cookie';

const DischargeInformation = (props) => {

  const cookie = Cookie.get('access_token')
  const { patientID } = props;
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ patientDischargeInfo, setPatientDischargeInfo ] = useState();

 useEffect(() => {
  setLoading(true)
  axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=basic_detail,delivery_detail,place_details,events&`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        if(res.data){
          const json_data = isJson(res.data)
          setPatientDischargeInfo(json_data.response[0])
          setLoading(false) 
        }
      }).catch((err) => {
        console.log("Error in Fetching Data",err)
        setError(true)
        setPatientDischargeInfo() 
        setLoading(false)
      }
    ) 
 }, [])

  return (
    <>
     {loading ? <div className='loading-div'><Loading/></div> 
      : error ? <div className='reload-div'><Reload/></div>
      :
      <div className='info-section'>
        
        <>
          <div className='info-header'>
            <p className='heading'>Discharge Details</p>
          </div>

          <div className='info-container'>
            <div>
              <p className='text1'>Patient Name</p>
              {patientDischargeInfo?.basic_detail?.name && patientDischargeInfo?.basic_detail?.name.length > charLimit ?
                <TooltipComponent title={`${patientDischargeInfo?.basic_detail?.name}`} content={limitStr(patientDischargeInfo?.basic_detail?.name, charLimit)} />
                :
                <p className='text2'>{patientDischargeInfo?.basic_detail?.name ? patientDischargeInfo?.basic_detail?.name : "- -"}</p>
              }
            </div>


            <div>
                <p className='text1'>Admission Time/Date</p>
                <p className='text2'>{getReadableTime(patientDischargeInfo?.basic_detail?.register_time)}</p>
            </div>
                
            <div>
              <p className='text1'>Delivery Time/Date</p>
              { patientDischargeInfo?.delivery_detail && patientDischargeInfo?.events && patientDischargeInfo?.events.length > 0 ? 
                <p className='text2'>{ (patientDischargeInfo.events.filter(obj => obj.event === "delivered")[0] !== undefined) ? getReadableTime(patientDischargeInfo.events.filter(obj => obj.event === "delivered")[0].event_time) : '--'}</p> 
                : 
                <p>--</p>
              }
            </div>

            <div>
              <p className='text1'>Discharge Time/Date</p>
              {patientDischargeInfo?.basic_detail?.current_status === "completed" && patientDischargeInfo.events.length !== undefined ? 
                <p className='text2'>{ (patientDischargeInfo.events.filter(obj => obj.event === "completed")[0] !== undefined) ? getReadableTime(patientDischargeInfo.events.filter(obj => obj.event === "completed")[0].event_time) : '--'} </p> 
                : <p>--</p>
              }
            </div>
                
            <div>
              <p className='text1'>Admitted Facility</p>
              {patientDischargeInfo?.place_details[patientDischargeInfo?.basic_detail?.registration_place_id]?.name &&  patientDischargeInfo?.place_details[patientDischargeInfo?.basic_detail?.registration_place_id]?.name.length > charLimit ?
                <TooltipComponent title={patientDischargeInfo?.place_details[patientDischargeInfo?.basic_detail?.registration_place_id]?.name} content={limitStr(patientDischargeInfo?.place_details[patientDischargeInfo?.basic_detail?.registration_place_id]?.name, charLimit)}  />
                :
                <p className='text2'>{patientDischargeInfo?.place_details[patientDischargeInfo?.basic_detail?.registration_place_id]?.name ? patientDischargeInfo?.place_details[patientDischargeInfo?.basic_detail?.registration_place_id]?.name : '--'}</p>
              }
            </div>

            <div>
                <p className='text1'>Condition of mother</p>
                <p className='text2'>{patientDischargeInfo?.delivery_detail?.mother_status ? patientDischargeInfo?.delivery_detail?.mother_status : '--'}</p>
            </div>
                
            <div>
                <p className='text1'>Mode of Delivery</p>
                <p className='text2'>{patientDischargeInfo?.delivery_detail?.delivery_type ? patientDischargeInfo?.delivery_detail?.delivery_type : '--'}</p>
            </div>

            <div>
              <p className='text1'>OPD Registration Number</p>
              {
                patientDischargeInfo?.basic_detail?.opd_registration_number && patientDischargeInfo?.basic_detail?.opd_registration_number.length > charLimit ? 
                  <TooltipComponent title={`${patientDischargeInfo?.basic_detail?.opd_registration_number}`} content={limitStr(patientDischargeInfo?.basic_detail?.opd_registration_number, charLimit)}/>
                : 
                  <p className='text2'>{patientDischargeInfo?.basic_detail?.opd_registration_number ? patientDischargeInfo?.basic_detail?.opd_registration_number : '--'}</p>
              }
            </div>
        
        {/* 
          These data are not available in backend, please check from your side 
          <div>
              <p className='text1'>Immunisation</p>
              <p className='text2'>-</p>
          </div>

          <div>
              <p className='text1'>Pre-Term</p>
              <p className='text2'>-</p>
          </div>

          <div>
              <p className='text1'>Inj Vit K</p>
              <p className='text2'>-</p>
          </div>

          <div>
              <p className='text1'>Other Advice</p>
              <p className='text2'>-</p>
          </div>

          <div>
              <p className='text1'>Follow up Date</p>
              <p className='text2'>-</p>
          </div> */}

        </div>
        </>

        {patientDischargeInfo?.delivery_detail?.child_details ?
            patientDischargeInfo?.delivery_detail?.child_details.map ((obj, index)=>{
            return (
              <div>

                  <div className='info-header'>
                    <p className='heading'>Child {index+1}</p>
                  </div>

                  <div className='info-container'>
                    <div>
                        <p className='text1'>Status</p>
                        <p className='text2'>{obj.child_status ? (obj.child_status === "live birth" ? "Alive" : obj.child_status) : "--"}</p> 
                    </div>

                    <div>
                        <p className='text1'>Gender</p>
                        <p className='text2'>{obj.gender ? obj.gender : '--' }</p>
                    </div>
                        
                    <div>
                        <p className='text1'>Weight(Kgs)</p>
                        <p className='text2'>{obj.weight ? obj.weight : '--'}</p> 
                    </div>

                    { obj.child_status === "live birth" || obj.child_status === 'dead' ?
                        <div>
                            <p className='text1'>Delivery Time</p>
                            <p className='text2'>{obj.delivery_time ? getReadableTime(obj.delivery_time) : "--"}</p>
                        </div>
                        : <></>
                    }
                        
                    { 
                      obj.child_status === "live birth" ?
                        <div>
                            <p className='text1'>Breastfeeding Date/Time</p>
                            <p className='text2'>{obj.breastfeeding_time ? getReadableTime(obj.breastfeeding_time):'--'}</p>
                        </div>
                      : <></> 
                    }

                    { 
                      obj.child_status !== "live birth" ? 
                        <>

                          <div>
                              <p className='text1'>
                                {
                                  obj.child_status === 'dead' ? "Death Time" : 
                                  obj.child_status === 'abortion' ? "Time of Abortion" : 
                                  obj.child_status === 'macreated still birth' ? "Time of Macerated Still Birth" : 
                                  obj.child_status === 'fresh still birth' ? "Time of Fresh Still Birth" : null
                                }
                              </p>
                              <p className='text2'>{obj.child_death_time ? getReadableTime(obj.child_death_time) : "--"}</p>
                          </div>

                          <div>
                              <p className='text1'>
                                  {
                                  obj.child_status === 'dead' ? "Death Cause" : 
                                  obj.child_status === 'abortion' ? "Reason for Abortion" : 
                                  obj.child_status === 'macreated still birth' ? "Reason for Macerated Still Birth" : 
                                  obj.child_status === 'fresh still birth' ? "Reason for Fresh Still Birth" : null
                                  }
                              </p>
                              {obj.child_death_cause && obj.child_death_cause.length > charLimit ?
                                  <TooltipComponent title={`${convertChildCode(obj.child_death_cause)}`} content={limitStr(convertChildCode(obj.child_death_cause),charLimit)} />
                                  :
                                  <p className='text2'>{obj.child_death_cause ? convertChildCode(obj.child_death_cause) : "--"}</p>
                              }
                          </div>

                          <div>
                              <p className='text1'>
                                  {
                                  obj.child_status === 'dead' ? "Other Death Cause" : 
                                  obj.child_status === 'abortion' ? "Other Reason for Abortion" : 
                                  obj.child_status === 'macreated still birth' ? "Other Reason for Macerated Still Birth" : 
                                  obj.child_status === 'fresh still birth' ? "Other Reason for Fresh Still Birth" : null
                                  }
                              </p>
                              {obj.child_death_cause_other && obj.child_death_cause_other.length > charLimit ?
                                  <TooltipComponent title={`${obj.child_death_cause_other}`} content={limitStr(obj.child_death_cause_other)} />
                                  :
                                  <p className='text2'>{obj.child_death_cause_other ? obj.child_death_cause_other : "--"}</p>
                              }
                          </div>
                        </> 
                        : <></>
                    }

                  </div>
              </div>
            )
          }) : <></>
        }

      </div> 
     }
    </>
  )
}

export default DischargeInformation