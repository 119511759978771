import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    liveBasicDetail: []
}

const realTimeBasicDetailSlice = createSlice({
    name: 'liveBasicDetail',
    initialState,
    reducers: {
        addRealTimeBasicDetail: ( state, action) => {
            state.liveBasicDetail = action.payload
        }}
})

export const {addRealTimeBasicDetail} = realTimeBasicDetailSlice.actions;
export default realTimeBasicDetailSlice.reducer