import axios from "axios";
import React, {useState, useEffect, useRef} from 'react'
import {Dialog  } from "@mui/material";
import '../login/login.scss'
import urls from "../../utils/url";
import { useNavigate} from "react-router-dom";
import { isJson } from "../../utils/converter";
import Cookie from 'js-cookie';
import logos from "../../utils/logos";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [visibility, setVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(" ");
    const [isError, setIsError] = useState(false);
    const [open, setOpen] = useState(false);
    const [notificationPermission, setNotificationPermission] = useState('default');
    const [accountType, setAccountType] = useState();

//     const indexList = [0, 1, 2];
//     const [activeIndex, setActiveIndex] = useState(0);

    const passwordInputRef = useRef(null);
    const navigate =  useNavigate();

    useEffect(() => {
        const accessToken = Cookie.get('access_token');

        const cookieData = Cookie.get('data');

        if (cookieData) {
            const parsedData = JSON.parse(cookieData);
            const account_type = parsedData.account_type;
            setAccountType(account_type); 

            if (accessToken) {
                if (account_type && ['Daksh SI', 'Basic', 'Navam'].includes(account_type)) {
                    navigate('/dashboard/report');
                } else {
                    navigate('/dashboard/analytics');
                }
            } else {
                navigate('/');
            }
        } 
    }, []);

    const handleEmailChange = (e) => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    //     const requestNotificationPermission = () => {
    //         Notification.requestPermission().then((permission) => {
    //           setNotificationPermission(permission);
    //         });
    //     };

    //     useEffect(() => {
    //         requestNotificationPermission();
    //         if (notificationPermission === 'granted') {
    //             setOpen(false)
    //           } else if (notificationPermission === 'denied') {
    //             setOpen(true)
    //             // Handle the case where the user has blocked access to notifications
    //           } else {
    //             //setOpen(true)
    //             requestNotificationPermission();
    //             console.warn('Notification permission not granted yet');
    //             // Handle the case where permission is not granted yet
    //           }
            
    //     }, [notificationPermission]);

    //     useEffect(() => {
    //         const intervalId = setInterval(() => {
    //           setActiveIndex((prevIndex) => {
    //             const nextIndex = (prevIndex + 1) % indexList.length;
        
    //             // Stop the interval when reaching the last index
    //             // if (nextIndex === 3) {
    //             //   clearInterval(intervalId);
    //             // }
        
    //             return nextIndex;
    //           });
    //         }, 2000); // 2 seconds
        
    //         // Clear the interval when the component unmounts
    //         return () => clearInterval(intervalId);
    //       }, []); 

    useEffect(() => {
      // Check if it's not the first load by checking the local storage flag
      const isFirstLoad = localStorage.getItem('firstLoad') === null;
  
      if (!isFirstLoad) {
        Notification.requestPermission().then(permission => {
        
          if (permission === 'default' || permission === 'denied') {
            // User dismissed the notification prompt, show the custom popup
            setOpen(true);
          }
          // Set 'firstLoad' to false since the user has made a choice
          localStorage.setItem('firstLoad', false);
        });
      } else {
        // Set 'firstLoad' to false on the initial load
        localStorage.setItem('firstLoad', false);
      }
    }, []);
  
    
    const handleLoginSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const lowerUserName = username.toLowerCase();

        if(window.localStorage.getItem('onesignalId') != null){
            if(window.localStorage.getItem('isPushNotificationsEnabled') == 'true'){
                await axios.post( `${urls.BETA_URL}/nurse/login/`,
                    {
                        "username": lowerUserName, 
                        "password": password,
                        "subscriber_id":  window.localStorage.getItem('onesignalId')
                    },
                    { headers: {
                        'Request-ID': 'allow|me', 
                        'Content-Type': 'application/json'
                    }
                }).then((res) => {
                    if(res.data){
                        const json_data = isJson(res.data)

                        window.localStorage.setItem('username', username)
                        
                        Cookie.set('access_token', json_data.response.access_token, { 
                            expires: 30, // expires in 30 days
                            path: '/'   // set the cookie to be accessible from all paths
                        });

                        const userLogin = lowerUserName && typeof lowerUserName === 'string' && lowerUserName.length > 0 ? lowerUserName.split('@') : null;
 
                        const adminName = json_data.response.associated_users.find((user,index) => user.username === lowerUserName)

                        const subscription = json_data.response.associated_places.map((place,index) => { 
                            return (
                                place?.configurations?.daksh_subscription ? place.configurations?.daksh_subscription : "--"
                            )
                        })

                        const associatedPlaces = res.data.response.associated_places;

                        const hospitalName =  res.data.response.associated_places[0].name;

                        const expiryTime  = res.data.response.associated_places[0].expiry_time;

                        const hospitalID = res.data.response.associated_places[0].id;

                        let fetalAlgorithmEnabled = null;

                        // Iterate through the array to find the object with the `fetal_algorithm_enabled` key
                        for (let place of associatedPlaces) {
                            if (place.configurations && place.configurations.hasOwnProperty('fetal_algorithm_enabled')) {
                                fetalAlgorithmEnabled = place?.configurations?.fetal_algorithm_enabled;
                                break;
                            }
                            // if(place.hasOwnProperty('name')){
                            //     hospitalName = place.name;
                            //     break;
                            // }

                            // console.log(hospitalName)
                        }

                        const data = {
                            user_name: adminName && adminName?.first_name ? adminName.first_name : "--",
                            subscription_type: subscription && subscription.length > 0 && subscription && subscription[0] === 'FREE'  ? "BASIC" 
                                               : subscription && subscription.length > 0 && subscription[0] ? subscription[0] 
                                               :  "--",
                            account_type :  userLogin && userLogin[1] === 'daksh.janitri' ? 'Premium' 
                                            : userLogin && userLogin[1] === 'daksh.live'  ? 'Basic' 
                                            : userLogin && userLogin[1] === 'daksh.si' ? 'Daksh SI' 
                                            // : userLogin && userLogin[1] === 'daksh.navam' || 'DAKSH.NAVAM' ? 'Navam' 
                                            : null,
                        }

                        const cookieData = {
                            user_name: data.user_name,
                            account_type: data.account_type,
                            fetal_distress_algorithm: fetalAlgorithmEnabled,
                           
                        };

                        const hospitalDetail = {
                            hospitalName:hospitalName,
                            hospitalID:hospitalID,
                            expiryTime:expiryTime,
                            subscription_type: data.subscription_type,
                        };

                        Cookie.set('data', JSON.stringify(cookieData), {
                            expires: 30, // expires in 30 days
                            path: '/', // set the cookie to be accessible from all paths
                        });

                        Cookie.set('hospital_detail', JSON.stringify(hospitalDetail), {
                            expires: 365, // expires in 365 days
                            path: '/', // set the cookie to be accessible from all paths
                        });

                        const cookies_data = Cookie.get('data');

                        if (cookies_data) {
                            const account_type = cookies_data.account_type;
                            setAccountType(account_type); 
                        } 

                        // data.account_type && ['Daksh SI','Basic', 'Navam'].includes(data.account_type)
                        if(data.account_type && ['Daksh SI','Basic'].includes(data.account_type)){
                            navigate('/dashboard/report')
                        }
                        else{
                            navigate('/dashboard/analytics')
                        }

                        setIsLoading(false)
                    }
                }).catch((err) => {
                    console.log("Login API fail",err)
                    setError("Invalid Credentials")
                    setIsError(true)
                    setIsLoading(false)
                    setAccountType()
                })
            } else {
                setOpen(true)
            }
        } else {
            setOpen(true)
        }
    }

    const togglePassword = () => {
        let password = document.getElementById('formPassword')
        if(password.type==="password")
            password.type="text"
        else
            password.type="password"
        setVisibility(!visibility)
    }

    const handleEmailKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          if (passwordInputRef.current) {
            passwordInputRef.current.focus();
          }
        }
    };

    return (
        <div>
            <div className="center">

                <div className="top-left-logos">
                     <img src="./logos/cdcso-image.svg"  alt="CDSCO"  className="left-corner-login-image"/>
                     <img src="./logos/iso-image.svg"  alt="ISO " className="left-corner-login-image"/>
                </div>

                <Dialog open={open} className='dialog-login'>
                    <div className="dialog">
                        <div className="invalid-credential-div" style={{alignmentBaseline:`baseline`}}>
                            <img src = "./error.png" alt="Error" className='error-img' />
                            <p className="normal-text"> Error: To proceed to the login page please allow <span className="bold"> 'Notifications' </span> and then click <span className="bold"> 'Reload'</span> to continue.</p>
                        </div>

                        <div className='step-boxs'>
                            <div className='box'>
                                <p className="text">Step 1</p>
                            </div>
                            <img src = "./Arrow.svg" alt="Error" className='arrow-img'/>

                            <div className='box'>
                                <p className="text">Step 2</p>
                            </div>

                            <img src = "./Arrow.svg" alt="Error" className='arrow-img'/>

                            <div className='box'>
                                <p className="text">Step 3</p>
                            </div>
                        </div>

                        <div className='gif-box'>
                            <div className='gif'>
                                <p className="text">Click the icon</p>
                                <img className = "inactive-gif" src={`frame1.svg`} alt="Step-1"/>
                            </div>
                            <div className='gif'>
                                <p className="text">Turn ON the notification toggle</p>
                                <img className = "inactive-gif" src={`frame2.svg`} alt="Step-2"/>
                            </div>
                            <div className='gif'>
                                <p className="text">Click on Reload</p>
                                <img className = "inactive-gif" src={`frame3.svg`} alt="Step-3"/>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className='login-form'>

                    <img src="janitri-registered-logo.svg" loading="lazy" alt="Janitri Logo" className="login-janitri-logo"/>

                    <p className="sub-title">Your Pregnancy and Newborn Monitoring Partner</p>

                    { isError &&
                        <div className="invalid-credential-div" style={{alignmentBaseline:`baseline`, marginTop:`-1.2rem`, padding:`0.5rem 3rem`, marginBottom:`0.5rem`}}>
                            <img src = "./error.png" alt="Invalid Credentials" className='error-img' width={30} height={30}/>
                            <p className="normal-text" style={{textAlign: `center`, fontSize: `1.25rem`, color:`red`}}>{error}</p>
                        </div>
                    } 

                    <div  className='form-content' style={{textAlign: `center`}}>
                        <div className="rounded-1">
                            <form onSubmit={handleLoginSubmit} style={{display: `flex`, flexDirection: `column`}}>
                                <label className="login-input-label">User ID</label>
                                <div className="passowrd-div">
                                    <input
                                        id="formEmail"
                                        name="email"
                                        type="text"
                                        autofocus
                                        value={username}
                                        onChange={handleEmailChange}
                                        onKeyPress={handleEmailKeyPress}
                                        placeholder="Enter your User ID"
                                        className="login-input-field"
                                        style={{width:`100%`}}
                                    />
                                </div>

                                <label className="login-input-label">Password</label>
                                <div className="passowrd-div">
                                    <input
                                        id="formPassword"
                                        name="password"
                                        type={visibility ? "text" : "password"} 
                                        autofocus
                                        value={password}
                                        ref={passwordInputRef}
                                        onChange={handlePasswordChange}
                                        placeholder="Enter your Password"
                                        className="login-input-field"
                                        style={{width:`100%`}}
                                      
                                    />
                                     { visibility ?
                                        <img className='passowrd-visible' src="/Unhide.svg" width={25} height={25} alt=' Password Visible' style={{ zIndex: 1100, padding: `1rem`}} onClick={togglePassword} />
                                        :
                                        <img className='passowrd-visible' src="/Hide.svg" width={25} height={25} alt='Password Not Visible' style={{ zIndex: 1100, padding: `1rem`}} onClick={togglePassword} />
                                     }
                                </div>

                                {/* <a href='/forgot-password' style={{textAlign: `right`, pointerEvents: `none`, opacity: 0}}>
                                    <p style={{color: `#A0A0A0`,textDecoration: `underline`, textDecorationColor: `#A0A0A0`}}>Forgot your password?</p>
                                </a> */}

                                <button type='submit' className='login-button'>
                                    {isLoading ? 'Logging in...' : 'Log In'}
                                </button>
                                
                            </form>
                        </div>
                    </div>

                </div>
                
                <div className="partner-and-supporter">Partners & Supporters</div>

                <div className="logo-carousel">
                    <div className="logo-track">
                        {
                            logos.map((logo, index) => (
                
                                <div className="logo-item" key={index}>
                                    <img src={logo.image} alt={`Logo ${index + 1}`} />
                                  
                                </div>
                        )
                        )}
                        
                    </div>
                </div>
               

            </div>
        </div>

        
    )
}

export default Login

                     

                        





