import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { getReadableDateOnly, getReadableTime } from '../../../utils/converter';
import {useLocation, useParams } from "react-router-dom";
import './devicedata.scss'
import KeyarGraph from './keyarGraph';
import NoData from '../../common/NoData';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import SIGraph from './SIGraph';


const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      backgroundColor: '#FBF8FF', // Set your custom background color
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeviceGraphs = ({open, setOpen, handleClose, deviceData, deviceName, loading, error, basicDetail, otherDetail, placeDetail}) => {

    //const navigate =  useNavigate();
    const {patientID} = useParams();
    const location = useLocation()
    const data = location.state;
    const [activeIndex, setActiveIndex] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)


    const showGraph = (index) => {
        setActiveIndex(index)
        setIsPlaying(false)
    }

    const getReadableTimeOnly = (date_string) => {
        if(date_string === 'undefined' || date_string == '' || parseInt(date_string) == 253402300799 || parseInt(date_string) == 0){
            return '-';
        } else if(String(date_string).length == 13){
            return moment(date_string).format('hh:mm A');
        } else {
            return moment(date_string*1000).format('hh:mm A');
        }
    }

    const handleReload = () => {
        window.location.reload();
    }
    
  return (

    <CustomDialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div className='device-file-header'>
            <div className='patient-information'>
               
                  <img className = "back-arrow" src= "/back_arrow.svg" alt='' onClick = {() => handleClose()} />
                  <div className='update'>
                    <img style={{width:`20px`,height:`20px`, marginLeft: `30px`}} src="/update.svg " alt="update" onClick = {() => handleReload()}/>
                    <p className='update_time'>Last Updated: {getReadableTime(data.last_update_time)}</p>
                  </div>
               </div>
              <button className="button" disabled={true}> View Case Sheet </button>
            
        </div>
        {   
            loading ? 
                <Loading/>
            : 
                error ? 
                    <div className='no-data'>
                        <Reload/>
                    </div>
                
            : 
                deviceData && deviceData.length > 0 ?
                    
                    <div className='graph-components'>
                        <div className='device-section-sidebar' >
                            <p className='nst-traces'>{deviceName == 'Keyar DT' || deviceName == 'Keyar CM' || deviceName == 'Keyar Echo' ? "NST Traces": "OSI Trends" }</p>
                            
                            {
                                deviceData.map((data, index) => (
                                    <div key={index} className={activeIndex === index ? 'active-text' : 'inactive-text'} onClick={() => showGraph(index)}>
                                        <img src={activeIndex === index ? "/calendar-icon.svg" : '/inactive-calendar.png'} alt="Calendar Icon" />
                                        <p>{getReadableDateOnly(data.data_time)}</p>
                                        <p>{getReadableTimeOnly(data.data_time)}</p>
                                    </div>
                            ))}
                            
                        </div>

                        <div className='right-graph-component'>

                            

                            {
                                deviceName == 'Keyar DT' || deviceName == 'Keyar CM' || deviceName == 'Keyar Echo' ?
                                    <KeyarGraph data={deviceData[activeIndex]}  basicDetail={basicDetail} placeDetail={placeDetail} otherDetail={otherDetail} deviceName={deviceName} isPlaying={isPlaying} setIsPlaying={setIsPlaying}/>
                                :
                                deviceName == 'Daksh SI' ? 
                                    <SIGraph data={deviceData} deviceName={deviceName}/>
                                :
                                <div/>
                            }

                        </div>

                    </div>
                    
            : 
                <div className='no-data'>
                    <NoData/>
                </div>
        } 
    </CustomDialog>

  )
}

export default DeviceGraphs
