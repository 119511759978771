import React, { useEffect, useState, useRef } from 'react'
import DetailCard from '../../components/centralMonitorCards/Cards/DetailCard'
//import { handleNotificationEvent } from '../../utils/notificationhandler'
import { connect } from 'react-redux';
import { addKeyarData } from '../../redux/features/central_monitor/keyarData';
import { addRealTimeBasicDetail } from '../../redux/features/central_monitor/basic_detail';
import axios from 'axios';
import urls from '../../utils/url';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import Cookie from 'js-cookie';
import { isJson } from '../../utils/converter';
import Cookies from "js-cookie";


const CentralMonitor = ({addKeyarData}) => {

  const cookie = Cookie.get('access_token')
  // console.log(cookie)
  const [prevKeyarData, setPrevKeyarData] = useState([])
  const [basicDetail, setBasicDetail] = useState([])
  const [loading, setLoading] = useState(false)
  const [isMonitoring, setIsMonitoring] = useState(false)
  const [delay, setDelay] = useState(5000);
  const [fetchDataPerSecsEnum, setfetchDataPerSecsEnum] = useState(5)
  const [currentDelayIndex, setCurrentDelayIndex] = useState(0);  
  const [hasReloaded, setHasReloaded] = useState(false);
  const [logs, setLogs] = useState([]);

  const handleNotificationEvent = (e) => {
    
    const dataContent = JSON.parse(e.data.payload.content)
    //console.log(dataContent)
    if(dataContent.type == 'patient_keyar_data'){
      if ((e.data.payload != null && e.data.payload != undefined) && (dataContent !== null && dataContent != undefined)){
        let data = JSON.parse(e.data.payload.content)
        if(data.content.message == 'keyar_test_started'){
          setTimeout(() => {
            setIsMonitoring(true)
          }, fetchDataPerSecsEnum*1000);
          
        }

        //console.log(data.content, data.message)
        // let temp_keyarparams= data.content.keyar_params
        // let basic_detail = data.content.basic_detail
        // let pa_pv_details = data.content.pa_pv_examination
        // let keyarparams = []
        // let basicdetails = []
        // let papvdetails = []
        // basicdetails = [...basicdetails, data.content]
        // if (Array.isArray(temp_keyarparams) && temp_keyarparams.length > 0){
        //   keyarparams = temp_keyarparams.reduce((accumulator, obj) => {
        //     const foundObject = accumulator.find(item => item.param_type === obj.param_type);
        //     if (!foundObject) {
        //       accumulator.push(obj);
        //     }
        //     return accumulator;
        //   }, []);
        // }

        //ADD BASIC DETAIL AND UPDATE IT WHENEVER THE VALUES CHANGES FROM NOTIFICATION
        // for (let i in basicdetails){
        //   let patientID = basicdetails[i].patient_id

        //   let basic_detail = basicdetails.map(obj => {
        //       return {
        //         name : data.content.basic_detail.name,
        //         age : data.content.basic_detail.age,
        //         ga : data.content.pa_pv_examination.gestation_period,
        //         patientID: patientID
        //       }
        //   })


        //   let temp_basic_data = basicDetail
        //   if(!(basicDetail.map(obj => obj[0].patientID).includes(patientID))){
        //     let tempbasicdata = [...basicDetail, basic_detail]
        //     setBasicDetail(tempbasicdata)
        //     addRealTimeBasicDetail(tempbasicdata)
        //   }
        // }
        // console.log(basicDetail)


        //ADD LIVE KEYAR DATA AND UPDATE IT WHENEVER THE VALUES CHANGES FROM NOTIFICATION
        // for (let i in keyarparams) {
        //   let patientID = keyarparams[i].patient_id
        //   let keyar_data = []
        //   if (keyarparams != undefined && keyarparams != null){
        //     keyar_data = keyarparams.map((data, key) => {
        //       return{
        //         param: data.param_type,
        //         value: Object.values(data?.data_set?.values || []).toString(),
        //         dataset: data.data_set.values,
        //         metadata: data.meta_data
        //       }
        //     })
        // }
        //   let prev_keyar_data = prevKeyarData
        //   //ADD NEW PATIENT'S KEYAR DATA
        //   if(!(prevKeyarData.map(obj => obj.patientID).includes(patientID))){
        //     let temp_keyar_data = [...prevKeyarData, {patientID, keyar_data, 'isNotificationComing' : true}]
        //     setPrevKeyarData(temp_keyar_data)
        //     //window.localStorage.setItem('keyarData', JSON.stringify(temp_keyar_data))
        //     addKeyarData(temp_keyar_data)
        //   }

      //     //UPDATE THE DATA WHENEVER NEW NOTIFICATION COMES
      //     else if (prevKeyarData.map(obj => obj.patientID).includes(patientID)){
      //       let updated_keyar_data = [...prevKeyarData]
      //       for (let i = 0; i < prevKeyarData.length; i++) {
      //         if (prevKeyarData[i].patientID === patientID) {
      //           let keyar_data_to_update = [{patientID, keyar_data, 'isNotificationComing' : true}]
      //           setPrevKeyarData(keyar_data_to_update)

      //           if(keyarData.length > 1){
      //             return
      //           } else {
      //             addKeyarData(keyar_data_to_update)
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

    }}

  }

  // useEffect(() => {
  //   fetchEnum()
  // },[])

  useEffect(() => {
    let intervalId;
    fetchLatestKeyarData()
    
    if (isMonitoring){
      intervalId = setInterval(fetchLatestKeyarData, 10000); // Calls every 10 seconds
    } 


    return () => clearInterval(intervalId);
    
  },[isMonitoring])




  useEffect(() => {
    if (!isMonitoring) {
      const timerId = setTimeout(fetchLatestKeyarData, delay);
      return () => clearTimeout(timerId);
    }
  }, [isMonitoring, delay, currentDelayIndex]);

  const fetchLatestKeyarData = async () => {
    const startTime = new Date();
    try {
      const res = await axios.get(`${urls.BETA_URL}/tesla/live-tests-summary`,
      {
        headers: {
        'Request-ID': 'allow|me',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      })
        const json_data = isJson(res.data)
        const data = json_data.response
        
        if (data && data.live_tests == undefined){
          updateDelay(); 
          setIsMonitoring(false)
          addKeyarData([])   
        } else{
          setIsMonitoring(true)
          resetDelay()
          const patient_keyar_data = data.live_tests
          const keyar_data = Object.values(patient_keyar_data).map(obj => {
            return {
              patientName : obj.patient_name,
              keyarData : obj.keyar_data_sets[0].keyar_params,
              patientID: obj.keyar_data_sets[0].patient_id
            }
          })
          addKeyarData(keyar_data)
        }
      //logRequest(res.config.url, startTime, null, res);

      } catch(err) {
        //logRequest(null, startTime, err);

        console.log("Central Monitor API Failed",err)
        
        updateDelay()
        setLoading(false)
      }
  }

  const updateDelay = () => {
    const delaysInSecs = [5, 5, 10, 15, 30, 60, 2*60, 5*60, 10*60, 15*60, 30*60, 60*60];
    let nextDelayIndex = currentDelayIndex + 1;

    if (nextDelayIndex >= delaysInSecs.length) {
        nextDelayIndex = delaysInSecs.length - 1; // Cap the index to the max value
    }

    setCurrentDelayIndex(nextDelayIndex);
    const nextDelay = delaysInSecs[nextDelayIndex] * 1000;
    setDelay(nextDelay);

  };

  const resetDelay = () => {
    setDelay(5000); // Reset to initial delay of 5 seconds
    setCurrentDelayIndex(0); // Reset index
  };

  //console.log(logs)

  const exportLogs = () => {
    const csvContent = "data:text/csv;charset=utf-8," + logs.map(e => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    saveAs(encodedUri, "logs.csv");
  };

  // const logRequest = (url, startTime, error = null, response = null) => {
  //   const endTime = new Date();
  //   const duration = endTime - startTime;
  //   const log = {
  //     url: url || (error?.config?.url || 'Unknown URL'),
  //     startTime: startTime.toLocaleTimeString(),
  //     endTime: endTime.toLocaleTimeString(),
  //     duration: `${duration} ms`,
  //     status: error ? error.response?.status : response.status,
  //     success: !error
  //   };
  //   setLogs(prevLogs => [...prevLogs, log]);
  // };

 

  
  // const fetchEnum = async () => {
  //   await axios.get(`${urls.BETA_URL}/tesla/app-enums/?source_app=Daksh&categories=keyar&`,
  //     {
  //       headers: {
  //       'Request-ID': 'allow|me',
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${cookie}`
  //     }
  //     }).then((res) => {
  //       console.log(res.data.response.enums)
  //     }).catch((err) => {
  //       console.log("Enum API Failed",err)
  //       //fetchLatestKeyarData()
  //       setLoading(false)
  //       //setError("Invalid Credentials")
  //       //setIsLoading(false)
  //     })
  // }


    navigator.serviceWorker.onmessage = ((e) => {
      handleNotificationEvent(e)
    })
  

  return (
    <div style={{background: '#0E0E0E',  margin: 0, padding: 0, height:`100vh`}}>
      <DetailCard/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  keyarData: state.keyarData.keyarData,
  liveBasicDetail: state.liveBasicDetail.liveBasicDetail
});

const mapDispatchToProps = {
  addKeyarData,
  addRealTimeBasicDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(CentralMonitor)
