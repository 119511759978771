import React, { useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  ListItemText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check"; // Green checkmark icon

const TraceThicknessDropdown = () => {
  const [selectedValue, setSelectedValue] = useState(1.25); // Default to 1.25
  const [isUserSelected, setIsUserSelected] = useState(false); // Track if the user has made a selection

  const options = ["1.00", "1.25", "1.35", "1.45", "1.50"];

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setIsUserSelected(true); // Update that user has made a selection
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 2,
      }}
    >
      <FormControl
        variant="outlined"
        sx={{
          minWidth: 150,
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            borderColor: "#D3D3D3",
            "&.Mui-focused": {
              borderColor: "#6D28D9",
            },
          },
        }}
      >
        
        <Select
          value={selectedValue}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) =>
            !isUserSelected ? "Trace Thickness" : selected.toFixed(2) // Show placeholder until user selects a value
          }
          sx={{
            "& .MuiSelect-select": {
              padding: "10px 12px",
              fontSize: "14px",
              color: isUserSelected ? "black" : "gray", // Gray for placeholder
            },
          }}
        >
          {options.map((value) => (
            <MenuItem
              key={value}
              value={parseFloat(value)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ListItemText primary={value} />
              {parseFloat(value) === selectedValue && (
                <CheckIcon sx={{ color: "green" }} />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TraceThicknessDropdown;
