import React,{useEffect, useState} from 'react';
import { Scatter } from 'react-chartjs-2';
import '../osi/osiCommon.scss';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsHeatmap from "highcharts/modules/heatmap";
import axios from 'axios';
import urls from '../../utils/url';
import moment from 'moment';
import {useParams } from "react-router-dom";
import {getReadableDateOnly, isJson } from '../../utils/converter';
import PatientHistoryCard from './patientHistoryCard'
import Loading from '../common/loading';
import Reload from '../common/Reload';
import NoData from '../common/NoData';
import {useLocation } from "react-router-dom";
import Cookie from 'js-cookie';

HighchartsHeatmap(Highcharts);

const OsiGraph = () => {

  const cookie = Cookie.get('access_token')
  const {patientID} = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [osiData, setOsiData] = useState([]);
  const [threshold, setThreshold] = useState();
  const [osiValue, setOsiValues] = useState(); // in the form of [[timestamp,osivalue]] and using it to plot graph
  const [firstTime, setFirstTime] = useState();
  //const [lastTime, setLastTime] = useState();
  const [tickPositions, setTickPositions] = useState([])
  const [osiStatistics, setOsiStatistics] = useState([]);
  const [isActive, setIsActive] = useState(0);
  const location = useLocation()
  const data = location.state;
 
  var chartMarginLeft = 70;
  var chartMarginRight = 40;
  var chartMarginTop = 60;
  var chartMarginBottom = 60;
  var legendFont = 10;
  var itemMargin = 2;

  function getTickPositions(data_time) {
    // maximum 24 hours
    var positions = [];
   
    // 30 minutes difference between 2 tick positions
    for(var i = 0; i <= 48; i++){
        positions.push(data_time + (i*1800*1000));
    }
    return positions;
}

function getStep(){
  var width = window.screen.width;
  var step = 1 + parseInt((1280 - width));
  if(step < 1 ){
      step = 1;
  }
  return step;
}

const handleClick = (index) => {
  setIsActive(index);
};

useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=labor_data`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        if(res.data){
          const json_data = isJson(res.data)
          const response = json_data.response[0].labor_data
          const sorted_data = response.sort((a, b) => (a.data_time > b.data_time) ? 1 : -1); // sorting the data time in ascending order
          setOsiData(sorted_data);
          setLoading(false) 
        }
      }).catch((err) => {
        console.log("Error in Fetching Data",err)
        setError(true)
        setLoading(false)
      }
    )
  },[patientID])

const getLastOsiValue = (value) => {
  const timestampIntegers = value.map(entry => parseInt(entry[0]));

  // Check if there is data
  if (timestampIntegers.length === 0) {
    return { latestTimestamp: null, latestValue: null };
  }

  // Find the maximum timestamp
  const latestTimestamp = Math.max(...timestampIntegers);

  // Find the index of the latest timestamp
  const latestIndex = timestampIntegers.indexOf(latestTimestamp);

  // Check if the index is valid
  if (latestIndex === -1 || latestIndex >= value.length) {
    return { latestTimestamp: null, latestValue: null };
  }

  // Extract the value corresponding to the latest timestamp
  const latestValue = value[latestIndex][1];

  return { latestTimestamp, latestValue };
}

useEffect (() => {
  
  Highcharts.setOptions({
    global: {
      useUTC: false
    }
  });

  //Filtering Threshold Value
  const thresholdValue = osiData.filter(obj => obj.data_type === "osi_threshold")
  const osiThresholdValue = thresholdValue.map(obj => [parseInt(moment(obj.data_time * 1000).format('x')), parseFloat(obj.param_1)])
  const { latestTimestamp, latestValue } = getLastOsiValue(osiThresholdValue)
  setThreshold(latestValue ? latestValue : '0.8') // settings default value of threshold to 0.8

  // Filtering BP Data 
  const bpData = osiData.filter(obj => obj.data_type === "bp")
  const systolic_bp = bpData.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)]) // in the form of [[data_time,param_1(systolic bp)],[data_time,param_1(systolic bp)],..] 
  
  // Filtering Pulse Data 
  const pulseData = osiData.filter(obj => obj.data_type === "pulse" )
  const temp_pulse = pulseData.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)]) // in the form of [[data_time,param_1(pulse)],[data_time,param_1(pulse)],..]

  systolic_bp.sort((a, b) => a[0] - b[0]);   //sort systolic bp data based on order of data_time
  temp_pulse.sort((a, b) => a[0] - b[0]);   //sort pulse data based on order of data_time
  
  // OSI calculation formula (pulse / bp)
  const osi_value = systolic_bp.map(systolic => {
    const pulse = temp_pulse.find(pulse => pulse[0] === systolic[0]);

    if (pulse) {
      const calculatedResult = (pulse[1]/systolic[1]).toFixed(1);
      const roundedResult = Math.round(calculatedResult * 10) / 10;
      return [systolic[0]*1000, roundedResult];
    } 
    else {
        
        return [systolic[0], null]; // Handle the case when a corresponding pulse data is not found
    }
    
  });

  setOsiValues(osi_value);

  const firstTickPosition = osi_value && osi_value.length > 0 ? (osi_value[0][0]) : null;
  setFirstTime(firstTickPosition)
  setTickPositions(getTickPositions(firstTickPosition))

  // code for removing duplicate pair of timestamp and value from "osi_value"
  const uniqueOsiData = [];
  const uniqueOsiValues = new Set();
  osi_value.forEach((arr) => {
    const key = arr.toString();
  
    if (!uniqueOsiValues.has(key)) {
      uniqueOsiValues.add(key);
      uniqueOsiData.push(arr);
    }
  });
  
  setOsiValues(uniqueOsiData);

  // OSI Statistics
  const osiStatistics = uniqueOsiData.map(osi => {
    const pulse = temp_pulse ? temp_pulse.find(pulse => pulse[0]*1000 === osi[0]): null;
    const systolic = systolic_bp.find(systolic => systolic[0]*1000 === osi[0]);
  
    const osi_value = osi[1];
    const pulseValue = pulse ? pulse[1] : null;
    const systolicValue = systolic ? systolic[1] : null;

    return{
      systolic_bp: systolicValue,
      pulse_rate : pulseValue,
      time: osi[0],
      osi_value: osi_value
    }
  });

  setOsiStatistics(osiStatistics)

},[osiData])


const osiGraph = {
  chart: {
      type:'line',
      marginLeft: chartMarginLeft,
      marginRight:chartMarginRight,
      marginTop: chartMarginTop,
      marginBottom:chartMarginBottom,
      scrollablePlotArea: {
        minWidth: 2500,
        scrollPositionX: -1
      },
      borderRadius:12,// graph border radius
      height:470, // height of chart

      
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
        align: 'right',
        alignTo: 'spacingBox'
    }
  },

  title: {
    text: null
  },

  xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        hour: '%H:%M', // Format for hours and minutes
      },
      scrollbar: {
        enabled: true  // Enable scrollbar
      },
      title: {
        text: null
      },
      min: tickPositions[0],
      max: tickPositions.slice(-1).pop(),
      tickPositions: tickPositions,
      labels: {
          formatter: function () {
            return Highcharts.dateFormat('%H:%M ', this.value);
          },
          step: getStep(),
      },
      gridLineColor: 'rgba(0, 0, 0, 0.1)',
      gridLineWidth: 1.1,
      gridLineDashStyle: 'Dash',
      lineColor: '#A163F1',
      lineWidth: 1,
  },

  yAxis: {
      title: {
        text: null
      },
      tickInterval: 10,
      gridLineColor: 'rgba(0, 0, 0, 0.1)',
      gridLineWidth: 1.1,
      gridLineDashStyle: 'Dash',
      lineColor: '#A163F1',
      lineWidth: 1,
      min: 0,
      max: 2,
      plotLines: [
          {
              color: 'red',
              width: 1,
              value: threshold,
              dashStyle: 'Dash'
          },
        
      ],
      tickPositioner: function () {
          var positions = [],
          i = 0;
          while(i <= 2){
              positions.push(i);
              i+=0.2;
          }
          const roundedNumber = positions.map(obj => parseFloat(obj.toFixed(2)))
          return roundedNumber;
      }
  },
  
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    itemMarginTop: itemMargin,
    itemMarginBottom: itemMargin,
    zIndex: 20,
    backgroundColor : '#FFFFFF',
    floating : true,
  },

  series: [
    {
      name: 'OSI Value',
      data: osiValue ? osiValue.map(([x, y],index) => ({
        x,
        y,
        marker: {
          fillColor: y > threshold ? 'red' : 'green',
        },
      })) : [],
      lineWidth: 1.2,
      color: 'black', // Set the default line color to black
      marker: {
        enabled: true,
        radius: 4, // Set the radius of the marker
        
      },
    }
  ],
 
}

 return (
    <div className='osi-info-section'>
      {
        loading ? <div className="loading-div" style={{paddingLeft:`0%`, marginTop:`13rem`}}><Loading/></div>
        : error ? <Reload/>
        : osiValue && osiValue.length > 0 ? 
          <>
            <div className='graph-section' >
              
              
              <div className='osi-info-header'>
                <p className='heading text-1'>OSI Trends</p>
                <hr className='hr-line'/>
                <p className='heading text-1 time-text'> {firstTime ? getReadableDateOnly(firstTime) : '--'}</p>  
              </div>

            {
              osiValue && osiValue.length ? 

                <div className='graph-container'>
                  
                    <div className='graph-header'>
                      <p className='graph-heading'>OSI Trends</p>
                      <div className='graph-info'>
                          <img src = '/threshold.svg' alt='Threshold' />
                          <div style={{paddingTop:`0.8rem`}}>
                            <p className='axis-text'>X-axis = <span className='axis-value'>Time</span></p>
                            <p className='axis-text'>Y-axis = <span className='axis-value'>OSI</span></p>
                          </div>
                      </div>
                    </div>

                    <HighchartsReact
                      highcharts={Highcharts}
                      options={osiGraph}
                      constructorType={'chart'}
                      allowChartUpdate={true}  
                    /> 

                </div> 
                : <> </>
            }

            </div>

            <div className='card-section'>
                <p className='patient-text'> Patient History </p>
                <hr className='hr-line'/>
                
                {
                  osiStatistics && osiStatistics.length > 0 ? osiStatistics.map((object,index) => {
                    return (
                      <div key={index} className={isActive === index ? 'active-card' : 'card'} onClick={() => handleClick(index)} >
                        <PatientHistoryCard index={index} time={object.time} osi_value={object.osi_value} systolic_bp={object.systolic_bp} pulse_rate={object.pulse_rate} ></PatientHistoryCard>
                      </div>
                    )}) 
                    : <></>
                }
              </div>
        
          </> 
        : <div style={{paddingTop:`60px`, paddingLeft:`8%`}} ><NoData/></div>
        }   
    </div>  
    )
}

export default OsiGraph
