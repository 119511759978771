import moment from "moment";
import { useState } from 'react';
import patientDetailFieldMap from '../../src/utils/fieldMap'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// set the charLimit to check the Tool Tip Condition 
export const charLimit = 20;

var valueNotAvailable = '-';

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 16,
      fontFamily: 'Poppins',
      border: '0.5px solid #A163F1', 
    },
  }));

  export const useTooltip = () => {

    const [toolTipOpen, setToolTipOpen] = useState(false);
  
    const handleTooltipOpen = () => {
      setToolTipOpen(true);
    };
  
    const handleTooltipClose = () => {
      setToolTipOpen(false);
    };
  
    return {
      toolTipOpen,
      handleTooltipOpen,
      handleTooltipClose,
    };
};

export const TooltipComponent = ({ title, content }) => {

    const { toolTipOpen, handleTooltipOpen, handleTooltipClose } = useTooltip();
  
    return (
      <ClickAwayListener disableOnClickAway={!toolTipOpen}>
        <LightTooltip title={title} arrow placement="top" TransitionComponent={Zoom} open={toolTipOpen} onClose={handleTooltipClose}>
          <p onClick={handleTooltipOpen}> {content} </p>
        </LightTooltip>
      </ClickAwayListener>
    );
};

// This Function is used apply Tool Tip on the text based on Character Limit
export const limitStr = (text, charLimit, className) => (
    <span className={className}>
      {text.length > charLimit ? `${text.slice(0, charLimit)}..` : text}
      {text.length > charLimit && (
        <span style={{ color: '#A163F1', cursor: 'pointer', fontSize:`14px` }}>Read More</span>
      )}
    </span>
);
  

// Function to convert time string to Time only (hh:mm A)
export const getReadableTimeOnly = (date_string) => {

    if(date_string === 'undefined' || date_string == '' || parseInt(date_string) == 253402300799 || parseInt(date_string) == 0){
        return '-';
    } else if(String(date_string).length == 13){
        return moment(date_string).format('hh:mm A');
    } else {
        return moment(date_string*1000).format('hh:mm A');
    }
}

// Function to convert time string to Date and Time (DD MMM YYYY hh:mm A)
export const getReadableTime = (date_string) => {
    if(date_string === 'undefined' || date_string == '' || parseInt(date_string) == 253402300799 || parseInt(date_string) == 0){
        return '-';
    } else if(String(date_string).length == 13){
        return moment(date_string).format('DD MMM YYYY hh:mm A');
    } else {
        return moment(date_string*1000).format('DD MMM YYYY hh:mm A');
    }
}

// Function to convert time string to Date only (DD MMM YYYY)
export const getReadableDateOnly = (date_string) => {
    if(date_string === 'undefined' || date_string == '' || parseInt(date_string) == 253402300799 || parseInt(date_string) == 0){
        return '-';
    } else if(String(date_string).length == 13){
        return moment(date_string).format('DD MMM YYYY');
    } else {
        return moment(date_string*1000).format('DD MMM YYYY');
    }
}

 export const getSentenceCase = (text) => {

    if(typeof(text) === 'undefined' || text == '' || text == null) {
        return "-";
    } else if(text.toLowerCase() == "na"){
        return "NA";
    } 
    return text.replace(/\w\S*/g, function(str){
        return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    });
}

// To check if the object is undefined or defined
export const checkUndefined = (object, value) => {
    const result = object?.[value];

    if (result !== undefined) {
        return result;
    } else {
        return '-';
    }
};

// Function to manipulate data of referral details of a patient
export const getReferralDetails = (events, places) => {

    var refer_details = [];
    events.forEach((value) => {
        if (value['event'] === 'referred_before_delivery' || value['event'] === 'referred_after_delivery') {
            var data = {};
            var refer_message_mother = [];
            var refer_message_child = [];
            // Separating Child and Mother Referral Reasons
            value['refer_messages'].forEach((message, inner_index) => {
                
                if(message['refer_type'].includes("MOTHER")){
                    refer_message_mother.push(message['refer_message'])}
                else{
                    refer_message_child.push(message['refer_message'])} 
            });

            data['refer_message_child'] = refer_message_child;
            data['refer_message_mother'] = refer_message_mother;
            data['event_id'] = value['id'];
            data['patient_id'] = value?.patient_id;
            data['referred_place_id'] = value?.refer_detail?.referred_place_id;
            data['place_id'] = value?.place_id;
            data['referred_place_name'] = places?.[data['referred_place_id']]?.name || '-';
            data['event_time'] = value['event_time'] * 1000 
            
            var referral_facility_other = value?.refer_detail?.referred_place_other;
            
            if (referral_facility_other !== '' && data['referred_place_name'] === '-') {
                data['referred_place_name'] = referral_facility_other;
            }
            
            data['place_name'] = places?.[data['place_id']?.toString()]?.name || '-';
            refer_details.push(data);
        }
    });

    return refer_details;
};

// To Transform Strings from (ex. patient_data to Patient Data)
export const transformText = (string) => {
    var updatedString = string.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
    return updatedString;
  };

// To Transform Date String in to format of (ex. 19 - Oct - 2023)
 export  const formatDate = (dateString) => {
    const months = {
        'Jan': 'Jan', 'Feb': 'Feb', 'Mar': 'Mar', 'Apr': 'Apr', 'May': 'May', 'Jun': 'Jun',
        'Jul': 'Jul', 'Aug': 'Aug', 'Sep': 'Sep', 'Oct': 'Oct', 'Nov': 'Nov', 'Dec': 'Dec'
    };

    const [day, month, year] = dateString.split(' ');
    const formattedDate = `${day} - ${months[month]} - ${year}`;
    return formattedDate;
};

export const getPostPartumData = (post_labor_data) => {
   
    var context = {
        'mother': {},
        'child': [],
        
    };
    context['mother'] = getMotherPostPartumData(post_labor_data.mother);
    context['child'] = [];
    var i = 0;

    for(var child_id in post_labor_data.child){
        var child_context = getChildPostPartumData(post_labor_data['child'][child_id]);
        child_context['child_no'] = (i+1).toString();
        child_context['child_id'] = child_id;
        context['child'].push(child_context);
        i = i + 1;
    }
    return context;
  }

  export const getMotherPostPartumData = (mother_data) => {
    var response = { 'data_times': [], 'data_values': [] };
    var data_times = [];
    var data_values = [];

    var mother_params = {
        'mother_pulse': 'Pulse Rate (Per Minute)',
        'mother_bp': 'Blood Pressure (mm Hg)',
        'mother_temperature': 'Temperature (F)',
        'mother_breast': 'Breasts',
        'mother_bleeding': 'Bleeding P/V',
        'mother_episiotomy_tear': 'Episiotomy/Tear',
        'mother_uterine_tone': 'Uterine Tone'
    };

    var time_based_post_partum_data = {};

    mother_data.forEach((value) => {
        if (!time_based_post_partum_data[value.data_type]) {
            time_based_post_partum_data[value.data_type] = {};
        }
        time_based_post_partum_data[value.data_type][value.data_time] = value;
        if (!data_times.includes(value.data_time)) {
            data_times.push(value.data_time);
        }
    });
    data_times.sort();

    for (var key in mother_params) {
        let row = [];
        row.push(mother_params[key]);
        data_times.forEach((time) => {
            if ( key == 'mother_bp' && time_based_post_partum_data?.mother_blood_pressure_systolic?.[time.toString()] !== undefined && time_based_post_partum_data?.mother_blood_pressure_diastolic?.[time.toString()] !== undefined ) {
                row.push(time_based_post_partum_data['mother_blood_pressure_systolic'][time]['param_1'] + '/' + time_based_post_partum_data['mother_blood_pressure_diastolic'][time]['param_1']);
            } else if (typeof(time_based_post_partum_data?.[key]?.[time.toString()]) !== 'undefined') {
                row.push(getSentenceCase(time_based_post_partum_data[key][time]['param_1']));
            } else {
                row.push('-');
            }
        });
        data_values.push(row);
    }

    var readable_data_times = [];
    data_times.forEach((value) => {
        readable_data_times.push(getReadableTime(value));
    });

    response['data_times'] = readable_data_times;
    response['data_values'] = data_values;
    response['data_exists'] = readable_data_times.length;
    return response;
  };

  export const getChildPostPartumData = (child_data) => {
    var response = { 'data_times': [], 'data_values': [] };
    var data_times = [];
    var data_values = [];

    var child_params = {
        'child_urine_passed': 'Urine Passed',
        'child_stool_passed': 'Stool Passed',
        'child_activity': 'Activity',
        'child_umbilical_stump': 'Umbilical Stump',
        'child_sucking': 'Sucking',
        'respiratory_rate': 'Respiratory Rate',
        'child_temperature': 'Temperature (F)',
        'child_jaundice': 'Jaundice',
    };

    var time_based_post_partum_data = {};

    child_data.forEach((value) => {
        if (!time_based_post_partum_data[value.data_type]) {
            time_based_post_partum_data[value.data_type] = {};
        }
        time_based_post_partum_data[value.data_type][value.data_time] = value;
        if (!data_times.includes(value.data_time)) {
            data_times.push(value.data_time);
        }
    });
    data_times.sort();

    for (var key in child_params) {
        let row = [];
        row.push(child_params[key]);

        data_times.forEach((time) => {
            if (typeof(time_based_post_partum_data?.[key]?.[time.toString()]) !== 'undefined') {
                row.push(getSentenceCase(time_based_post_partum_data[key][time]['param_1']));
            } else {
                row.push('-');
            }
        });
        data_values.push(row);
    }

    var readable_data_times = [];
    data_times.forEach((value) => {
        readable_data_times.push(getReadableTime(value));
    });

    response['data_times'] = readable_data_times;
    response['data_values'] = data_values;
    response['data_exists'] = readable_data_times.length;
    return response;
  }

  export const getLaborDataValue = (data) => {

    switch(data.data_type){     

        case 'bp':
            return data.param_1 + ' / ' + data.param_2;

        case 'contraction':
            let durationStep = getContractionDurationStep(data.param_2);
            let duration
            if(durationStep == 1) {
                duration = '<20 seconds';
            } else if(durationStep == 2) {
                duration = '20-40 seconds';
            } else {
                duration = '>40 seconds';
            }
            return data.param_1 + ' / ' + duration;

        case 'oxytocin':
            return data.param_1 + ' / ' + data.param_2;

        case 'drugs':
            return data.param_1 + ' / ' + data.param_2 + ' / ' + data.param_3;

        default:
            return data.param_1;

    }
}

export const getContractionDurationStep = (param2) => {
    var step = (parseInt(param2)/20) + 1;
    if (step > 3) {
        step = 3;
    }
    return step;
}

export const isJson = (string) => {
    try{
        const jsonString = string.replace(/NaN/g, '"NaN"');
        return JSON.parse(jsonString);
    }catch (e){
       return string;
    }
}

export const getContext = (data, formType) => {

    var context = {};
    patientDetailFieldMap[formType].map((value,index) => {
        var dataValue = '-';
        if (value['type'] == 'string'){
            dataValue = getSentenceCase(data[formType][value['attribute']]);
        } else if (value['type'] == 'datetime'){
            dataValue = getReadableTime(data[formType][value['attribute']]);
        } else if (value['type'] == 'date'){
            dataValue = getReadableDateOnly(data[formType][value['attribute']]);
        } else if(value['type'] == 'integer'){
            dataValue = getData(data[formType][value['attribute']]);
        } else if(value['type'] == 'boolean'){
            dataValue = data[formType][value['attribute']] ? 'Yes' : 'No';
        } else if(value['type'] == 'enum'){
            dataValue = data[formType][value['attribute']] ? value['mapping'][data[formType][value['attribute']]] : '-';
        }
        context[value['attribute']] = dataValue;
    });
    return context;
}


export const getData = (param) => {
    if (typeof(param) === 'undefined' || param == ""){
        return valueNotAvailable;
    } else {
        return param;
    }
}

// Death cause code for Child (Delivery Info) conversion to their respective values
export const convertChildCode = (codesString) => {
    // Mapping of codes to their corresponding values
    const codeValues = {
      CODE_ASPHYXIA: 'Asphyxia',
      CODE_LOW_BIRTH_WEIGHT: 'Low Birth Weight',
      CODE_PREMATURITY: 'Prematurity',
      CODE_SEPSIS: 'Sepsis',
      CODE_JAUNDICE: 'Jaundice',
      CODE_CONGENITAL_HEART_DISEASE: 'Congenital Heart Disease',
      CODE_PNEUMONIA: 'Pneumonia',
    };
  
   
    const valuesArray = codesString.split(',').map(code => codeValues[code] || code).join(', ');
    return valuesArray;
};

//Death cause code for Mother (Delivery Info) conversion to their respective values
export const convertMotherCode = (codesString) => {
    // Mapping of codes to their corresponding values
    const codeValues = {
        CODE_PPH: 'Postpartum Haemorrhage (PPH)',
        CODE_APH: 'Antepartum Haemorrhage (APH)',
        CODE_GESTATIONAL_DIABETES_MELLITUS: 'Gestational Diabetes Mellitus',
        'CODE_PIH/PRE_ECLAMPSIA': 'PIH/Pre Eclampsia',
        CODE_SEPSIS: 'Sepsis',
        CODE_ECLAMPSIA: 'Eclampsia',
        CODE_ANEMIA: 'Anemia',
    };

    const valuesArray = codesString.split(',').map(code => codeValues[code] || code).join(', ');
    return valuesArray;
};


export const getChildStatusString = (deliveryDetail) => {
    
    let aliveChildCount = 0;
    let deadChildCount = 0;

    if (deliveryDetail && deliveryDetail.child_details) {
        deliveryDetail.child_details.forEach(child => {
            if (child.child_status === "live birth") {
                aliveChildCount++;
            } 
            if(child.child_status === 'fresh still birth' || child.child_status === 'dead' || child.child_status === 'abortion' || child.child_status === 'macreated still birth') {
                deadChildCount++;
            }
        });
    }

    let statusString = " ";
    if (aliveChildCount > 0) {
        statusString += `Alive - ${aliveChildCount}`;
    }
    if (deadChildCount > 0) {
        statusString += `${aliveChildCount > 0 ? ", " : " "} Dead - ${deadChildCount}`;
    }

    return statusString;
  }
