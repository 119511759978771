import React, { useCallback } from 'react'
import "./analytics.scss";
import {useState, useEffect} from 'react';
import axios from 'axios';
import urls from '../../utils/url';
import { connect } from 'react-redux';
import { isJson } from '../../utils/converter';
import Cookie from 'js-cookie';
import Loading from '../../components/common/loading';
import Reload from '../../components/common/Reload';
import { LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import LocalDatePicker from '../../components/common/datepicker';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import {getReadableDateOnly} from '../../utils/converter';
import Filter from '../../components/common/filter';
import IconButton from '@mui/material/IconButton';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  }));
  
const StyledChip = styled(Chip)({
    borderRadius: '500px',
    border: '1px solid #C7C7C7',
    background: '#F8F3FF',
    height: '1.875rem',
    flexShrink: 0,
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 300,
    textTransform: 'capitalize',
    color: '#707070',
  });
  
  const StyleChip = styled(Chip)({
    borderRadius: '500px',
    border: '1px solid #C7C7C7',
    background: '#fff',
    height: '1.875rem',
    flexShrink: 0,
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 300,
    textTransform: 'capitalize',
    color: '#707070',
  });

const AntenatalDose = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: '#DDCDF1',
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
      backgroundColor: '#A163F1',
    },
}));

const ImmunizationDose = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: '#FFE5F0',
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
      backgroundColor: '#FF8FB1',
    },
}));

const FemaleSexRatio = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: '#FFE5F0',
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
      backgroundColor: '#FF8FB1',
    },
}));

const MaleSexRatio = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: '#DDCDF1',
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
      backgroundColor: '#A163F1',
    },
}));

export const AntenatalProgressWithLabel = ({ value }) => {
    const getProgressValue = (type) => {
      const total = (value?.none?.count || 0) + (value?.one_dose?.count || 0) + (value?.two_dose?.count || 0);
    
      return value?.[type]?.count && total ? (value[type].count / total) * 100 : 0;
    };
  
    const getCountValue = (type) => {
      return value?.[type]?.count || 0;
    };
  
    return (

      <div className="antenatal-progress-container" >

           
            <div className="analytics-card-text alignment" >None</div>
            <div style={{ width: '18.75rem' }}>
                <div className="outer-linear-progress">
                    <AntenatalDose variant="determinate" value={getProgressValue('none') } />
                </div>
            </div>
            <div className="analytics-card-figure alignment">{getCountValue('none')}</div>
        

    
            <div className="analytics-card-text alignment">One Dose</div>
            <div style={{ width: '100%' }}>
                <div className="outer-linear-progress">
                    <AntenatalDose variant="determinate" value={getProgressValue('one_dose')} />
                </div>
            </div>
            <div className="analytics-card-figure alignment">{getCountValue('one_dose')}</div>
        

    
            <div className="analytics-card-text alignment">Two Dose</div>
            <div style={{ width: '100%' }}>
                <div className="outer-linear-progress">
                    <AntenatalDose variant="determinate" value={getProgressValue('two_dose')} />
                </div>
            </div>
            <div className="analytics-card-figure alignment">{getCountValue('two_dose')}</div>

      </div>
    );
};

export const ImmunizationWithLabel = ({ value }) => {

    const getProgressValue = (type) => {
      const total = (value?.BCG?.count || 0) + (value?.['Hep B']?.count || 0) + (value?.['OPV -0']?.count || 0) + (value?.['Vitamin K1']?.count || 0);
      return value?.[type]?.count && total ? (value[type].count / total) * 100 : 0;
    };
  
    const getCountValue = (type) => {
      return value?.[type]?.count || 0;
    };
  
    return (

      <div className="immunization-progress-container" >

        <div className="analytics-card-text alignment"> BCG </div>
        <div style={{ width: `50rem` }}>
            <div className="outer-linear-progress">
                <ImmunizationDose variant="determinate" value={getProgressValue(['BCG'])} />
            </div>
        </div>
        <div className="analytics-card-figure alignment">{getCountValue(['BCG'])}</div>

    
        <div className="analytics-card-text alignment"> OVP-0 </div>
        <div style={{ width: `50rem` }}>
            <div className="outer-linear-progress">
                <ImmunizationDose variant="determinate" value={getProgressValue(['Hep B'])} />
            </div>
        </div>
        <div className="analytics-card-figure alignment">{getCountValue(['Hep B'])}</div>
    

    
        <div className="analytics-card-text alignment"> Hep B </div>
        <div style={{ width: '50rem' }}>
            <div className="outer-linear-progress">
            <ImmunizationDose variant="determinate" value={getProgressValue(['OPV -0'])} />
            </div>
        </div>
        <div className="analytics-card-figure alignment">{getCountValue(['OPV -0'])}</div>
    


        <div className="analytics-card-text alignment"> Vitamin K1 </div>
        <div style={{ width: '50rem' }}>
            <div className="outer-linear-progress">
                <ImmunizationDose variant="determinate" value={getProgressValue(['Vitamin K1'])} />
            </div>
        </div>
        <div className="analytics-card-figure alignment">{getCountValue(['Vitamin K1'])}</div>

      </div>
    );
};

export const SexRatioWithLabel = ({ value }) => {

    const getProgressValue = (type) => {
      const total = (value?.male?.count || 0) + (value?.female?.count || 0);
      return value?.[type]?.count && total ? (value[type].count / total) * 100 : 0;
    };
  
    const getCountValue = (type) => {
      return value?.[type]?.count || 0;
    };
  
    return (

      <div className="antenatal-progress-container" >

           
            <div className="analytics-card-text alignment" >Male</div>
            <div style={{ width: '18.75rem' }}>
                <div className="outer-linear-progress">
                    <MaleSexRatio variant="determinate" value={getProgressValue('male')} />
                </div>
            </div>
            <div className="analytics-card-figure alignment">{getCountValue('male')}</div>
        
            <div className="analytics-card-text alignment" >Female</div>
            <div style={{width: '18.75rem' }}>
                <div className="outer-linear-progress">
                    <FemaleSexRatio variant="determinate" value={getProgressValue('female')} />
                </div>
            </div>
            <div className="analytics-card-figure alignment">{getCountValue('female')}</div>
        
      </div>
    );
};


const Analytics = ({configuration}) => {
  
  const cookie = Cookie.get('access_token')
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [error, setError] = useState(false);
  const [value1, setValue1] = useState(); 
  const [value2, setValue2] = useState();
  const [analyticData, setAnalyticData]= useState({});
  const [isFiltered, setIsFiltered] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [placeDetails, setPlaceDetails] = useState([]); // State to store the data from the backend
  const [hasFiltersBeenApplied, setHasFiltersBeenApplied] = useState(false);
  const [places, setPlaces] = useState([]);
  const [placeID, setPlaceID] = useState('');
  const [openEndDateCalender, setOpenEndDateCalender] = useState(false);
  const [showHelper, setShowHelper] = useState(false); 

  const toValue = Math.floor(value2?.$d.getTime()/1000);
  const fromValue =  Math.floor(value1?.$d.getTime()/1000);
 
  const [availableFilters, setAvailableFilters] = useState({
    'State' : [],
    'District': [],
    'Taluka/Block': [],
    'Facility': []
  })

  const [filters, setFilters] = useState({
    State: 'All',
    District: 'All',
    'Taluka/Block': 'All',
    Facility: 'All'
  });

  const handleStartDateChange = (newValue) => {
    setValue1(newValue);
    if (newValue) {
      setOpenEndDateCalender(true); // Open the end date picker when a start date is selected
      setShowHelper(true);
    }
  };

  const handleEndDateChange = (date) => {
    setValue2(date); // Set end date
    setOpenEndDateCalender(false); // Close end date picker once a date is selected
    setShowHelper(false);
  };
  
  const handleActiveButton = (ind) => {
    setActiveIndex(ind);
  }

  const updateAvailableFilters = (changedFilter, selectedValue) => {
    // Depending on which filter was changed, update the other filters' options
    let newAvailableFilters = {...availableFilters};
  
    if (changedFilter === 'State') {
      newAvailableFilters.District = ['All', ...removeDuplicate(placeDetails.filter(place => selectedValue === 'All' || place.state === selectedValue).map(place => place.district))];
      newAvailableFilters['Taluka/Block'] = ['All']; // Reset to 'All'
      newAvailableFilters.Facility = ['All']; // Reset to 'All'
    } else if (changedFilter === 'District') {
      newAvailableFilters['Taluka/Block'] = ['All', ...removeDuplicate(placeDetails.filter(place => selectedValue === 'All' || place.district === selectedValue).map(place => place.taluk))];
      newAvailableFilters.Facility = ['All']; // Reset to 'All'
    } else if (changedFilter === 'Taluka/Block') {
      newAvailableFilters.Facility = ['All', ...removeDuplicate(placeDetails.filter(place => selectedValue === 'All' || place.taluk === selectedValue).map(place => place.name))];
    }
    // Finally, set the new available filters
    setAvailableFilters(newAvailableFilters);
  };

  const clearSelectedFilters = (categoryToDelete, valueToDelete) => {
    if (categoryToDelete in filters) {
      setFilters(prevFilters => {
        if (prevFilters[categoryToDelete] === valueToDelete) {
          const { [categoryToDelete]: removed, ...rest } = prevFilters;
          return { ...rest, [categoryToDelete]: 'All' };
        }
        return prevFilters;
      });
    }
    if (categoryToDelete === 'dateFilters'){
      setValue1(null)
      setValue2(null)
    }

    const dateFiltered = value1 != null || value2 != null;
    //console.log(dateFiltered, 'date');
    //console.log(selectedOptionsFiltered, 'type')
    const filtersFiltered = Object.values(filters).some(value => value !== 'All' && value !== '');
    // console.log(filtersFiltered, 'filters')
    // console.log(dateFiltered || selectedOptionsFiltered || filtersFiltered, 'final')
    setIsFiltered(dateFiltered || filtersFiltered)

    const activeFilters = checkIfFiltersAreActive();
    if (!activeFilters){
      setHasFiltersBeenApplied(false);
      setIsFiltered(false);
    }

    handleApplyFilters();
    
  };

  useEffect(() => {
    const states = ['All', ...removeDuplicate(placeDetails.map(place => place.state))];
    const districts = ['All', ...removeDuplicate(placeDetails.map(place => place.district))];
    const talukas = ['All', ...removeDuplicate(placeDetails.map(place => place.taluk))];
    const facilities = ['All', ...removeDuplicate(placeDetails.map(place => place.name))];
    
    setAvailableFilters({
      State: states,
      District: districts,
      'Taluka/Block': talukas,
      Facility: facilities
    });
  }, [placeDetails]);

  const removeDuplicate = (data) => {
    const temp_arr = data.filter((value, index, self) => {
      return value !== '' && self.indexOf(value) === index;
    });
    return [...temp_arr]
  }

  const clearAllFilters = () => {
    // Set all keys in filters to 'All'
    setFilters(prevFilters => {
      let resetFilters = {};
      Object.keys(prevFilters).forEach(key => {
        resetFilters[key] = 'All';
      });
      return resetFilters;
    });
    
    setValue1(null)
    setValue2(null)
    setIsFiltered(false);
    handleReload();
  }

  const fetchPlaceIDs = useCallback(async () => {

    if (loading) {
        return;
    }
    setLoading(true);

    await axios.get(`${urls.BETA_URL}/website/places/`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        if(res.data){
            const json_data = isJson(res.data)
            setPlaces(json_data.response)
            const associate_place = json_data.response
            setPlaceDetails(associate_place)
            let place_ids;
            if (isFiltered){
              // Process response to fetch reports based on new places
              const filteredData = associate_place.filter(place => {
                return (filters.State === 'All' || filters.State === place.state) &&
                      (filters.District === 'All' || filters.District === place.district) &&
                      (filters['Taluka/Block'] === 'All' || filters['Taluka/Block'] === place.taluk) &&
                      (filters.Facility === 'All' || filters.Facility === place.name);
              });
              place_ids = filteredData.map(obj => obj.id).toString();
              
            } else {
              place_ids = associate_place.map(obj => obj.id).toString()

            }
            setPlaceID(place_ids)
            fetchAnalyticsData(place_ids);
        }
      }).catch((err) => {
        setPlaceID('')
        setError(true)
        setLoading(false)
      }) 
  },[filters, isFiltered, cookie, toValue,fromValue])
  
  useEffect(() => {
    fetchPlaceIDs();
  }, [fetchTrigger]);


  const handleFilterChange = (filterType, selectedValue) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: selectedValue
    }));

    updateAvailableFilters(filterType, selectedValue);
  };

  const checkIfFiltersAreActive = () => {
    const dateFiltered = value1 != null && value2 != null;
    const filtersFiltered = Object.values(filters).some(value => value !== 'All' && value !== '');
    return dateFiltered || filtersFiltered;
  };
  
  useEffect(() => {
    const active = checkIfFiltersAreActive();
    setIsFiltered(active);
  },[filters, fromValue, toValue])
  

  const fetchAnalyticsData = useCallback(async (placeIDs) => {

    setLoading(true);

    let events = '';

    if (isFiltered) {
        events = `from_time=${isNaN(fromValue) ? '' : fromValue}&to_time=${isNaN(toValue) ? `${''}` : toValue}&`;
    }

    await axios.get(`${urls.BETA_URL}/api/v2/dashboard-data/?place_ids=${placeIDs}&${isFiltered ? events : '&'}`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        if(res.data){
            const json_data = isJson(res.data)
            setAnalyticData(json_data);
            setLoading(false);
        }
      }).catch((err) => {
        console.log("Error in Fetching Data",err)
        setLoading(false);
        setError(true);
      }
    )
  },[fromValue, toValue, isFiltered, value1, value2])


  const handleApplyFilters = () => {
    setIsFiltered(true); // This will trigger the effect to fetch reports with filters
    setFetchTrigger(t => !t); // Toggle the state to force useEffect to run
  };

  const handleReload = () => {
    window.location.reload();
  }


  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
 
  return (
    <>
     { 
            error ? <Reload/>
        : 
            <div className='analytics'>

                <div className='search-filter-div'>
                        <div className="search-filter-analytics" >

                            <div className='filter-button'>
                                <img src={"/filter_icon.svg"} alt="Select Filter" className='filter-icon'/>
                                <p className='filter-text'>Select Filters</p>
                            </div> 

                            <ExpandMore expand={isExpanded} onClick={handleExpandClick} aria-expanded={isExpanded} aria-label="show more">
                            
                                <img src="/down_arrow.png" alt="arrow" style={{ borderRadius: '100%', border:`1px solid #2C2C2C`, padding :`0.5rem` }} className='down-arrow'  onClick={() => handleExpandClick()}/>
                        
                            </ExpandMore>
                        
                        </div>

                        {
                            isFiltered ? 
                                <div className='selected-filters'>
                                    <StyledChip label='Clear All' onDelete={clearAllFilters} />
                                    {
                                        !isNaN(fromValue) && !isNaN(toValue) ?
                                        <StyleChip label={`${getReadableDateOnly(fromValue)} - ${getReadableDateOnly(toValue)}`} onDelete={() => clearSelectedFilters('dateFilters', null)} />
                                        : 
                                        null
                                    }
                                    {
                                        filters && (
                                            Object.entries(filters).map(([key, value]) =>
                                            { 
                                            return (value !== 'All' && value !== '') ? (
                                                <StyleChip  label={`${key}: ${value}`} onDelete={() => clearSelectedFilters(key, value)} />
                                            ) : null
                                            }
                                        ))
                                    }

                                </div>
                            : 
                                <div/>
                        }
                 

                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>

                        <img src="/hr-long.svg" alt="hr" style={{width: '98%', margin:`auto`}}/>

                        <div className='filter-button-div'>
                            <button className={activeIndex === 0 ? 'active-filter-button' : 'inactive-filter-button'}
                              onClick={() => handleActiveButton(0)}>
                                Filter by Date
                            </button>

                            <button className={activeIndex === 1 ? 'active-filter-button' : 'inactive-filter-button'} 
                              onClick={() => handleActiveButton(1)} disabled={value1 && !value2}>
                                Filter by Place
                            </button>
                        </div>

                        <div className="filter-container">
                        {
                            activeIndex === 0 ? 
                            <div className='container' >
                                {showHelper && <p className="helper-text">Please select the End Date</p>}
                                <div className='date-filter-container' style={{marginTop:`1.25rem`}}>
                                <div className='date-picker'>
                                    <p className='text1'> Start Date </p> 
                                    <LocalDatePicker 
                                    value={value1} 
                                    setValue={handleStartDateChange}
                                    />
                                </div>

                                <div className="date-picker">              
                                    <p className="text1">End Date</p>
                                    <LocalDatePicker
                                    value={value2}
                                    setValue={handleEndDateChange}
                                    open={openEndDateCalender}
                                    onClose={() => setOpenEndDateCalender(false)}
                                    minDate={value1}
                                    />
                    
                                </div>
                                </div>
                            </div> 
                            : 
                            <></> 
                        }
                    
                        { 
                            activeIndex === 1 ? 
                            Object.keys(availableFilters).map((filterType,key) => (
                                <Filter key={key} heading={filterType} options={availableFilters[filterType]} onFilterChange={handleFilterChange} selectedValue={filters[filterType]}  />
                            )) 
                            : 
                            null
                        }

                        </div>

                        <div className='apply-filter-container'>
                            <button disabled={!isFiltered} className={isFiltered ? 'apply-button' : 'inactive-apply-button'} onClick={() => handleApplyFilters()}>Apply & Search</button>
                        </div>

                    </Collapse>

                </div>
                
                {
                    loading ? <div style={{display:`flex`,alignItems:`center`,justifyContent:`center`}}> <Loading/> </div>
                    :
                    <div className='analytics-section'>

                        { Object.keys(analyticData || []).length > 0 &&  analyticData != undefined  &&
                            <>

                                <div className='analytics-page'>

                                    <div className='delivery-section'>
                                            <div className="analytics-legend">
                                                Deliveries
                                            </div>
                                            
                                            <div className='first-delivery-column'>

                                                <div className= "delivery-center">

                                                    <div className='delivery-form-card'>
                                                            <img src="/analytics/delivery_form.svg"  className='delivery-image' />
                                                        
                                                                <p className="covid-figure">{analyticData.total_deliveries || 0}</p>
                                                                <p className="covid-text"> Total Delivery Forms Filled</p>
                                                       
                                                    </div>
                                                        
                                                    <div className='analytics-info-card'>
                                                        <img src="/analytics/deliveries_partograph.png" className='analytic-image' />
                                                        <div>
                                                            <p className='analytics-card-figure'>{analyticData.partograph_generated?.count || 0}</p>
                                                            <p className='analytics-card-text'>Partograph Generated</p>
                                                        </div>
                                                
                                                    </div>
                                                </div>

                                                <div className="delivery-column-grid">
                                                    
                                                    <div className='analytics-info-card' >
                                                        <img src="/analytics/normal_deliveries.png"   className='analytic-image' />
                                                        <div >
                                                            <p className='analytics-card-figure'>{analyticData.delivery_type?.normal?.count || 0}</p>
                                                            <p className='analytics-card-text'>Normal Deliveries</p>
                                                        </div>
                                                    </div>
                                                    <div className='analytics-info-card' >
                                                        <img src="/analytics/vaccum_deliveries.png"  className='analytic-image' />
                                                        <div>
                                                            <p className='analytics-card-figure'>{analyticData.delivery_type?.vacuum?.count || 0}</p>
                                                            <p className='analytics-card-text'>Vaccum Deliveries</p>
                                                        </div>
                                                    </div>
                        
                                                    <div className='analytics-info-card' >
                                                        <img src="/analytics/c_section.png"  className='analytic-image' />
                                                        <div >
                                                            <p className='analytics-card-figure'>{analyticData.delivery_type?.c_section?.count || 0}</p>
                                                            <p className='analytics-card-text'>C-Section Generated</p>
                                                        </div>
                                                    </div>
                                                    <div className='analytics-info-card' >
                                                        <img src="/analytics/high_risk_pregnancies.png"  className='analytic-image' />
                                                        <div >
                                                            <p className='analytics-card-figure'>{analyticData.complications?.high_risk?.count || 0}</p>
                                                            <p className='analytics-card-text'>High-Risk Pregnancies</p>
                                                        </div>
                                                    </div>
                                                
                                                    <div className='analytics-info-card' >
                                                        <img src="/analytics/day_deliveries.png"  className='analytic-image' />
                                                        <div >
                                                            <p className='analytics-card-figure'>{analyticData.delivery_time?.day?.count || 0}</p>
                                                            <p className='analytics-card-text'>Day Deliveries</p>
                                                        </div>
                                                    </div>
                                                    <div className='analytics-info-card'>
                                                        <img src="/analytics/night_deliveries.png"  className='analytic-image'/>
                                                        <div >
                                                            <p className='analytics-card-figure'>{analyticData.delivery_time?.night?.count || 0}</p>
                                                            <p className='analytics-card-text'>Night Deliveries</p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </div>

                                    </div>

                                    <div className='family-planning-section'>

                                        <div className='delivery-section'>
                                            <div className="analytics-legend">
                                                Family Planning
                                            </div>

                                            <div className='family-column'>

                                                <div className= "delivery-center">
                                                    <div className="family-circle-container" >
                                                        <div className="family-circle">
                                                        <div className="inner-family-circle">
                                                            <img src="/analytics/parents.svg"  className='analytic-image' />
                                                            <p className="covid-figure">{analyticData.family_planning_method?.total?.count || 0}</p>
                                                            <p className="covid-text">Total Family Planning</p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="family-palnning-div">
                                                    
                                                    <div className='family-planning-card'>
                                                        <p className='analytics-card-figure'>{analyticData.family_planning_method?.PPIUCD?.count || 0}</p>
                                                        <p className='analytics-card-text'>PPIUCD</p>
                                                
                                                    </div>

                                                    <div className='family-planning-card' >
                                                        <p className='analytics-card-figure'>{analyticData.family_planning_method?.other?.count || 0}</p>
                                                        <p className='analytics-card-text'>Other</p>
                                                    </div>
                                                </div>

                                            </div>
                                                
                                        </div>

                                        <div className='steroids-death-div'>
                                            <div className='delivery-section'>
                                                <div className="analytics-legend">
                                                Antenatal Steroids
                                                </div>

                                                <div className="progress-container">
                                                    <AntenatalProgressWithLabel value={analyticData.antenatal_steroid} />
                                                </div>
                                            </div>

                                            <div className='delivery-section'>
                                                <div className="analytics-legend">
                                                    Neonatal Deaths & Still Birth Reasons
                                                </div>

                                                <div className="neonatal-grid">
                                                        <div className="neonatal-center">
                                                            <div className="neonatal-square">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.child_death?.prematurity?.count || 0}</div>
                                                            </div>
                                                            </div>
                                                            <div className="child-birth-label">Prematurity</div>
                                                        </div>

                                                        <div className="neonatal-center">
                                                            <div className="neonatal-square">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.child_death?.sepsis?.count || 0}</div>
                                                            </div>
                                                            </div>
                                                            <div className="child-birth-label">Sepsis</div>
                                                        </div>

                                                        <div className="neonatal-center">
                                                            <div className="neonatal-square">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.child_death?.asphyxia?.count || 0}</div>
                                                            </div>
                                                            </div>
                                                            <div className="child-birth-label">Asphyxia</div>
                                                        </div>

                                                        <div className="neonatal-center">
                                                            <div className="neonatal-square">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{(analyticData.child_death?.other?.count + analyticData.child_death?.pneumonia?.count + analyticData.child_death?.congenital_heart_disease?.count + analyticData.child_death?.jaundice?.count + analyticData.child_death?.low_birth_weight?.count) || 0}</div>
                                                            </div>
                                                            </div>
                                                            <div className="child-birth-label">Other</div>
                                                        </div>
                                                </div>

                                            </div>
                                        </div>
                                    
                                    </div>
                                    
                                    <div className='family-planning-section'>
                                        <div className='delivery-section' >
                                                <div className="analytics-legend">
                                                    Maternal Deaths
                                                </div>
                                                
                                                <div className='maternal-death-column'>

                                                    <div className="deliveries-circle-container">
                                                        <div className="family-circle " style={{background: `linear-gradient(40deg, #F9ABAB, #FFFFFF)`, }}>
                                                            <div className="inner-family-circle ">
                                                                <img src="/analytics/total_maternal_deaths.png"/>
                                                                <p className="covid-figure">{analyticData.mother_status?.dead?.count || 0}</p>
                                                                <p className="covid-text">Total Maternal Deaths</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="delivery-column-grid">
                                                        
                                                        <div className='analytics-info-card' >
                                                            <img src="/analytics/death_tag.png"   className='analytic-image' />
                                                            <div >
                                                                <p className='analytics-card-figure'>{analyticData.mother_death?.pph?.count || 0}</p>
                                                                <p className='analytics-card-text'>PPH</p>
                                                            </div>
                                                        </div>
                                                        <div className='analytics-info-card' >
                                                            <img src="/analytics/death_tag.png"  className='analytic-image' />
                                                            <div>
                                                                <p className='analytics-card-figure'>{analyticData.mother_death?.aph?.count || 0}</p>
                                                                <p className='analytics-card-text'>APH</p>
                                                            </div>
                                                        </div>
                            
                                                        <div className='analytics-info-card' >
                                                            <img src="/analytics/death_tag.png"  className='analytic-image' />
                                                            <div >
                                                                <p className='analytics-card-figure'>{analyticData.mother_death?.gestational_diabetes_mellitus?.count || 0}</p>
                                                                <p className='analytics-card-text'>Gestational Diabetes</p>
                                                            </div>
                                                        </div>
                                                        <div className='analytics-info-card' >
                                                            <img src="/analytics/death_tag.png"  className='analytic-image' />
                                                            <div >
                                                                <p className='analytics-card-figure'>{analyticData.mother_death?.pih_pre_eclampsia?.count || 0}</p>
                                                                <p className='analytics-card-text'>PIH/Pre-Eclampsia</p>
                                                            </div>
                                                        </div>
                                                    
                                                        <div className='analytics-info-card' >
                                                            <img src="/analytics/death_tag.png"  className='analytic-image' />
                                                            <div >
                                                                <p className='analytics-card-figure'>{analyticData?.mother_death?.sepsis?.count || 0}</p>
                                                                <p className='analytics-card-text'>Sepsis</p>
                                                            </div>
                                                        </div>
                                                        <div className='analytics-info-card'>
                                                            <img src="/analytics/death_tag.png"  className='analytic-image' />
                                                            <div >
                                                                <p className='analytics-card-figure'>{analyticData.mother_death?.other?.count || 0}</p>
                                                                <p className='analytics-card-text'>Other</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                </div>

                                        </div>
                                    </div>
                                
                                    <div className='family-planning-section'>

                                        <div className='delivery-section'>

                                            <div className="analytics-legend">
                                                Child Births
                                            </div>

                                            <div className='family-column'>

                                                <div className="child-birth-grid">
                                                    <div className="child-birth-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.child_status?.live_birth?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Live Births</div>
                                                    </div>

                                                    <div className="child-birth-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.gestation_type?.pre_term?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Preterm Deliveries</div>
                                                    </div>

                                                    <div className="child-birth-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.birth_weight?.less_than_2500?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Low Birth Weight</div>
                                                    </div>

                                                    <div className="child-birth-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.resuscitated?.yes?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Babies Resuscitated</div>
                                                    </div>

                                                    <div className="child-birth-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.breastfeeding_within_one_hour?.yes?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Breastfed Within 1 Hour</div>
                                                    </div>

                                                    <div className="child-birth-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.phototherapy_used?.yes?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Phototherapy Used</div>
                                                    </div>

                                                    <div className="neonatal-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.child_status?.dead?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Neonatal Deaths</div>
                                                    </div>

                                                    <div className="child-birth-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.child_status?.fresh_still_birth?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Fresh Still Births</div>
                                                    </div>

                                                    <div className="child-birth-center">
                                                        <div className="child-birth-square ">
                                                            <div className="inner-neonatal-square">
                                                                <div className="child-birth-figure">{analyticData.child_status?.macreated_still_birth?.count || 0}</div>
                                                            </div>
                                                        </div>
                                                        <div className="child-birth-label">Macreated Still Births</div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        
                                        <div className='delivery-section'>
                                            <div className="analytics-legend">
                                                Sex Ratio at Birth
                                            </div>

                                            <div className="progress-container">
                                                <div className="sex-ratio-circle">
                                                    <div className="inner-sex-ratio-circle" style={{
                                                    backgroundImage: `conic-gradient(
                                                        #DDCDF1 ${((analyticData.gender?.male?.count / (analyticData.gender?.male?.count + analyticData.gender?.female?.count)) * 100)}%,
                                                        #FFE5F0 ${((analyticData.gender?.female?.count / (analyticData.gender?.male?.count + analyticData.gender?.female?.count)) * 100)}%
                                                    )`
                                                    }}></div>
                                                    <p className="analytics-card-figure">Total: {analyticData.gender?.male?.count  + analyticData.gender?.female?.count}</p>
                                                </div>
                                            
                                                <SexRatioWithLabel value={analyticData.gender} />
                                            </div>
                                        </div>
                                    

                                    </div>

                                    <div className='family-planning-section'>
                                        <div className='delivery-section'>
                                            <div className="analytics-legend">
                                                Immunization
                                            </div>

                                            <div className="progress-container">
                                                <ImmunizationWithLabel value={analyticData.immunization} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                            </>
                        }

                    </div>
                }

            </div>
     }
    </>
  )
}


const mapStateToProps = (state) => ({
    //configuration: state.configuration.configuration,
});

export default connect(mapStateToProps)(Analytics)


