const logos = [
    {
        id:1,
        image:'/logos/Clinical Partners/Asset 79@4x.png'
    },
    {
        id:2,
        image:'/logos/Clinical Partners/Asset 80@4x.png'
    },
    {
        id:3,
        image:'/logos/Clinical Partners/Asset 81@4x.png'
    },
    {
        id:4,
        image:'/logos/Clinical Partners/Asset 82@4x.png'
    },
    {
        id:5,
        image:'/logos/Clinical Partners/Asset 83@4x.png'
    },
    {
        id:6,
        image:'/logos/Clinical Partners/Asset 84@4x.png'
    },
    {
        id:7,
        image:'/logos/Clinical Partners/Asset 85@4x.png'
    },
    {
        id:8,
        image:'/logos/Clinical Partners/Asset 86@4x.png'
    },
    {
        id:9,
        image:'/logos/Clinical Partners/Asset 87@4x.png'
    },
    {
        id:10,
        image:'/logos/Supporters/Asset 89@4x.png'
    },
    {
        id:11,
        image:'/logos/Supporters/Asset 90@4x.png'
    },
    {
        id:12,
        image:'/logos/Supporters/Asset 91@4x.png'
    },
    {
        id:13,
        image:'/logos/Supporters/Asset 92@4x.png'
    },
    {
        id:14,
        image:'/logos/Supporters/Asset 96@4x.png'
    },
    {
        id:15,
        image:'/logos/Supporters/Asset 97@4x.png'
    },
    {
        id:16,
        image:'/logos/Supporters/Asset 98@4x.png'
    },
    {
        id:17,
        image:'/logos/Supporters/Asset 99@4x.png'
    },
    {
        id:18,
        image:'/logos/Supporters/Asset 100@4x.png'
    },
    {
        id:19,
        image:'/logos/Supporters/Asset 101@4x.png'
    },
    {
        id:20,
        image:'/logos/Supporters/Asset 102@4x.png'
    },
    {
        id:21,
        image:'/logos/Supporters/Asset 103@4x.png'
    },
    {
        id:22,
        image:'/logos/Supporters/Asset 104@4x.png'
    },
    {
        id:23,
        image:'/logos/Supporters/Asset 106@4x.png'
    },
    {
        id:24,
        image:'/logos/Supporters/Asset 107@4x.png'
    },
    {
        id:25,
        image:'/logos/Supporters/Asset 108@4x.png'
    },
    {
        id:26,
        image:'/logos/Supporters/Asset 109@4x.png'
    },
    {
        id:27,
        image:'/logos/Supporters/Asset 110@4x.png'
    },
    {
        id:28,
        image:'/logos/Supporters/Asset 111@4x.png'
    },
    {
        id:29,
        image:'/logos/Supporters/Asset 112@4x.png'
    },
    {
        id:30,
        image:'/logos/Supporters/Asset 113@4x.png'
    },
    {
        id:31,
        image:'/logos/Supporters/Asset 114@4x.png'
    },
    {
        id:32,
        image:'/logos/Supporters/Asset 115@4x.png'
    },
    {
        id:33,
        image:'/logos/Supporters/Asset 116@4x.png'
    },
    {
        id:34,
        image:'/logos/Supporters/Asset 117@4x.png'
    },
    {
        id:35,
        image:'/logos/Supporters/Asset 118@4x.png'
    },
    {
        id:36,
        image:'/logos/Supporters/Asset 119@4x.png'
    },
    {
        id:37,
        image:'/logos/Supporters/Asset 120@4x.png'
    },
    {
        id:38,
        image:'/logos/Supporters/Asset 121@4x.png'
    },
    {
        id:39,
        image:'/logos/Supporters/Asset 122@4x.png'
    },
    {
        id:40,
        image:'/logos/Supporters/Asset 123@4x.png'
    },
    {
        id:41,
        image:'/logos/Supporters/Asset 124@4x.png'
    },
    {
        id:42,
        image:'/logos/Supporters/Asset 125@4x.png'
    },
    {
        id:43,
        image:'/logos/Supporters/Asset 126@4x.png'
    },
    {
        id:44,
        image:'/logos/Supporters/Asset 127@4x.png'
    },
    {
        id:45,
        image:'/logos/Supporters/Asset 128@4x.png'
    },
    {
        id:46,
        image:'/logos/Supporters/Asset 89@4x.png'
    },
   
   
]

export default logos