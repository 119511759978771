import React,{useEffect, useState} from 'react'
import '../common.scss';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { isJson } from '../../../utils/converter';
import axios from 'axios';
import urls from '../../../utils/url';
import moment from 'moment';
import Cookie from 'js-cookie';

HighchartsHeatmap(Highcharts);


const IntrapartumGraph = (props) => {

  const cookie = Cookie.get('access_token')
  const {patientID} = props;
  
  const [active, setActive] = useState(0)
  const [loading, setLoading] = useState(false)
  const [intrapartumData, setIntrapartumData] = useState([])
  const [firstCervixTime, setFirstCervixTime] = useState(0)
  const [firstCervixValue, setFirstCervixValue] = useState('')
  const [firstReadingTime, setFirstReadingTime] = useState(0)
  const [plotPartograph, setPlotPartograph] = useState(false)
  const [tickPositions, setTickPositions] = useState([])
  const [hourTickPostions, setHourTickPostions] = useState([])
  const [fhr, setFhr] = useState([])
  const [contraction, setContraction] = useState({})
  const [amniotic, setAmniotic] = useState({})
  const [headDescent, setHeadDescent] = useState([])
  const [cervix, setCervix] = useState([])
  const [alerts, setAlerts] = useState([])
  const [actions, setActions] = useState([])
  const [oxytocin, setOxytocin] = useState([])
  const [drugs, setDrugs] = useState([])
  const [systolic, setSystolic] = useState([])
  const [diastolic, setDiastolic] = useState([])
  const [pulse, setPulse] = useState([])
  const [temperature, setTemperature] = useState([])
  const [ activeText, setActiveText ] = useState('Partograph');

  useEffect(() => {
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=labor_data`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        if(res.data){
          const json_data = isJson(res.data)
          const response = json_data.response[0].labor_data
          const new_data = response.map(obj => ({...obj, data_time: obj.data_time *= 1000 }))
          const sorted_data = new_data.sort((a, b) => (a.data_time > b.data_time) ? 1 : -1);
          setIntrapartumData(sorted_data)
          setLoading(false) 

          //console.log( sorted_data)
          sorted_data.filter(obj => {
            if (obj.data_type === 'cervix_diameter' && parseInt(obj.param_1) >= 5 && firstCervixTime == 0){
              setPlotPartograph(true)
            }
          })
          
        }
      }).catch((err) => {
        console.log("Error in Fetching Data",err)
        setLoading(false)
      }
    )
  },[])


  useEffect(() => {

    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });

    if (plotPartograph){
      //CERVIX RELATED DATA
      var cervix_data = intrapartumData.filter(obj => obj.data_type === "cervix_diameter" && parseInt(obj.param_1) >= 5)
      setCervix(cervix_data.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)]))
      const [first_cervix_time, first_cervix_value]  = [parseInt(moment(cervix_data[0].data_time).format('x')),cervix_data[0].param_1]

      if (firstCervixTime == 0){
        setFirstReadingTime(parseInt(moment(cervix_data[0].data_time).format('x')))
        setFirstCervixValue(parseInt(cervix_data[0].param_1))
      } else {
        //If first value is 9, means that graph has to start 5 hours back coz 1cm = 1 hour and graph starts from 4.
        const first_timestamp = first_cervix_time - ((parseInt(first_cervix_value) - 5) * 3600 * 1000);
        setFirstReadingTime(first_timestamp)
        setFirstCervixValue(parseInt(cervix_data[0].param_1))
      }

      setTickPositions(getTickPositions(first_cervix_time));
      setHourTickPostions(getHourTickPositions(first_cervix_time));

      //ALERTS AND ACTIONS
      let alert = []
      let action = []
      let reading_time = ''
      for(var j=0; j<16; j++){
        reading_time = first_cervix_time + (1800*1000)*(j+0);
        alert.push([reading_time, (j*0.5) + 5 ]);
        reading_time = first_cervix_time + (1800*1000)*(j+8);
        action.push([reading_time, (j*0.5)+ 5 ]);

        setActions(action)
        setAlerts(alert)
      }
      
      //FHR DATA
      const fhr_data = intrapartumData.filter(obj => obj.data_type === "fetal_heart_rate" && parseInt(obj.data_time) >= first_cervix_time)
      const fhr = fhr_data.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)])
      setFhr(fhr)
 
      //AMNIOTIC FLUID
      const amniotic_fluid_data =  intrapartumData.filter(obj => obj.data_type === "amniotic_fluid" && parseInt(obj.data_time) >= first_cervix_time)
      const temp_amniotic_fluid = {}
      amniotic_fluid_data.forEach(obj => {
        const amniotic_fluid_time = parseInt(moment(obj.data_time).format('x'))
        for(var intervals = 1 ; intervals < tickPositions.length ; intervals++){
          if(tickPositions[intervals-1] <= parseInt(amniotic_fluid_time) && parseInt(amniotic_fluid_time) < tickPositions[intervals]){
            temp_amniotic_fluid[tickPositions[intervals-1].toString()] = obj.param_1;
          }
        }
      })
      
      setAmniotic(temp_amniotic_fluid)

      //HEAD DESCENT DATA
      const head_descent_data =  intrapartumData.filter(obj => obj.data_type === "head_descent" && parseInt(obj.data_time) >= first_cervix_time)
      const head_descent = head_descent_data.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)])
      setHeadDescent(head_descent)
      
      //BP DATA
      const bp_data = intrapartumData.filter(obj => obj.data_type === "bp" && parseInt(obj.data_time) >= first_cervix_time)
      const systolic_bp = bp_data.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)])
      const diastolic_bp = bp_data.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_2)])
      setSystolic(systolic_bp)
      setDiastolic(diastolic_bp)

      //PULSE DATA
      const pulse_data = intrapartumData.filter(obj => obj.data_type === "pulse" && parseInt(obj.data_time) >= first_cervix_time)
      const temp_pulse = pulse_data.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)])
      setPulse(temp_pulse)

      //CONTRACTION DATA
      const contraction_data = intrapartumData.filter(obj => obj.data_type === "contraction" && parseInt(obj.data_time) >= first_cervix_time)
      let temp_contractions = {};
      // contraction_data.filter(obj => {
      //   if(parseInt(obj.param_3) < 20){
      //     contractionLessThan20.push([parseInt(obj.data_time), parseInt(obj.param_1)])
      //   } else if (parseInt(obj.param_3) >= 20 && parseInt(obj.param_3) <= 40){
      //     contractionIn20and40.push([parseInt(obj.data_time), parseInt(obj.param_1)])
      //   } else if (parseInt(obj.param_3) > 40){
      //     contractionMoreThan40.push([parseInt(obj.data_time), parseInt(obj.param_1)])
      //   }
      // })
      contraction_data.forEach(obj => {
        var timing = moment(obj.data_time).format('x');
        for(var intervals = 1 ; intervals < tickPositions.length ; intervals++){
            if(tickPositions[intervals-1] <= parseInt(timing) && parseInt(timing) < tickPositions[intervals]){
                temp_contractions[tickPositions[intervals-1]] = {
                    'number' : parseInt(obj.param_1),
                    'duration': getContractionDurationStep(parseInt(obj.param_2)),
                    'intensity': 2, // 23 Oct 2018 - parseInt(value.param_3) -- Hardcoded for now. Not used anymore, seems to be outdated
                };
            }
        }
      })
      setContraction(temp_contractions)
      //console.log(contraction_data, contractionLessThan20, contractionIn20and40, contractionMoreThan40)

      //var moulding_data = intrapartumData.filter(obj => obj.data_type === "moulding" && parseInt(obj.data_time) >= first_cervix_time)

      //OXYTOCIN DATA
      const oxytocin_data = intrapartumData.filter(obj => obj.data_type === "oxytocin" && parseInt(obj.data_time) >= first_cervix_time)
      let temp_oxytocin = {}
      oxytocin_data.forEach( value => {
        var timing = moment(value.data_time).format('x');
        for(var intervals = 1 ; intervals < tickPositions.length ; intervals++){
            if(tickPositions[intervals-1] <= parseInt(timing) && parseInt(timing) < tickPositions[intervals]){
                temp_oxytocin[tickPositions[intervals-1]] = {'conc' : value.param_1, 'drops' : value.param_2};
            }
        }
      })
      setOxytocin(temp_oxytocin)


      //TEMPERATURE DATA
      const temperature_data = intrapartumData.filter(obj => obj.data_type === "temperature" && parseInt(obj.data_time) >= first_cervix_time)
      let temp_temperature = []
      temperature_data.forEach( value => {
        var timing = moment(value.data_time).format('x');
        for(var intervals = 2 ; intervals < tickPositions.length ; intervals+=2){
            if(tickPositions[intervals-2] <= parseInt(timing) && parseInt(timing) < tickPositions[intervals]){
              temp_temperature[tickPositions[intervals-2]] = value.param_1;
            }
        }
      })
      setTemperature(temp_temperature)

      //DRUGS AND IV DATA
      const drugs_data = intrapartumData.filter(obj => obj.data_type === "drugs" && parseInt(obj.data_time) >= first_cervix_time)
      let temp_drugs = {}
      drugs_data.forEach( value => {
        var timing = moment(value.data_time).format('x');
        for(var intervals = 2 ; intervals < tickPositions.length ; intervals+=2){
            if(tickPositions[intervals-2] <= parseInt(timing) && parseInt(timing) < tickPositions[intervals]){
              temp_drugs[tickPositions[intervals-2]] = value.param_1 + '<br/>(' + value.param_2 + ')<br/>' + value.param_3;
            }
        }
      })
      setDrugs(temp_drugs)


      var bishop_score = intrapartumData.filter(obj => obj.data_type === "bishop_score" && parseInt(obj.data_time) >= first_cervix_time)
      var placenta = intrapartumData.filter(obj => obj.data_type === "Placenta_Complication" && parseInt(obj.data_time) >= first_cervix_time)
      var complication_data = intrapartumData.filter(obj => obj.data_type === "TEMPERATURE_Postpartum_child_complication" && parseInt(obj.data_time) >= first_cervix_time)
    }
  },[intrapartumData, plotPartograph])

  

  function getTickPositions(data_time){
    var positions = [];
    for(var i = 0; i < 25; i++){
        positions.push(data_time + (i*1800*1000));
    }
    return positions;
  }

  function getHourTickPositions(data_time){
    var positions = [];
    for(var i = 0; i < 13; i++){
        positions.push(data_time + (i*3600*1000));
    }
    return positions;
  }

  function getStep(){
    var width = window.screen.width;
    var step = 2 + parseInt((1280 - width)/150);
    if(step < 2){
        step = 2;
    }
    return step;
  }

  function tooltipFormat(val){
    if (val.y == '') {
        return Highcharts.dateFormat('%d %b %Y %I:%M:%S %p', val.x) + '<br/>' +
                        '<b>' + val.series.name + '</b>';
    }
    return Highcharts.dateFormat('%d %b %Y %I:%M:%S %p', val.x) + '<br/>' +
                        '<b>' + val.series.name + ': ' + val.y + '</b>';
  }

  function heatMapTooltipFormat(val){
    return Highcharts.dateFormat('%d %b %Y %I:%M:%S %p', val.point.x) + '<br/>' +
                        '<b>' + val.series.name + ': ' + val.point.value + '</b>';
  }

  function getTextCoords(chart){

    var texts = ['Alert', 'Action'];
    var final_response = [];
    var i = 0;

    // var cervix_val = 0;
    // var cervix_val_diff = 1;
    
    // if(chart.series[0].data.length > 0){
    //     cervix_val = chart.series[0].data[0].y;
    //     var cervix_val_diff = 5 - (cervix_val - 4);
    // }
    
    // if(cervix_val_diff < 1){
    //     cervix_val_diff = 1;
    // }

    while(i < 2){
        var point_1 = chart.series[i+1].data[0];
        var point_2 = chart.series[i+1].data[5];
        var slope = (point_2.plotY - point_1.plotY)/(point_2.plotX - point_1.plotX);
        var slope_deg = Math.atan(slope)*180/Math.PI;
        var width = window.screen.width;
        var fontSize = 6 + (12/1280)*width;
        var diff = (10/1280)*width;
        var result = {
                'x' : point_2.plotX + chart.plotLeft - diff, 
                'y' : point_2.plotY + chart.plotTop, 
                'fontSize': fontSize + 'px', 
                'slope_deg' : slope_deg,
                'text' : texts[i]
            };
        final_response.push(result);
        i++;
    }
    return final_response;
  }

  function getContractionDurationStep(param2) {
    var step = parseInt(param2/20) + 1;
    if (step > 3) {
        step = 3;
    }
    return step;
  }

  let amniotic_data = [];
  let contraction_duration = {};
  let contractions_data = [
      { name : '< 20 seconds', data : [] },
      { name : '20-40 seconds', data : [] },
      { name : '> 40 seconds', data : [] },
  ];
  let contractions_intensity = {};
  let oxytocin_data = []
  let temp_contractions = contraction
  let drugs_data = [];
  let temperature_data = []
  
  
  // Not used anymore - Intensity is not to be looked for.
  // var intensity_labels = ['', 'Mild', 'Moderate', 'Strong'];
  let intensity_labels = [];
  let temp_amniotic = amniotic 

  for(let intervals = 1 ; intervals < tickPositions.length ; intervals++){
    let label_pos = parseInt((tickPositions[intervals-1] + tickPositions[intervals])/2);

    //AMNIOTIC
    if(typeof(amniotic[tickPositions[intervals-1]]) === 'undefined'){
        temp_amniotic[tickPositions[intervals-1]] = '';
    }
    amniotic_data.push([label_pos, 0, temp_amniotic[tickPositions[intervals-1]]]);

    //CONTRACTION
    if(typeof(temp_contractions[tickPositions[intervals-1]]) === 'undefined'){
      contractions_data[0]['data'].push([label_pos, 0]);
      contractions_data[1]['data'].push([label_pos, 0]);
      contractions_data[2]['data'].push([label_pos, 0]);
    } else {
        var curr_tick = tickPositions[intervals-1];
        var duration = temp_contractions[curr_tick]['duration'];
        if (duration > 0){
            contractions_data[duration-1]['data'].push([label_pos, temp_contractions[curr_tick]['number']]);
            contractions_data[duration%3]['data'].push([label_pos, 0]);
            contractions_data[(duration + 1)%3]['data'].push([label_pos, 0]);

            //Preparing data labels for mild, moderate and strong
            if(typeof(intensity_labels[temp_contractions[curr_tick]['intensity']]) !== 'undefined'){
                contractions_intensity[curr_tick] = intensity_labels[temp_contractions[curr_tick]['intensity']];
            } else {
                contractions_intensity[curr_tick] = '';
            }
            
        }
    }

    //OXYTOCIN
    if(typeof(oxytocin[tickPositions[intervals-1]]) === 'undefined'){
      oxytocin[tickPositions[intervals-1]] = {'conc' : '',  'drops' : ''};
    }

    oxytocin_data.push([label_pos, 1, oxytocin[tickPositions[intervals-1]]['conc']]);
    oxytocin_data.push([label_pos, 0, oxytocin[tickPositions[intervals-1]]['drops']]);

    //DRUGS

  }

  for(let intervals = 2 ; intervals < tickPositions.length ; intervals+=2){
    let label_pos = parseInt((tickPositions[intervals-2] + tickPositions[intervals])/2);
    if(typeof(drugs[tickPositions[intervals-2]]) === 'undefined'){
        drugs[tickPositions[intervals-2]] = '';
    }
    drugs_data.push([label_pos, 0, drugs[tickPositions[intervals-2]]]);

    if(typeof(temperature[tickPositions[intervals-2]]) === 'undefined'){
        temperature[tickPositions[intervals-2]] = '';
    }
    temperature_data.push([label_pos, 0, temperature[tickPositions[intervals-2]]]);
  }

  var chartMargin = 70;
  var legendFont = 10;
  var markerRadius = 8;
  var lineWidth = 4;
  var alertWidth = 2;
  var itemMargin = 2;

  const fetalGraph = {
      chart: {
          //marginLeft: chartMargin,
          marginLeft: 70,
          marginRight: 50,
      },
      title: {
          text: '',
      },
      xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
              millisecond: '%H:%M:%S.%L',
              second: '%H:%M:%S',
              minute: '%H:%M',
              hour: '%H:%M',
              day: '%e %b',
              week: '%e %b',
              month: '%b \'%y',
              year: '%Y'
          },
          min: tickPositions[0],
          max: tickPositions.slice(-1).pop(),
          tickPositions: tickPositions,
          labels: {
              formatter: function () {
                return Highcharts.dateFormat('%I:%M %p', this.value);
              },
              step: getStep(),
          },
          title: {
              text: ''
          },
          gridLineColor:'#000000',
          gridLineWidth: 1,
      },
      yAxis: {
          title: {
              text: 'Fetal Heart Rate'
          },
          tickInterval: 1,
          gridLineColor: '#000000',
          gridLineWidth: 1,
          min: 80,
          max: 200,
          // plotLines: [
          //     {
          //         color: '#000000',
          //         width: 3,
          //         value: 110
          //     },
          //     {
          //         color: '#000000',
          //         width: 3,
          //         value: 170
          //     },
          // ],
          tickPositioner: function () {
              var positions = [],
              i = 80;
              while(i <= 200){
                  positions.push(i);
                  i+=10;
              }
              return positions;
          }
      },
      tooltip: {
          valueSuffix: '',
          formatter: function(){
              var val = this;
              return tooltipFormat(val);
          }
      },
      legend: {
        align: 'right',
        verticalAlign: 'bottom',
        layout: 'vertical',
        y: -30,

        itemMarginTop: itemMargin,
        itemMarginBottom: itemMargin,
        itemStyle: {
            lineHeight: legendFont.toString()+ 'px',
            fontSize: legendFont.toString() + 'px',
        },
        borderWidth: 1,
        borderColor: '#000000',
        zIndex: 2,
        backgroundColor : '#FFFFFF',
        floating : true,

      },
      series: [
          {
              name: 'Fetal Heart Rate',
              data: fhr,
              lineWidth: lineWidth,
              marker:{
                  radius : markerRadius,
                  symbol: "circle"
              },
              color: '#278DD1'
          }
      ]
  
  } 
  
  const amnioticGraph = {
    chart: {
      type: 'heatmap',
      marginLeft: 70,
      marginRight: 50,
      height: 100,
      backgroundColor: '#FFFFFF',
    },
    title: {
        text: ''
    },

    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e %b',
            week: '%e %b',
            month: '%b \'%y',
            year: '%Y'
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M %p', this.value);
            },
            step: getStep(),
        },
        title: {
            text: ''
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },

    yAxis: {
        categories: ['Amniotic<br/>Fluid'],
        title: null,
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    tooltip:{
        formatter: function(){
            var val = this;
            return heatMapTooltipFormat(val);
        }
    },
    legend: {
        enabled:false
    },
    
    colorAxis: {
        stops: [
            [0,'green'],
            [0.5,'orange'],
            [0.9,'red']
        ],
        min: -20
    },
    series: [{
        borderWidth: 1,
        background: "#000000",
        shadow: false,
        borderColor: "#FFFFFF",
        data: amniotic_data,
        name: 'Amniotic Fluid',
        dataLabels: {
          enabled: true,
          format: '{point.value}',
          style: {
            color: '#000000',
          },
        },
    }]

      
  }

  var series_data = [
    {
        name: 'Cervix Diameter (cm)',
        data: cervix,
        lineWidth: lineWidth,
        marker:{
            radius : markerRadius,
            
        },
        color: '#438EFF'
    },
    // {
    //   name: 'Descent of Head',
    //   data: headDescent,
    //   lineWidth: lineWidth,
    //   marker:{
    //       radius : markerRadius,
    //       symbol: "square"
    //   },
    //   color: '#012F68'
    // },  
    
  ];

  if(alerts.length > 0){
    series_data.push({
        name: 'Alert',
        data: alerts,
        showInLegend: false,
        enableMouseTracking: false,
        marker: {
            enabled: false
        },
        width: alertWidth,
        color: '#179D62'
    });
    series_data.push({
        name: 'Action',
        data: actions,
        showInLegend: false,
        enableMouseTracking: false,
        marker: {
            enabled: false
        },
        width: alertWidth,
        color: '#D92400'
    });
  }


  const cervixAndHeadDescentGraph = {
    title: {
      text: '',
    },
    chart: {
      marginLeft: 70,
      marginRight: 50,
        events: {
            redraw: function () {
                var chart = this;
                if(alerts.length > 0){
                    var coords = getTextCoords(chart);
                    coords.forEach((value, ind) => {
                      chart.renderer.text(value['text'], value['x'], value['y'])
                            .attr({
                                rotation: value['slope_deg'],
                                id : value['text'] + '_text'
                            })
                            .css({
                                color: '#222222',
                                fontSize: value['fontSize']
                            })
                            .add();
                  })
                }
            }
        }
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e %b',
            week: '%e %b',
            month: '%b \'%y',
            year: '%Y'
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M %p', this.value);
            },
            step: getStep(),
        },
        title: {
            text: ''
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    yAxis: {
        title: {
            text: 'Cervix Diameter (cm) and Head Descent'
        },
        tickInterval: 1,
        min: 0,
        max: 10,
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    tooltip: {
        valueSuffix: ' cm',
        formatter: function(){
            var val = this;
            return tooltipFormat(val);
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'bottom',
        layout: 'vertical',
        y:-30,

        itemMarginTop: itemMargin,
        itemMarginBottom: itemMargin,
        itemStyle: {
            lineHeight: legendFont.toString()+ 'px',
            fontSize: legendFont.toString() + 'px',
        },
        borderWidth: 1,
        borderColor: '#000000',
        zIndex: 2,
        backgroundColor : '#FFFFFF',
        floating : true,

    },
    series: series_data
  }

  const headDescentGraph = {
    title: {
      text: '',
    },
    chart: {
      marginLeft: 70,
      marginRight: 50,
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e %b',
            week: '%e %b',
            month: '%b \'%y',
            year: '%Y'
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M %p', this.value);
            },
            step: getStep(),
        },
        title: {
            text: ''
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    yAxis: {
        title: {
            text: 'Head Descent'
        },
        tickInterval: 1,
        min: -4,
        max: 4,
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    tooltip: {
        valueSuffix: '',
        formatter: function(){
            var val = this;
            return tooltipFormat(val);
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'bottom',
        layout: 'vertical',
        y:-30,

        itemMarginTop: itemMargin,
        itemMarginBottom: itemMargin,
        itemStyle: {
            lineHeight: legendFont.toString()+ 'px',
            fontSize: legendFont.toString() + 'px',
        },
        borderWidth: 1,
        borderColor: '#000000',
        zIndex: 2,
        backgroundColor : '#FFFFFF',
        floating : true,

    },
    series: [
        {
            name: 'Descent of Head',
            data: headDescent,
            lineWidth: lineWidth,
            marker:{
                radius : markerRadius,
                symbol: "square"
            },
            color: '#012F68'
        }
    ]
  }

  const contractionGraph = {
    chart: {
      type: 'column',
      marginLeft: 70,
      marginRight: 50,
    },
    title: {
        text: '',
    },
    legend: {
        align: 'right',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        y:-30,

        itemMarginTop: itemMargin,
        itemMarginBottom: itemMargin,
        itemStyle: {
            lineHeight: legendFont.toString() + 'px',
            fontSize: legendFont.toString() + 'px'
        },
        borderWidth: 1,
        borderColor: '#000000',
        zIndex: 2,
        backgroundColor : '#FFFFFF',
        floating : true,

    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e %b',
            week: '%e %b',
            month: '%b \'%y',
            year: '%Y'
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M %p', this.value);
            },
            step: getStep(),
        },
        title: {
            text: ''
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    yAxis: {
        min: 0,
        max: 5,
        tickPositions:[0,1,2,3,4,5],
        title: {
            text: 'Contractions (10 Min)'
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    tooltip:{
        formatter: function(){
            var val = this;
            return tooltipFormat(val);
        }
    },
    plotOptions: {
        series: {
            pointPadding: 0, // Defaults to 0.1
            groupPadding: 0 // Defaults to 0.2
        },
        column: {
            stacking:'normal',
            pointPadding: 0.1,
            borderWidth: 1,
            pointRange: 0,
            pointPlacement: 'between',
            dataLabels: {
                enabled: true,
                rotation: -90,
                color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
                style: {
                    textShadow: '0 0 3px black'
                },
                formatter: function(){
                    var obj = contractions_intensity;
                    if(this.point.y > 0){
                        //Because of middle align, 15 mins are added.
                        return obj[parseInt(this.point.x) - (900*1000)];
                    }
                }
            }
        },

    },
    series: contractions_data

  }

  const oxytocinAndDropsGraph = {
    chart: {
      type: 'heatmap',
      marginLeft: 70,
      marginRight: 50,
      height: 250
    },
    title: {
        text: ''
    },

    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e %b',
            week: '%e %b',
            month: '%b \'%y',
            year: '%Y'
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M %p', this.value);
            },
            step: getStep(),
        },
        title: {
            text: ''
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },

    yAxis: {
        categories: ['Drops<br/>(Per Min)', 'Oxytocin<br/>(U/L)'],
        title: null,
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    tooltip: {
        formatter: function(){
            var val = this;
            return heatMapTooltipFormat(val);
        }
    },
    legend: {
        enabled:false
    },
    
    colorAxis: {
        stops: [
            [0,'green'],
            [0.5,'orange'],
            [0.9,'red']
        ],
        min: -20
    },

    series: [{
        borderWidth: 1,
        background: "#000000",
        shadow: false,
        borderColor: "#FFFFFF",
        data: oxytocin_data,
        name: 'Oxytocin',
        dataLabels: {
          enabled: true,
          format: '{point.value}',
          style: {
            color: '#000000',
          },
        },
    }]
  }

  const drugsAndIVGraph = {
    chart: {
      type: 'heatmap',
      marginLeft: 70,
      marginRight: 50,
      height: 150,
    },
    title: {
        text: ''
    },

    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e %b',
            week: '%e %b',
            month: '%b \'%y',
            year: '%Y'
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M %p', this.value);
            },
            step: getStep(),
        },
        title: {
            text: ''
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },

    yAxis: {
        categories: ['Drugs<br/>and IV<br/>Fluids'],
        title: null,
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    tooltip:{
        formatter: function(){
            var val = this;
            return heatMapTooltipFormat(val);
        }
    },
    legend: {
        enabled:false
    },
    
    colorAxis: {
        stops: [
            [0,'green'],
            [0.5,'orange'],
            [0.9,'red']
        ],
        min: -20
    },

    plotOptions: {
        series: {
            borderWidth: 1,
            background: "#000000",
            shadow: false,
            borderColor: "#FFFFFF",
            dataLabels: {
                enabled: true,
                color: '#000000',
                overflow: 'none',
                verticalAlign: "middle",
                align: "center"
            }
        }
    },
    series: [{
        name: 'Drugs',
        data: drugs_data,
        dataLabels: {
          enabled: true,
          format: '{point.value}',
          style: {
            color: '#000000',
          },
        },
    }]
  }

  const pulseAndBloodPressureGraph = {
    chart: {
      marginLeft: 70,
      marginRight: 50,
    },
    title: {
        text: '',
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e %b',
            week: '%e %b',
            month: '%b \'%y',
            year: '%Y'
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M %p', this.value);
            },
            step: getStep(),
        },
        title: {
            text: ''
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    yAxis: {
        title: {
            text: 'Pulse & Blood Pressure'
        },
        tickInterval: 1,
        gridLineColor: '#000000',
        gridLineWidth: 1,
        min: 60,
        max: 180,
        tickPositioner: function () {
            var positions = [],
            i = 60;
            while(i <= 180){
                positions.push(i);
                i+=10;
            }
            return positions;
        }
    },
    tooltip: {
        formatter: function(){
            var val = this;
            return tooltipFormat(val);
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        y:-20,

        itemMarginTop: itemMargin,
        itemMarginBottom: itemMargin,
        itemStyle: {
            lineHeight: legendFont.toString() + 'px',
            fontSize: legendFont.toString() + 'px',
        },
        borderWidth: 1,
        borderColor: '#000000',
        zIndex: 2,
        backgroundColor : '#FFFFFF',
        floating : true,

    },
    series: [
        {
            name: 'Pulse',
            data: pulse,
            lineWidth: lineWidth,
            marker:{
                radius : markerRadius,
                symbol: "triangle"
            },
            color: '#93eb82'
        },
        {
            name: 'Systolic BP',
            data: systolic,
            lineWidth: lineWidth,
            marker:{
                radius : markerRadius,
                symbol: "circle"
            },
            color: '#278DD1'
        },
        {
            name: 'Diastolic BP',
            data: diastolic,
            lineWidth: lineWidth,
            marker:{
                radius : markerRadius,
                symbol: "square"
            },
            color: '#012F68'
        },
    ]
  }

  const temperatureGraph = {
    chart: {
      type: 'heatmap',
      marginLeft: 70,
      marginRight: 50,
      height: 100,
      backgroundColor: '#FFFFFF',
    },
    title: {
        text: ''
    },

    xAxis: {
        type: 'datetime',
        // dateTimeLabelFormats: {
        //     millisecond: '%H:%M:%S.%L',
        //     second: '%H:%M:%S',
        //     minute: '%H:%M',
        //     hour: '%H:%M',
        //     day: '%e %b',
        //     week: '%e %b',
        //     month: '%b \'%y',
        //     year: '%Y'
        // },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M %p', this.value);
            },
            step: getStep(),
        },
        title: {
            text: ''
        },
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },

    yAxis: {
        categories: ['Temp<br/>(F)'],
        title: null,
        gridLineColor: '#000000',
        gridLineWidth: 1,
    },
    tooltip:{
        formatter: function(){
            var val = this;
            return heatMapTooltipFormat(val);
        }
    },
    legend: {
        enabled:false
    },
    
    colorAxis: {
      visible: false,
    },

    
    series: [{
        name: 'Temperature',
        data: temperature_data,
        dataLabels: {
          enabled: true,
          format: '{point.value}',
          style: {
            color: '#000000',
          },
        },
    }]
  }

  return (
    <div className='partograph' id="partograph">
        
          <HighchartsReact
              highcharts={Highcharts}
              options={fetalGraph}
              constructorType={'chart'}
              allowChartUpdate={true}  
          />
        
       
          <HighchartsReact
            highcharts={Highcharts}
            options={amnioticGraph}
            containerProps={{ className: "hc-graph small" }}
          />
        
        
          <HighchartsReact
            highcharts={Highcharts}
            options={cervixAndHeadDescentGraph}
            constructorType={'chart'}
            allowChartUpdate={true}  
          />
        
        
          <HighchartsReact
            highcharts={Highcharts}
            options={contractionGraph}
            constructorType={'chart'}
            allowChartUpdate={true}  
          />
        
          <HighchartsReact
            highcharts={Highcharts}
            options={oxytocinAndDropsGraph}
            constructorType={'chart'}
            allowChartUpdate={true}  
          />
        
          <HighchartsReact
            highcharts={Highcharts}
            options={drugsAndIVGraph}
            constructorType={'chart'}
            allowChartUpdate={true}  
          />
        
          <HighchartsReact
            highcharts={Highcharts}
            options={pulseAndBloodPressureGraph}
            constructorType={'chart'}
            allowChartUpdate={true}  
          />
       
          <HighchartsReact
            highcharts={Highcharts}
            options={temperatureGraph}
            constructorType={'chart'}
            allowChartUpdate={true}  
          />
       
      </div>
  )
}

export default IntrapartumGraph

