import React, { useEffect, useState } from 'react';
import '../common/cardAlerts.scss';
import { useNavigate } from 'react-router-dom';

const CardAlerts = (props) => {

  const { patientID, patientName, lastUpdateTime, alerts, complications, statusTab } = props;
  const navigate = useNavigate();
  const [isCompletedTab, setIsCompletedTab] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [complicationMessage, setComplicationMessage] = useState("");
  const [visited, setVisited] = useState(false);

  const handleNavigation = (patientId, patientName) => {
    const data = {
      alertIndex: 10,
      patient_name: patientName,
      patient_id: patientId,
    };
    navigate(`/dashboard/patient/${patientId}`, { state: data });
  }

  useEffect(() => {
    if (statusTab === 'completed') {
      setIsCompletedTab(true);
    }
  }, [statusTab]);

  useEffect(() => {
  
    const filteredAlerts = alerts && alerts.length > 0 ? alerts.filter(obj => obj.alert_type !== "Placenta_Complication" && obj.alert_type !== "PLACENTA_DELIVERED"): [];

    let concatenatedAlertMessage = filteredAlerts && filteredAlerts.length > 0 ? filteredAlerts.map(obj => obj.alert_message).join(', ') : "";

    setAlertMessage(concatenatedAlertMessage);
  }, [alerts]);

  useEffect(() => {
    let concatenatedComplicationsMessage =  complications && complications.length > 0 ? complications.map(obj => obj.complication_message).join(', ') : "";
    setComplicationMessage(concatenatedComplicationsMessage);
  }, [complications]);


  return (
    (alertMessage.length > 0 || complicationMessage.length > 0 ) &&
       
          <div className={`user-card ${visited ? 'visited' : 'not-visited'}`}>
            
            <div className='alerts-box'>
              { 
                alertMessage && 
                  <div className='warning-div'>
                    {isCompletedTab && <img src='/alerts-warning.svg' alt='Alert Warning' className='warning-icon'/>}
                     <strong className='bold-text'>Alerts:</strong>
                     <p>{alertMessage}</p>
                  </div>
              }
              {
                complicationMessage &&
                  <div className='warning-div'>
                    {isCompletedTab && <img src='/complications-warning.svg' alt='Complication Warning' className='warning-icon'/>}
                      <strong className='bold-text'>Complications:</strong> 
                      <p>{complicationMessage}</p>
                  </div>
              }
            </div>

            <img  src={visited ? '/arrow_forward_white.svg' : '/arrow_forward_black.svg'} alt='Arrow' 
              width={35} height={35} onClick={() => handleNavigation(patientID,patientName)} className='alert-arrow'
            />

          </div>
        
    
  );
}

export default CardAlerts;
