import React, {useState, useEffect, useCallback} from 'react'
import '../report/report.scss'
import axios from 'axios';
import urls from '../../utils/url';
import { connect } from 'react-redux';
import { addPatientDetail } from '../../redux/features/details/patientDetail';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import LocalDatePicker from '../../components/common/datepicker';
import Filter from '../../components/common/filter';
import { useNavigate } from 'react-router-dom';
import {limitStr, getReadableTime, getReferralDetails, isJson, TooltipComponent, getChildStatusString,getReadableDateOnly} from '../../utils/converter'
import { medicalData } from '../../utils/sheetConstants';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../components/common/loading';
import InputLabel from '@mui/material/InputLabel';
import Reload from '../../components/common/Reload';
import PartographLayout from '../../components/partograph/partograph';
import fieldMap from '../../utils/fieldMap';
import {downloadReport} from '../../utils/excelSheet';
import { IconButton } from '@mui/material';
import Cookie from 'js-cookie';
import Chip from '@mui/material/Chip';
import CardAlerts from '../../components/common/cardAlerts';


// You can adjust the styles by using the `styled` function
const StyledInputLabel = styled(InputLabel)({
  //color: '#171717',
  fontSize: '1rem',
  fontWeight: '500',
  marginTop: '1.25rem',
  marginBottom: '0.625rem',
  fontFamily: 'Poppins',
});

const StyledSelect = styled(Select)({
  '& .MuiPaper-root': {
    backgroundColor: '#faf6ff', // This will change the background color of the dropdown
  },
  borderRadius: '4px',
  border: '0.5px solid #BFBFBF',
  background: '#fff',
  width: '9.375rem', // You can adjust the width as needed
  '& .MuiSelect-select': {
    //background: '#faf6ff',
    height: '0.625rem', // This might be too small, adjust as needed
    padding: '0.625rem',
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: 'Poppins !important',
  '&:hover': {
    backgroundColor: '#faf6ff', 
  },
  '&.Mui-selected': {
    backgroundColor: '#faf6ff', 
    '&:hover': {
      backgroundColor: '#faf6ff', // to maintain the same color on hover for a selected item
    },
  },
});

const StyledChip = styled(Chip)({
  borderRadius: '500px',
  border: '1px solid #C7C7C7',
  background: '#F8F3FF',
  height: '1.875rem',
  flexShrink: 0,
  fontFamily: 'Poppins',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 300,
  textTransform: 'capitalize',
  color: '#707070',
});

const StyleChip = styled(Chip)({
  borderRadius: '500px',
  border: '1px solid #C7C7C7',
  background: '#fff',
  height: '1.875rem',
  flexShrink: 0,
  fontFamily: 'Poppins',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 300,
  textTransform: 'capitalize',
  color: '#707070',
});

const Report = ({isMrn}) => {

  // isMrn: For Malaysia Specific Requirement

  // console.log(isMrn)
  
  const cookie = Cookie.get('access_token')
  const navigate =  useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [value1, setValue1] = useState(); 
  const [value2, setValue2] = useState();
  const [openPartograph, setOpenPartograph ]= useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [placeID, setPlaceID] = useState('');
  const [patientID, setPatientID] = useState('');
  const [activeIndex, setActiveIndex] = useState(0)
  const [patientTab, setPatientTab] = useState('all_patient');
  const [startIndex, setStartIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [patients, setPatients] = useState([]);
  let [totalPatient, setTotalPatient] = useState(0);
  const [searchID, setSearchID] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchPatients, setSearchPatients] = useState([]);
  const [placeDetails, setPlaceDetails] = useState([]); // State to store the data from the backend
  const [searchError, setSearchError] = useState(false)
  const [places, setPlaces] = useState([]);
  const [isDownloading , setIsDownloading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false)
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [hasFiltersBeenApplied, setHasFiltersBeenApplied] = useState(false);
  const [openEndDateCalender, setOpenEndDateCalender] = useState(false);
  const [showHelper, setShowHelper] = useState(false); 
  

  const initialSelectedOptions = {};

  Object.keys(medicalData).forEach(key => {
    initialSelectedOptions[key] = '';
  });

  const [selectedOptions, setSelectedOptions] = useState (initialSelectedOptions)

  const toValue = Math.floor(value2?.$d.getTime()/1000);
  const fromValue =  Math.floor(value1?.$d.getTime()/1000);
 
  const [availableFilters, setAvailableFilters] = useState({
    'State' : [],
    'District': [],
    'Taluka/Block': [],
    'Facility': []
  })

  const [filters, setFilters] = useState({
    State: 'All',
    District: 'All',
    'Taluka/Block': 'All',
    Facility: 'All'
  });

  const premuimTabOptions = {
    "All Patients": "all_patient",
    "Intrapartum Tab": "intra_partum_process",
    "Postpartum Tab": "post_partum_process",
    "Completed Tab": "completed"
  };

  const basicTabOptions = {
    "All Patients": "all_patient",
    "Live Patients": "intra_partum_process",
    "Completed": "completed"
  }

  const handleStartDateChange = (newValue) => {
    setValue1(newValue);
    if (newValue) {
      setOpenEndDateCalender(true); // Open the end date picker when a start date is selected
      setShowHelper(true);
    }
  };

  const handleEndDateChange = (date) => {
    setValue2(date); // Set end date
    setOpenEndDateCalender(false); // Close end date picker once a date is selected
    setShowHelper(false);
  };
  
  useEffect(() => {
    const cookieData = Cookie.get('data');
    if (cookieData) {
        const parsedData = JSON.parse(cookieData);
        const account_type = parsedData.account_type;
        setAccountType(account_type); 
    } 
  },[])

  // For medical data filters
  const handleSelectChange = async (event, key) => {
    const value = event.target.value === "All" ? "" : event.target.value;

    setSelectedOptions({
      ...selectedOptions,
      [key]: value
    });
  };

  const handleFilterChange = (filterType, selectedValue) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: selectedValue
    }));

    updateAvailableFilters(filterType, selectedValue);
  };

  useEffect(() => {
    const active = checkIfFiltersAreActive();
    setIsFiltered(active);
  },[selectedOptions, filters, fromValue, toValue, patientTab])
  

  const checkIfFiltersAreActive = () => {
    const dateFiltered = value1 != null && value2 != null;
    //console.log(dateFiltered, 'date')
    const selectedOptionsFiltered = Object.values(selectedOptions).some(value => value !== 'All' && value !== '');
    console.log(selectedOptionsFiltered, 'type')
    const filtersFiltered = Object.values(filters).some(value => value !== 'All' && value !== '');
    //console.log(filtersFiltered, 'filters')
    //console.log(dateFiltered || selectedOptionsFiltered || filtersFiltered, 'final')
    return dateFiltered || selectedOptionsFiltered || filtersFiltered;
  };
  
  const clearSelectedFilters = (categoryToDelete, valueToDelete) => {

    
    if (categoryToDelete in selectedOptions) {
      setSelectedOptions(prevSelectedOptions => {
        if (prevSelectedOptions[categoryToDelete] === valueToDelete) {
          const { [categoryToDelete]: removed, ...rest } = prevSelectedOptions;
          return { ...rest, [categoryToDelete]: '' };
        }
        return prevSelectedOptions;
      });
    }

    if (categoryToDelete in filters) {
      setFilters(prevFilters => {
        if (prevFilters[categoryToDelete] === valueToDelete) {
          const { [categoryToDelete]: removed, ...rest } = prevFilters;
          return { ...rest, [categoryToDelete]: 'All' };
        }
        return prevFilters;
      });
    }


    if (categoryToDelete === 'dateFilters'){
      setValue1(null)
      setValue2(null)
    }


    const dateFiltered = value1 != null || value2 != null;
    //console.log(dateFiltered, 'date')
    const selectedOptionsFiltered = Object.values(selectedOptions).some(value => value !== 'All' && value !== '');
    //console.log(selectedOptionsFiltered, 'type')
    const filtersFiltered = Object.values(filters).some(value => value !== 'All' && value !== '');
    // console.log(filtersFiltered, 'filters')
    // console.log(dateFiltered || selectedOptionsFiltered || filtersFiltered, 'final')
    setIsFiltered(dateFiltered || selectedOptionsFiltered || filtersFiltered)

    const activeFilters = checkIfFiltersAreActive();
    if (!activeFilters){
      setHasFiltersBeenApplied(false);
    }
    
    handleApplyFilters();
    
  };

  const clearAllFilters = () => {
    setSelectedOptions(prevSelectedOptions => {
      let resetOptions = {};
      Object.keys(prevSelectedOptions).forEach(key => {
        resetOptions[key] = '';
      });
      return resetOptions;
    });
  
    // Set all keys in filters to 'All'
    setFilters(prevFilters => {
      let resetFilters = {};
      Object.keys(prevFilters).forEach(key => {
        resetFilters[key] = 'All';
      });
      return resetFilters;
    });

    setValue1(null)
    setValue2(null)
    setIsFiltered(false)
    handleReload();
  }

  const handleReload = () => {
    window.location.reload();
  }

  const updateAvailableFilters = (changedFilter, selectedValue) => {
    // Depending on which filter was changed, update the other filters' options
    let newAvailableFilters = {...availableFilters};
  
    if (changedFilter === 'State') {
      newAvailableFilters.District = ['All', ...removeDuplicate(placeDetails.filter(place => selectedValue === 'All' || place.state === selectedValue).map(place => place.district))];
      newAvailableFilters['Taluka/Block'] = ['All']; // Reset to 'All'
      newAvailableFilters.Facility = ['All']; // Reset to 'All'
    } else if (changedFilter === 'District') {
      newAvailableFilters['Taluka/Block'] = ['All', ...removeDuplicate(placeDetails.filter(place => selectedValue === 'All' || place.district === selectedValue).map(place => place.taluk))];
      newAvailableFilters.Facility = ['All']; // Reset to 'All'
    } else if (changedFilter === 'Taluka/Block') {
      newAvailableFilters.Facility = ['All', ...removeDuplicate(placeDetails.filter(place => selectedValue === 'All' || place.taluk === selectedValue).map(place => place.name))];
    }
  
    // Finally, set the new available filters
    setAvailableFilters(newAvailableFilters);
  };

  const handleClosePartograph = () => {
    setOpenPartograph(false);
  };

// Initialize available filters when component mounts or when placeDetails change
  useEffect(() => {
    const states = ['All', ...removeDuplicate(placeDetails.map(place => place.state))];
    const districts = ['All', ...removeDuplicate(placeDetails.map(place => place.district))];
    const talukas = ['All', ...removeDuplicate(placeDetails.map(place => place.taluk))];
    const facilities = ['All', ...removeDuplicate(placeDetails.map(place => place.name))];
    
    setAvailableFilters({
      State: states,
      District: districts,
      'Taluka/Block': talukas,
      Facility: facilities
    });
  }, [placeDetails]);
 
  const handleTabChange = (e) => {
    setPatientTab(e.target.value);
    setStartIndex(0);
    setSearchID('')
    setPatients([]);
    setTotalPatient(0);
    handleApplyFilters();
  };

  const handleExpandClick = () => {
    setSearchID('')
    setIsExpanded(!isExpanded);
  };

  const removeDuplicate = (data) => {
    const temp_arr = data.filter((value, index, self) => {
      return value !== '' && self.indexOf(value) === index;
    });
    return [...temp_arr]
  }

  const handleActiveButton = (ind) => {
    setActiveIndex(ind);
  }
  
  const handleReportDownload = async () => {

    setIsDownloading(true)
    const count = 100;
    let events;
    let data = [];
    let activeStatus = patientTab === "all_patient" ? '' : patientTab;
    
    let start_time = isNaN(fromValue) ? '' : fromValue
    let end_time = isNaN(toValue) ?  '': toValue

    if (isFiltered){
      // Since we're applying filters, we reset the startIndex to 0
      events = `events=${selectedOptions.timeline}&cervix_dilatations=${selectedOptions.cervix_dilatation}&mother_statuses=${selectedOptions.mother_status}&genders=${selectedOptions.child_gender}&weights=${selectedOptions.baby_birth_weight}&child_statuses=${selectedOptions.child_status}&delivery_types=${selectedOptions.delivery_type}&keyar_data=${selectedOptions.keyar_data}&from_time=${isNaN(start_time) ? '' : start_time}&to_time=${isNaN(end_time) ? '' : end_time}&`
    }

    if (totalPatient && totalPatient !== undefined && totalPatient !== 0 && totalPatient !== ''){

      for (let startIndexInProcess = 0; startIndexInProcess < totalPatient; startIndexInProcess += count) {
       
          await axios.get(`${urls.BETA_URL}/nurse/patients/?place_ids=${placeID}&current_status=${activeStatus}&exclude=keyar_dataset_params&start=${startIndexInProcess}&count=${count}&${isFiltered ? events : '&'}`,
           {
              headers: {
                  'Request-ID': 'allow|me',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${cookie}`
              }
          }).then((res) => {
              if (res.data) {
                  const json_data = isJson(res.data);
                  const patient_data = json_data.response.patients;
                  data = data.concat(patient_data);
            }
          }).catch((err) => {
              console.log("Get Patient API failed", err);
              setLoading(false);
          });
      }

      await downloadReport(fieldMap, data);
      setIsDownloading(false)
        
    }
  }
    
// This function will now be called when the "Apply & Search" button is clicked
  const handleApplyFilters = () => {
    setIsExpanded(false)
    setPatients([]);
    setTotalPatient(0);
    setIsFiltered(true); // This will trigger the effect to fetch reports with filters
    setStartIndex(0)
    setFetchTrigger(t => !t); // Toggle the state to force useEffect to run
  };
 
  const fetchReports = useCallback(async(placeID) => {
    let count = 20;
    let activeStatus = patientTab === "all_patient" ? '' : patientTab;
    let events = '';
  
    if(isFiltered){
      // Since we're applying filters, we reset the startIndex to 0
      events = `events=${selectedOptions.timeline}&cervix_dilatations=${selectedOptions.cervix_dilatation}&mother_statuses=${selectedOptions.mother_status}&genders=${selectedOptions.child_gender}&weights=${selectedOptions.baby_birth_weight}&child_statuses=${selectedOptions.child_status}&delivery_types=${selectedOptions.delivery_type}&keyar_data=${selectedOptions.keyar_data}&from_time=${isNaN(fromValue) ? '' : fromValue}&to_time=${isNaN(toValue) ? `${''}` : toValue}&`
    }
    
    const url = `${urls.BETA_URL}/nurse/patients/?place_ids=${placeID}&current_status=${activeStatus}&forms=basic_detail,events,place_details,user_details,delivery_detail,labor_data,pa_pv_examination,obstetric_histories,alerts,complications&start=${startIndex}&count=${count}&${isFiltered ? events : '&'}`;
    const headers = {
      'Request-ID': 'allow|me', 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${cookie}`
    };
  
    setLoading(true);
    try {
      const res = await axios.get(url, { headers });
      const json_data = isJson(res.data);
      const data = json_data.response;
     // console.log(data)
  
      setTotalPatient(data.total);
  
      if(data.total > 0){
        setPatients(prevPatients => [...prevPatients, ...data.patients]);
        setStartIndex(startIndex+count);
  
        if (startIndex + count >= data.total) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (err) {
      console.error("Error in Fetching Data", err);
      setError(true);
      setTotalPatient(0);
      setPatients([]);
    } finally {
      setLoading(false);
    }
  }, [fromValue, selectedOptions, toValue, isFiltered, startIndex, patientTab]);

  const fetchPlaceIDs = useCallback(async () => {
    if (loading) return;
    setLoading(true)
    await axios.get(`${urls.BETA_URL}/website/places/`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        if(res.data){
            const json_data = isJson(res.data)
            setPlaces(json_data.response)
            const associate_place = json_data.response
            setPlaceDetails(associate_place)
            let place_ids;
            if (isFiltered){
              // Process response to fetch reports based on new places
              
              const filteredData = associate_place.filter(place => {
                return (filters.State === 'All' || filters.State === place.state) &&
                      (filters.District === 'All' || filters.District === place.district) &&
                      (filters['Taluka/Block'] === 'All' || filters['Taluka/Block'] === place.taluk) &&
                      (filters.Facility === 'All' || filters.Facility === place.name);
              });
              place_ids = filteredData.map(obj => obj.id).toString();
              
            } else {
              place_ids = associate_place.map(obj => obj.id).toString()

            }
            setPlaceID(place_ids)
            fetchReports(place_ids)
            setLoading(false)    
        }
      }).catch((err) => {
        setPlaceID('')
        setError(true)
        setLoading(false)
      }) 
  },[filters, isFiltered, fetchReports, cookie, loading])
  
  useEffect(() => {
    fetchPlaceIDs();
  }, [fetchTrigger, patientTab]);
  
  const search = async (searchString) => {
    setLoading(true)
    setIsExpanded(false)

    if(searchString.length > 0 || searchID.length > 0){
      setIsSearch(true)
    }
    
    await axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${searchString}&forms=basic_detail,events,place_details,user_details,delivery_detail,labor_data,pa_pv_examination&`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        const json_data = isJson(res.data)
        setSearchPatients(json_data.response)
        setSearchError(false)
        setPatients([]);
        setTotalPatient(0);
        setHasMore(false);
        setLoading(false);

      }).catch((err) => {
        console.log("Error in Fetching Patient Data",err)
        setSearchPatients([])
        setIsSearch(false)
        setSearchError(true)
        setLoading(false)
      }
    )
  }

  const formatString = (inputString) => {
    const words = inputString.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const resultString = capitalizedWords.join(' ');
    return resultString;
  }
  
  const openViewFile = (patientId, patientName, lastUpdateTime, mrn) => {
    const data = {
      patient_name : patientName,
      patient_id : patientId,
      last_update_time : lastUpdateTime,
      mrn:mrn,
    }
    navigate(`/dashboard/patient/${patientId}`, {state: data});
  }

  const openViewPartograph = (patient_id) => {
    setPatientID(patient_id);
    setOpenPartograph(true)
  }

  const handleNumericValue = (e) => {
    // Allow the default behavior for pasting
    if (e.ctrlKey || e.metaKey) {
      return;
    }

    // Prevent any non-numeric key presses
    if (!/^\d+$/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(e.key)) {
      e.preventDefault();
    }
  };
  

  const formatLabel = (label) => {
    return label.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };


  const getDisplayName = (category, value) => {
    const formattedCategory = formatLabel(category);
    const categoryData = medicalData[category];
    const categoryKey = Object.keys(categoryData).find(key => categoryData[key] === value);
    const formattedValue = formatLabel(categoryKey || value);
    return `${formattedCategory}: ${formattedValue}`;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        search(searchID);
    }
  };

  return (
    <div className='report-section'>

     { 
       error ? 
        <div className='error-div'>
          <Reload/>
        </div> 
      :
        isDownloading ? 
          <div className="loading-div" style={{ marginTop:`30%`, marginRight:`10%`}}>
             <Loading text={'Downloading Please Wait....'}/>
          </div> 
      :
        <>
          <div className='reports'> 

            <div className='search-filter-div'>

                <div className="search-filter">
                  
                    <div className="search-input-div" onKeyDown={handleKeyPress}>
                        <img src="/search.svg" alt="Search Icon" className='search-icon'/>
                        <input type="text" placeholder="Enter Patient ID Here"
                          value={searchID} 
                          onChange={(e) => setSearchID(e.target.value)} 
                          onKeyDown={(e) => handleNumericValue(e)}  
                          className="search-input" />
                        <button className='search-button' onClick={()=> search(searchID)}>Search</button>
                    </div>

                    <Select labelId="demo-simple-select-label" id="demo-simple-select"
                      sx={{
                        width: '24%',
                        borderRadius: '100px',
                        border: '0.5px solid #A163F1',
                        height: '3.5rem',
                        background: '#F8F5FF',
                        fontFamily: 'Poppins',
                        '&:hover': { border: 'transparent' },
                        '& .MuiSelect-select': {
                          color: '#A163F1',
                          fontSize: '1.1rem',
                          fontWeight: '400',
                          mt: '5px',
                          ml: '10px',
                        },
                      }} 
                      value={patientTab}
                      onChange={handleTabChange}
                      >
                      {
                        Object.entries(accountType && accountType.length > 0  && accountType === 'Basic' ? basicTabOptions : premuimTabOptions).map(([key, value]) => (
                          <StyledMenuItem key={value} value={value}> {key} </StyledMenuItem>
                        ))
                      }
                    </Select>
                   
                    {  
                      isExpanded ? 
                          <div className='close-button' onClick={() => handleExpandClick()}>
                            <img src={"/close-icon.svg"} alt="Close Icon"/>
                            <p className='close-text'>Close</p>
                          </div> 
                        : 
                          <div className='filter-button' onClick={() => handleExpandClick()}>
                            <img src={"/filter_icon.svg"} alt="Select Filter" className='filter-icon'/>
                            <p className='filter-text'>Select Filters</p>
                          </div> 
                    }

                </div>

                {isFiltered ? 
                  <div className='selected-filters'>
                      <StyledChip label='Clear All' onDelete={clearAllFilters} />
                      {
                        !isNaN(fromValue) && !isNaN(toValue) ?
                          <StyleChip label={`${getReadableDateOnly(fromValue)} - ${getReadableDateOnly(toValue)}`} onDelete={() => clearSelectedFilters('dateFilters', null)} />
                        : 
                          null

                      }
                    {
                      filters && (
                        Object.entries(filters).map(([key, value]) =>
                        { 
                          return (value !== 'All' && value !== '') ? (
                            <StyleChip  label={`${key}: ${value}`} onDelete={() => clearSelectedFilters(key, value)} />
                          ) : null
                        }
                      ))
                    }
                    {
                      selectedOptions && (
                        Object.entries(selectedOptions).map(([category, value]) =>
                        (value !== 'All' && value !== '') ? (
                          <StyleChip label={getDisplayName(category, value)} onDelete={() => clearSelectedFilters(category, value)} />
                        ) : null
                      ))
                    }
                  </div>
                : 
                  <div/>
                }
                

                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  
                  <img src="/hr-long.svg" alt="hr" style={{width: '98%', margin: `10px`}}/>

                  <div className='filter-button-div'>

                      <button className={activeIndex === 0 ? 'active-filter-button' : 'inactive-filter-button'} onClick={() => handleActiveButton(0)}>Filter by Date</button>

                      <button className={activeIndex === 1 ? 'active-filter-button' : 'inactive-filter-button'} 
                              onClick={() => handleActiveButton(1)} disabled={value1 && !value2} >
                                Filter by Place
                      </button>
                      {
                        accountType && accountType.length > 0 && accountType !== 'Daksh SI' && accountType !== 'Basic' ?
                          <button className={activeIndex === 2 ? 'active-filter-button' : 'inactive-filter-button'} 
                            onClick={() => handleActiveButton(2)} disabled={value1 && !value2} >
                              Filter by Data
                          </button> 
                        : 
                          <></>
                      }
                  </div>

                  <div className="filter-container">
                        {
                            activeIndex === 0 ? 
                            <div className='container' >
                                {showHelper && <p className="helper-text">Please select the End Date</p>}
                                <div className='date-filter-container' style={{marginTop:`1.25rem`}}>
                                <div className='date-picker'>
                                    <p className='text1'> Start Date </p> 
                                    <LocalDatePicker 
                                    value={value1} 
                                    setValue={handleStartDateChange}
                                    />
                                </div>

                                <div className="date-picker">              
                                
                                    <p className="text1">End Date</p>
                                    <LocalDatePicker
                                    value={value2}
                                    setValue={handleEndDateChange}
                                    open={openEndDateCalender}
                                    onClose={() => setOpenEndDateCalender(false)}
                                    minDate={value1}
                                    />
                    
                                </div>
                                </div>
                            </div> 
                            : 
                            <></> 
                        }
                    
                    { 
                        activeIndex === 1 ? 
                          Object.keys(availableFilters).map((filterType,key) => (
                             <Filter key={key} heading={filterType} options={availableFilters[filterType]} onFilterChange={handleFilterChange} selectedValue={filters[filterType]}  />
                          )) 
                        : 
                          null
                    }

                      { 
                      
                        activeIndex === 2 ? 
                            Object.keys(medicalData).map((obj, index) => (
                                <div key={index}>
                                    <StyledInputLabel variant="standard" className='input-label'> {formatString(obj)} </StyledInputLabel>
                                        <StyledSelect labelId="demo-simple-select-label" id="demo-simple-select" 
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                        transformOrigin: {
                                          vertical: 'top',
                                          horizontal: 'left',
                                        },
                                        getContentAnchorEl: null, // This is important to prevent the Menu from using the Select input as the anchor
                                      }}
                                      value={selectedOptions[obj] || "All"} onChange={(event) => handleSelectChange(event, obj)}>
                                      
                                      {Object.entries(medicalData[obj]).map(([label, value], index) => {
                                        return (
                                          <StyledMenuItem key={index} value={value}>{label}</StyledMenuItem>
                                        );
                                      })}
                                    </StyledSelect>
                                </div> 
                        )) 
                        : 
                          null
                    }

                  </div>

                  <div className='apply-filter-container'>
                    <button disabled={!isFiltered} className={isFiltered ? 'apply-button' : 'inactive-apply-button'} onClick={() => handleApplyFilters()}>Apply & Search</button>
                  </div>
                
                </Collapse> 

            </div>

          </div>

          <div className='reports'>
         
            {
              searchError ? 
                  <div className='search-error-div'>
                    No Patients Found
                  </div> 
                : 
                  <>

                    <div className='result-container'>
                      <p> Results found - {totalPatient ? totalPatient : searchPatients && searchPatients.length > 0 ? searchPatients.length : 'No patient found'}</p>
                      {
                        accountType && accountType.length > 0 && accountType !== 'Basic' ?
                            <p className='download-link' onClick={() => handleReportDownload()}> Download All Reports </p>
                          : 
                            null
                      }
                    </div> 

                    <div className='patient-data-section'>

                        <InfiniteScroll dataLength={isSearch? searchPatients.length : patients.length  } next={searchPatients && searchPatients.length > 0 ? null : fetchPlaceIDs} hasMore={hasMore} loader={<Loading/>}
                        >

                          <div className='patient-list'>
                            {
                              ((patients.length > 0 && patients) ||  (searchPatients && searchPatients.length > 0))

                                ? 
                                    ( patients.length > 0 ? patients : searchPatients).map((obj, index) => {
                                      let patientId = obj.basic_detail.id;
                                      let lastUpdateTime = obj.basic_detail.last_update_time;
                                      let patientName = obj.basic_detail.name;
                                      let mrn = obj.basic_detail.mrn;
                                      
                                      // if cervix_diameter is less than 5 than view partograph button will be disable else it will be able
                                      const new_data = obj.labor_data && obj.labor_data.map(obj => ({...obj, data_time: obj.data_time *= 1000 }))
                                      const sorted_data = new_data && new_data.sort((a, b) => (a.data_time > b.data_time) ? 1 : -1);
                                      let disablePartograph = sorted_data && sorted_data.filter(obj => obj.data_type === 'cervix_diameter' && parseInt(obj.param_1) >= 5).length > 0 ? true : false


                                      const refer_details = getReferralDetails(obj.events, obj.place_details)

                                      let referralArray = [];

                                      if(refer_details.length > 0) {
                                      
                                          let placeIdExists = places.find((place) => place.id === refer_details[refer_details.length-1].referred_place_id)

                                          if(placeIdExists) {
                                            
                                            if (refer_details[refer_details.length - 1].refer_message_mother.length > 0) {
                                              let refer_mother_from = {
                                                  refer_place: refer_details[refer_details.length - 1].place_name,
                                                  refer_type: 'from',
                                                  refer_person: 'Mother',
                                                  refer_mother_msg: Array.isArray(refer_details[refer_details.length - 1].refer_message_mother) ? refer_details[refer_details.length - 1].refer_message_mother.join(' , ') : " "
                                              }
                                              
                                              referralArray.push(refer_mother_from);
                                            }

                                            if (refer_details[refer_details.length - 1].refer_message_child.length > 0) {
                                              let refer_child_from = {
                                                  refer_place: refer_details[refer_details.length - 1].place_name,
                                                  refer_type: 'from',
                                                  refer_person: 'Child',
                                                  refer_child_msg:  Array.isArray(refer_details[refer_details.length - 1].refer_message_child) ? refer_details[refer_details.length - 1].refer_message_child.join(' , ') : " "
                                              };
                                              referralArray.push(refer_child_from);
                                            }

                                          } else {
                                              if(refer_details[refer_details.length-1].refer_message_mother.length > 0){

                                                let refer_mother_to = {
                                                  refer_place : refer_details[refer_details.length-1].referred_place_name,
                                                  refer_type : 'to',
                                                  refer_person : 'Mother',
                                                  refer_mother_msg:  Array.isArray(refer_details[refer_details.length - 1].refer_message_mother) ? refer_details[refer_details.length - 1].refer_message_mother.join(' , ') : ""
                                                }
                                                referralArray.push(refer_mother_to)
                                              }

                                              if (refer_details[refer_details.length - 1].refer_message_child.length > 0) {
                                                let refer_child_to = {
                                                    refer_place: refer_details[refer_details.length - 1].referred_place_name,
                                                    refer_type: 'to',
                                                    refer_person: 'Child',
                                                    refer_child_msg:  Array.isArray(refer_details[refer_details.length - 1].refer_message_child) ? refer_details[refer_details.length - 1].refer_message_child.join(' , ') : ""
                                                };
                                                referralArray.push(refer_child_to);
                                            }
                                          }
                                      }

                                      return (
                                            <>
                                              {
                                                  <div key= {obj.id} className={`patient-obj ${patientTab === 'all_patient' && obj.basic_detail.current_status === "post_partum_process" ? 'postpartum-border' 
                                                    : patientTab === 'all_patient' && obj.basic_detail.current_status === "intra_partum_process" ? 'intrapartum-border' 
                                                    : patientTab === 'all_patient' && obj.basic_detail.current_status === "completed" ? 'completed-border' : null}`}  >
                                                    
                                                    <div className='patient-details'>

                                                      {
                                                        obj.basic_detail?.name.length > 30 ?
                                                          <span style={{display: 'inline-flex'}}>
                                                              <TooltipComponent title={`${obj.basic_detail.name}`} content={limitStr(obj.basic_detail.name, 30, 'patient-data')}/>
                                                              <span style={{ display: 'inline-block' }}>
                                                                <p className='patient-data'>
                                                                {'  '} ({isMrn ? obj.basic_detail.id : obj.basic_detail.mrn}) - {obj.basic_detail.age && obj.basic_detail.age !== "NA" ? `${obj.basic_detail.age} years` : "NA"}
                                                                </p>
                                                              </span>
                                                          </span>
                                                        : 
                                                          <p className='patient-data'>
                                                            {(obj.basic_detail.name)} ({isMrn ? obj.basic_detail.id : obj.basic_detail.mrn}) {obj.basic_detail.age && obj.basic_detail.age !== "NA" ? `  - ${obj.basic_detail.age} years`  : `- NA`}
                                                          </p>
                                                      }

                                                      <div className='patient-update'>

                                                        {
                                                          
                                                          patientTab === 'all_patient' && obj.basic_detail.current_status === "post_partum_process"  && accountType && accountType.length > 0 && accountType !== 'Basic' ? 
                                                            <div className='postpartum-box' > Postpartum </div>
                                                          : patientTab === 'all_patient' && obj.basic_detail.current_status === "intra_partum_process" ? 
                                                            <div className='intrapartum-box'>{accountType && accountType.length > 0 && accountType === 'Basic' ? 'Live Patients' : 'Intrapartum'}</div>
                                                          : patientTab === 'all_patient' && obj.basic_detail.current_status === "completed" ? 
                                                            <div className='completed-box'> Completed</div> 
                                                          : 
                                                          null 

                                                        }

                                                        <div className='update'>
                                                          <img src="/patient_Updation.svg " alt="Update Icon"></img>
                                                          <p className='last-sync-text'> Last Sync : { (obj.basic_detail?.last_update_time) ? getReadableTime(obj.basic_detail.last_update_time) : '' }</p>
                                                        </div>

                                                      </div>

                                                    </div>

                                                    <div className='information'>

                                                        <div className='patient-info'> 

                                                          <div>
                                                              <p className='text-1'>Admitted Facility</p>
                                                              {
                                                                  obj?.place_details[obj?.basic_detail?.registration_place_id]?.name && obj?.place_details[obj?.basic_detail?.registration_place_id]?.name.length > 18 ? 
                                                                    <TooltipComponent className= "text-2" title={`${obj?.place_details[obj?.basic_detail?.registration_place_id]?.name}`} content={limitStr(obj?.place_details[obj?.basic_detail?.registration_place_id]?.name, 18, 'text-2')} />
                                                                : 
                                                                    <p className='text-2'>{obj?.place_details[obj?.basic_detail?.registration_place_id]?.name ? obj?.place_details[obj?.basic_detail?.registration_place_id]?.name : '--'}</p>
                                                              }
                                                          </div>

                                                          { 
                                                            accountType && accountType.length > 0 && accountType !== 'Daksh SI' ?  
                                                                <div>
                                                                    <p className='text-1'>Doctor</p>
                                                                    {
                                                                        obj.user_details[obj.basic_detail.registration_doctor_id]?.first_name && obj.user_details[obj.basic_detail.registration_doctor_id]?.first_name.length > 18 ?
                                                                            <TooltipComponent title={`${obj.user_details[obj.basic_detail.registration_doctor_id]?.first_name}`} content={limitStr(obj.user_details[obj.basic_detail.registration_doctor_id]?.first_name, 18, 'text-2')} />
                                                                            : 
                                                                            <p  className='text-2'>{(obj.user_details[obj.basic_detail.registration_doctor_id]?.first_name) ? obj.user_details[obj.basic_detail.registration_doctor_id]?.first_name : "--"}</p>
                                                                    }
                                                                </div>
                                                              :  
                                                                <></> 
                                                          }

                                                          <div>
                                                              <p className='text-1'>Nurse</p>
                                                              {
                                                                  obj.user_details[obj.basic_detail.registration_worker_id]?.first_name && obj.user_details[obj.basic_detail.registration_worker_id]?.first_name.length > 18 ?
                                                                      <TooltipComponent title={`${obj.user_details[obj.basic_detail.registration_worker_id]?.first_name}`} content={limitStr(obj.user_details[obj.basic_detail.registration_worker_id]?.first_name, 18,'text-2')} />
                                                                      : 
                                                                      <p className='text-2'>{(obj.user_details[obj.basic_detail.registration_worker_id]?.first_name) ? obj.user_details[obj.basic_detail.registration_worker_id]?.first_name : "--"}</p>
                                                              }
                                                          </div>

                                                          <div>
                                                              <p className = 'text-1'> Patient Phone No</p>
                                                              <p className = 'text-2'> {obj?.basic_detail?.phone === "NA" ? "--" : obj.basic_detail.phone} </p>
                                                          </div> 

                                                          {
                                                            accountType && accountType.length > 0 && accountType === 'Basic' ?
                                                              <div>
                                                                <p className='text-1'>Number of Fetus</p>
                                                                <p className='text-2'>{obj?.obstetric_histories && obj?.obstetric_histories.length > 0 ? obj.obstetric_histories[0].no_of_children : "--"}</p> 
                                                              </div>  
                                                              : 
                                                                <></>
                                                          }

                                                          {
                                                            accountType && accountType.length > 0 && accountType === 'Basic' ?
                                                              <div>
                                                                <p className='text-1'>Presentation</p>
                                                                <p className='text-2'>{(obj.pa_pv_examination.presentation) ? obj.pa_pv_examination.presentation : "--"}</p> 
                                                              </div>  
                                                              : 
                                                                <></>
                                                          }

                                                          { 
                                                            accountType && accountType.length > 0 && accountType !== 'Daksh SI' && accountType !== 'Basic'?  
                                                              <div>
                                                                  <p className='text-1'>Guardian</p>
                                                                  {
                                                                      obj?.basic_detail?.guardian_name && obj?.basic_detail?.guardian_name.length > 18 ?
                                                                          <TooltipComponent title={`${obj.basic_detail.guardian_name}`} content={limitStr(obj.basic_detail.guardian_name, 18, "text-2")} />
                                                                          :
                                                                          <p className='text-2'>{(obj.basic_detail.guardian_name) ? obj.basic_detail.guardian_name : "--"}</p>
                                                                  }
                                                                </div>
                                                        
                                                            : 
                                                                <></> 
                                                          }

                                                          {
                                                            accountType && accountType.length > 0 && accountType !== 'Basic' ?
                                                              <div>
                                                                <p className='text-1'>Admission Time</p>
                                                                <p className='text-2'>{ (obj.events.filter(obj => obj.event==="registered")[0] !== undefined) ? getReadableTime(obj.events.filter(obj => obj.event==="registered")[0].event_time) : '--' }</p> 
                                                              </div>  
                                                              : 
                                                                <></>
                                                          }

                                                          {
                                                          accountType && accountType.length > 0 && accountType !== 'Basic' ?
                                                              <div>
                                                                  <p className='text-1'>Delivery Time</p>
                                                                  <p className='text-2'>{ (obj.events.filter(obj => obj.event==="delivered")[0] !== undefined) ? getReadableTime(obj.events.filter(obj => obj.event==="delivered")[0].event_time) : '--' }</p>
                                                              </div> 
                                                            : 
                                                              <></>
                                                          }
                                                          
                                                        {
                                                          accountType && accountType.length > 0 && accountType !== 'Basic' ?
                                                            <div>
                                                              <p className='text-1'>Discharge Time</p>
                                                              {
                                                                obj.basic_detail.current_status === "completed" && obj.events.length !== undefined ? 
                                                                  <p className='text-2'>{ (obj.events.filter(obj => obj.event==="completed")[0] !== undefined) ? getReadableTime(obj.events.filter(obj => obj.event==="completed")[0].event_time) : '--' } </p> 
                                                                : 
                                                                  <p> -- </p> 
                                                              }
                                                            </div>
                                                            : 
                                                            <></>
                                                        }
                                                          
                                                              
                                                          { 
                                                            accountType && accountType.length > 0 && accountType !== 'Daksh SI' && accountType !== 'Basic'?
                                                              <div>
                                                                <p className='text-1'>Mother Status</p>
                                                                {
                                                                  Object.keys(obj.delivery_detail).length !== undefined && Object.keys(obj.delivery_detail).length > 0 ? 
                                                                    <p className='text-2'> {obj.delivery_detail.mother_status} </p> 
                                                                  : 
                                                                  <p> -- </p> 
                                                                }
                                                              </div> 
                                                            :
                                                              <></> 
                                                          }

                                                          { 
                                                            accountType  && accountType.length > 0 && accountType !== 'Daksh SI' && accountType !== 'Basic' ?  
                                                              <div>
                                                                <p className='text-1'>Child Status</p>
                                                                {
                                                                  Object.keys(obj.delivery_detail).length !== undefined && Object.keys(obj.delivery_detail).length > 0 ? 
                                                                    <p className='text-2'>
                                                                      {obj.delivery_detail?.child_details.length > 0 ? getChildStatusString(obj.delivery_detail) : '--'}
                                                                    </p> 
                                                                  : 
                                                                    <p>--</p> 
                                                                }
                                                              </div> 
                                                            : <></>
                                                          } 
                                                                    
                                                        </div>

                                                        <img src = "/horizontal_Icon.svg" alt=''/>
                                                        
                                                        {
                                                          accountType &&  accountType.length > 0 && accountType === 'Premium' &&
                                                          <div className='Alerts'> 
                                                            <CardAlerts patientID={obj.id} patientName={patientName} lastUpdateTime={lastUpdateTime} alerts={obj.alerts} complications={obj.complications} statusTab={patientTab}/>
                                                          </div>
                                                        }
                                                       

                                                        <div className = { accountType && accountType.length > 0 && accountType !== 'Daksh SI' && accountType !== 'Basic' ? 'buttons': 'osi-buttons'} >
                                                          {
                                                            accountType &&  accountType.length > 0 && accountType !== 'Daksh SI' && accountType !== 'Basic' ?
                                                                <button disabled={!disablePartograph} className={disablePartograph ? 'active-partograph-button' : 'inactive-partograph-button'} onClick={(e) => openViewPartograph(patientId)}>View Partograph</button>
                                                            : 
                                                                <></>
                                                          }
                                                          <button className='active-button' onClick={() => openViewFile(patientId, patientName, lastUpdateTime, mrn)}> View Files </button> 
                                                        </div>
                                                            
                                                    </div>
                                                   
                                                    {
                                                       accountType &&  accountType.length > 0 && accountType === 'Premium' &&
                                                          <div className='referral-div'>
                                                          {
                                                            referralArray && referralArray.length > 0 ?
                                                              referralArray.map((value, index) => {
                                                                return (
                                                                  <div className = {`referral-info ${value.refer_person === 'Mother' && value.refer_type === 'to' ? 'yellow-background' 
                                                                    : value.refer_person === 'Mother' && value.refer_type === 'from' ? 'blue-background' 
                                                                    : value.refer_person === 'Child' && value.refer_type === 'from' ? 'brown-background' 
                                                                    : value.refer_person === 'Child' && value.refer_type === 'to' ? 'pink-background' : null}`} key = {index}>
                                                                      
                                                                        <img className='person-image' 
                                                                          src = {value.refer_person === 'Mother' && value.refer_type === 'to' ? '/mother-outgoing.svg'  
                                                                          : value.refer_person === 'Mother' && value.refer_type === 'from' ? '/mother-incoming.svg' 
                                                                          : value.refer_person === 'Child' && value.refer_type === 'from' ? '/child-incoming.svg' 
                                                                          : value.refer_person === 'Child' && value.refer_type === 'to' ? '/child-outgoing.svg' : null} 
                                                                          alt = "Person Images"/>
                                                                        <p>{value.refer_person} has been referred 
                                                                          <span>{value.refer_type} </span>{value.refer_place} hospital for 
                                                                          {value.refer_person === 'Mother' ? value.refer_mother_msg : value.refer_child_msg} reason
                                                                        </p>
                                                                        
                                      
                                                                  </div>
                                                                )
                                                              })
                                                              : null
                                                          }
    
                                                          </div>
                                                    }

                                                  </div> 
                                              } 
                                              
                                            </>
                                            
                                      )
                                      }
                                    ) 
                                  : 
                                    null 
                            }
                          </div>

                        </InfiniteScroll>

                    </div>
                  </>
            }
              
          </div>

          {  
            openPartograph ? 
              <PartographLayout  openPartograph={openPartograph} setOpenPartograph={setOpenPartograph} handleClickOpen={openViewPartograph} handleClosePartograph={handleClosePartograph} patientID={patientID}/> 
            : 
              null
          }

        </>
     }

    </div>
  )  
}          

const mapDispatchToProps = {
  addPatientDetail
}

const mapStateToProps = (state) => ({
  //place_detail: state.placeDetail.placeDetail,
  //configuration: state.configuration.configuration,  
});

export default connect(mapStateToProps, mapDispatchToProps)(Report)
