import React,{useState, useEffect} from 'react'
import '../Cards/DetailCard.scss'
import Card from './Card'
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import BackButton from '../../common/BackButton';
import SearchButton from '../../common/SearchButton';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import urls from '../../../utils/url';
import Loader from '../../common/loader';
import CentralMonitorError from '../../../pages/error/centralMonitorError';
import Cookie from 'js-cookie';

const DetailCard = ({keyarData}) => {

  const navigate = useNavigate();

  const cookie = Cookie.get('access_token');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const temp_keyar_data = [...keyarData].sort((a, b) => a.patientID - b.patientID);

    const currentTime = Date.now();
    const sixtyMinutesAgo = currentTime - (60 * 60 * 1000);

    const filteredPatients = temp_keyar_data.filter(patient => {
      // Check each patient's keyarData for any latest_data timestamps within the last 60 minutes
      return patient.keyarData.some(dataItem => {
        return Object.keys(dataItem.latest_data).some(timestamp => {
          return parseInt(timestamp) >= sixtyMinutesAgo;
        });
      });
    });


    setData(filteredPatients)

    if (filteredPatients.length > 0){
      setError(false)
    } else {
      setError(true)
    }

  }, [keyarData])
  

  
  

  const logout = () => {
    setLoading(true)
    axios.post(`${urls.BETA_URL}/nurse/logout/`,
    {
      "subscriber_id":  window.localStorage.getItem('onesignalId')
    },
    {
      headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
    }
    ).then((res) => {
      localStorage.removeItem('access_token')
      localStorage.removeItem('username')
      navigate('/')
      setLoading(false)
    }).catch((error) => {
        console.log('logout failed', error)
        setLoading(false)
    })
    
  }

  const back = () => {
    navigate('/dashboard/report')
  }

  

  return (
    <>
    {
      loading ? 
        <div style={{marginTop: '20%', position: 'relative'}}>
          <Loader/>
        </div>
      : 
        
        <div className="main">
          <div className='header'>
            <div onClick={back}>
              <BackButton/>
            </div>
            
            <p className='real-time-monitoring'>Real Time Monitoring</p>

            <div className='search-div'>
              <SearchButton/>
              {/* <img src="/three-dots.png" alt="" className='threedots' onClick={handleOpen}/>
              <Modal open={open} onClose={handleClose}>
                <div className='modal' style={{width: '100px', backgroundColor: '#FFF', height: '30px', border: 'none', left: '93%', position: 'relative', top: '55px'}}>
                  <p style={{textAlign: 'center', fontFamily: 'Poppins, sans-serif', padding: '5px'}} onClick={logout}>Log Out</p>
                </div>
              </Modal> */}
            </div>
            
          </div>
          
          { data && data !== undefined && data.length > 0 ? 
            <div style={{marginTop: 10}} className='grid'>
              {
                data.map( (obj,key) => (
                  <Card props={obj} key={key}/> 
                ))
              }
            </div>
            :
            error ? 
              <CentralMonitorError/>
            :
              <div/>
          }  
        </div>
    }
    </>

    
  )
}


const mapStateToProps = (state) => ({
  keyarData: state.keyarData.keyarData,
});

export default connect(mapStateToProps)(DetailCard)