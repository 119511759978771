import React from 'react'
import '../common/common.scss'

const NoData = () => {

  return (
    <>
       <img src='/no_data_recorded.png' alt="no-data-recorded" className='no-recorded-data' />
    </>
  )
}

export default NoData