import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/login/login";
import CentralMonitor from "../pages/central_monitor/centralMonitor";
import ChartLayout from "../pages/central_monitor/central_monitor_graph/chartLayout";
import Report from "../pages/report/report";
import Analytics from "../pages/analytics/analytics";
import Dashboard from "../pages/dashboard";
import ViewLayout from "../components/viewFiles/viewLayout";
import { useNavigate } from "react-router-dom";
import React, {useEffect} from 'react'


export const router = createBrowserRouter([ 
    {
        path: "/",
        id: "Login",
        element: <Login/>,
        //errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/central-monitor",
        id: "Central-Monitor",
        element: <CentralMonitor/>,
        //errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/central-monitor/graph/:patientID",
        id: "Graph",
        element: <ChartLayout/>,
        //errorElement: <ErrorPage />,
    },
      {
        path: "/dashboard/report",
        id: "Report",
        element: <Dashboard/>,
        //errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/analytics",
        id: "Analytics",
        element: <Dashboard/>,
        //errorElement: <ErrorPage />,
    },
    {
        path: "/logout",
        id: "logout",
        element: <Dashboard/>,
        //errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/profile",
        id: "Profile",
        element: <Dashboard/>,
        //errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/in-process",
        id: "In-Process",
        element: <Dashboard/>,
        //errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/patient/:patientID",
        id: "ViewLayout",
        element: <ViewLayout/>,
        //errorElement: <ErrorPage />,
    },
    // Wildcard route
    {
        path: "*",
        id: "CatchAll",
        element: <CatchAllRedirect />,
    }
])


function CatchAllRedirect() {
    const navigate = useNavigate();
    
    // Redirect to "/" when the path doesn't match any other routes
    useEffect(() => {
      navigate("/", { replace: true });
    }, []);
  
    return null; // No need to render anything
  }