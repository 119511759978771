import React from 'react';
import { useState, useEffect } from 'react';
import '../common.scss';
import axios from 'axios';
import urls from '../../../utils/url';
import { getReadableTime,getReadableDateOnly, getReadableTimeOnly, limitStr, charLimit, isJson, TooltipComponent } from '../../../utils/converter';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import Cookie from 'js-cookie';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

const StyledInputLabel = styled(InputLabel)({
  color: '#171717',
  fontSize: '16px',
  fontWeight: '500',
  fontFamily: 'Poppins',
});

const StyledSelect = styled(Select)({
  '& .MuiPaper-root': {
    backgroundColor: '#faf6ff', // This will change the background color of the dropdown
  },
  borderRadius: '4px',
  border: '0.5px solid #BFBFBF',
  background: '#fff',
  width: '100%', // You can adjust the width as needed
  '& .MuiSelect-select': {
    //background: '#faf6ff',
    height: 'auto', // This might be too small, adjust as needed
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: 'Poppins !important',
  // '&:hover': {
  //   backgroundColor: '#faf6ff', // for hover state
  // },
  '&.Mui-selected': {
    backgroundColor: '#faf6ff', // for selected item
    '&:hover': {
      backgroundColor: '#faf6ff', // to maintain the same color on hover for a selected item
    },
  },
});

const BasicInfo = (props) => {

  const { patientID } = props;
  const cookie = Cookie.get('access_token')
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ patientBasicInfo, setPatientBasicInfo ] = useState({});
  const [doctorNotes, setDoctorNotes] = useState({})
  const [selectedDoctor, setSelectedDoctor] = useState(Object.keys(doctorNotes)[0] || '');
  const [selectedNotes, setSelectedNotes] = useState(doctorNotes[selectedDoctor] || []);

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=basic_detail,admission_note,treatment_detail,alerts,complications,notes,user_details&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)

            const doctor_notes = json_data.response[0]?.notes
            const user_details = json_data.response[0]?.user_details

            const doctorNotesObj = processNotes(doctor_notes, user_details);
            setDoctorNotes(doctorNotesObj)
            
            setPatientBasicInfo(json_data.response[0]);
            setLoading(false) 
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err);
          setError(true)
          setPatientBasicInfo()
          setLoading(false)
        }
      ) 
  }, [])

  const getDoctorNameById = (userId, user_details) => {
    return user_details[userId] ? `${user_details[userId].first_name} ${user_details[userId].last_name}`.trim() : '--';
  };


  const processNotes = (doctor_notes, user_details) => {
    const doctorNotes = {};

    doctor_notes.forEach(note => {
      const doctorName = getDoctorNameById(note.user_id, user_details);
      if (!doctorNotes[doctorName]) {
        doctorNotes[doctorName] = []; // Initialize with an empty array if not exists
      }
      // Push new note into the array
      doctorNotes[doctorName].push({
        date: note.note_time,
        note: note.note_text // Assuming the note text is stored in note_text
      });
    });

    return doctorNotes;
  };

  const handleSelectChange = (event) => {
    const doctorName = event.target.value;
    setSelectedDoctor(doctorName);
    setSelectedNotes(doctorNotes[doctorName] || []);
  };

  return (
    <>
      {loading ? <div className='loading-div'><Loading/></div> 
      : error ? <div className='reload-div'><Reload/></div>
      :
      <>
        <div className = 'info-section'>

          {/* Patient Basic Detail */}
          <>
            <div className = 'info-header'>
              <p className = 'heading'> Patient's Basic Details </p>
            </div>

            <div className = 'info-container'>
             
              <div>
                <p className='text1'>Patient's Name</p>
                {patientBasicInfo?.basic_detail?.name && patientBasicInfo?.basic_detail?.name.length > charLimit ? 
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.name}`} content={limitStr(patientBasicInfo?.basic_detail?.name, charLimit)} />
                  : 
                  <p className='text2'>{patientBasicInfo?.basic_detail?.name ? patientBasicInfo.basic_detail.name : '- -'}</p>
                }
              </div>


              <div>
                  <p className = 'text1'> Patient's Phone </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.phone ? patientBasicInfo?.basic_detail?.phone : "- -"} </p>
              </div>
                  
              <div>
                  <p className = 'text1'> Admission Time </p>
                  <p className = 'text2'> {getReadableTime(patientBasicInfo?.basic_detail?.register_time)} </p>
              </div>

              <div>
                  <p className = 'text1'> Last Data Time </p>
                  <p className = 'text2'> {getReadableTime(patientBasicInfo?.basic_detail?.last_update_time)} </p>
              </div>
                  
              <div>
                  <p className = 'text1'> Aadhar Number </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.aadhaar_number ? patientBasicInfo?.basic_detail?.aadhaar_number : "- -"} </p>
              </div>

              <div>
                <p className='text1'>Address</p>
                {patientBasicInfo?.basic_detail?.address && patientBasicInfo?.basic_detail?.address.length > charLimit ?
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.address}`} content={limitStr(patientBasicInfo?.basic_detail?.address, charLimit)} />
                  :
                  <p className='text2'>{patientBasicInfo?.basic_detail?.address ? patientBasicInfo.basic_detail.address : "- -"}</p>
                }
              </div>

              <div>
                <p className='text1'>Guardian Name</p>
                {patientBasicInfo?.basic_detail?.guardian_name && patientBasicInfo?.basic_detail?.guardian_name.length > charLimit ?
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.guardian_name}`} content={limitStr(patientBasicInfo?.basic_detail?.guardian_name, charLimit)} />
                  :
                  <p className='text2'>{patientBasicInfo?.basic_detail?.guardian_name ? patientBasicInfo.basic_detail.guardian_name : "- -"}</p>
                }
              </div>

              <div>
                  <p className = 'text1'> Guardian Relation </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.guardian_relation ? patientBasicInfo?.basic_detail?.guardian_relation : "- -" } </p>
              </div>

              <div>
                  <p className = 'text1'> Marital Status </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.marital_status ? patientBasicInfo?.basic_detail?.marital_status : "- -" } </p>
              </div>

              <div>
                  <p className = 'text1'> Bed Number </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.bed_number ? patientBasicInfo?.basic_detail?.bed_number : "- -" } </p>
              </div>

              <div>
                  <p className = 'text1'> BPL </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.bpl ? patientBasicInfo?.basic_detail?.bpl  : "- -"} </p>
              </div>
                  
              <div>
                <p className='text1'>Birth Companion</p>
                {patientBasicInfo?.basic_detail?.birth_companion && patientBasicInfo?.basic_detail?.birth_companion.length > charLimit ?
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.birth_companion}`} content={limitStr(patientBasicInfo?.basic_detail?.birth_companion, charLimit)} />
                  :
                  <p className='text2'>{patientBasicInfo?.basic_detail?.birth_companion ? patientBasicInfo.basic_detail.birth_companion : "- -"}</p>
                }
              </div>

              <div>
                <p className='text1'>MCTS ID</p>
                {patientBasicInfo?.basic_detail?.mcts_number && patientBasicInfo?.basic_detail?.mcts_number.length > charLimit ?
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.mcts_number}`} content={limitStr(patientBasicInfo?.basic_detail?.mcts_number, charLimit)} />
                  :
                  <p className='text2'>{patientBasicInfo?.basic_detail?.mcts_number ? patientBasicInfo.basic_detail.mcts_number : "- -"}</p>
                }
              </div>

              <div>
                <p className='text1'>IPD Registration Number</p>
                {patientBasicInfo?.basic_detail?.ipd_registration_number && patientBasicInfo?.basic_detail?.ipd_registration_number.length > charLimit ?
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.ipd_registration_number}`} content={limitStr(patientBasicInfo?.basic_detail?.ipd_registration_number, charLimit)} />
                  :
                  <p className='text2'>{patientBasicInfo?.basic_detail?.ipd_registration_number ? patientBasicInfo.basic_detail.ipd_registration_number : "- -"}</p>
                }
              </div>

              <div>
                <p className='text1'>OPD Registration Number</p>
                {patientBasicInfo?.basic_detail?.opd_registration_number && patientBasicInfo?.basic_detail?.opd_registration_number.length > charLimit ?
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.opd_registration_number}`} content={limitStr(patientBasicInfo?.basic_detail?.opd_registration_number, charLimit)} />
                  :
                  <p className='text2'>{patientBasicInfo?.basic_detail?.opd_registration_number ? patientBasicInfo.basic_detail.opd_registration_number : "- -"}</p>
                }
              </div>

              <div>
                <p className='text1'>Asha</p>
                {patientBasicInfo?.basic_detail?.asha && patientBasicInfo?.basic_detail?.asha.length > charLimit ? 
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.asha}`} content={limitStr(patientBasicInfo?.basic_detail?.asha, charLimit)} />
                  :
                  <p className='text2'>{patientBasicInfo?.basic_detail?.asha ? patientBasicInfo.basic_detail.asha : "- -"}</p>
                }
              </div>

              <div>
                  <p className = 'text1'> Age </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.age ? patientBasicInfo?.basic_detail?.age : "- -"} </p>
              </div>

              <div>
                  <p className = 'text1'> Booked </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.booked ? patientBasicInfo?.basic_detail?.booked  : "- -" } </p>
              </div>

            </div>
          </>
          
          {/* Patient Admission Note */}
          <>
              <div className = 'info-header'>
                <p className = 'heading'> Admission Notes </p>
              </div>

              <div className = 'info-container'>

                <div>
                  <p className='text1'>Chief Complaints</p>
                  {patientBasicInfo?.admission_note?.chief_complaints && patientBasicInfo?.admission_note?.chief_complaints.length > charLimit ?
                    <TooltipComponent title={`${patientBasicInfo?.admission_note?.chief_complaints}`} content={limitStr(patientBasicInfo?.admission_note?.chief_complaints, charLimit, 'text2')} />
                    :
                    <p className='text2'>{patientBasicInfo?.admission_note?.chief_complaints ? patientBasicInfo.admission_note.chief_complaints : "- -"}</p>
                  }
                </div>

                <div>
                    <p className = 'text1'> LMP </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.lmp ? getReadableTime (patientBasicInfo?.admission_note?.lmp) : "- -"} </p>
                </div>
                    
                <div>
                    <p className = 'text1'> EDD </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.edd ? getReadableTime(patientBasicInfo?.admission_note?.edd) : "- -"}</p>
                </div>

                <div>
                    <p className = 'text1'> Gravida </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.gravida ? patientBasicInfo?.admission_note?.gravida : "- -"} </p>
                </div>
                    
                <div>
                    <p className = 'text1'> Para </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.para ? patientBasicInfo?.admission_note?.para : "- -"} </p>
                </div>

                <div>
                    <p className = 'text1'> Living Children </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.living_children ? patientBasicInfo?.admission_note?.living_children : "- -"} </p>
                </div>

                <div>
                    <p className = 'text1'> Abortion </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.abortion ? patientBasicInfo?.admission_note?.abortion : "- -"} </p>
                </div>

                <div>
                  <p className='text1'>Other Chief Complaints</p>
                  {patientBasicInfo?.admission_note?.chief_complaints_other && patientBasicInfo?.admission_note?.chief_complaints_other.length > charLimit ?
                    <TooltipComponent title={`${patientBasicInfo?.admission_note?.chief_complaints_other}`} content={limitStr(patientBasicInfo?.admission_note?.chief_complaints_other, charLimit)} />
                    :
                    <p className='text2'>{patientBasicInfo?.admission_note?.chief_complaints_other ? patientBasicInfo.admission_note.chief_complaints_other : "- -"}</p>
                  }
                </div>

              </div>
          </>

          {/* Patient Treatment Details */}
          <>
            <div className = 'info-header'>
              <p className = 'heading'> Antenatal Treatment Details </p>
            </div>

            <div className = 'info-container'>
              <div>
                  <p className = 'text1'> TT </p>
                  <p className = 'text2'> {patientBasicInfo?.treatment_detail?.tt ? patientBasicInfo?.treatment_detail?.tt : "- -"} </p>
              </div>

              <div>
                  <p className = 'text1'> IFA taken </p>
                  <p className = 'text2'> {patientBasicInfo?.treatment_detail?.ifa_taken ? patientBasicInfo?.treatment_detail?.ifa_taken : "- -"} </p>
              </div>
                  
              <div>
                <p className='text1'>Other Drug</p>
                {patientBasicInfo?.treatment_detail?.other_drug_detail && patientBasicInfo?.treatment_detail?.other_drug_detail.length > charLimit ?
                  <TooltipComponent title={`${patientBasicInfo?.treatment_detail?.other_drug_detail}`} content={limitStr(patientBasicInfo?.treatment_detail?.other_drug_detail, charLimit)} />
                  :
                  <p className='text2'>{patientBasicInfo?.treatment_detail?.other_drug_detail ? patientBasicInfo.treatment_detail.other_drug_detail : "- -"}</p>
                }
              </div>

              <div>
                  <p className = 'text1'> Antenatal Steroid </p>
                  <p className = 'text2'> {patientBasicInfo?.treatment_detail?.antenatal_steroid ? patientBasicInfo?.treatment_detail?.antenatal_steroid : "- -"}</p>
              </div>

            </div>
          </>

        </div> 

          {/*Complications*/}
         <div className = 'info-section'>

          <div className = 'info-header'>
            <p className = 'heading'> Complications </p>
          </div>

          <div className = 'complication-container'>
                {
                  patientBasicInfo && patientBasicInfo?.complications && patientBasicInfo?.complications.length > 0 && patientBasicInfo?.complications != undefined && patientBasicInfo?.complications != null && patientBasicInfo?.complications != '' && Object.keys(patientBasicInfo?.complications).length > 0 ?
                  patientBasicInfo.complications.map((complicationData, index) => (
                    <div className='complications' key={index}>
                      <span className="complication-title"> {complicationData.complication_message} </span>
                      <span className="complication-title"> {getReadableDateOnly(complicationData.complication_time)}</span>
                      <span className="complication-title"> {getReadableTimeOnly(complicationData.complication_time)} </span>
                    </div>
                ))
                :
                  <div> No Complication Recorded </div>
                }
          </div>
         </div>

          {/*Doctor Notes */}
        <div className = 'info-section'>
            <div className = 'info-header'>
              <p className = 'heading'> Doctor Notes </p>
            </div>
            <div className = 'doctor-notes-container'>
                  
                { 
                  doctorNotes && Object.keys(doctorNotes || []).length > 0 && doctorNotes != undefined && doctorNotes != null && doctorNotes != '' ? 
                    <div>
                      
                      <p className='select-doctor-label'>Select Doctor</p>
                      
                      <FormControl fullWidth>
                        <StyledInputLabel id="demo-simple-select-label">Select Doctor</StyledInputLabel>

                        <StyledSelect
                          labelId="demo-simple-select-label"
                          value={selectedDoctor}
                          label="Age"
                          onChange={handleSelectChange}
                          MenuProps={{
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null, // This is important to prevent the Menu from using the Select input as the anchor
                            PaperProps: {
                              style: {
                                marginTop: '8px', // Adjust this as needed to create space between the Select and the Menu
                              },
                            },
                          }}
                        >
                          {Object.keys(doctorNotes).map((doctorName) => (
                            <StyledMenuItem value={doctorName}>{doctorName}</StyledMenuItem>
                          ))}
                          
                        </StyledSelect>
                      </FormControl>
                      {selectedNotes.length > 0 && (
                        <div >
                            
                            {selectedNotes.map((note, index) => (
                              <div className='note-displayer' key={index}>
                                <p className='note-date'>{note.note}</p>
                                <p className='note-date'>{`${getReadableDateOnly(note.date)}   ${getReadableTimeOnly(note.date)}`}</p>
                              </div>
                            ))}
                          
                        </div>
                      )}
                    </div>
                  :
                    <div>
                      <p>No Doctor Notes Recorded</p> 
                    </div>
                }

            </div>
        </div>

      </>
      }
    </> 
  )
}

export default BasicInfo