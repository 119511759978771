import React, { useEffect , useState} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import urls from '../../utils/url';
import '../casesheet/casesheet.scss'
import { getReadableTime, getReferralDetails, getPostPartumData, getLaborDataValue , isJson, convertChildCode, convertMotherCode} from '../../utils/converter';
import IntrapartumGraph from '../viewFiles/intrapartumData/IntrapartumGraph';
import { dataEntries } from '../../utils/sheetConstants';
import Cookie from 'js-cookie';
import Loading from '../common/loading';
import Reload from '../common/Reload';


const CaseSheetLayout = (props) => {

  const { patientID, caseSheetOpen, setCaseSheetOpen } = props;
  
  const cookie = Cookie.get('access_token')
  const [ loading, setLoading] = useState(false);
  const [ error, setError] = useState(false);
  const [ patientData, setPatientData] = useState();
  const [ postpartumparams, setpostpartumparams] = useState([]);
  const [ referralData, setReferralData] = useState([]);
  const [ partograph , setPartograph] = useState();
  const [ patientFormData, setPatientFormData] = useState();

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPatientData(json_data.response[0])
            setLoading(false) 
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err);
          setError(true)
          setPatientData()
          setLoading(false)
        }
      ) 
   }, [])

const printCaseSheet = () => {
    const closeButton = document.querySelector('.close-button'); 
    const printButton = document.querySelector('.print-button');
    const partograph = document.getElementById('partograph');

    let originalWidth;

    if (partograph) {
        // Store the original width of the partograph
        partograph.style.width = '900px';
    }
    
    closeButton.style.display = 'none';
    printButton.style.display = 'none';

    setTimeout(() => {
        window.print();
    }, 2000);

   
    window.addEventListener('afterprint', () => {
        partograph.style.width = originalWidth;
        closeButton.style.display = 'block';
        printButton.style.display = 'inline-flex';
    });
};

   useEffect(() => {
    
    if(patientData?.post_partum_data) {
       const postpartum_details =  getPostPartumData(patientData['post_partum_data'])
       setpostpartumparams([postpartum_details])
    }
    
    if(patientData?.events && patientData?.events.length > 0 && patientData?.place_details){
        const refer_details = getReferralDetails(patientData['events'], patientData['place_details'])
        setReferralData(refer_details)
    }

    if(patientData?.labor_data && patientData?.labor_data.length > 0){
        const new_data = patientData.labor_data.map(obj => ({...obj, data_time: obj.data_time *= 1000 }))
        const sorted_data = new_data.sort((a, b) => (a.data_time > b.data_time) ? 1 : -1);
        setPartograph(sorted_data.filter(obj => obj.data_type === 'cervix_diameter' && parseInt(obj.param_1) >= 5).length > 0)
        
    }
    
    if(patientData?.consent_details && patientData?.consent_details.length > 0){

        let array = [];
     
        if (patientData?.consent_details) {
            const formData = patientData?.consent_details.map((form, index) => {
                return form.consent_categories.map((categories, ind) => {
                    array.push({
                        form: {
                            consent_categorie: categories.category_name,
                            signee_name: form.signee_name,
                            signee_guardian_name: form.signee_guardian_name,
                            signee_guardian_relation: form.signee_guardian_relation,
                            signee_age: form.signee_age,
                            signee_address: form.signee_address,
                            signee_patient_relation: form.signee_patient_relation,
                            attendant_signature_file: form.attendant_signature_file,
                            attendant_name: form.attendant_name,
                            signee_signature_file : form.signee_signature_file
                        }
                    });
                });
            });
            
            setPatientFormData(array)
        }

    }

   },[patientData])

  const handleClose = () => {
    setCaseSheetOpen(false);
    setPatientData();
  }
  
 // For Consent Form
  const transformText = (text) => {
    const transformedText = text.split('_').map(word => {
      return word.toLowerCase();
    }).join(' ');
  
    return transformedText;
  }


  return (

    <>
        { loading ? <div className='loading-div' style={{paddingRight:`10%`, marginTop:`20%`}}><Loading/></div> 
        : error ? <div className='reload-div'><Reload/></div>
        :
        <div className = 'casesheet-container' id='casesheet'>

            <CloseIcon className = 'close-button' onClick = {() => handleClose()}/>

            <div className = 'sheet-header'>
                <button className = 'print-button' onClick = { () => printCaseSheet()}> Print Case Sheet </button>
                <h3 className = "sheet-header" style={{textAlign: `center`, textTransform:`capitalize`}}> {patientData?.place_details[patientData?.basic_detail?.registration_place_id].name} - CASE SHEET </h3>
            </div>
            
                {/* Basic Detail */}
                    <div className='print-container'>
                        <div className='sheet-info-header'>
                        <p className='heading '>Patient's Basic Details</p>
                        </div>
                        
                        <div className='sheet-info-container'>
                            <div className='sheet-info-section'>
                                <p className='text1'>Patient's Name</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.name ? patientData?.basic_detail?.name: "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Aadhar Number</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.aadhaar_number ? patientData?.basic_detail?.aadhaar_number : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Patient's Phone</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.phone ? patientData?.basic_detail?.phone : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Address</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.address ? patientData?.basic_detail?.address : "- -" }</p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className='text1'>Bed Number</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.bed_number ? patientData?.basic_detail?.bed_number : "- -" }</p>
                            </div> 

                            <div className='sheet-info-section'>
                                <p className='text1'>BPL</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.bpl ? patientData?.basic_detail?.bpl  : "- -"}</p>
                            </div>
                                
                            <div className='sheet-info-section'> 
                                <p className='text1'>Admission Time</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.register_time ? getReadableTime(patientData?.basic_detail?.register_time) : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Last Update Time</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.last_update_time ? getReadableTime(patientData?.basic_detail?.last_update_time) : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Age</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.age ? patientData?.basic_detail?.age : "- -" }</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Guardian Relation</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.guardian_relation ? patientData?.basic_detail?.guardian_relation  : "- -" }</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Guardian Name</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.guardian_name ? patientData?.basic_detail?.guardian_name : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>IPD Registration Number</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.ipd_registration_number ? patientData?.basic_detail?.ipd_registration_number : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>OPD Registration Number</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.opd_registration_number ? patientData?.basic_detail?.opd_registration_number: "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Marital Status</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.marital_status ? patientData?.basic_detail?.marital_status : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Birth Companion</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.birth_companion ? patientData?.basic_detail?.birth_companion  : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>MCTS ID</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.mcts_number ? patientData?.basic_detail?.mcts_number : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Booked</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.booked ? patientData?.basic_detail?.booked  : "- -" }</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className='text1'>Asha</p>
                                <p className='text2 breakableText'>{patientData?.basic_detail?.asha ? patientData?.basic_detail?.asha  : "- -" }</p>
                            </div>
                            
                        </div>
                    </div>
            
                {/* Admission Note */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Admission Note </p>
                        </div>
                        
                        <div className = 'sheet-info-container'>
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Chief Complaints </p>
                                <p className = 'text2 breakableText'> {patientData?.admission_note?.chief_complaints ? patientData?.admission_note?.chief_complaints : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> LMP </p>
                                <p className = 'text2 breakableText'> {patientData?.admission_note?.lmp ? getReadableTime(patientData?.admission_note?.lmp) : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> EDD </p>
                                <p className = 'text2 breakableText'> {patientData?.admission_note?.edd ? getReadableTime(patientData?.admission_note?.edd) : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Gravida </p>
                                <p className = 'text2 breakableText'> {patientData?.admission_note?.gravida ? patientData?.admission_note?.gravida : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Para </p>
                                <p className = 'text2 breakableText'> {patientData?.admission_note?.para ? patientData?.admission_note?.para : "- -"} </p>
                            </div >

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Living Children </p>
                                <p className = 'text2 breakableText'> {patientData?.admission_note?.living_children ? patientData?.admission_note?.living_children : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Abortion</p>
                                <p className = 'text2 breakableText'> {patientData?.admission_note?.abortion ? patientData?.admission_note?.abortion : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Chief Complaints Other </p>
                                <p className = 'text2 breakableText'> {patientData?.admission_note?.chief_complaints_other ? patientData?.admission_note?.chief_complaints_other : "- -"} </p>
                            </div>
                        </div>
                    </div>
                
                {/*  Medical History */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Medical History </p>
                        </div>
                        
                        <div className='sheet-info-container'>
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Hypertension </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.hypertension ? patientData?.medical_history?.hypertension : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Diabetes Mellitus </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.diabetese_mellitus ? patientData?.medical_history?.diabetese_mellitus : "- -"} </p>
                            </div >

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Tuberculosis </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.tuberculosis ? patientData?.medical_history?.tuberculosis : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Bronchial Asthma </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.bronchial_asthma ? patientData?.medical_history?.bronchial_asthma : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Heart Disease </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.heart_disease ? patientData?.medical_history?.heart_disease : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Other Medical Conditions </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.other_medical_condition_details ? patientData?.medical_history?.other_medical_condition_details : "- -"} </p>
                            </div>
                            
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Surgery </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.surgery_detail ?  patientData?.medical_history?.surgery_detail : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Drug Allergy </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.drug_allergy_details ? patientData?.medical_history?.drug_allergy_details : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Contraceptive </p>
                                <p className = 'text2 breakableText'> {patientData?.medical_history?.contraceptive ? patientData?.medical_history?.contraceptive : "- -"} </p>
                            </div>
                                
                        </div>
                    </div>

                {/*  Family History */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Family History </p>
                        </div>
                        
                        <div className = 'sheet-info-container'>
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Hypertension </p>
                                <p className = 'text2 breakableText'> {patientData?.family_history?.hypertension ? patientData?.family_history?.hypertension : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Diabetes Mellitus </p>
                                <p className = 'text2 breakableText'> {patientData?.family_history?.diabetese_mellitus ? patientData?.family_history?.diabetese_mellitus : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Tuberculosis </p>
                                <p className = 'text2 breakableText'> {patientData?.family_history?.tuberculosis ? patientData?.family_history?.tuberculosis : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Asthma </p>
                                <p className = 'text2 breakableText'> {patientData?.family_history?.asthma ? patientData?.family_history?.asthma : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Twins </p>
                                <p className = 'text2 breakableText'> {patientData?.family_history?.twins ? patientData?.family_history?.twins : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Other Medical Conditions </p>
                                <p className = 'text2 breakableText'> {patientData?.family_history?.other_medical_condition_details ? patientData?.family_history?.other_medical_condition_details : "- -"} </p>
                            </div>
                        </div>
                    </div>
            
                {/* Obstetric History*/}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Obstetric History </p>
                        </div>
                        
                        { 
                            patientData?.obstetric_histories && patientData?.obstetric_histories.length > 0 ? 
                                patientData?.obstetric_histories.map((object, index) => {
                                    return (
                                        <div>
                                            <p style={{background:`#888888`, textAlign:`center`, color:`white`, marginLeft:`10px`, marginRight:`10px`}}> Child {index+1} </p>
                                            
                                            <div className='sheet-info-container'>
                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Pregnancy Year </p>
                                                    <p className = 'text2 breakableText'> {object?.pregnancy_year ? object?.pregnancy_year : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Pregnancy Complications </p>
                                                    <p className = 'text2 breakableText'> {object?.pregnancy_complication ? object?.pregnancy_complication : "- -"} </p>
                                                </div>
                                                    
                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Complication reason </p>
                                                    <p className = 'text2 breakableText'> {object?.complication_reason ? object?.complication_reason : "- -"} </p>
                                                </div >

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Complication reason other </p>
                                                    <p className = 'text2 breakableText'> {object?.complication_reason_other ? object?.complication_reason_other : "- -"} </p>
                                                </div>
                                    
                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> place of Delivery </p>
                                                    <p className = 'text2 breakableText'> {object?.delivery_place? object?.delivery_place : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Delivery place other </p>
                                                    <p className = 'text2 breakableText'> {object?.delivery_place_other ? object?.delivery_place_other: "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1' > mode of Delivery </p>
                                                    <p className = 'text2 breakableText' > {object?.delivery_mode ? object?.delivery_mode : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> outcome of Delivery </p>
                                                    <p className = 'text2 breakableText'> {object?.delivery_outcome ? object?.delivery_outcome : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Gestational Age </p>
                                                    <p className = 'text2 breakableText'> {object?.gestational_age ? object?.gestational_age : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> child weight (Kgs) </p>
                                                    <p className = 'text2 breakableText'> {object?.child_weights ? object?.child_weights : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> child gender </p>
                                                    <p className = 'text2 breakableText'> {object?.child_genders ? object?.child_genders : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> note </p>
                                                    <p className = 'text2 breakableText'> {object?.notes ? object?.notes : "- -"} </p>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            : 
                            <p style={{textAlign:`center`}}> There is no Obstetric History</p> 
                        }
                    </div>

                {/* Physical Examination */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Physical Examination </p>
                        </div>
                        
                        <div className = 'sheet-info-container'>
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Height(cms) </p>
                                <p className = 'text2 breakableText'> {patientData?.physical_examination?.height ? patientData?.physical_examination?.height : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Weight(kgs) </p>
                                <p className = 'text2 breakableText'> {patientData?.physical_examination?.weight ? patientData?.physical_examination?.weight : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Pallor </p>
                                <p className = 'text2 breakableText'> {patientData?.physical_examination?.pallor ? patientData?.physical_examination?.pallor : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Jaundice </p>
                                <p className = 'text2 breakableText'> {patientData?.physical_examination?.jaundice ? patientData?.physical_examination?.jaundice : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Pedal Edema </p>
                                <p className = 'text2 breakableText'> {patientData?.physical_examination?.pedal_edema ? patientData?.physical_examination?.pedal_edema : "- -"} </p>
                            </div>
                        </div>
                        
                    </div>
                
                {/* Pa Pv Examination */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Pa Pv Examination </p>
                        </div>
                        
                        <div className = 'sheet-info-container'>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Gestation type </p>
                                <p className = 'text2 breakableText'> {patientData?.pa_pv_examination?.gestation_type ? patientData?.pa_pv_examination?.gestation_type : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Gestation Period </p>
                                <p className = 'text2 breakableText'> {patientData?.pa_pv_examination?.gestation_period? patientData?.pa_pv_examination?.gestation_period : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Fundal Height </p>
                                <p className = 'text2 breakableText'> {patientData?.pa_pv_examination?.fundal_height ? patientData?.pa_pv_examination?.fundal_height : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Presentation </p>
                                <p className = 'text2 breakableText'> {patientData?.pa_pv_examination?.presentation ? patientData?.pa_pv_examination?.presentation : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Presentation Part </p>
                                <p className = 'text2 breakableText'> {patientData?.pa_pv_examination?.presenting_part ? patientData?.pa_pv_examination?.presenting_part : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Presenting Part Other </p>
                                <p className = 'text2 breakableText'> {patientData?.pa_pv_examination?.presenting_part_other ? patientData?.pa_pv_examination?.presenting_part_other : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Fetal Movements </p>
                                <p className = 'text2 breakableText'> {patientData?.pa_pv_examination?.fetal_movements ? patientData?.pa_pv_examination?.fetal_movements : "- -"} </p>
                            </div>
                            
                            <div className='sheet-info-section' >
                                <p className = 'text1'> Pelvis Adequacy </p>
                                <p className = 'text2 breakableText'> {patientData?.pa_pv_examination?.pelvic_adequacy ? patientData?.pa_pv_examination?.pelvic_adequacy : "- -"} </p>
                            </div>
                        </div>
                        
                    </div>

                {/* Investigation */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Investigation </p>
                        </div>
                        
                        <div className = 'sheet-info-container'>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Blood Group & Rh </p>
                                <p className = 'text2 breakableText'>{patientData?.investigation?.blood_group ? patientData?.investigation?.blood_group : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Haemoglobin </p>
                                <p className = 'text2 breakableText'>{patientData?.investigation?.haemoglobin ? patientData?.investigation?.haemoglobin : "- -"}</p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> VDRL/RPR </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.vdrl_rpr ? patientData?.investigation?.vdrl_rpr : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> HBS AG </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.hbs_ag ? patientData?.investigation?.hbs_ag : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> HIV </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.hiv ? patientData?.investigation?.hiv : "- -"}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Anti D </p>
                                <p className = 'text2 breakableText'> { patientData?.investigation?.anti_d ? patientData?.investigation?.anti_d : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Urine Albumin </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.urine_albumin ? patientData?.investigation?.urine_albumin : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Urine Sugar </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.urine_sugar ? patientData?.investigation?.urine_sugar : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> RBS  </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.rbs ? patientData?.investigation?.rbs : "- -"} </p>
                            </div>

                        
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Syphilis </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.syphilis ? patientData?.investigation?.syphilis : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Malaria </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.malaria ? patientData?.investigation?.malaria : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Staff Nurse Feedback </p>
                                <p className = 'text2 breakableText'> {patientData?.investigation?.staff_nurse_feedback ? patientData?.investigation?.staff_nurse_feedback : "- -"} </p>
                            </div>
                        </div>
                        
                    </div>

                {/* Treatment Detail */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Treatment Detail </p>
                        </div>
                        
                        <div className = 'sheet-info-container'>
                            <div className='sheet-info-section'>
                                <p className = 'text1'> TT </p>
                                <p className = 'text2 breakableText'> {patientData?.treatment_detail?.tt ? patientData?.treatment_detail?.tt : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> IFA taken </p>
                                <p className = 'text2 breakableText'> {patientData?.treatment_detail?.ifa_taken ? patientData?.treatment_detail?.ifa_taken : "- -"} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Other Drug Detail </p>
                                <p className = 'text2 breakableText'> {patientData?.treatment_detail?.other_drug_detail ? patientData?.treatment_detail?.other_drug_detail : "- -"} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Antenatal Steroid </p>
                                <p className = 'text2 breakableText'> {patientData?.treatment_detail?.antenatal_steroid ? patientData?.treatment_detail?.antenatal_steroid : "- -"} </p>
                            </div>

                        </div>
                        
                    </div>
                
                {/* If Data Entries(Labour Data) of a patient is available then only show that field(div) else not */}
                { patientData?.labor_data && patientData?.labor_data.length > 0 ?
                        <div className='print-container'>
                            <div className = 'sheet-info-header'>
                            <p className = 'heading'> Data Entries </p>
                            </div>

                            <div className = 'margin-section '>
                                <table style = {{width: `100%`, borderSpacing: `0px`, paddingLeft:`10px`, paddingRight:`10px`}}>
                                        <tr className = "table-grid" style = {{color: `#000`, background: `#888888`, textAlign: `center`}}>
                                            <td className = "content-label" style = {{color: `#ffffff`, border: `1px solid #000000`}}> Time </td>
                                            <td className = "content-label" style = {{color: `#ffffff`, border: `1px solid #000000`}}> Parameter Name </td>
                                            <td className = "content-label" style = {{color: `#ffffff`, border: `1px solid #000000`}}> Parameter Value </td>
                                        </tr>

                                        {patientData?.labor_data && patientData?.labor_data.length > 0 && patientData?.labor_data.map((row,ind) => (
                                            <tr className = "table-grid" style = {{color: `#000`, width: `100%`, textAlign: `center`}}>
                                                <td className = "content-label" style = {{border: `1px solid #000000`}}> {getReadableTime(row.data_time)} </td>
                                                <td className = "content-label" style = {{border: `1px solid #000000`}}> {dataEntries[row.data_type]} </td>
                                                <td className = "content-label" style = {{border: `1px solid #000000`}}> {getLaborDataValue(row)} </td>
                                            </tr>
                                        ))}
                                </table>
                            </div>
                        </div> 
                    : null}

                {/* Partograph */}
                    <div style={{paddingTop:`1rem`}} className='print-container'>
                        <div className = 'sheet-info-header'>
                            <p className = 'heading'> Partograph </p>
                        </div>
                        {
                            partograph ? 
                              <IntrapartumGraph patientID={patientID}/>
                            : 
                              <p style={{textAlign:`center`, paddingBottom:`1rem`}}>No Partograph Generated</p>
                        }
                    
                    </div>
                    
                {/* Pre Delivery Checklist */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Pre Delivery Checklist </p>
                        </div>
                        
                        <div className = 'sheet-info-container'>
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Spotlight </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.spotlight ? patientData?.pre_delivery_checklist?.spotlight : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Gloves </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.glove ? patientData?.pre_delivery_checklist?.glove : '--'} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Soap and Water </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.soap_and_water ? patientData?.pre_delivery_checklist?.soap_and_water : '--'} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Oxytocin Syringe </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.oxytocin_syringe ? patientData?.pre_delivery_checklist?.oxytocin_syringe : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Sanity Pad </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.sanity_pad ? patientData?.pre_delivery_checklist?.sanity_pad : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Towel </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.towel ? patientData?.pre_delivery_checklist?.towel : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Scissors </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.scissor ? patientData?.pre_delivery_checklist?.scissor : '--'} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Mucus Extractor </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.mucus_extractor ? patientData?.pre_delivery_checklist?.mucus_extractor : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Cord Ligature </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.cord_ligature ? patientData?.pre_delivery_checklist?.cord_ligature : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Bag and Mask </p>
                                <p className = 'text2 breakableText'> {patientData?.pre_delivery_checklist?.bag_and_mask ? patientData?.pre_delivery_checklist?.bag_and_mask : '--'} </p>
                            </div>

                        </div>
                        
                    </div>
                
                {/* Delievery Detail */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Delievery Detail </p>
                        </div>

                        <div className = 'sheet-info-container' style = {{marginBottom:`0px`}}>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Mother Status </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.mother_status ? patientData?.delivery_detail?.mother_status : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Delivery Type </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.delivery_type ? patientData?.delivery_detail?.delivery_type : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Place of delivery </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.delivery_hospital_type ? patientData?.delivery_detail?.delivery_hospital_type : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Number of Children </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.no_of_children ? patientData?.delivery_detail?.no_of_children : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Pushing Done </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.pushing_done ? patientData?.delivery_detail?.pushing_done : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Nurse ID </p>
                                <p className = 'text2 breakableText'> {patientData?.basic_detail?.registration_worker_id ? patientData?.basic_detail?.registration_worker_id : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Nurse Name </p>
                                <p className = 'text2 breakableText'>{patientData?.user_details[patientData?.basic_detail?.registration_worker_id]?.first_name ? patientData?.user_details[patientData?.basic_detail?.registration_worker_id]?.first_name : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Assist Provided </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.assist_provided ? patientData?.delivery_detail?.assist_provided : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Labour Type </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.labor_type ? patientData?.delivery_detail?.labor_type : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Completeness Checked  </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.completeness_checked ? patientData?.delivery_detail?.completeness_checked : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Placenta Weight 1 </p>
                                <p className = 'text2 breakableText'>{patientData?.delivery_detail?.placenta_weight_1 ? patientData?.delivery_detail?.placenta_weight_1 : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Placenta Weight 2 </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.placenta_weight_2 ? patientData?.delivery_detail?.placenta_weight_2 : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Blood Loss Estimation </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.blood_loss_estimation ? patientData?.delivery_detail?.blood_loss_estimation : '--' }</p>
                            </div>
                            
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Robson Classification </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.robson_classification ? patientData?.delivery_detail?.robson_classification : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Death Cause </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.death_cause ? convertMotherCode(patientData?.delivery_detail?.death_cause) : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Death Cause Other </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.death_cause_other ? patientData?.delivery_detail?.death_cause_other : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Death Time </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.death_time ? getReadableTime(patientData?.delivery_detail?.death_time) : '--'} </p>
                            </div>

                        </div>
                        
                        {
                            patientData?.delivery_detail?.child_details  && patientData?.delivery_detail?.child_details.length > 0 ? 
                                patientData?.delivery_detail?.child_details.map((object, index) => {
                                    return (
                                        <div>

                                            <p style = {{background:`#888888`, textAlign:`center`, color:`white`, marginLeft:`10px`, marginRight:`10px`}}> Child {index+1} </p>
                                            
                                            <div className = 'sheet-info-container'>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Child Status </p>
                                                    <p className = 'text2 breakableText'> {object?.child_status ? object?.child_status : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Gender </p>
                                                    <p className = 'text2 breakableText'> {object?.gender ? object?.gender : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Weight(Kgs) </p>
                                                    <p className = 'text2 breakableText'> {object?.weight ? object?.weight : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Delivery Time </p>
                                                    <p className = 'text2 breakableText'> {object?.delivery_time ? getReadableTime(object?.delivery_time) : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Apgar Score </p>
                                                    <p className = 'text2 breakableText'> {object?.apgar_score ? object?.apgar_score : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Death Cause </p>
                                                    <p className = 'text2 breakableText'> {object?.child_death_cause ? convertChildCode(object?.child_death_cause) : '--' } </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Death Cause Other </p>
                                                    <p className = 'text2 breakableText'> {object?.child_death_cause_other ? object?.child_death_cause_other : '--'} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Death Time </p>
                                                    <p className = 'text2 breakableText'> {object?.child_death_time ? getReadableTime(object?.child_death_time) : '--'} </p>
                                                </div>

                                            </div>

                                        </div>
                                    )
                                })
                            : null
                    }
                    </div>
        
                {/* Post Delievery Checklist */}
                    <div className='print-container'>
                        <div className = 'sheet-info-header'>
                        <p className = 'heading'> Post Delivery Checklist </p>
                        </div>

                        <div className = 'sheet-info-container' style={{marginBottom:`0px`}}>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> 3rd Stage Active Management </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.third_stage_active_management ? patientData?.delivery_detail?.third_stage_active_management : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Episiotomy Done </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.episiotomy_done ? patientData?.delivery_detail?.episiotomy_done : '--'} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Delayed Cord Clamping </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.delayed_cord_clamping ? patientData?.delivery_detail?.delayed_cord_clamping : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> PPIUCD Counselling </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.ppiucd_counselling ? patientData?.delivery_detail?.ppiucd_counselling : '--'} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Perineum Tear </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.perineun_tear ? patientData?.delivery_detail?.perineun_tear : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Repair </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.repair ? patientData?.delivery_detail?.repair : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Placenta Delivered </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.placenta_delivered ? patientData?.delivery_detail?.placenta_delivered : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Mode of Placenta Delivery </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.placenta_delivered_observation ? patientData?.delivery_detail?.placenta_delivered_observation : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> cct </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.cct ? patientData?.delivery_detail?.cct : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Family Planning </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.family_planning_method ? patientData?.delivery_detail?.family_planning_method : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Other Family Planning </p>
                                <p className = 'text2 breakableText'>{patientData?.delivery_detail?.family_planning_method_other ? patientData?.delivery_detail?.family_planning_method_other : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Counselling on Danger Sign </p>
                                <p className = 'text2 breakableText'>{patientData?.delivery_detail?.counselling_on_danger_sign ? patientData?.delivery_detail?.counselling_on_danger_sign : '--'}</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Oxytocin </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.oxytocin ? patientData?.delivery_detail?.oxytocin : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Tab Misoprostol </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.misoprostol ? patientData?.delivery_detail?.misoprostol : '--'} </p>
                            </div>
                            
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Uterine Massage </p>
                                <p className = 'text2 breakableText'> { patientData?.delivery_detail?.uterine_massage ? patientData?.delivery_detail?.uterine_massage : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Complications during delvery </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.complications ? patientData?.delivery_detail?.complications : '--'} </p>
                            </div>


                        </div>
                        
                        {patientData?.delivery_detail?.child_details  && patientData?.delivery_detail?.child_details.length > 0 ? 
                            patientData?.delivery_detail?.child_details.map((object, index) => {
                                return (
                                    <div>

                                        <p style={{background:`#888888`, textAlign:`center`, color:`white`, marginLeft:`10px`, marginRight:`10px`}}> Child {index+1} </p>
                                        
                                        <div className = 'sheet-info-container'>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Baby Cry </p>
                                                <p className = 'text2 breakableText'> {object.baby_cry ? object.baby_cry : '--'} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'>  Baby Needs Resuscitation </p>
                                                <p className = 'text2 breakableText'> {object.baby_need_resuscitation ? object.baby_need_resuscitation : '--'} </p>
                                            </div>
                                                
                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Oxygen Given </p>
                                                <p className = 'text2 breakableText'> {object.oxygen_given ? object.oxygen_given : '--'} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Mucous Sucker Used </p>
                                                <p className = 'text2 breakableText'> {object.oxygen_given ? object.oxygen_given : '--'} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Breastfeeding Within 1 hour </p>
                                                <p className = 'text2 breakableText'> {object.breastfeeding_within_one_hour ? object.breastfeeding_within_one_hour : '--'} </p>
                                            </div>

                                            <div>
                                                <p className = 'text1'> Breastfeeding Time</p>
                                                <p className = 'text2 breakableText'> {object.breastfeeding_time ? object.breastfeeding_time : '--'} </p>
                                            </div>
                                                
                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> PPTCT Treatment Given </p>
                                                <p className = 'text2 breakableText'> {object.pptct_treatment_given ? object.pptct_treatment_given : '--'} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Congenital Anomaly </p>
                                                <p className = 'text2 breakableText'> {object.congenital_anomaly ? object.congenital_anomaly : '--'} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Immunization </p>
                                                <p className = 'text2 breakableText'> {object.immunization ? object.immunization : '--'} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> APGAR Score </p>
                                                <p className = 'text2 breakableText'> {object.apgar_score ? object.apgar_score : '--'} </p>
                                            </div>
                                                
                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Length Of Chord </p>
                                                <p className = 'text2 breakableText'> {object.chord_length ? object.chord_length : '--'} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Complications </p>
                                                <p className = 'text2 breakableText'> {object.complications ? object.complications : '--'} </p>
                                            </div >

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Other Congenital Anomaly </p>
                                                <p className = 'text2 breakableText'> {object.congenital_anomaly_other ? object.congenital_anomaly_other : '--'} </p>
                                            </div>


                                        </div>

                                    </div>
                                )
                            })
                            : null }
                    </div>
                
                {/* If Postpartum Data of a patient is available then only show that field(div) else not */}
                { 
                    postpartumparams.length > 0 && postpartumparams[0]['mother'].data_values.length > 0 && postpartumparams[0]['mother'].data_times.length > 0 ?

                        <div className='print-container'>

                            <div className = 'sheet-info-header'>
                                <p className = 'heading'> PostPartum Data </p>
                            </div>
                            
                            { 
                                postpartumparams.length > 0 ? 
                                    <table style = {{width: `100%`, borderSpacing: `0px`, padding: `0px 10px`}}>
                                        <tr style = {{background: `#888`, width: `100%`, textAlign: `center`}}>
                                            <td className = "content-label" style = {{color: `#ffffff`, border: `1px solid #000000`}}> Parameter Name </td>
                                            
                                                {postpartumparams[0]['mother'].data_values.length > 0 && postpartumparams[0]['mother'].data_times.map((val,ind) => {
                                                    return <td className = "content-label" style={{color: `#ffffff`, border: `1px solid #000000`}}> {val} </td>
                                                })}
                                                
                                        </tr>

                                        { 
                                        postpartumparams.length > 0 && postpartumparams[0]['mother'] ? 
                                                ( postpartumparams[0]['mother'].data_values.map((row,ind) => {
                                                    return (
                                                        <tr style={{border: `1px solid #000000`, color: `#000`, width: `100%`, textAlign: `center`}}>
                                                            { row.map((innerrow) => {
                                                                return <td className = "content-label" style={{color: `#000`, border: `1px solid #000000`}}> {innerrow} </td>
                                                            })}
                                                        </tr>
                                                    )
                                                }))
                                            : null
                                        }

                                    </table> 
                                : null
                            }
                            
                            { 
                                postpartumparams.length > 0 && postpartumparams[0]['child'] ?
                                    (postpartumparams[0]['child'].map((value,index) => {
                                        return (
                                            <div>

                                                <p style={{background:`#888888`, textAlign:`center`, color:`white`, marginLeft:`10px`, marginRight:`10px`}}> Child {index+1} </p>

                                                <table style={{width: `100%`, borderSpacing: `0px`, padding: `0rem 10px`}}>

                                                    <tr style={{background: `#888`, color: `#fff`, width: `100%`, textAlign: `center`}}>
                                                        <td className="content-label" style={{color: `#ffffff`, border: `1px solid #000000`}}>Time</td>
                                                        {value.data_times.map((obj,ind) => {
                                                            return <td className="content-label" style={{color: `#ffffff`, border: `1px solid #000000`}}>{obj}</td>
                                                        })}
                                                    </tr>

                                                    {
                                                        value.data_values.map((object, ind) => {
                                                            return (
                                                                <tr key={ind} style={{ color: '#000', width: '100%', textAlign: 'center' }}>
                                                                    {
                                                                        object.map((value, index) => (
                                                                            <td key={index} className="content-label" style={{ border: '1px solid #000000' }}>{value}</td>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </table>
                                            </div>
                                        )
                                    }))
                                    :
                                    null
                            }

                        </div> 
                    : 
                    null
                }

            
                {/* Discharge Detail */}
                    <div className='print-container'>

                        <div className = 'sheet-info-header'>
                          <p className = 'heading'> Discharge Details </p>
                        </div>

                        <div className = 'sheet-info-container' style={{marginBottom:`0px`}}>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Patient Name </p>
                                <p className = 'text2 breakableText'> {patientData?.basic_detail?.name ? patientData?.basic_detail?.name : "--" } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Admission Time/Date </p>
                                <p className = 'text2'> {getReadableTime(patientData?.basic_detail?.register_time)} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Delivery Time/Date </p>
                                {
                                    patientData?.events.length !== undefined && patientData?.events.length > 0 ?
                                    <p className = 'text2'>{ (patientData?.events.filter(obj => obj.event === "delivered")[0] !== undefined) ? getReadableTime(patientData?.events.filter(obj => obj.event === "delivered")[0].event_time) : '--'} </p> 
                                    : <p className = 'text2'>--</p>
                                }
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Discharge Time/Date </p>
                                {
                                    patientData?.basic_detail?.current_status === "completed" && patientData.events.length !== undefined ? 
                                        <p className = 'text2'> {(patientData?.events.filter(obj => obj.event === "completed")[0] !== undefined) ? getReadableTime(patientData?.events.filter(obj => obj.event === "completed")[0].event_time) : '--'} </p> 
                                    : <p className = 'text2'>--</p>
                                }
                            </div>
                                
                            <div className='sheet-info-section' >
                                <p className = 'text1'> Admitted Facility </p>
                                <p className = 'text2 breakableText'> {patientData?.place_details[patientData?.basic_detail?.registration_place_id].name ? patientData?.place_details[patientData?.basic_detail?.registration_place_id].name : '--' } </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Final Outcome </p>
                                <p className = 'text2 breakableText'>-</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Condition of mother </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.mother_status ? patientData?.delivery_detail?.mother_status : '--'} </p>
                            </div>
                                
                            <div className='sheet-info-section'>
                                <p className = 'text1'> Mode of Delivery </p>
                                <p className = 'text2 breakableText'> {patientData?.delivery_detail?.delivery_type ? patientData?.delivery_detail?.delivery_type : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> OPD Registration Number </p>
                                <p className = 'text2 breakableText'> {patientData?.basic_detail?.opd_registration_number ? patientData?.basic_detail?.opd_registration_number : '--'} </p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Immunisation </p>
                                <p className = 'text2 breakableText'>-</p>
                            </div>

                            <div className='sheet-info-section'> 
                                <p className = 'text1'> Pre-Term </p>
                                <p className = 'text2 breakableText'>-</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Inj Vit K </p>
                                <p className = 'text2 breakableText'>-</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Other Advice </p>
                                <p className = 'text2 breakableText'>-</p>
                            </div>

                            <div className='sheet-info-section'>
                                <p className = 'text1'> Follow up Date </p>
                                <p className = 'text2 breakableText'>-</p>
                            </div>
                            

                        </div>
                        
                        {
                            patientData?.delivery_detail?.child_details  && patientData?.delivery_detail?.child_details.length > 0 ? 
                                patientData?.delivery_detail?.child_details.map((object, index) => {
                                    return (

                                        <div>
                                            <p style={{background:`#888888`, textAlign:`center`, color:`white`, marginLeft:`10px`, marginRight:`10px`}}> Child {index+1} </p>
                                            
                                            <div className = 'sheet-info-container'>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Child Status </p>
                                                    <p className = 'text2 breakableText'> {object?.child_status ? object?.child_status : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Gender </p>
                                                    <p className = 'text2 breakableText'> {object?.gender ? object?.gender : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Weight(Kgs) </p>
                                                    <p className = 'text2 breakableText'> {object?.weight ? object?.weight : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Delivery Time </p>
                                                    <p className = 'text2'> {object?.delivery_time ? getReadableTime(object?.delivery_time) : "- -"} </p>
                                                </div>

                                                <div className='sheet-info-section'>
                                                    <p className = 'text1'> Breastfeeding Time </p>
                                                    <p className = 'text2'> {object?.breastfeeding_time ? getReadableTime(object?.breastfeeding_time) : "- -"} </p>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            : null 
                        }
                    </div>

                {/* If consent forms of a patient are available then only show that field(div) else not */}
                {
                    patientFormData && patientFormData.length > 0 ? (
                        <div className='print-container'>

                            <div className='sheet-info-header' style={{ marginBottom: '0px' }}>
                              <p className='heading'>Consent Forms</p>
                            </div>

                            {
                                patientFormData.map((obj, index) => (
                                    <div key={index} className='sheet-form-container'>
                                        <div className='form-header'>
                                        <p className='form-heading'>Consent for {obj.form.consent_categorie}</p>
                                        </div>

                                        <div className='form-content'>
                                        <p>
                                            I <span className='underline'>{obj.form.signee_name}</span> {transformText(obj.form.signee_guardian_relation)}{' '}
                                            <span className='underline'>{obj.form.signee_guardian_name}</span> aged{' '}
                                            <span className='underline'>{obj.form.signee_age}</span> resident of{' '}
                                            <span className='underline'>{obj.form.signee_address}</span> related with{' '}
                                            <span className='underline'>{patientData?.basic_detail?.name}</span> (pregnant woman) as{' '}
                                            <span className='underline'>{obj.form.signee_patient_relation}</span> for medical procedures.{' '}
                                            {obj.form.consent_categorie} without any pressure.
                                            <br />
                                            <br />
                                            <ul>
                                            <li>I have been informed about the probable consequences of this procedure in detail, in the language I understand.</li>
                                            <li>I am signing this consent form without any undue pressure and in my complete consciousness.</li>
                                            <li>I provide consent to use anaesthetics and all other medications which the healthcare centre may think fit.</li>
                                            <li>
                                                I will remain present after the procedure/operation for a health check-up and follow up at a nearby hospital as
                                                I am told at the healthcare centre. On failing to do so, I will solely be responsible.
                                            </li>
                                            <li>The doctor/provider shall not be held responsible in case of any complication/mishap.</li>
                                            </ul>
                                        </p>
                                        </div>

                                        <div className='signature-container'>
                                        <div className='sign-detail'>
                                            <img src={obj.form.signee_signature_file} alt='Patient Signature' />
                                            <p>{obj.form.signee_name}</p>
                                            <p>Name & signature of patient</p>
                                        </div>

                                        <div className='sign-detail'>
                                            <img src={obj.form.attendant_signature_file} alt='Attendant Signature' />
                                            <p>{obj.form.attendant_name}</p>
                                            <p>Name & signature of Attendant</p>
                                        </div>
                                        </div>
                                    </div>
                                ))
                            }
                            
                        </div>
                    ) 
                    : 
                    null
                }

            
                {/* If Referral Details of a patient is available then only show that field(div) else not */}
                    { 
                        referralData && referralData.length > 0 ? 
                            referralData.map((object, index) => {
                                return(
                                    <div className='print-container'>
                                        <div className = 'sheet-info-header'>
                                        <p className = 'heading'> Referrals </p>
                                        </div>
                                        
                                        <div className = 'sheet-info-container' style={{marginBottom:`0px`}}>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Referred Person </p>
                                                <p className = 'text2 breakableText'> {object.refer_message_mother &&  object.refer_message_mother.length > 0  ? "Mother" : "- -"} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Reeferral Time/Date </p>
                                                <p className = 'text2'> {object.refer_message_mother &&  object.refer_message_mother.length > 0 && object.event_time ? getReadableTime(object.event_time) : "- -"} </p>
                                            </div>
                                                
                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Referred to/from </p>
                                                <p className = 'text2 breakableText'> {object.refer_message_mother &&  object.refer_message_mother.length > 0 && object.referred_place_name ? object.referred_place_name : "- -"} </p>
                                            </div>

                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Referral Reason</p>
                                                {
                                                    object.refer_message_mother &&  object.refer_message_mother.length > 0 ?
                                                            object.refer_message_mother.map((val,index) => {
                                                                return (
                                                                    <p className = 'text2 breakableText'> {index+1} - {val} </p>
                                                                )
                                                            })
                                                            : <p> No Reasons Recorded</p>
                                                }
                                            </div>
                                                
                                            <div className='sheet-info-section'>
                                                <p className = 'text1'> Treatment Given </p>
                                                <p className = 'text2 breakableText'> -- </p>
                                            </div>
                                        
                                        </div>
                        
                                        {object.refer_message_child && object.refer_message_child.length > 0 ?
                                            <>
                                                <p style={{background:`#888888`, textAlign:`center`, color:`white`, marginLeft:`10px`, marginRight:`10px`, marginTop:`0px`}}> Child </p>

                                                <div className = 'sheet-info-container'  style={{marginBottom:`0px`}}>

                                                    <div className='sheet-info-section'>
                                                        <p className = 'text1'> Referred Person </p>
                                                        <p className = 'text2'> Child </p>
                                                    </div>

                                                    <div className='sheet-info-section'>
                                                        <p className = 'text1'> Reeferral Time/Date </p>
                                                        <p className = 'text2 breakableText'> {getReadableTime(object.event_time)} </p>
                                                    </div>
                                                        
                                                    <div className='sheet-info-section'>
                                                        <p className = 'text1'> Referred to/from </p>
                                                        <p className = 'text2 breakableText'> {object.referred_place_name} </p>
                                                    </div>
                                                        
                                                    <div className='sheet-info-section'>
                                                        <p className = 'text1' > Treatment Given </p>
                                                        <p className = 'text2 breakableText'> -- </p>
                                                    </div>

                                                    <div className='sheet-info-section'>
                                                        <p className = 'text1' > Referral Reason</p>
                                                        { object.refer_message_child.map((val,index) => {
                                                            return (
                                                                <p className = 'text2 breakableText' > {index+1} - {val} </p>
                                                            )
                                                        })}
                                                        
                                                    </div>
                                                
                                                </div> 

                                            
                                            </>
                                            : null
                                        }
                                    </div> 
                            )})
                        : 
                        null
                    }
                

                <div className="doctor-sign" style={{fontWeight: 600}}>
                        Name & Signature of Healthcare Professional 
                </div>

                
        </div>
        }
    </>
  )
}

export default CaseSheetLayout