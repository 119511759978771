import React from 'react';
import axios from 'axios';
import urls from '../../../utils/url';
import { useState, useEffect } from 'react';
import '../common.scss';
import { getReadableTime, getReferralDetails, isJson} from '../../../utils/converter';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import NoData from '../../common/NoData';
import Cookie from 'js-cookie';

const ReferralDetails = (props) => {

  const cookie = Cookie.get('access_token')
  const { patientID } = props;
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ patientReferralDetails , setPatientReferralDetails ] = useState();
  const [ activeText, setActiveText ] = useState('Mother');
  const [ referType, setReferType] = useState('')

  const handleToggle = (text) => {
    setActiveText(text);
  };

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=events,place_details&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            console.log(json_data.response[0]);
            setPatientReferralDetails(json_data.response[0])
            setLoading(false)
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err)
          setError(true)
          setPatientReferralDetails()
          setLoading(false)
        }
      ) 
  }, [])

  var referralData;
   if(patientReferralDetails?.events && patientReferralDetails?.events.length > 0 && patientReferralDetails?.place_details){
        referralData = getReferralDetails(patientReferralDetails.events, patientReferralDetails.place_details)
   }
   

   
  // const getUniqueReferrals = (referrals) => {
  //   console.log(referrals)
  //   const seen = new Set();
  //   return referrals.filter(referral => {
  //     const duplicate = seen.has(referral.id); // Change `id` to your unique key
  //     seen.add(referral.id);
  //     return !duplicate;
  //   });
  // };
  
  // var referralData;
  // if (patientReferralDetails?.events && patientReferralDetails?.events.length > 0 && patientReferralDetails?.place_details) {
  //   referralData = getReferralDetails(patientReferralDetails.events, patientReferralDetails.place_details);
  
  //   // Remove duplicates from referralData if any
  //   referralData = getUniqueReferrals(referralData);
  // }

 // console.log(referralData)
 
  return (
    <>
    { loading ? <div className='loading-div'><Loading/></div> 
    : error ? <div className='reload-div'><Reload/></div>
    : referralData && Object.keys(referralData).length > 0 ? 
      <div className='info-section'>
          <div className='referral-section' >
            <div className='toggle-header'>
              <div className='toggle-button'>
                <p className={activeText === 'Mother' ? 'active-toggle-text' : 'inactive-toggle-text'} onClick={() => handleToggle('Mother')}> Mother </p>
                <hr className='vertical-line'/>
                <p className={activeText === 'Child' ? 'active-toggle-text' : 'inactive-toggle-text'} onClick={() => handleToggle('Child')}> Child </p>
              </div>
            </div>
            
            <div className='referral-heading'>
              <p className='header-text'>Referral Summary </p>
            </div>

            { referralData.map((obj,index) => {
              return(
                  <div>
                      <div className='referral-column'>
                        <div>
                          <p className='text-one'>Referred Person</p>
                          <p className='text-two'>{activeText}</p>
                        </div>

                        <div>
                          <p className='text-one'>Referral Time/Date</p>
                          <p className='text-two'>{obj?.event_time ? getReadableTime(obj?.event_time) : '--'}</p>
                        </div>

                        <div>
                          <p className='text-one'>Referred </p>
                          <p className='text-two'>{obj?.referred_place_name ? obj?.referred_place_name : '--'}</p>
                        </div>
                    </div>

                    <hr/>

                    <div className='referral-column'>
                      <div>
                          <p className='text-one'> Referral Reason:</p>
                          {
                            (obj?.refer_message_mother && obj?.refer_message_mother.length > 0 && activeText === "Mother" ? obj?.refer_message_mother : obj?.refer_message_child && obj?.refer_message_child.length > 0 && activeText === "Child" ? obj?.refer_message_child : [])
                              .map((object,index) => {
                                return(
                                  <p className='text-two'>{index+1}-{object}</p>
                                )
                              })
                          } 
                      </div>

                      {/* <div>
                          <p className='text-one'>Referral Reason:</p>
                          {obj?.refer_message_child && obj?.refer_message_child.length > 0 && obj?.refer_message_child.map((object,index) => {
                              return(
                                <p className='text-two'>{index+1}-{object}</p>
                              )
                            }) }
                      </div> */}
                  </div>

                  {/* <div className='referral-column'>
                    <div>
                      <p className='text-one'>Treatment Given:</p>
                      <p className='text-two' >some Reason extending this full line asnfjgiwefciwecwnfjewnckjw ck cjkas ajjcdn</p>
                    </div>
                  </div> */}
                </div>
            )})}
          
          </div>
      </div>
    :  <div className='no-data-div'><NoData/></div>
    }
    </>
  )
}

export default ReferralDetails