import React from 'react'
import '../common/common.scss'

const Reload = () => {

const handleReload = () => {
    window.location.reload();
    };
    
  return (
    <div className='reload'>
       <img src ="/went_wrong.svg" alt="Something Went Wrong" className='reload-image'/>
       <p className='reload-text'>Something went wrong at our end. Please click ‘Refresh’</p>
       <button onClick={handleReload} className='reload-button'> Refresh  </button>
    </div>
  )
}

export default Reload
