import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    placeDetail: []
}

const placeDetailSlice = createSlice({
    name: 'placeDetail',
    initialState,
    reducers: {
        addPlaceDetail: ( state, action) => {
            state.placeDetail = action.payload
        }}
})

export const {addPlaceDetail} = placeDetailSlice.actions;
export default placeDetailSlice.reducer