import React from 'react'
import '../error/error.scss'

const CentralMonitorError = () => {
  return (
    <div className='central-monitor-error'>
      <div className='image-div'>
        
        <img src="../no-live-test.png" alt="" className='cm-error-image'/>
      </div>
      <p className='no-live-test-text'>No live test is currently being done</p>
      <div style={{display: 'flex', marginTop: 20, marginBottom: 20}}>
        <img src="../hr-or.png" alt=""  style={{height: '1px', marginTop: '15px', marginRight: '15px'}}/>
        <p className='or-text'>or</p>
        <img src="../hr-or.png" alt="" style={{height: '1px', marginTop: '15px', marginLeft: '15px'}}/>
      </div>
      
      <p className='retry-text'>Patient data might be syncing please wait or refresh the browser</p>
    </div>
  )
}

export default CentralMonitorError
