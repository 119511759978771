import { TextField } from '@mui/material'
import React from 'react'

const SearchButton = () => {
  return (
    <div className='search-div'>
        <input type="text" id="lname" name="lname" placeholder="Search Patient" className='search'/>
        <img src="/search-new.png" alt="search" className='search-image'/>
    </div>
  )
}

export default SearchButton
