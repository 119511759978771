import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    keyarData: []
}

const keyarDataSlice = createSlice({
    name: 'keyarData',
    initialState,
    reducers: {
        addKeyarData: ( state, action) => {
            state.keyarData = action.payload
        }}
})

export const {addKeyarData} = keyarDataSlice.actions;
export default keyarDataSlice.reducer
