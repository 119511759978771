import { useEffect } from "react";
import OneSignal from 'react-onesignal';

const useOneSignal = () =>
  useEffect(() => { 
    window.OneSignal = window.OneSignal || [];
     OneSignal.init({
          appId: "caebfab0-7031-4e87-9f9a-646cbe9a646b",
          notifyButton: {
          enable: true,
          },
          allowLocalhostAsSecureOrigin: true,
      }).then(async () => {
          await OneSignal.setSubscription(true)
          await OneSignal.registerForPushNotifications()
          await OneSignal.getUserId().then(userId => {
              window.localStorage.setItem('onesignalId', userId)
              window.localStorage.setItem('isPushNotificationsEnabled','true')
          })
      });
    return () => {
        window.OneSignal = undefined;
    };
  }, []); // <-- run this effect once on mount

export default useOneSignal