import React from 'react';
import { useEffect, useState } from 'react';
import './profile.scss';
import Cookie from "js-cookie";
import { getReadableDateOnly } from '../../utils/converter';

const Profile = ({}) => {

  // const cookie = Cookies.get('access_token')
  const [subscriptionType , setSubscriptionType] = useState();
  const [hospitalName, setHospitalName] = useState();
  const [expiryDate, setExpiryDate]  = useState();
  const [hospitalID, setHospitalID]  = useState();
  const [statusMessage, setStatusMessage] = useState("");
  const [theme, setTheme] = useState(() => {
    return Cookie.get("theme") || "dark-theme"; // Default to "Dark-theme"
  });

  const themez =  Cookie.get("theme");
  console.log(themez)

  useEffect(() => {
    const cookieData = Cookie.get('hospital_detail');
    if (cookieData) {
        const parsedData = JSON.parse(cookieData);
        const subscription_type = parsedData.subscription_type;
        const hospital_name = parsedData.hospitalName;
        const expiry_time = parsedData.expiryTime;
        const hospital_id = parsedData.hospitalID;

        setSubscriptionType(subscription_type);
        setHospitalName(hospital_name);
        setExpiryDate(expiry_time);
        setHospitalID(hospital_id);
    } 
  },[])

  const toggleTheme = () => {
    const newTheme = theme === "light-theme" ? "dark-theme" : "light-theme";
    setTheme(newTheme);
    Cookie.set("theme", newTheme, 
    { 
      expires: 365,
      path:'/',
    }); 
  };

  // const parseDate = (dateString) => {
  //   const [day, month, year] = dateString.split(" ");
  //   const monthIndex = new Date(`${month} 1`).getMonth(); // Get the month index
  //   return new Date(year, monthIndex, parseInt(day, 10)); // Construct a Date object
  // };

  // const checkSubscriptionStatus = (expiryDateString) => {
  //     const currentDate = new Date();
  //     console.log(currentDate)
  //     const expiryDate = parseDate(expiryDateString);

  //     // Reset hours, minutes, and seconds for accurate comparison
  //     currentDate.setHours(0, 0, 0, 0);
  //     expiryDate.setHours(0, 0, 0, 0);

  //     // Calculate the difference in days
  //     const timeDifference = expiryDate - currentDate;
  //     const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  //     if (daysLeft < 0) {
  //         return "Your subscription has expired.";
  //     } else if (daysLeft === 0) {
  //         return "Your subscription expires today.";
  //     } else if (daysLeft <= 7) {
  //         return `Your subscription will expire in ${daysLeft} days.`;
  //     } else {
  //         return `Your subscription is active and will expire in ${daysLeft} days.`;
  //     }
  // };

  // useEffect(() => {
  //     const message = checkSubscriptionStatus(expiryDate);
  //     setStatusMessage(message);
  // }, [expiryDate]);

   console.log(theme);


  return (
    <div className='profile-page'>

      <div className='settings'>
        <img src="/settings.svg" alt="Profile Image" width={20} height={20}/>
        <p className='setting-text'>Profile</p>
      </div>

      <hr className='hrline-setting'/>   

      <div  className='hospital-details'>
        <div className='detail-div'>
          <p className="text-label bold">Hospital ID : </p>
          <p className="text-label">{hospitalID}</p>
        </div>

        <div className='detail-div'>
          <p className="text-label bold">Name of Hospital : </p>
          <p className="text-label">{hospitalName}</p>
        </div>

        <div className='detail-div'>
          <p className="text-label bold">Expiry of Subscription : </p>
          <p className="text-label">{getReadableDateOnly(expiryDate)} </p>
        </div>

        <div className='detail-div'>
          <p className="text-label bold">Subsription type : </p>
          <p className="text-label">{subscriptionType}</p>
        </div>
      </div>     

      <div className='settings'>
        <img src="/settings-icon.svg" alt="Settings Image" width={20} height={20}/>
        <p className='setting-text'>Settings</p>
      </div>

      <hr className='hrline-setting'/>  

      <div className="theme-toggle-container">  
        <p className="text-label bold">Live Monitoring Theme</p>
        
        <div className={`toggle-container ${theme}`} onClick={toggleTheme}>
            <div className="toggle-circle">
              {theme === "light-theme" ? "Light" : "Dark"}
            </div>
        </div>
      </div>  
      
     
    </div>
  );
};

export default Profile
