import React, { useState, useRef, useEffect } from 'react';
import './devicedata.scss'

const AudioPlayer = ({fileURL,isPlaying, setIsPlaying}) => {
  // State to hold the current time
  
  const [currentTime, setCurrentTime] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(0);
  const [tooltipTime, setTooltipTime] = useState('');
  const [tooltipStyle, setTooltipStyle] = useState({});



  // State to manage play/pause
  //const [isPlaying, setIsPlaying] = useState(false);

  // Ref for the audio element
  const audioRef = useRef(null);


  // Format time from seconds to MM:SS
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Play or pause the audio
  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Update the current time
  const updateTime = (e) => {
    setCurrentTime(e.target.currentTime);
  };

  // Seek the audio
  const onScrub = (value) => {
    const audio = audioRef.current;
    audio.currentTime = value;
    setCurrentTime(value);
  };

  // Convert currentTime and duration to slider value
  const currentTimeSliderValue = () => {
    if (audioRef.current) {
      return (currentTime / audioRef.current.duration) * 100 || 0;
    }
    return 0;
  };

  const handleMouseMove = (e) => {
    const slider = e.target;
    const max = slider.max;
    const val = slider.value;
    const newPosition = (val / max) * slider.clientWidth;
    setTooltipPosition(newPosition);
    const newTime = (val / 100) * audioRef.current.duration;
    setTooltipTime(formatTime(newTime));
  };

  useEffect(() => {
    setShowTooltip(false); // Initially, tooltip is not shown
  }, []);

  return (
    
    <div>
        { fileURL && fileURL != undefined && fileURL != '' ?
            <>
                <div className='player'>
                {/* Slider to show progress */}
                    <input
                        type="range"
                        value={currentTimeSliderValue()}
                        step="1"
                        min="0"
                        max="100"
                        onChange={(e) => onScrub(e.target.valueAsNumber)}
                        className='player-range'
                        onMouseMove={handleMouseMove}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    />
                    {showTooltip && (
                        <div className='tooltip' style={{  left: tooltipPosition }}>{tooltipTime}</div>
                    )}
                    {/* <div>
                        
                        <span>{formatTime(currentTime)}</span>
                        <span>/</span>
                        
                        <span>{audioRef.current ? formatTime(audioRef.current.duration) : '0:00'}</span>
                    </div> */}
                </div>
                <audio
                    ref={audioRef}
                    src={fileURL} // Replace with your audio file path
                    onTimeUpdate={updateTime}
                    onLoadedMetadata={updateTime} // Update time to ensure duration is not NaN
                />

                <div className='play-button-div'>
                    <button onClick={togglePlayPause} className='play-pause-button'>
                        {isPlaying ? 
                            <>
                                <img src="/pause-button.png" alt="pause" className='play-pause'/> 
                                <p className='pause-text'> Pause Recording</p>
                            </>
                        : 
                            <>
                                <img src="/play-button.png" alt="play" className='play-pause'/> 
                                <p className='pause-text'> Play Recording</p>
                            </>
                        }
                    </button>
                </div>
            </>
        :
            <div/>
        }
      
    </div>
  );
};

export default AudioPlayer;
