import React, { useEffect,useCallback } from 'react'
import { useState } from 'react';
import SideLayout from '../components/common/sidelayout'
import { useLocation } from 'react-router-dom';
import Analytics from './analytics/analytics';
import Report from '../pages/report/report'
import Settings from './settings/settings';
import Profile from './profile/profile'; 
import './dashboard.scss'
import moment from 'moment';
import Cookie from 'js-cookie';
import axios from 'axios';
import urls from '../utils/url';
import { isJson } from '../utils/converter';
import AlertModal from '../components/alertModal/alertModal';
import { filter } from 'lodash';

const Dashboard = () => {

  const location = useLocation();
  const cookie = Cookie.get('access_token')
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ liveAlerts, setLiveAlerts] = useState([]);
  const [ alertData, setAlertData] = useState([]);
  const [endDate, setEndDate] = useState(moment().unix().toString());
  const [startDate, setStartDate] = useState(moment().subtract(4, 'days').unix().toString());
  const [placeID, setPlaceID] = useState('');
  const [accountType, setAccountType] = useState('');
  const [openAlertPanel, setOpenAlertPanel] = useState(false);
  const [ showChat, setShowChat] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [ isMrn, setIsMrn] = useState(true); // For Malaysia Specific Requirement

  const cookieData = Cookie.get('data');

  //For Back to top button position
  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 600); // Change 400 to your desired scroll position threshold
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const ShowChat = () => {
    setShowChat(!showChat)
  }

  useEffect(() => {
    // Update startDate whenever endDate changes
    setStartDate(moment.unix(Number(endDate)).subtract(4, 'days').unix().toString());
  }, [endDate]);

  const handleReload = () => {
    window.location.reload();
  }

  useEffect(() => {
    fetchPlaceIDs();
  },[])

  useEffect(() => {
    if (cookieData) {
        const parsedData = JSON.parse(cookieData);
        const account_type = parsedData.account_type;
        const algorithmEnabled = parsedData.fetal_distress_algorithm;
        //console.log(algorithmEnabled)

        setIsMrn(algorithmEnabled);
        setAccountType(account_type); 
    }
  },[])


  const fetchPlaceIDs = useCallback(async () => {
    setLoading(true)
    await axios.get(`${urls.BETA_URL}/website/places/`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        if(res.data){
            const json_data = isJson(res.data)
            const associate_place = json_data.response
            let place_ids;
            place_ids = associate_place.map(obj => obj.id).toString()
            setPlaceID(place_ids)
            fetchAlerts(place_ids);
            setLoading(false)    
        }
      }).catch((err) => {
        setPlaceID('')
        setError(true)
        setLoading(false)
      }) 
  },[ cookie, loading])
  

  const fetchAlerts = (placeID) => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?place_ids=${placeID}&forms=alerts,basic_detail&from_time=${startDate}&to_time=${endDate}&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data);
            const alert = json_data.response;

            const nonEmptyAlerts = alert.map((object, index) => {
              let filteredAlerts = object.alerts.length > 0 && object.alerts.filter((alert, index) => 
                alert.alert_type !== 'Placenta_Complication' && alert.alert_type !== 'PLACENTA_DELIVERED'
              )

              if(filteredAlerts && filteredAlerts.length > 0){
                let concatenatedAlertMessage = filteredAlerts && filteredAlerts.length > 0 ? filteredAlerts.map(object => object.alert_message).join(', ') : "";
                return{
                  live_alerts: concatenatedAlertMessage,
                  patient_name: object.basic_detail?.name,
                  patient_id: object.id,
                  time: filteredAlerts[0]?.data_time,
                  mrn: object.basic_detail?.mrn
                };
              } else {
                return null;
              }
            }).filter(object => object !== null);

            setAlertData(nonEmptyAlerts);
            setLoading(false);

          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err);
          setError(true)
          setLiveAlerts([]);
          setLoading(false)
        }
      ) 
  }

  const togglePanel = () => {
    setOpenAlertPanel(!openAlertPanel);
  }; 


  return (
    <>
     
      <div className={openAlertPanel ? 'blur-dashboard' : 'dashboard'}>
          <SideLayout />
          <div className="main-layout">

            <div className="dashboard-header">
              <div className="update-div">
                <img style={{ marginLeft: `40px`, cursor:`pointer` }} src="/update.svg" alt="Update Icon" onClick={() => handleReload()} width={18} height={18}/>
                <p className="update-text">Last Update: {moment().format('DD MMM YYYY hh:mm A')}</p>
              </div>
              {
                accountType && accountType.length > 0 && (accountType === 'Premium') && (
                  <div className="notification-bell" onClick={() => togglePanel()}>
                    <img src="/alerts_notifications.svg" alt="Alerts Notifications" width={40} height={40} className="bell-icon" />
                    {alertData.length > 0 && 
                        <div className="notification-badge">{alertData.length}</div>
                    }
           
                  </div>
              )}
            </div>

            <div className="component">

              {location.pathname === '/dashboard/analytics' ? (
                <Analytics />
              ) : location.pathname === '/dashboard/report' ? (
                <Report isMrn={isMrn}/>
              ) : location.pathname === '/settings' ? (
                <Settings />
              ) : location.pathname === '/dashboard/profile' ? (
                <Profile />
              ) : null}

              {/* { accountType && ['Premium'].includes(accountType) &&
                <div className = "back-to-top show-back-to-top" onClick={() => ShowChat()} style={{bottom:`150px`}}>
                  <p className='back-to-top-text'>Start Chat</p>
                </div>
              } */}

              {/* { 
                showChat && 

                  <div className = "show-chatbot show-back-to-top"  style={{bottom:`200px`}}>
                      <iframe
                        allow="microphone;"
                        width="350"
                        height="450"
                        src="https://console.dialogflow.com/api-client/demo/embedded/598ef404-f1bb-4911-8a19-d0f63c0a3aca"
                        title="chatbot"
                        >
                      </iframe>  
                  </div>
              }  */}

              <div className = {`back-to-top ${isVisible ? 'show-back-to-top' : 'hide-back-to-top'}`} onClick={scrollToTop}>
                <img src = "/arrow_upward.svg" alt="Arrow Upward "/>
                <p className='back-to-top-text'>Back To Top</p>
              </div>

            </div>

          </div>
      </div>

      { openAlertPanel && <AlertModal alertData={alertData} onClose={togglePanel} openAlertPanel={openAlertPanel} isMrn={isMrn}/>}
  </>
   
  )
}

export default Dashboard
