import React, { useEffect, useState } from 'react'
import '../common/common.scss'
import { Link,useLocation } from 'react-router-dom';
import urls from '../../utils/url';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import moment from 'moment';

const SideLayout = () => {

  const cookie = Cookie.get('access_token')
  const [activeIndex, setActiveIndex] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState();
  // const [subscriptionType , setSubscriptionType] = useState();
  // const [userName, setUserName] = useState();
  
  const location = useLocation();

  useEffect(() => {
    const cookieData = Cookie.get('data');
    if (cookieData) {
        const parsedData = JSON.parse(cookieData);
        const account_type = parsedData.account_type;
        const subscription_type = parsedData.subscription_type;
        const user_name = parsedData.user_name;
        // console.log(subscription_type);
        setAccountType(account_type); 
        // setSubscriptionType(subscription_type);
        // setUserName(user_name);
    } 
  },[accountType])

  const handleClick = (index) => {
    setActiveIndex(index)
  }

  useEffect(() => {
    const urls = ['/dashboard/analytics', '/dashboard/report', '/logout', '/dashboard/profile']
    const currentIndex = urls.findIndex(obj => obj === location.pathname)
    setActiveIndex(currentIndex)
  },[location, navigate])


  const handleLogout = async () => {
    setLoading(true)
    axios.post(`${urls.BETA_URL}/nurse/logout/`,
    {
      "subscriber_id":  window.localStorage.getItem('onesignalId')
    },
    {
      headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
    }
    ).then((res) => {

      Cookie.remove('access_token');
      Cookie.remove('data');
      localStorage.removeItem('username');
      navigate('/');
      setLoading(false);

    }).catch((error) => {
        console.log('Logout failed', error)
        setLoading(false)
    })
  }

  return (

    <div className='side-layout'>

      <div className='side-header'>
        <img src="/menu.svg" alt="menu" className='menu-icon'/>
        <p className='menu-text'>Menu</p>
      </div>

      <img src = "/hr.svg" alt = "Horizontal Line" style={{marginTop:`2rem`}} className="sidelayout-hr"/>

      <div className='sidelayout'>

        <div className='upper-sidelayout'>
            { 
              accountType && !['Basic','Daksh SI','Navam'].includes(accountType) &&
                <div style={{display: 'flex'}}>
                  <div className={activeIndex == 0 ? 'active' : 'in-active'} onClick={() => handleClick(0)}>
                    <Link to= {`/dashboard/analytics`} className={'side-name'}> 
                      <div className='side-text'>
                        <img src={activeIndex == 0 ? '/active-analytics.svg' : '/analytics.svg'} alt="circle" className='circle-img active-image'/>
                        <p className='text' >Analytics</p>
                      </div>
                    </Link>
                  </div>
                  { activeIndex === 0 ?
                      <img src="/active.png" alt="active" className='active-sign' />
                      : 
                      <div/>
                  }
                </div>  
            }
        
            <div style={{display: 'flex'}}>
              <div className={activeIndex == 1 ? 'active' : 'in-active'} onClick={() => handleClick(1)}>
                <Link to= {`/dashboard/report`} className='side-name'> 
                  <div className='side-text'>
                    <img src={activeIndex == 1 ? '/active-reports.svg' : '/reports.svg'} alt="circle" className='circle-img active-image'/>
                    <p className='text'> Patient Reports </p>
                  </div>
                </Link>
              </div>
              { 
                activeIndex == 1 ?
                  <img src="/active.png" alt="active" className='active-sign' />
                  : 
                  <div/>
              }
            </div>
            
            { 
              accountType && !['Daksh SI'].includes(accountType) &&
                <div >
                  <div className={'in-active'}>
                    <Link to= {`/dashboard/central-monitor`}  target="_blank" rel="noopener noreferrer" className='side-name'> 
                      <div className='side-text'>
                        <img src={ "/central-monitor.svg"} alt="circle" className='circle-img ' />
                        <p className='text'>Central Monitor</p>
                      </div>
                    </Link>
                  </div> 
                </div> 
            }

            <img src = "/hr.svg" alt = "Horizontal Line" style={{marginBottom:`2rem`}} className="sidelayout-hr"/>

        </div>

        <div className='lower-sidelayout'>

          <div>
            <div style={{display: 'flex',}}>
              <div className={activeIndex == 3 ? 'active' : 'in-active'} onClick={() => handleClick(3)}>
                <Link to={`/dashboard/profile`} className='side-name'> 
                  <div className='side-text'>
                    <img src={activeIndex == 3 ? '/active-settings.svg' : "/settings.svg"} alt="circle" className='circle-img active-image'/>
                    <div>
                      {/* <p className='text'>{ userName ? userName : null }</p>
                      <p className='small-text'>{ subscriptionType ? subscriptionType : null }</p> */}
                      <p className='text'>Profile</p>
                    </div>

                  </div>
                </Link>
              </div>
              { activeIndex == 3 ?
                <img src="/active.png" alt="active" className='active-sign' />
                : <div/>
              }
            </div>
            
            
            <div>
              <div className={activeIndex == 2 ? 'active' : 'in-active'} onClick={() => {handleLogout()}}>
                <Link  className='side-name'> 
                  <div className='side-text'>
                    <img src={activeIndex == 2 ? '/active-logout.svg' : "/logout.svg"} alt="circle" className='circle-img'/>
                    <p className='text'>Logout</p>
                  </div>
                </Link>
              </div>
              { activeIndex == 2 ?
                <img src="/active.png" alt="active" className='active-sign' />
                : <div/>
              }
            </div>

          </div>

          <img src = "/hr.svg" alt = "Horizontal Line" className="sidelayout-hr" />
        </div>

        <div className='sidelayout-footer'>
          <img src="/janitri-registered-logo.svg"  alt = "Janitri Logo" className='sidelayout-janitri-logo' />
          <p className='date-footer'>{moment().format('DD/MM/YYYY')}</p>
        </div>
       
      </div>

       
       

       

   </div>
  )
}

export default SideLayout
