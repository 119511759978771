import React from 'react'
import './keyar-layout.scss'

const Interpretation = ({interpretationData, difference, interpretationDisable}) => {
   // console.log(interpretationDisable)
    
  return (
    <>
    { interpretationDisable ?
        (
            <div className='interpretation-box'>
                <div className='interpretation-block'>
                    <div className='interpretation-data'>
                        <div className="interpretation-header">FHR1 Interpretation</div>
                        { difference != '' && difference >= 600000 ? 
                            <div className='fhr1-block'>
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Baseline</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.baseline || '--'}</p>
                                    </div>
                                </div>
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Baseline Heart Rate</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.baseline_heart_rate || '--'}</p>
                                    </div>
                                </div> 
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Acceleration</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.acceleration || '--'}</p>
                                    </div>
                                </div>
                                
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Deceleration</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.deceleration || '--'}</p>
                                    </div>
                                </div> 
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Variability</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.variability || '--'}</p>
                                    </div>
                                </div>
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Short Term Variability</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.short_term_variablity || '--'}</p>
                                    </div>
                                </div>
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Contrations</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.contrations || '--'}</p>
                                    </div>
                                </div>
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Average Duration</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.average_duration || '--'}</p>
                                    </div>
                                </div> 
                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Trace Category</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{interpretationData.interpretation_for_fhr1?.trace_category || '--'}</p>
                                    </div>
                                </div> 

                                <div className='nst-header nst-title'>
                                    <div className='fhr_rows'>
                                        <p className='fhr-keys'>Total Monitoring Time</p>
                                        <p className='colon'>:</p>
                                        <p className='fhr-value'>{`${Math.round(difference/60000)} min` || '--'}</p>
                                    </div>
                                </div> 
                            </div>
                        :
                        <div style={{height: 250}}>
                            <div className='nst-header nst-title'>
                                <div className='fhr_rows'>
                                    <p className='fhr-keys'>Total Monitoring Time</p>
                                    <p className='colon'>:</p>
                                    <p className='fhr-value'>{Math.round(difference/60000)} min</p>
                                </div>
                            </div>
                        <div>
                            <p style={{textAlign: 'center', fontSize: 12, padding: '5px 5px'}}>
                                No data available before 10 minutes of monitoring
                            </p>
                        </div>
                        </div>
                        }
                    </div>
                </div>

                <div className='interpretation-block'>
                    <div className="interpretation-header" >
                        Additional Notes
                    </div>
                </div>
            </div>
        )
    :
        <div className='notes-box'>
            <div className="interpretation-header" >
                Additional Notes
            </div>
        </div>
    }
    </>
  )
}

export default Interpretation
