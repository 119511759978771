import React,{useState, useEffect} from 'react'
import '../Cards/DetailCard.scss'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addliveOneSecKeyarData } from '../../../redux/features/central_monitor/liveOneSecKeyarData';
import keyarData from '../../../redux/features/central_monitor/keyarData';
import DelayedCard from './DelayedCard';
import Cookie from 'js-cookie';
import axios from 'axios';
import urls from '../../../utils/url';


const Card = ({props, liveBasicDetail, addliveOneSecKeyarData}) => {

    const obj = props
    const {patientID} = props;

    // console.log(obj);
    // console.log(patientID);
    
    const cookie = Cookie.get('access_token');
    const cookieValueString = Cookie.get('data');
    const [critical , setCritical] = useState(false)
    const [fhrcritical , setfhrCritical] = useState(false)
    const [fhr2critical , setfhr2Critical] = useState(false)
    const [mhrcritical , setmhrCritical] = useState(false)
    const [uccritical , setucCritical] = useState(false)
    const [keyar, setKeyar] = useState({})
    const [isActive, setIsActive] = useState(true)
    const timestampsPerUpdate = 4; // Number of timestamps to display per update
    const updateInterval = 1000;
    const [oneSecKeyarData, setOneSecKeyarData] = useState({})
    const [isDelayedByOneMin, setIsDelayedByOneMin] = useState(false)
    const [loading, setLoading ] = useState(false);
    const [error, setError ] = useState(false);
    const [ patientBasicDetail, setPatientBasicDetail] = useState();
    const [interpretationsDisable, setInterpretationsDisable] = useState("");
  
    useEffect(() => {
      if (cookieValueString) {
        const cookieValue = JSON.parse(cookieValueString);
        const accessToken = cookieValue.access_token;
        const algorithm = cookieValue.fetal_distress_algorithm;

        console.log(algorithm)
  
        setInterpretationsDisable(algorithm);
        // console.log('Access Token:', accessToken);
        // console.log('Algorithm:', algorithm);
      }
    }, [cookieValueString])
   

    useEffect(() => {
        const data = props.keyarData
        const patientID = props.patientID
        
        if(data !== undefined && data.length> 0){
            const fhrValue = data.filter(val => val.param_type == 'fhr')[0]?.latest_data
            const mhrValue = data.filter(val => val.param_type == 'mhr')[0]?.latest_data
            const ucValue = data.filter(val => val.param_type == 'uc')[0]?.latest_data
            const fhr2Value = data.filter(val => val.param_type == 'fhr_two')[0]?.latest_data
            const ucMetaData = data.filter(val => val.param_type == 'uc')[0]?.meta_data
            const realtimeContractionData = Object.keys(ucMetaData).length > 0 ? {
                contraction: Math.floor(ucMetaData?.contractions),
                duration: Math.floor(ucMetaData?.duration)
            } : {}

            const sortedFHRData = Object.entries(fhrValue).sort(([a], [b]) => parseInt(a) - parseInt(b));
            const sortedMHRData = Object.entries(mhrValue).sort(([a], [b]) => parseInt(a) - parseInt(b));
            const sortedUCData = Object.entries(ucValue).sort(([a], [b]) => parseInt(a) - parseInt(b));
            const sortedFHR2Data = Object.entries(fhr2Value).sort(([a], [b]) => parseInt(a) - parseInt(b));
            let currentIndex = 0;

            const intervalId = setInterval(() => {
                // Determine the slice of timestamps to display
                const fhrSlice = sortedFHRData.slice(currentIndex, currentIndex + timestampsPerUpdate);
                const mhrSlice = sortedMHRData.slice(currentIndex, currentIndex + timestampsPerUpdate);
                const ucSlice = sortedUCData.slice(currentIndex, currentIndex + timestampsPerUpdate);
                const fhr2Slice = sortedFHR2Data.slice(currentIndex, currentIndex + timestampsPerUpdate);
                const temp_delay =  Date.now() - parseInt(ucSlice[ucSlice.length - 1][0])
                const delayInSecs =  Math.floor(temp_delay/1000)
                const delay = formatTime(delayInSecs)
                const fhrObject = convertArrayToObject(fhrSlice)
                const fhr2Object = convertArrayToObject(fhr2Slice)
                const mhrObject = convertArrayToObject(mhrSlice)
                const ucObject = convertArrayToObject(ucSlice)


                if (delayInSecs > 60){
                    setIsDelayedByOneMin(true)
                } else{
                    setIsDelayedByOneMin(false)
                }
               
                setKeyar({
                    fhr : fhrSlice,
                    mhr: mhrSlice,
                    uc: ucSlice,
                    fhr2: fhr2Slice,
                    delay: delay,
                    realtimeContractions: realtimeContractionData,
                })

                setOneSecKeyarData({
                    [patientID] : {
                    fhrDataset : fhrObject,
                    fhr2Dataset: fhr2Object,
                    mhrDataset: mhrObject,
                    ucDataset: ucObject
                }})
                currentIndex = (currentIndex + timestampsPerUpdate) % sortedFHRData.length;
            }, updateInterval);

            return () => clearInterval(intervalId);
            
        } else if (!data || data.length === 0){
            addliveOneSecKeyarData({})
        }
            

    }, [props])


    useEffect(() => {
        if (Object.keys(keyar).length > 0){
             
            if (keyar.fhr.length > 0){
                let fhr_data = Math.floor(keyar.fhr[0][1])
                if (fhr_data === undefined || fhr_data === '0.0' || fhr_data === 0 || fhr_data === ''){
                    setfhrCritical(false) 
                } else if (fhr_data > 160 || fhr_data < 110){
                    setfhrCritical(true)
                } else if (fhr_data <= 160 || fhr_data >= 110){
                    setfhrCritical(false)
                }
            }

            if (keyar.fhr2.length > 0){
                let fhr2_data = Math.floor(keyar.fhr2[0][1])
                if (fhr2_data === undefined || fhr2_data === '0.0' || fhr2_data === 0 || fhr2_data === ''){
                    setfhr2Critical(false) 
                } else if (fhr2_data > 160 || fhr2_data < 110){
                    setfhr2Critical(true)
                } else if (fhr2_data <= 160 || fhr2_data >= 110){
                    setfhr2Critical(false)
                }
            }

            if (keyar.mhr.length > 0){
                let mhr_data = Math.floor(keyar.mhr[0][1])
                if (mhr_data === undefined || mhr_data === '0.0' || mhr_data === 0 || mhr_data === ''){
                    setmhrCritical(false) 
                } else if (mhr_data > 100 || mhr_data < 60){
                    setmhrCritical(true)
                } else if (mhr_data <= 100 || mhr_data >= 60){
                    setmhrCritical(false)
                }
            }
        }

        if (fhr2critical || fhrcritical || mhrcritical){
            setCritical(true)
        } else {
            setCritical(false)
        }

    },[keyar, fhrcritical, fhr2critical, mhrcritical])

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
    
        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(remainingSeconds).padStart(2, '0');
    
        return `${paddedMinutes} min ${paddedSeconds} sec`;
    }

    const convertArrayToObject = (array) => {
        const resultObject = {};
        array.forEach(item => {
          const [key, value] = item;
          resultObject[key] = value;
        });
        return resultObject;
    }

    addliveOneSecKeyarData(oneSecKeyarData)


    useEffect(() => {
        setLoading(true)
        axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=basic_detail&`,
            {
              headers: {
              'Request-ID': 'allow|me', 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${cookie}`
            }
            }).then((res) => {
              if(res.data){
                const json_data = res.data
                setPatientBasicDetail(json_data.response[0].basic_detail)
                setLoading(false)
                
              }
            }).catch((err) => {
              console.log("Error in Fetching Data",err)
              setError(true)
              setLoading(false)
            }
          ) 
      },[patientID])
    


 console.log(patientBasicDetail)

 console.log(interpretationsDisable)

  return (
    <div >
        { obj && obj !== undefined && keyar && Object.keys(keyar).length > 0 && keyar != undefined ?
            <div >
                <div key={obj.patientID} index={obj.patientID} style={{marginLeft: 10}} className={critical ? 'critical_card' : 'normal_card'}>
                    <div style={{width: 352, marginTop: 15, marginBottom: 15}}>
                        <p className='name'>{obj.patientName}</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 15}}>

                            <p className='patient_id'>{`${ interpretationsDisable ? "ID : " : "MRN : "}` + `${ interpretationsDisable ? obj.patientID : patientBasicDetail.mrn}`}</p>
                            
                            <div className='connected_ellipse'>
                                <p className='connected'> Connected</p>
                                <div className='ellipse_green'></div>
                            </div> 
                           

                            
                        </div>

                    </div>
                    {
                        isDelayedByOneMin ? 
                            <DelayedCard keyar={keyar} fhr2critical={fhr2critical} fhrcritical={fhrcritical} mhrcritical={mhrcritical} uccritical={uccritical}/>
                            : 
                            <div>

                            {
                                keyar && Object.keys(keyar).length > 0 && keyar != undefined ?
                                
                                    <div className={fhrcritical === true ? 'critical_inside_card' : 'normal_inside_card'}>
                                        <div className='logo'>
                                            <p className={fhrcritical == true? 'critical_logo_name' : 'logo_name'}>
                                                FHR 
                                            </p>
                                            {/* <img src={ '../../fhr.png' } alt="FHR Logo" className='imageLogo'/> */}
                                        </div>
                                        <div>
                                            
                                        </div>
                                        <div className='number-div'>
                                            <p className={fhrcritical == true? 'critical_number' : 'fhr_number'}> { keyar.fhr.length == 0 || keyar.fhr[0][1] === '0.0' || keyar.fhr[0][1] == '' || keyar.fhr[0][1] == undefined ? '--' :  Math.floor(keyar.fhr[0][1])} </p>
                                            <p className={fhrcritical == true? 'critical_bpm' : 'bpm'}>BPM</p>
                                        </div>
                                    </div>
                                
                                : 
                                <div className='normal_inside_card'/>
                            }
                            
                            {
                                keyar && Object.keys(keyar).length > 0 && keyar != undefined ?

                                
                                    <div className={fhr2critical == true? 'critical_inside_card' : 'normal_inside_card'}>
                                        <div className='logo'>
                                            <p className={fhr2critical == true? 'critical_logo_name' : 'logo_name'}>
                                                FHR2
                                            </p>
                                            {/* <img src={ '../../fhr.png'} alt="FHR Logo" className='imageLogo'/> */}
                                        </div>
                                        <div>
                                            
                                        </div>
                                        <div className='number-div'>
                                            <p className={fhr2critical == true? 'critical_number' : 'fhr_number'}> { keyar.fhr2.length == 0 || keyar.fhr2[0][1] === '0.0' || keyar.fhr2[0][1] == '' || keyar.fhr2[0][1] == undefined ? '--' : Math.floor(keyar.fhr2[0][1])}  </p>
                                            <p className={fhr2critical == true? 'critical_bpm' : 'bpm'}>BPM</p>
                                        </div>
                                    </div>
                                
                                : 
                                <div className='normal_inside_card'/>
                            }
                            {
                                keyar && Object.keys(keyar).length > 0 && keyar != undefined ?
                                
                                    <div className={mhrcritical == true? 'critical_inside_card' : 'normal_inside_card'}>
                                        <div className='logo'>
                                            <p className={mhrcritical == true? 'critical_logo_name' : 'logo_name'}>
                                                MHR
                                            </p>
                                            {/* <img src={'../../mhr.png'} alt="MHR Logo" className='imageLogo'/> */}
                                        </div>
                                        <div>
                                            
                                        </div>
                                        <div className='number-div'>
                                            <p className={mhrcritical == true? 'critical_number' : 'fhr_number'}> { keyar.mhr.length == 0 || keyar.mhr[0][1] === '0.0' || keyar.mhr[0][1] == '' || keyar.mhr[0][1] == undefined ? '--' : Math.floor(keyar.mhr[0][1])}  </p>
                                            <p className={mhrcritical == true? 'critical_bpm' : 'bpm'}>BPM</p>
                                        </div>
                                    </div>
                                
                                : 
                                <div className='normal_inside_card'/>
                            }
                            {
                                keyar && Object.keys(keyar).length > 0 && keyar != undefined ?
                                
                                    <div className={uccritical == true? 'critical_inside_card' : 'normal_inside_card'}>
                                        <div className='logo'>
                                            <p className={uccritical == true? 'critical_logo_name' : 'logo_name'}>
                                                { 'UA' }
                                            </p>
                                            {/* <img src={ '../../uc_logo.png'} alt="UC Logo" className='imageLogo'/> */}
                                        </div>
                                        <div className='number-div'>
                                            <p className='duration'>{ keyar?.realtimeContractions?.duration == undefined || keyar?.realtimeContractions?.duration == '' || keyar?.realtimeContractions?.duration == 0 ? '--' : keyar?.realtimeContractions?.duration}</p>
                                            <p className='bpm'>Avg. UC Duration</p>
                                        </div>
                                        
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='number-div'>
                                            
                                            <p className={uccritical == true? 'critical_uc_number' : 'uc_number'}> { keyar.uc.length == 0 || keyar.uc[0][1] === '0.0' || keyar.uc[0][1] == '' || keyar.uc[0][1] == undefined ? '--' : Math.floor(keyar.uc[0][1])}   </p>
                                            {/* <p className={uccritical == true? 'critical_bpm' : 'bpm'}>Avg. UC Count</p> */}
                                        </div>
                                    </div>
                                
                                : 
                                <div className='normal_inside_card'/>
                            }
                            </div>
                    }
                    
                    <div className='button_div'>
                        <div className='delayed-div'>
                            <p className='delayed'> Delayed By:</p>
                            <p className='time'> - {keyar.delay}</p>
                        </div>
                        <Link to= {{pathname: `/dashboard/central-monitor/graph/${obj.patientID}`, state: { data: keyar }}}  target="_blank" rel="noopener noreferrer" >
                             <button className='view_button' >
                                View 
                            </button>
                        </Link>
                    </div>
                </div>
                
            </div>
            :
            <></>
        }
    </div>
  )
}

const mapStateToProps = (state) => ({
    liveBasicDetail: state.liveBasicDetail.liveBasicDetail,
});

const mapDispatchToProps = {
    addliveOneSecKeyarData
};

export default connect(mapStateToProps,mapDispatchToProps)(Card)

