import React from 'react';
import './alertModal.scss';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import { getReadableTime } from '../../utils/converter';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const AlertModal = ({ alertData, onClose, openAlertPanel, isMrn }) => {

  const navigate = useNavigate();

  const handleNavigation = (patientId, patientName) => {
    const data = {
      alertIndex: 10,
      patient_name: patientName,
      patient_id: patientId,
    };
    navigate(`/dashboard/patient/${patientId}`,  { state: data });
  }

  return (

    <Dialog fullScreen open={openAlertPanel} onClose={onClose}  TransitionComponent={Transition} className='alert-modal'>

      <div className = 'notification-panel'>
        <div className = 'notification-header'>
          <h2 className = 'header-text'>Live Alerts</h2>
          <img src='/cross-icon.svg' alt='Close Button' onClick={onClose} width={30} height={30} className='alert-close-button'/>
        </div>
        <p  className='alert-note'> Note: If the patient is currently being monitored at your facility, any live alerts generated during the monitoring will be displayed here.</p>
      </div>

     {
        alertData.length > 0 ? 
            <ul className='notification-list'>
              { alertData.map((notification, index) => (
                <>
                  <div className='notification-div' onClick={() => handleNavigation(notification.patient_id, notification.patient_name)}>
                    <img  src='/notifications.svg' alt='Notification Icon' width={28} height={28} />
                    <div key={index} className="notification-item">
                        <div className='alert-details'>
                            <strong>{notification.patient_name} ({isMrn ? notification.patient_id : notification.mrn}) </strong> 
                            <p className='alert-date-text'>{getReadableTime(notification.time)}</p>
                        </div>
                        <p className='alert-text'>Alert: {notification.live_alerts}</p>
                    </div>
                  </div>

                  <hr className='line'/> 
                </>
              ))}
            </ul>
        :
        
          <div className='no-alerts'> 
            <img src='/no-alerts.svg' alt='No Alerts'/>
            <p className='no-alerts-text'>There are no alerts to show!</p>
          </div>
      }
    </Dialog>

  );
};

export default AlertModal;
