import React, { useState, useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import {getReadableTime, getReadableTimeOnly } from '../../../utils/converter'
import AudioPlayer from './AudioPlayer'
import NoData from '../../common/NoData'
import Interpretation from './InterpretationData'
import jsPDF from "jspdf";
import './keyar-layout.scss';
import Grid from '@mui/material/Grid';
import * as htmlToImage from 'html-to-image';
import Cookie from 'js-cookie';
import { styled } from '@mui/material/styles';
import {MenuItem,Select,FormControl,InputLabel,Box,ListItemText} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import TraceThicknessDropdown from './dropdown'

const StyledSelect = styled(Select)({
  '& .MuiPaper-root': {
    backgroundColor: '#faf6ff', // This will change the background color of the dropdown
  },
  borderRadius: '4px',
  border: '0.5px solid #BFBFBF',
  background: '#fff',
  width: '9.375rem', 
  '& .MuiSelect-select': {
    height: '1rem', 
    padding: '0.625rem',
  },
});


const  KeyarGraph = ({data, deviceName, isPlaying, setIsPlaying, basicDetail, otherDetail, placeDetail, selectedSubTab}) => {
    
    //console.log(basicDetail)

    const cookieData = Cookie.get('data');
    const [isKeyarEcho, setIsKeyarEcho] = useState(false);
    const [isKeyarDT, setIsKeyarDT] = useState(false);
    const [isKeyarCM, setIsKeyarCM] = useState(false);
    const [interpretationData, setInterpretationData] = useState({});
    const [generatedTime, setGeneratedTime] = useState('');
    const [difference, setDifference] = useState('');
    const [fileURL, setFileURL] = useState(null);
    const [fhr, setFHr] = useState([]);
    const [mhr, setMHR] = useState([]);
    const [uc, setUC] = useState([]);
    const [fhr2, setFHR2] = useState([]);
    const [startTime, setStartTime] = useState('');
    const [fmm, setFmm] = useState([]);
    const [spo2, setSpo2] = useState([]);
    const [afm, setAfm] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [keyarData, setKeyarData] = useState({});
    const [download, setDownload] = useState(false);
    const [interpretationDisable, setInterpretationDisable] = useState('');
    const [isDeviceData, setIsDeviceData] = useState();
    const [ nstTime, setNstTime] = useState(data?.data_time);
    const [ isMrn, setIsMrn] = useState(); // For Malaysia Specific Requirement
    const [traceThickness, setTracethickness] = useState(1.25); // Default to 1.25 for trace Thickness
    const [isUserSelected, setIsUserSelected] = useState(false); // Track if the user has made a selection
  
    const tracethicknessOptions = ["1.00", "1.25", "1.50", "1.75", "2.00"];
  
    const handleTraceThicknessChange = (event) => {
      setTracethickness(event.target.value);
      setIsUserSelected(true); // Update that user has made a selection
    };
  
  
    useEffect(() => {
      if (cookieData) {
          const parsedData = JSON.parse(cookieData);
          const algorithmEnabled = parsedData.fetal_distress_algorithm;
          setIsMrn(algorithmEnabled);
      }
    },[cookieData])

    useEffect(() => {
      if (deviceName == 'Keyar DT'){
        setIsKeyarDT(true)
      } else if (deviceName == 'Keyar CM'){
        setIsKeyarCM(true)
      } else if (deviceName == 'Keyar Echo'){
        setIsKeyarEcho(true)
      }

      const keyardata = data?.params
      const start_time = data?.data_time
      const startTime = String(start_time).length === 10 ? start_time : start_time/1000
      setStartTime(startTime)
      const interpretation_data = data?.fetal_distress_processed_data

      const temp_fileURL = data?.file_url
      setFileURL(temp_fileURL)


      let interpretation_for_fhr1;
      let interpretation_for_fhr2;
      let fhr2_signal_present;
      if (interpretationData){
        if ('fhr1_data' in interpretation_data && 'fhr2_data' in interpretation_data) {
          interpretation_for_fhr1 = interpretation_data.fhr1_data.fetal_distress_data;
          interpretation_for_fhr2 = interpretation_data.fhr2_data.fetal_distress_data;
          fhr2_signal_present = interpretation_data.fhr2_data.signal_present;
        }
      }else {
          interpretation_for_fhr1 = interpretation_data;
          interpretation_for_fhr2 = {};
          fhr2_signal_present = false
      }

      setInterpretationData({ interpretation_for_fhr1, interpretation_for_fhr2, fhr2_signal_present });

      //To Remove Any Duplicate param type
      // const keyar = keyardata?.reduce((accumulator, obj) => {
      //     const foundObject = accumulator.find(item => item.param_type === obj.param_type);
      //     if (!foundObject) {
      //       accumulator.push(obj);
      //     }
      //     return accumulator;
      // }, []);


      const sortTimestamp = (data) => {
        return Object.fromEntries(Object.entries(data || []).sort((a, b) => a[0] - b[0]));
      }

      const fhrData = keyardata?.find(obj => obj.param_type === 'fhr').data_set
      const fhr2Data = keyardata?.find(obj => obj.param_type === 'fhr_two').data_set
      const mhrData = keyardata?.find(obj => obj.param_type === 'mhr').data_set
      const ucData = keyardata?.find(obj => obj.param_type === 'uc').data_set
      const spo2Data = keyardata?.find(obj => obj.param_type === 'spo2')?.data_set || {}

      //console.log(spo2Data)
      const fhrMetaData = keyardata?.find(val => val.param_type == 'fhr').meta_data
      const fetalMarker = fhrMetaData?.fetal_movement || [];

    // Check if the dataset is in the new format (objects with 'type' and 'timestamp') or legacy format (just timestamps)
      const isNewFormat = fetalMarker.length > 0 && typeof fetalMarker[0] === 'object';

      let automatedFetalMarker = [];
      let manualFetalMarker = [];
      let legacyFmm = [];

      if (isNewFormat) {
        // New format: separate automated and manual markers
        automatedFetalMarker = fetalMarker.filter(data => data.type === 'automated').map(obj => [parseInt(obj.timestamp)/1000, 205]);
        manualFetalMarker = fetalMarker.filter(data => data.type === 'manual').map(obj => [parseInt(obj.timestamp)/1000, 205]);
      } else {
        // Legacy format: all entries are treated as manual
        legacyFmm = fetalMarker.map(timestamp => [parseInt(timestamp)/1000, 205]);
      }

      const combinedManualFmm = [...manualFetalMarker, ...legacyFmm];
      setAfm(automatedFetalMarker)
      setFmm(combinedManualFmm)

      const sortedFhr = sortTimestamp(fhrData )
      const sortedMhr = sortTimestamp(mhrData )
      const sortedUc = sortTimestamp(ucData )
      const sortedFhr2 = sortTimestamp(fhr2Data)
      const sortedSpo2 = sortTimestamp(spo2Data)


      let fhr = Object.entries(sortedFhr).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key*1000), value == 0 ? null : Number(value)]);
      let fhr2 = Object.entries(sortedFhr2).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key*1000), value == 0 ? null : Number(value)]);
      let mhr = Object.entries(sortedMhr).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key*1000), value == 0 ? null : Number(value)]);
      let uc = Object.entries(sortedUc).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key*1000), value == 0 ? null : Number(value)]);
      let spo2 = Object.entries(sortedSpo2).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key*1000), value == 0 ? null : Number(value)]);

      setFHr(fhr)
      setFHR2(fhr2)
      setMHR(mhr)
      setUC(uc)
      setSpo2(spo2)
      uc.sort((a, b) => a[0] - b[0]);
      // console.log(uc,"UC")
      const lastUcTimestamp = uc && uc.length > 0 && uc[uc.length - 1][0];
      let temp_difference;
      if(lastUcTimestamp){
        temp_difference = lastUcTimestamp*1000 - start_time*1000;
      }
      
      // console.log(temp_difference,"Difference",lastUcTimestamp,"Last UC TimeStamp",start_time,"Start Time")

      setDifference(temp_difference)

      //console.log(sortedFhr, 'fhr')

      var currentdate = new Date().toLocaleString();
      let ampm = new Date().getHours() >= 12 ? 'PM' : 'AM';
      let generatedDate = currentdate + " " + ampm;
      setGeneratedTime(generatedDate)
    },[data])

    useEffect(() => {
      if(placeDetail){
        const placeId = Object.keys(placeDetail)[0]
        const place = placeDetail[placeId];
        if(place){
          const place_configuration = place?.configurations?.fetal_algorithm_enabled;
          setInterpretationDisable(place_configuration);
        }
      }
     }, [placeDetail])

    var chartMargin = 70;
    var legendFont = 10;
    var markerRadius = 8;
    var lineWidth = traceThickness;
    var alertWidth = 2;
    var itemMargin = 2;

    let chartHeightForFHRMHR;
    let chartHeightForUC;
    if ( window.outerHeight < 950 && window.outerWidth < 1450){
        chartHeightForFHRMHR = '60%' // 60
        chartHeightForUC = '75%'     // 35
    } else if(window.outerHeight >= 950  &&  window.outerWidth >= 1450){

        chartHeightForUC = '25%'   // 25
        chartHeightForFHRMHR = '30%' // 40
    }

    // const printNST = () => {
    //   setDownload(true)
      
    //   setisLoading(true);
    //   //window.print()
    //   // document.getElementById('keyar_graph_document_header').style.display = "block";
    //   // document.getElementById('keyar_interpretation_block').style.display = "block";
    //   const inputText = document.getElementById('download-nst-report');

    //   console.log(inputText.style.width)
    //   const inputArea = document.getElementById('graph')

    //   html2canvas(inputText, { scale: 1, useCORS: true})
    //   .then(canvas => {
    //       let imgWidth = 400;
    //       let imgHeight = canvas.height * imgWidth / canvas.width;

    //       const imgData = canvas.toDataURL('img/png');
    //       const pdf = new jsPDF('l', 'mm', 'a4');
    //       pdf.addImage(imgData, 'PNG', 0, 0,  304, 223);
    //       window.open(pdf.output("bloburl"), "_blank");
    //       pdf.save('id'+"_keyar_report.pdf");
    //       setisLoading(false);
    //       //document.getElementById('keyar_graph_document_header').style.display = "none";
    //       //keyarGraphs(basicDetail.keyar_data, 'all', false, false, setdataTimes, state.startTime)
    //   })
    // }

  //   const printNST = () => {
  //     setDownload(true);
  //     setisLoading(true);
  //     const inputText = document.getElementById('download-nst-report');
  //     const inputArea = document.getElementById('graph');
  
  //     htmlToImage.toPng(inputText)
  //     .then((dataUrl) => {
  //         let img = new Image();
  //         img.src = dataUrl;
  //         img.onload = () => {
  //             let imgWidth = 304;
  //             let imgHeight = img.height * imgWidth / img.width;
  //             const pdf = new jsPDF('l', 'mm', 'a4');
              
  //             pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth, 220);
  //             window.open(pdf.output("bloburl"), "_blank");
  //            // pdf.save('id' + "_keyar_report.pdf");
  //             setisLoading(false);
  //         };
  //     })
  //     .catch((error) => {
  //         console.error('Error generating PDF:', error);
  //         setisLoading(false);
  //     });
  // }

  const printNST = () => {
    setDownload(true);
    setisLoading(true);
    const inputText = document.getElementById('download-nst-report');

    htmlToImage.toCanvas(inputText)
        .then((canvas) => {
            const dataUrl = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'mm', 'a4');
            pdf.addImage(dataUrl, 'PNG', 0, 0, 304, 220); 

            // Open PDF in a new window for printing
            window.open(pdf.output('bloburl'), '_self');
            setisLoading(false);
        })
        .catch((error) => {
            console.error('Error generating PDF:', error);
            setisLoading(false);
        });
};


  Highcharts.setOptions({
    global: {
      useUTC: false,
      useHTML:true
    }
  });


  const fhrGraph = {
    chart: {
      height: 498,
      zoomType: 'x',
     
    },
    credits: {
      enabled: false
    },
    // mapNavigation: {
    //   zoomIn: true,
    //   zoomOut: true
    // },
    time: {
      useUTC: false
    },
    title: {
        text: '',
    },
    xAxis: {
        type: 'Time',
        dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e %b',
            week: '%e %b',
            month: '%b \'%y',
            year: '%Y'
        },
        min: startTime,
        max: startTime + 21 * 60,
        scrollbar: {
            enabled: true
        },
        tickInterval: 180,
        minorTickInterval: 30,
        minorGridLineColor: `rgba(0, 0, 0, 0.2)`,
        gridLineColor: 'rgba(0, 0, 0)',
        gridLineWidth:0.7,
       
        labels: {
            formatter: function () {
                return getReadableTimeOnly(parseInt(this.value));
            },
            // step: getStep(),
        },
        title: {
            text: 'Time'
        },
    },
    yAxis: {
        title: {
            text: 'FHR, MHR, Fetal Marker',
            style: {
              fontFamily: 'Poppins, sans-serif',  
              fontSize: '14px',                   
              fontWeight: '500'                  
            }
        },
      
        plotBands: [{
            color: `rgba(183,178,255,0.25)`,
            gridLineColor:`white`,
            from: 110,
            to: 160,
            zIndex: -1,
        }],
        tickInterval: 20,
        minorTickInterval: 5,
        // minorTickInterval: 10,
        min: 50,
        max: 210,
        startOnTick: false,
        endOnTick: false,
        minorGridLineColor: `rgba(0, 0, 0, 0.2)`,
        gridLineColor: 'rgba(0, 0, 0)',
        gridLineWidth: 0.7,
    },
    tooltip: {
        // formatter: function(){
        //     var val = this;
        //     return tooltipFormat(val);
        // }
        formatter: function() {
            return  '<b>' + this.series.name +'</b><br/>' +
                getReadableTime(parseInt(this.key)) + '<br/>' + this.y;
        }
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      layout: 'horizontal',
      itemDistance: 50,
      zIndex: 200,
      borderWidth: 1,
      borderColor: '#000000',
      backgroundColor : '#FFFFFF',
      itemStyle: {
        fontFamily: 'Poppins, sans-serif', 
        cursor: 'pointer',                  
        fontSize: '12px'                   
      },
    },
    
    series: [
        {
            name: 'Fetal Heart Rate',
            data: fhr,
            lineWidth:lineWidth,
            marker:{
                //radius : markerRadius,
                symbol: "url(/mhr-symbol.svg)"
            },
            color: 'rgb(254,141,7)',
            zIndex: 5,
        },
        {
            name: 'Maternal Heart Rate',
            data: mhr,
            lineWidth: lineWidth,
            marker:{
                //radius : markerRadius,
                symbol: "url(/fhr-symbol.svg)",
            },
            color: 'rgb(239, 55, 151)',
            zIndex: 5,
        },
        {
            name: 'Fetal Heart Rate 2',
            data: fhr2,
            lineWidth: lineWidth,
            marker:{
                //radius : markerRadius,
                symbol: "circle"
            },
            color: '#2680EB',
            zIndex: 5
        },
        {
            name: 'Manual Fetal Marker',
            data: fmm,
            type: 'scatter',
            marker: {
                radius: 5, 
                symbol: 'circle', 
                fillColor: 'rgb(239, 55, 151)',
            },
            color: 'rgb(239, 55, 151)', 
            zIndex: 5 
        },
        {
          name: 'Automated Fetal Marker',
          data: afm,
          type: 'scatter',
          marker: {
            radius: 5, 
            symbol: 'circle', 
            fillColor: 'white', 
            lineWidth: 2, 
            lineColor: 'rgb(239, 55, 151)' 
          },
          color: 'rgb(239, 55, 151)', 
          zIndex: 5 
      },
    ]
  } 
 
  
  const ucGraph = {
    chart: {
      height: 344,
  },
  credits: {
    enabled: false
  },
  time: {
    useUTC: false
  },
  title: {
      text: '',
  },
  xAxis: {
      type: 'Time',
      dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e %b',
          week: '%e %b',
          month: '%b \'%y',
          year: '%Y'
      },
      min: startTime,
      max: startTime + 21 * 60,
      scrollbar: {
          enabled: true
      },
      gridLineColor: 'rgba(0, 0, 0)',
      gridLineWidth:0.7,
      minorGridLineColor: `rgba(0, 0, 0, 0.3)`,
      
      tickInterval: 180,
      minorTickInterval: 30,
      labels: {
          formatter: function () {
              return getReadableTimeOnly(parseInt(this.value));
          },
          // step: getStep(),
      },
      title: {
          text: 'Time'
      },
   
  },
  yAxis: {
      title: {
          text: 'Uterine Activity & SpO<sub>2</sub>', 
          useHTML: true, // Enable HTML for title
          style: {
            fontFamily: 'Poppins, sans-serif',  
            fontSize: '14px',                   
            fontWeight: '500'                  
          }
      },
      tickInterval: 20,
      minorTickInterval: 10,
      minorGridLineColor: `rgba(0, 0, 0, 0.3)`,
      gridLineColor: '#000000',
      gridLineWidth:0.7,
      min: 0,
      max: 100,
  },
  tooltip: {
      // formatter: function(){
      //     var val = this;
      //     return tooltipFormat(val);
      // }
      formatter: function() {
          return  '<b>' + this.series.name +'</b><br/>' +
              getReadableTime(parseInt(this.key)) + '<br/>' + parseFloat(parseFloat(this.y).toFixed(2));
      }
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'horizontal',
    itemDistance: 25,
    zIndex: 200,
    borderWidth: 1,
    borderColor: '#000000',
    backgroundColor : '#FFFFFF',
    itemStyle: {
      fontFamily: 'Poppins, sans-serif', 
      cursor: 'pointer',                  
      fontSize: '12px'                   
    },
    labelFormatter: function() {
      return this.name.replace('SpO2', 'SpO<sub>2</sub>');  // Format SpO2 with subscript
    },
    useHTML: true 
  },
    series: [
      {
        name: 'Uterine Contractions',
        data: uc,
        lineWidth: lineWidth,
        marker:{
            //radius : markerRadius,
            symbol: "circle ccc"
        },
        color: 'rgb(239, 55, 151)'
      },
      {
        name: 'SpO2',
        data: spo2,
        lineWidth: lineWidth,
        marker:{
            //radius : markerRadius,
            symbol: "circle"
        },
        color: '#2680EB'
      },
    ]
  } 

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
        e.preventDefault();
        alert('Please click on Print button to print the NST report');
      }
    };
    
  useEffect(() => {
    const handleKeyDownEvent = (e) => {
      handleKeyDown(e);
    };
  
    window.addEventListener('keydown', handleKeyDownEvent);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, []);

  // console.log(data.data_time)

  return (

    <>
 
    <div id='nst-report'>

      <div className='reports-detail-div'> 
          <p className='reports-detail-text'>Reports {`>`} {basicDetail.name} ({isMrn ? basicDetail.id : basicDetail.mrn}) {`>`} Device Data {`>`} </p>
          <p className='device-name'> {deviceName} </p>
      </div>

      <div className='print-settings'>

        <Box sx={{display: "flex",flexDirection: "column",alignItems: "flex-start", marginTop: 2, fontFamily: 'Poppins'}} >
          <FormControl 
            sx={{
              minWidth: "11rem",
              "& .MuiOutlinedInput-root": { borderRadius: "8px", border:"0.5px solid #A163F1",
                "&.Mui-focused": {borderColor: "#A163F1"},
              }
            }}
          >

            {isUserSelected && (
              <InputLabel
                sx={{color:'#000000', fontFamily:'Poppins', fontSize:'1rem', transition: "all 1s ease-in-out", 
                    '&.Mui-focused': {color: '#000000'},
                   }}
              >
                Trace Thickness
              </InputLabel>
            )}

            <Select value={traceThickness} onChange={handleTraceThicknessChange} displayEmpty
                renderValue={(selected) =>
                  !isUserSelected ? "Trace Thickness" : selected.toFixed(2)
                }
                sx={{borderRadius: '8px',height: '2.5rem',background: '#FFFFFF !important',fontFamily: 'Poppins', marginTop:'0.5rem',
                    "& .MuiSelect-icon": {
                      color: "black", 
                    },
                    '&:hover': { border: '0.5px solid #A163F1' },
                    '& .MuiSelect-select': {fontSize: '1rem',fontWeight: '400',},
                    '& .MuiOutlinedInput-notchedOutline': {border: 'none'},
                  }}
                MenuProps={{
                  PaperProps: {
                    style: {marginTop: '0.2rem', backgroundColor: '#faf6ff !important'},
                  },
                }}
            >
              {tracethicknessOptions.map((value) => (
                <MenuItem key={value} value={parseFloat(value)}
                  sx={{ fontFamily: "Poppins, sans-serif",fontSize: '1rem',display: 'flex', justifyContent: 'space-between',alignItems: 'center',
                      '&:hover': {backgroundColor: '#faf6ff !important', fontFamily: 'Poppins'},
                      '&.Mui-selected': {backgroundColor: '#faf6ff !important',marginTop:'0.5rem',fontFamily: 'Poppins' },
                    }}
                >
                    <ListItemText primary={value}
                        primaryTypographyProps={{
                          style: {fontFamily: "Poppins"},
                        }}
                    />

                    {parseFloat(value) === traceThickness && (
                      <CheckIcon sx={{ color: "#6BBF59", width:'1.5rem', height:'1.5rem'}} />
                    )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
  
        <div className='keyar-layout-button-div' onClick={() => printNST()}>
          <img src="/white_print.svg" alt="Print Icon" width={20} height={20}/>
          <p className='print-nst-button'>Print</p>
        </div>

      </div>

      

    </div>

    {
      selectedSubTab ? 
        <div>
          {
            isKeyarEcho && selectedSubTab==='Recordings' ?
              fileURL != undefined && fileURL != '' && 
            ( 
              <div className='keyar-echo-div'>
                  <div className='upper-div'>
                    <p className='avg-fhr'>Average FHR: 130</p>
                    <p className='duration'>Duration: 04:23</p>
                  </div>

                  <div className="audio">
                    <p className='fhr-key'>FHR</p>
                    <p className='fhr-value'>120</p>
                    <AudioPlayer fileURL={fileURL} isPlaying={isPlaying} setIsPlaying={setIsPlaying}/>
                  </div>
                  
              </div>
            )

            : (isKeyarDT || isKeyarCM) && selectedSubTab==='NST'?
                <div id='download-nst-report' className='nst-report'>
                  <div id='basic-detail'>
                    <Grid container spacing={2} >
                      <div style={{width: '100%', display: 'flex', margin: '15px 0px 10px 30px'}}> 
                          <Grid item xs={2}>
                              <img src="/janitri-registered-logo.svg" alt="Janitri Logo" className="janitri_nst_logo" />
                          </Grid>
                          <Grid item xs={10}>
                                <Grid container spacing={1}>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Name</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'>{basicDetail.name || '--'}</p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Presentation</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'> {otherDetail.presentation || '--'}</p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> {isMrn ? 'Patient ID' : 'Patient MRN'}</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'> {(isMrn ? basicDetail.id : basicDetail.mrn) || '--'}</p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Age</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'>{basicDetail.age || '--'}</p>
                                      </div> 
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Number of fetus</p>
                                          <p className='basic_detail_field'> :</p>
                                          <p className='basic_detail_field'>{interpretationData.fhr2_signal_present === false ? 'Singleton/Single' : 'Twin'}</p>
                                      </div>  
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Device Used</p>
                                          <p className='basic_detail_field'> :</p>
                                          <p className='basic_detail_field'>{deviceName || '--'}</p>
                                      </div>  
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Gestational Age</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'>{otherDetail.gestation_period || '--'}</p>
                                      </div>  
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Time </p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'>{getReadableTime(data.data_time)}</p>
                                      </div>
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <div className='basic_detail_row'>
                                        <p className='basic_detail_name'> Total Monitoring Time </p>
                                        <p className='basic_detail_field'>:</p>
                                        <p className='basic_detail_field'>{`${Math.round(difference/60000)} min` || '--'}</p>
                                    </div>
                                  </Grid>  
                                </Grid>
                          </Grid>
                      </div>   
                    </Grid>
                    <hr className='nst-hr'/>
                              
                  </div>
                  <div className='keyar-layout' >
                    <div style={{width: '75%'}}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={fhrGraph}
                        constructorType={'chart'}
                        allowChartUpdate={true} 
                      />
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ucGraph}
                        constructorType={'chart'}
                        allowChartUpdate={true}
                      />
                    </div>
                    <Interpretation interpretationData={interpretationData} difference={difference} interpretationDisable={interpretationDisable}/>
                  </div>
                </div>
            : 
            <NoData/>
          }
        </div>
      :
        <div>
          {
            isKeyarEcho  ?
              fileURL != undefined && fileURL != '' && 
            ( 
              <div className='keyar-echo-div'>
                  <div className='upper-div'>
                    <p className='avg-fhr'>Average FHR: 130</p>
                    <p className='duration'>Duration: 04:23</p>
                  </div>

                  <div className="audio">
                    <p className='fhr-key'>FHR</p>
                    <p className='fhr-value'>120</p>
                    <AudioPlayer fileURL={fileURL} isPlaying={isPlaying} setIsPlaying={setIsPlaying}/>
                  </div>
                  
              </div>
            )

            : (isKeyarDT || isKeyarCM) ?
              <>

                <div id='download-nst-report' className='nst-report'>
                  <div id='basic-detail'>
                    <Grid container spacing={2} >
                      <div style={{width: '100%', display: 'flex', margin: '15px 0px 10px 30px'}}> 
                          <Grid item xs={2}>
                              <img src="/janitri-logo-latest.jpg" className="janitri_nst_logo" />
                          </Grid>
                          <Grid item xs={10}>
                                <Grid container spacing={1}>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Name</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'>{basicDetail.name || '--'}</p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Presentation</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'> {otherDetail.presentation || '--'}</p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Patient ID</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'> {basicDetail.id || '--'}</p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Age</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'>{basicDetail.age || '--'}</p>
                                      </div> 
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Number of fetus</p>
                                          <p className='basic_detail_field'> :</p>
                                          <p className='basic_detail_field'>{interpretationData.fhr2_signal_present === false ? 'Singleton/Single' : 'Twin'}</p>
                                      </div>  
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Device Used</p>
                                          <p className='basic_detail_field'> :</p>
                                          <p className='basic_detail_field'>{deviceName || '--'}</p>
                                      </div>  
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Gestational Age</p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'>{otherDetail.gestation_period || '--'}</p>
                                      </div>  
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4}>
                                      <div className='basic_detail_row'>
                                          <p className='basic_detail_name'> Time </p>
                                          <p className='basic_detail_field'>:</p>
                                          <p className='basic_detail_field'>{getReadableTime(data.data_time)}</p>
                                      </div>
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <div className='basic_detail_row'>
                                        <p className='basic_detail_name'> Total Monitoring Time </p>
                                        <p className='basic_detail_field'>:</p>
                                        <p className='basic_detail_field'>{`${Math.round(difference/60000)} min` || '--'}</p>
                                    </div>
                                  </Grid>  
                                </Grid>
                          </Grid>
                      </div>   
                    </Grid>
                    <hr className='nst-hr'/>
                              
                  </div>
                  <div className='keyar-layout' >
                    <div style={{width: '75%'}}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={fhrGraph}
                        constructorType={'chart'}
                        allowChartUpdate={true} 
                      />
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ucGraph}
                        constructorType={'chart'}
                        allowChartUpdate={true}
                      />
                    </div>
                    <Interpretation interpretationData={interpretationData} difference={difference} interpretationDisable={interpretationDisable}/>
                  </div>
                </div>
                
                
                
              </>
            : 
            <NoData/>
          }
        </div>
    }
    
    </>
  )
}

export default KeyarGraph
