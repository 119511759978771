import React,{useEffect, useState} from 'react';
import '../osi/osiCommon.scss';
import urls from '../../utils/url';
import moment from 'moment';
import {useNavigate, useLocation, useParams } from "react-router-dom";
import { getReadableTime, getReadableTimeOnly,getReadableDateOnly } from '../../utils/converter';


const PatientHistoryCard = (props) => {

const {si, index, time, osi_value, systolic_bp, pulse_rate, firstTime, lastTime, average_spo2, max, min, activeIndex} = props


return (
    <div>
        
        <div className='card-info' >

           <p style={{textAlign:`right`, fontSize:`14px`}}> {firstTime && lastTime ? `${getReadableDateOnly(firstTime)} ${getReadableTimeOnly(firstTime)} - ${getReadableTimeOnly(lastTime)}` : time ? getReadableTime(time) : null} </p>

            <div className='card-data'>
                <div className='card-values'>
                    <p style={si && activeIndex === index ? {fontSize:`48px`, fontWeight:`500`, lineHeight:`45px`, color: '#A163F1'} :{fontSize:`48px`, fontWeight:`500`, lineHeight:`45px`}}>{osi_value ? osi_value : average_spo2}</p>  
                    <p style={{fontSize:`14px`,fontWeight:`600`}}>{osi_value ? "OSI Value" : "SpO2"}</p>
                </div>
            
                <div style={{display:`flex`, flexDirection:`row`, gap:`1.5rem`, paddingLeft:`2.5rem`}}>
                    <div className='card-values'>
                        <p style={{fontSize:`20px`,fontWeight:`300`}}>{systolic_bp ? systolic_bp : max}</p>
                        <p style={{fontSize:`14px`,fontWeight:`400`}}>{systolic_bp ? "mmhg" : null}</p>
                        <p style={{fontSize:`14px`,fontWeight:`400`}}>{systolic_bp ? "SYS" : "(max)"}</p>
                    </div>

                    <div className='card-values'>
                        <p style={{fontSize:`20px`,fontWeight:`300`}}>{pulse_rate ? pulse_rate : min}</p>
                        <p style={{fontSize:`14px`,fontWeight:`400`}}>{pulse_rate ? "beats/min" : null}</p>
                        <p style={{fontSize:`14px`,fontWeight:`400`}}>{pulse_rate ?  "Pulse" : "(min)"}</p>
                    </div>
                </div>

            </div>

        </div>

    </div>
)

}
  
export default PatientHistoryCard