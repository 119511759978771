import React,{useEffect, useState} from 'react';
import { Scatter } from 'react-chartjs-2';
import '../osi/osiCommon.scss';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsHeatmap from "highcharts/modules/heatmap";
import axios from 'axios';
import urls from '../../utils/url';
import {useNavigate, useLocation, useParams } from "react-router-dom";
import { getReadableDateOnly, getReadableTimeOnly, getReadableTime, isJson } from '../../utils/converter';
import PatientHistoryCard from './patientHistoryCard';
import Loading from '../common/loading';
import Reload from '../common/Reload';
import NoData from '../common/NoData';
import Cookie from 'js-cookie';

HighchartsHeatmap(Highcharts);

const Spo2Graph = () => {

const cookie = Cookie.get('access_token') 
const {patientID} = useParams();
const [loading, setLoading] = useState(false);
const [error, setError] = useState(false);
const [spo2Data, setSpo2Data] = useState()
const [spo2Value, setSpo2Value] = useState();
const [spo2Statistics, setSpo2Statistics] = useState()
const [time, setTime] = useState();
const [tickPositions, setTickPositions] = useState([])
const [isActive, setIsActive] = useState(false);
const location = useLocation()
const data = location.state;

var chartMarginLeft = 70;
var chartMarginRight = 40;
var chartMarginTop = 60;
var chartMarginBottom = 60;
var legendFont = 10;
var itemMargin = 2;

function getTickPositions(dataTime) {
  // maximum 24 hours
  var positions = [];
  for(var i = 0; i <= 288; i++){
      positions.push(dataTime + (i*300*1000)); // 5 mins time Frame between two tick Positions
  }
  return positions;
}

function getStep(){
  var width = window.screen.width;
  var step = 1 + parseInt((1280 - width));
  if(step < 1 ){
      step = 1;
  }
  return step;
}

const parseTimestampsAndValues = (dataPoint) => {
  return [parseFloat(dataPoint[0]), parseFloat(dataPoint[1])];
};

useEffect(() => {
  setLoading(true);
  axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=keyar_data`, {
      headers: {
          'Request-ID': 'allow|me',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
      }
  }).then((res) => {
      if (res.data) {
          const json_data = isJson(res.data);
          setSpo2Data(json_data.response[0].keyar_data);
          console.log(json_data.response[0].keyar_data)
          setLoading(false);
      }
  }).catch((err) => {
      console.log("Error in Fetching Data", err);
      setError(true);
      setLoading(false);
  });
}, [patientID]);

useEffect(() => {
    Highcharts.setOptions({
        global: {
            useUTC: false
        }
  });

  const spo2Params = spo2Data && spo2Data.length > 0 ? spo2Data.map(obj => obj.params) : null;
  const filteredSpo2 = spo2Params && spo2Params.length > 0 ? spo2Params.map(obj => obj && obj.filter(data => data.param_type === 'spo2')) : null;
  const spo2DataValue = filteredSpo2 && filteredSpo2.length > 0 ? filteredSpo2.map(obj => obj && obj.map(object => object.data_set)) : null;

  // const data = spo2DataValue && spo2DataValue.length > 0 ? spo2DataValue.map((innerArray, key) => {
  //     if (innerArray && innerArray.length > 0) {
         
  //         const timestamps = Object.keys(innerArray[0]).map(item => parseFloat(item)); // Add null check
  //         const values = Object.values(innerArray[0]).map(item => parseFloat(item)); // Add null check

  //         timestamps.sort((a, b) => a - b); // sort timestamps to get firstTime and lastTime

  //         const max = Math.max(...values) + '%';
  //         const min = Math.min(...values) + '%';
  //         const averageSpo2 = Math.trunc(values.reduce((acc, val) => acc + val, 0) / values.length) + '%';
  //         const firstTime = timestamps[0];
  //         const lastTime = timestamps[timestamps.length - 1];
  //         const dataEntries = Object.entries(innerArray[0]); // Add null check

  //         dataEntries.sort((a, b) => a[0] - b[0]); // sort the dataEntries based on timestamps

  //         return {
  //             dataEntries,
  //             statistics: {
  //                 max,
  //                 min,
  //                 averageSpo2,
  //                 firstTime,
  //                 lastTime
  //             },
  //         };
  //     } else {
  //         return null;
  //     }
  // }).filter(Boolean) : [];

  const data = spo2DataValue && spo2DataValue.length > 0 ? spo2DataValue.map((innerArray, key) => {
    const timestamps = Object.keys(innerArray[0]).map(item => parseFloat(item));
    const values = Object.values(innerArray[0]).map(item => parseFloat(item));

    timestamps.sort((a, b) => a - b); // sort timestamps to get firstTime and lastTime

    const max = Math.max(...values) + '%';
    const min = Math.min(...values) + '%';
    const averageSpo2 = Math.trunc(values.reduce((acc, val) => acc + val, 0) / values.length) + '%';
    const firstTime = timestamps[0];
    const lastTime = timestamps[timestamps.length - 1];
    const dataEntries = Object.entries(innerArray[0]);

    dataEntries.sort((a, b) => a[0] - b[0]); // sort the dataEntries based on timestamps

    return {
        dataEntries,
        statistics: {
            max,
            min,
            averageSpo2,
            firstTime,
            lastTime
        },
    };
  }) : [];


  setSpo2Statistics(data);

  const mergedSpo2Data = data && data.length > 0 ? data.reduce((acc, object) => {
      return acc.concat(object.dataEntries);
  }, []) : [];

  const spo2Values = [];
  for (let i = 0; i < mergedSpo2Data.length; i += 1) {
      const dataPoint = mergedSpo2Data[i];
      const [timestamp, value] = parseTimestampsAndValues(dataPoint);
      spo2Values.push([timestamp, value]);
  }

  setSpo2Value(spo2Values);

  const firstTickPosition = spo2Values && spo2Values.length > 0 ? spo2Values[0][0] : null;
  setTime(firstTickPosition);

  if (firstTickPosition) {
      setTickPositions(getTickPositions(firstTickPosition));
  }
}, [spo2Data]);


const handleClick = (index) => {
  setIsActive(index);
};
 


const spo2Graph = {
  chart: {
    marginLeft: chartMarginLeft,
    marginRight:chartMarginRight,
    marginTop: chartMarginTop,
    marginBottom:chartMarginBottom,
    scrollablePlotArea: {
      minWidth: 12000,
      scrollPositionX: -1
      
    },
    plotOptions: {
      series: {
        sort: true
      }
    },
    borderRadius:12,// graph border radius
    endOnTick:true,
    height:500, // height of chart
    paddingLeft:45,
    
  },
  title: {
    text: null
  },
  
  xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M', // Format for hours and minutes
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%I:%M', this.value);
            },
      },
        gridLineColor: 'rgba(0, 0, 0, 0.1)',
        gridLineWidth: 2,
        gridLineDashStyle: 'Dash',
        lineColor: '#A163F1',
        lineWidth: 1.2,
     },


    yAxis: {
        title: {
            text: 'SPO2'
        },
        tickInterval: 10,
        gridLineColor: 'rgba(0, 0, 0, 0.1)',
        gridLineWidth: 1.1,
        gridLineDashStyle: 'Dash',
        lineColor: '#A163F1',
        lineWidth: 1.2,
        min: 85,
        max: 100,
        pointInterval: 10,
        tickPositioner: function () {
            var positions = [],
            i = 85;
            while(i <= 100){
                positions.push(i);
                i+=1;
            }
            return positions;
        }
    },
    
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      itemMarginTop: itemMargin,
      itemMarginBottom: itemMargin,
      zIndex: 20,
      backgroundColor : '#FFFFFF',
      floating : true,

    },
    series: [
      {
        name: 'SPO2 Trends',
        data: spo2Value,
        lineWidth: 1,
        color: 'black', 
        
      }
    ],
}

return (
  <div className='osi-info-section'>

   {
      loading ? <div className="loading-div" style={{paddingLeft:`0%`, marginTop:`13rem`}}><Loading/></div>
      : error ? <Reload/> 
      : spo2Value && spo2Value.length ? 
          <>
            <div className='graph-section ' >

              <div className='osi-info-header'>
                <p className='heading text-1'>SPO2 Trends</p>
                <hr className='hr-line'/>
                <p className='heading text-1 time-text'>{time ? getReadableDateOnly(time) : '--'}</p>
              </div>

              
              <div className='graph-container'>
                <div className='graph-header'>
                  <p className='graph-heading'>Spo2 Trends</p>
                  <div>
                    <p className='axis-text'>X-axis = <span className='axis-value'>Time</span></p>
                    <p className='axis-text'>Y-axis = <span className='axis-value'>SPO2</span></p>
                  </div>
                </div>

                  <HighchartsReact
                    highcharts={Highcharts}
                    options={spo2Graph}
                    constructorType={'chart'}
                    allowChartUpdate={true}  
                  />  
                
              </div> 

            </div>

            <div className='card-section'>
              <p className='patient-text'>Patient History</p>
              <hr className='hr-line'/>
              
              { 
                  spo2Statistics.map((object, ind) => {
                      return(
                        <div key={ind} className={isActive == ind ? 'active-card' : 'card'} onClick={() => handleClick(ind)} >
                          <PatientHistoryCard index={ind} firstTime={object.statistics.firstTime} lastTime={object.statistics.lastTime} average_spo2={object.statistics.averageSpo2} max={object.statistics.max} min={object.statistics.min}></PatientHistoryCard>
                        </div>
                      )
                  }) 
              }
            
            </div>

          </> 
      :<div style={{paddingTop:`60px`, paddingLeft:`8%`}} ><NoData/></div>
   }
   
  </div>  
)
}

export default Spo2Graph