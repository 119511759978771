import React from 'react';
import { useState, useEffect } from 'react';
import '../common.scss';
import axios from 'axios';
import urls from '../../../utils/url';
import { getReadableTime, charLimit, isJson, convertChildCode, convertMotherCode, TooltipComponent, limitStr } from '../../../utils/converter';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import Cookie from 'js-cookie';

const DeliveryInfo = (props) => {

    const cookie = Cookie.get('access_token');
    const { patientID } = props;
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ patientDeliveryInfo, setPatientDeliveryInfo ] = useState();


  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=delivery_detail,events,basic_detail,user_details&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPatientDeliveryInfo(json_data.response[0])
            setLoading(false)
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err)
          setError(true)
          setPatientDeliveryInfo()
          setLoading(false)
        }
      ) 
  }, [])

  return (

    <>
        { 
            loading ? <div className='loading-div'><Loading/></div> 
            : error ? <div className='reload-div'><Reload/></div>
            :
                <div className='info-section'>

                {/* Delivery Information */}
                <>
                    <div className='info-header'>
                        <p className='heading'>Delivery Details</p>
                    </div>

                    <div className='info-container'>
                        <div>
                            <p className='text1'>Mother Status</p>
                            <p className='text2'>{patientDeliveryInfo?.delivery_detail?.mother_status ? patientDeliveryInfo?.delivery_detail?.mother_status : '--' }</p>
                        </div>

                        <div>
                            <p className='text1'>Delivery Time</p>
                            {
                                patientDeliveryInfo?.events && patientDeliveryInfo?.events.length > 0 ?
                                <p className='text2'> { (patientDeliveryInfo?.events.filter(obj => obj.event === "delivered")[0] !== undefined) ? getReadableTime(patientDeliveryInfo?.events.filter(obj => obj.event === "delivered")[0].event_time) : '--'}</p>
                                : <p>--</p> 
                            } 
                        </div>
                            
                        <div>
                            <p className='text1'>Place Of Delivery</p>
                            <p className='text2'>{patientDeliveryInfo?.delivery_detail?.delivery_hospital_type ? patientDeliveryInfo?.delivery_detail?.delivery_hospital_type : '--' }</p>
                        </div>

                    </div>

                    {/* Based on the status of death, death-info-container will be shown else it will not shown */}
                    {patientDeliveryInfo?.delivery_detail?.death_cause || patientDeliveryInfo?.delivery_detail?.death_cause_other ?
                        <div className='death-info-container'>

                            <div>
                                <p className='text1'>Death Cause</p>
                                {
                                patientDeliveryInfo?.delivery_detail?.death_cause && patientDeliveryInfo?.delivery_detail?.death_cause.length > charLimit ? 
                                    <TooltipComponent title={`${convertMotherCode(patientDeliveryInfo?.delivery_detail?.death_cause)}`} content={limitStr(convertMotherCode(patientDeliveryInfo?.delivery_detail?.death_cause), charLimit)}/>
                                  : 
                                    <p className='text2'>{patientDeliveryInfo?.delivery_detail?.death_cause ? convertMotherCode(patientDeliveryInfo?.delivery_detail?.death_cause) : '- -'}</p>
                                }
                            </div>

                            <div>
                                <p className='text1'>Other Death Cause</p>
                                {
                                patientDeliveryInfo?.delivery_detail?.death_cause_other && patientDeliveryInfo?.delivery_detail?.death_cause_other.length > charLimit ?
                                    <TooltipComponent title={`${patientDeliveryInfo?.delivery_detail?.death_cause_other}`} content={limitStr(patientDeliveryInfo?.delivery_detail?.death_cause_other)} />
                                    :
                                    <p className='text2'>{patientDeliveryInfo?.delivery_detail?.death_cause_other ? patientDeliveryInfo.delivery_detail.death_cause_other : "- -"}</p>
                                }
                            </div>

                            <div>
                                <p className='text1'>Death Time</p>
                                <p className='text2'>{getReadableTime(patientDeliveryInfo?.delivery_detail?.death_time)}</p>
                            </div>

                        </div>
                    : null }
            
                    <div className='death-info-container'>

                        <div>
                            <p className='text1'>Delivery Type</p>
                            <p className='text2'>{patientDeliveryInfo?.delivery_detail?.delivery_type ? patientDeliveryInfo?.delivery_detail?.delivery_type : '--' }</p>
                        </div>

                        <div>
                            <p className = 'text1'> Number of Childeren </p>
                            <p className = 'text2'> {patientDeliveryInfo?.delivery_detail?.no_of_children ? patientDeliveryInfo?.delivery_detail?.no_of_children : '--' } </p>
                        </div>

                        <div>
                            <p className='text1'>Assist Provided</p>
                            <p className='text2'>{patientDeliveryInfo?.delivery_detail?.assist_provided ? patientDeliveryInfo?.delivery_detail?.assist_provided : '--' }</p>
                        </div>

                        <div>
                            <p className='text1'>Labour Type</p>
                            <p className='text2'>{patientDeliveryInfo?.delivery_detail?.labor_type ? patientDeliveryInfo?.delivery_detail?.labor_type : '--' }</p>
                        </div>

                        <div>
                            <p className='text1'>Pushing Done</p>
                            <p className='text2'>{patientDeliveryInfo?.delivery_detail?.pushing_done ? patientDeliveryInfo?.delivery_detail?.pushing_done : '--' }</p>
                        </div>

                        <div>
                            <p className = 'text1'>Robson Classification</p>
                            <p className = 'text2'>{patientDeliveryInfo?.delivery_detail?.robson_classification ? patientDeliveryInfo?.delivery_detail?.robson_classification : '--' }</p>
                        </div>

                        <div>
                            <p className = 'text1'> Nurse ID </p>
                            <p className = 'text2'> {patientDeliveryInfo?.basic_detail?.registration_worker_id ? patientDeliveryInfo?.basic_detail?.registration_worker_id : '--' } </p>
                        </div>

                        <div>
                        <p className='text1'>Nurse Name</p>
                        {patientDeliveryInfo?.user_details[patientDeliveryInfo?.basic_detail?.registration_worker_id]?.first_name && patientDeliveryInfo?.user_details[patientDeliveryInfo?.basic_detail?.registration_worker_id]?.first_name.length > charLimit ?
                            <TooltipComponent title={`${patientDeliveryInfo?.user_details[patientDeliveryInfo?.basic_detail?.registration_worker_id]?.first_name}`} content={limitStr(patientDeliveryInfo?.user_details[patientDeliveryInfo?.basic_detail?.registration_worker_id]?.first_name, charLimit)} />
                            :
                            <p className='text2'>{patientDeliveryInfo?.user_details[patientDeliveryInfo?.basic_detail?.registration_worker_id]?.first_name ? patientDeliveryInfo.user_details[patientDeliveryInfo?.basic_detail?.registration_worker_id]?.first_name : "--"}</p>
                        }
                        </div>


                        <div>
                            <p className = 'text1'> Completeness Checked  </p>
                            <p className = 'text2'> {patientDeliveryInfo?.delivery_detail?.completeness_checked ? patientDeliveryInfo?.delivery_detail?.completeness_checked : '--' } </p>
                        </div>

                        <div>
                            <p className = 'text1'> Placenta Weight 1 </p>
                            <p className = 'text2'>{patientDeliveryInfo?.delivery_detail?.placenta_weight_1 ? patientDeliveryInfo?.delivery_detail?.placenta_weight_1 : '--' } </p>
                        </div>

                        <div>
                            <p className = 'text1'> Placenta Weight 2 </p>
                            <p className = 'text2'> {patientDeliveryInfo?.delivery_detail?.placenta_weight_2 ? patientDeliveryInfo?.delivery_detail?.placenta_weight_2 : '--' } </p>
                        </div>

                        <div>
                            <p className = 'text1'> Blood Loss Estimation </p>
                            <p className = 'text2'> {patientDeliveryInfo?.delivery_detail?.blood_loss_estimation ? patientDeliveryInfo?.delivery_detail?.blood_loss_estimation : '--' }</p>
                        </div>

                    </div>
                </>
                
                {/* Child Information, if child details are available then only we will display those information else not */}
                {
                    patientDeliveryInfo?.delivery_detail?.child_details ? 
                        patientDeliveryInfo?.delivery_detail?.child_details.map((obj,index) => {
                            return(
                                <div>

                                    <div className='info-header'>
                                      <p className='heading'> Child {index+1} Details</p>
                                    </div>

                                    <div className='info-container'>
                                        <div>
                                            <p className='text1'>Status</p>
                                            <p className='text2'> {obj.child_status ? (obj.child_status === "live birth" ? "Alive" : obj.child_status) : "--"}</p>
                                        </div>

                                        <div>
                                            <p className='text1'>Gender</p>
                                            <p className='text2'>{obj.gender ? obj.gender : "--" }</p>
                                        </div>
                                            
                                        <div>
                                            <p className='text1'>Weight(Kgs)</p>
                                            <p className='text2'>{obj.weight ? obj.weight : "--" }</p>
                                        </div>

                                        
                                        { obj.child_status === "live birth" || obj.child_status === 'dead' ?
                                            <div>
                                                <p className='text1'>Delivery Time</p>
                                                <p className='text2'>{obj.delivery_time ? getReadableTime(obj.delivery_time) : "--"}</p>
                                            </div>
                                            : <></>
                                        }
                                            
                                        <div>
                                            <p className='text1'>Apgar Score</p>
                                            <p className='text2'>{obj.apgar_score ? obj.apgar_score : "--"}</p>
                                        </div>
                                       
                                       {/* If child is alive then death parameters will not display */}
                                       { 
                                            obj.child_status !== "live birth" ?
                                            (
                                                <>
                                                    <div>
                                                        <p className='text1'>
                                                            {
                                                                obj.child_status === 'dead' ? "Death Time" : 
                                                                obj.child_status === 'abortion' ? "Time of Abortion" : 
                                                                obj.child_status === 'macreated still birth' ? "Time of Macerated Still Birth" : 
                                                                obj.child_status === 'fresh still birth' ? "Time of Fresh Still Birth" : null
                                                            }
                                                        </p>
                                                        <p className='text2'>{obj.child_death_time ? getReadableTime(obj.child_death_time) : "--"}</p>
                                                    </div>

                                                    <div>
                                                        <p className='text1'>
                                                            {
                                                            obj.child_status === 'dead' ? "Death Cause" : 
                                                            obj.child_status === 'abortion' ? "Reason for Abortion" : 
                                                            obj.child_status === 'macreated still birth' ? "Reason for Macerated Still Birth" : 
                                                            obj.child_status === 'fresh still birth' ? "Reason for Fresh Still Birth" : null
                                                            }
                                                        </p>
                                                        {obj.child_death_cause && obj.child_death_cause.length > charLimit ?
                                                            <TooltipComponent title={`${convertChildCode(obj.child_death_cause)}`} content={limitStr(convertChildCode(obj.child_death_cause),charLimit)} />
                                                            :
                                                            <p className='text2'>{obj.child_death_cause ? convertChildCode(obj.child_death_cause) : "--"}</p>
                                                        }
                                                    </div>

                                                    <div>
                                                        <p className='text1'>
                                                            {
                                                            obj.child_status === 'dead' ? "Other Death Cause" : 
                                                            obj.child_status === 'abortion' ? "Other Reason for Abortion" : 
                                                            obj.child_status === 'macreated still birth' ? "Other Reason for Macerated Still Birth" : 
                                                            obj.child_status === 'fresh still birth' ? "Other Reason for Fresh Still Birth" : null
                                                            }
                                                        </p>
                                                        {obj.child_death_cause_other && obj.child_death_cause_other.length > charLimit ?
                                                            <TooltipComponent title={`${obj.child_death_cause_other}`} content={limitStr(obj.child_death_cause_other)} />
                                                            :
                                                            <p className='text2'>{obj.child_death_cause_other ? obj.child_death_cause_other : "--"}</p>
                                                        }
                                                    </div>
                                                </>
                                            )
                                            : 
                                            <></>

                                        }
                                    </div>
                                </div>
                            )}) 
                            
                    : <> </>
                } 

                </div>
        }
    </>
  )
}

export default DeliveryInfo