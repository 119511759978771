import React, { useEffect } from 'react';
import {useState} from 'react';
import  '../common.scss';
import urls from '../../../utils/url';
import axios from 'axios';
import DeviceGraphs from './DeviceGraphs';
import Cookie from 'js-cookie';
import moment from 'moment'


const DeviceData = (props) => {
  //console.log(props)
  
  const cookie = Cookie.get('access_token')
  const {patientID} = props;
  const [keyarCMData, setKeyarCMData] = useState([])
  const [keyarDTData, setKeyarDTData] = useState([])
  const [keyarEchoData, setKeyarEchoData] = useState([])
  const [osiData, setOsiData] = useState()
  const [activeIndex, setActiveIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(false)
  const [otherDetail, setOtherDetail] = useState([])
  const [basicDetail, setBasicDetail] = useState([])
  const [threshold, setThreshold] = useState();
  const [placeDetail, setPlaceDetail] = useState([])

  function sortDataByLatestTimestamp(data) {
    if (data && Object.keys(data).length > 0 && data.length > 0 && data != '' && data != null){
      return data?.sort((a, b) => b.data_time - a.data_time);
    }
  }

  useEffect(() => {
    fetchDeviceData()
  },[])

  const fetchDeviceData = async () => {
    await axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=keyar_data,basic_detail,pa_pv_examination,labor_data,place_details`,
    {
      headers: {
      'Request-ID': 'allow|me', 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${cookie}`
    }
    }).then((res) => {     
      if(res.data){
          const jsonData = isJson(res.data)
          const keyar_data = jsonData.response[0]?.keyar_data
          const labor_data = jsonData.response[0]?.labor_data

          const sortedKeyarData = sortDataByLatestTimestamp(keyar_data)

          let cm_data = []
          let dt_data = []
          let echo_data = []
          //let osi_data = []
          let undefined_data = []

          if (Object.keys(sortedKeyarData || []).length > 0){
            keyar_data?.forEach(item => {

              if (!item.params == undefined || !item.params == ''){
                const ucData = item.params.find(data => data.param_type == 'uc');
                if (Object.keys(ucData?.data_set || [].length > 0)){
                  const ucTimestamps = Object.keys(ucData.data_set || [])?.map(Number)?.sort((a, b) => a - b)
                  var timestampDifference = ucTimestamps[1] - ucTimestamps[0]
                }
              
                if ( ucData && ucData.serial_number.includes("JKEC")) {
                    echo_data.push(item);
                } else if (ucData && (ucData.serial_number.includes('JKDP') || ucData.serial_number.includes('eFM') || timestampDifference < 1100)) {
                    dt_data.push(item);
                } else if (ucData && (ucData.serial_number.includes('JKKP') || (timestampDifference > 1100 && timestampDifference < 2200))) {
                  cm_data.push(item);
                } else {
                  undefined_data.push([])
                }
              }
              // Add more conditions here for other serial number checks
            });
          }

          if (labor_data && labor_data.length > 0) {
              const thresholdValue = labor_data.filter(obj => obj.data_type === "osi_threshold");
              const osiThresholdValue = thresholdValue.map(obj => [parseInt(moment(obj.data_time * 1000).format('x')), parseFloat(obj.param_1)]);
              const { latestTimestamp, latestValue } = getLastOsiValue(osiThresholdValue);

          
              const bpData = labor_data.filter(obj => obj.data_type === "bp");
              const systolic_bp = bpData.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)]);
              systolic_bp.sort((a, b) => a[0] - b[0]); // Sort systolic bp data based on order of data_time
          
              const pulseData = labor_data.filter(obj => obj.data_type === "pulse");
              const temp_pulse = pulseData.map(obj => [parseInt(moment(obj.data_time).format('x')), parseInt(obj.param_1)]);
              temp_pulse.sort((a, b) => a[0] - b[0]); // Sort pulse data based on order of data_time
          
              const osi_value = systolic_bp.map(systolic => {
                  const pulse = temp_pulse.find(pulse => pulse[0] === systolic[0]);
                  if (pulse) {
                      const calculatedResult = (pulse[1] / systolic[1]).toFixed(1);
                      const roundedResult = Math.round(calculatedResult * 10) / 10;
                      return [systolic[0] * 1000, roundedResult];
                  } else {
                      return [systolic[0], null];
                  }
              });
          
              const uniqueOsiData = [];
              const uniqueOsiValues = new Set();
              if (osi_value) {
                  osi_value.forEach((arr) => {
                      const key = arr.toString();
                      if (!uniqueOsiValues.has(key)) {
                          uniqueOsiValues.add(key);
                          uniqueOsiData.push(arr);
                      }
                  });
              }
          
              const osiStatistics = uniqueOsiData && uniqueOsiData.length > 0 && uniqueOsiData.map(osi => {
                  const pulse = temp_pulse.find(pulse => pulse[0] * 1000 === osi[0]);
                  const systolic = systolic_bp.find(systolic => systolic[0] * 1000 === osi[0]);
                  const osi_value = osi[1];
                  const pulse_value = pulse ? pulse[1] : null;
                  const systolic_value = systolic ? systolic[1] : null;
          
                  return {
                      systolic_bp: systolic_value,
                      pulse_rate: pulse_value,
                      data_time: osi[0],
                      osi_value: osi_value,
                      threshold: latestValue ? latestValue : '0.8',
                  };
              });
          
              setOsiData(osiStatistics);
          }
        
          setKeyarCMData(cm_data)
          setKeyarDTData(dt_data)
          setKeyarEchoData(echo_data)
         
          
          const patient_detail = jsonData.response[0].basic_detail;
          const patient_other_detail = jsonData.response[0].pa_pv_examination;
          const place_detail = jsonData.response[0].place_details;

          // console.log(patient_detail)
          // console.log(patient_other_detail)

          setBasicDetail(patient_detail)
          setOtherDetail(patient_other_detail)
          setPlaceDetail(place_detail)
          // setPatientDetail({
          //   name : `${patient_detail.name}`,
          //   age: `${patient_detail.age}`,
          //   patient_id: `${patient_detail.id}`,
          //   ges_period: `${patient_other_detail.gestation_period}`
          // })
          //const keyar_data = latest_keyar_data[latest_keyar_data.length-1]
          // setKeyarData(keyar_data)
          // setStartTime(latest_keyar_data[latest_keyar_data.length-1].data_time)
          
          setLoading(false)
      }
    }).catch((err) => {
      console.log("Graph API failed",err)
      setError(true)
      setLoading(false)
    })
  }

  const isJson = (data) => {
    try{
        const jsonString = data.replace(/NaN/g, '"NaN"');
        return JSON.parse(jsonString);
    }catch (e){
       return data;
    }
  }

  const getLastOsiValue = (value) => {
    const timestampIntegers = value.map(entry => parseInt(entry[0]));
  
    // Check if there is data
    if (timestampIntegers.length === 0) {
      return { latestTimestamp: null, latestValue: null };
    }
  
    // Find the maximum timestamp
    const latestTimestamp = Math.max(...timestampIntegers);
  
    // Find the index of the latest timestamp
    const latestIndex = timestampIntegers.indexOf(latestTimestamp);
  
    // Check if the index is valid
    if (latestIndex === -1 || latestIndex >= value.length) {
      return { latestTimestamp: null, latestValue: null };
    }
  
    // Extract the value corresponding to the latest timestamp
    const latestValue = value[latestIndex][1];
  
    return { latestTimestamp, latestValue };
  }

  const showKeyarData = (index) => {
    setActiveIndex(index)
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }


  return (
    
    <div className='device-info'>

      <div className={activeIndex == 0 ? 'active' : 'inactive'} onClick={() => showKeyarData(0)}>
        <img src="/keyar-cm.png" alt = "Keyar CM" className='device-image' />
        <hr className='hr-line'/>
        <p>Keyar CM</p>
      </div>

      <div className={activeIndex == 1 ? 'active' : 'inactive'} onClick={() => showKeyarData(1)}>
        <img src="/keyar-dt.png" alt = "Keyar DT" className='device-image'/>
        <hr className='hr-line'/>
        <p>Keyar DT</p>
      </div>

      <div className={activeIndex == 2 ? 'active' : 'inactive'} onClick={() => showKeyarData(2)}>
        <img src="/keyar-echo.svg" alt = "keyar Echo" className='device-image'/>
        <hr className='hr-line'/>
        <p>Keyar Echo</p>
      </div>

      <div className={activeIndex == 3 ? 'active' : 'inactive'} onClick={() => showKeyarData(3)}>
        <img src="/osi.png" alt = "Daksh SI" className='device-image'/>
        <hr  className='hr-line'/>
        <p>Daksh SI</p>
      </div>


      { 
        open ? 
          <DeviceGraphs open={open} setOpen={setOpen} basicDetail={basicDetail} otherDetail={otherDetail} placeDetail={placeDetail} 
            handleClose={handleClose}
            deviceData={activeIndex == 0 ? keyarCMData : activeIndex == 1 ? keyarDTData : activeIndex == 2 ? keyarEchoData : activeIndex == 3 ? osiData : [] } 
            deviceName={activeIndex == 0 ? 'Keyar CM' : activeIndex == 1 ? 'Keyar DT' : activeIndex == 2 ? 'Keyar Echo' : activeIndex == 3 ? 'Daksh SI' : '--'} 
            loading={loading} error={error}/> 
        : 
          <div/>
      }

    </div>
  )
}

export default DeviceData