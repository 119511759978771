import React from 'react';
import { useState, useEffect } from 'react';
import '../viewFiles/common.scss';
import axios from 'axios';
import urls from '../../utils/url';
import { getReadableTime, limitStr, charLimit, isJson, TooltipComponent } from '../../utils/converter';
import Loading from '../common/loading';
import Reload from '../common/Reload';
import Cookie from 'js-cookie';


const PatientInformaton = (props) => {

  const { patientID } = props;
  const cookie = Cookie.get('access_token')
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ patientBasicInfo, setPatientBasicInfo ] = useState({});
  
  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=basic_detail,admission_note,pa_pv_examination,obstetric_histories&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPatientBasicInfo(json_data.response[0]);
            setLoading(false) 
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err);
          setError(true)
          setPatientBasicInfo()
          setLoading(false)
        }
      ) 
  }, [])

// console.log(patientBasicInfo)
  return (
    <>
      {loading ? <div className='loading-div'><Loading/></div> 
      : error ? <div className='reload-div'><Reload/></div>
      :
      <>
        <div className = 'info-section'>

          {/* Patient Basic Detail */}
          <>
            <div className = 'info-header'>
              <p className = 'heading'> Patient's Basic Details </p>
            </div>

            <div className = 'info-container'>
             
              <div>
                <p className='text1'>Patient's Name</p>
                {patientBasicInfo?.basic_detail?.name && patientBasicInfo?.basic_detail?.name.length > charLimit ? 
                  <TooltipComponent title={`${patientBasicInfo?.basic_detail?.name}`} content={limitStr(patientBasicInfo?.basic_detail?.name, charLimit)} />
                  : 
                  <p className='text2'>{patientBasicInfo?.basic_detail?.name ? patientBasicInfo.basic_detail.name : '- -'}</p>
                }
              </div>


              <div>
                  <p className = 'text1'> Patient's Phone </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.phone ? patientBasicInfo?.basic_detail?.phone : "- -"} </p>
              </div>
                  
              <div>
                  <p className = 'text1'> Admission Time </p>
                  <p className = 'text2'> {getReadableTime(patientBasicInfo?.basic_detail?.register_time)} </p>
              </div>

              <div>
                  <p className = 'text1'> Age </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.age ? patientBasicInfo?.basic_detail?.age : "- -"} </p>
              </div>

              <div>
                  <p className = 'text1'> Booked </p>
                  <p className = 'text2'> {patientBasicInfo?.basic_detail?.booked ? patientBasicInfo?.basic_detail?.booked  : "- -" } </p>
              </div>

            </div>
          </>
          
          {/* Patient Admission Note */}
          <>
              <div className = 'info-header'>
                <p className = 'heading'> Admission Notes </p>
              </div>

              <div className = 'info-container'>

                <div>
                    <p className = 'text1'> LMP </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.lmp ? getReadableTime (patientBasicInfo?.admission_note?.lmp) : "- -"} </p>
                </div>
                    
                <div>
                    <p className = 'text1'> EDD </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.edd ? getReadableTime(patientBasicInfo?.admission_note?.edd) : "- -"}</p>
                </div>

                <div>
                    <p className = 'text1'> Gestational Age </p>
                    <p className = 'text2'> {patientBasicInfo?.pa_pv_examination?.gestation_period ? patientBasicInfo?.pa_pv_examination?.gestation_period : "- -" }</p>
                </div>

                <div>
                    <p className = 'text1'> Gravida </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.gravida ? patientBasicInfo?.admission_note?.gravida : "- -"} </p>
                </div>
                    
                <div>
                    <p className = 'text1'> Para </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.para ? patientBasicInfo?.admission_note?.para : "- -"} </p>
                </div>

                <div>
                    <p className = 'text1'> Living Children </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.living_children ? patientBasicInfo?.admission_note?.living_children : "- -"} </p>
                </div>

                <div>
                    <p className = 'text1'> Abortion </p>
                    <p className = 'text2'> {patientBasicInfo?.admission_note?.abortion ? patientBasicInfo?.admission_note?.abortion : "- -"} </p>
                </div>

              </div>
          </>

        </div> 
      </>
      }
    </> 
  )
}

export default PatientInformaton