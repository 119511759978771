import React, { useState, useRef , useEffect} from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { render } from 'react-dom'
import { getReadableTimeOnly, getReadableTime } from '../../utils/converter'
import Cookie from "js-cookie";

const Graph = ({ fhrData, fhr2Data, mhrData, ucData , fourDataPoint, isNotificationComing, fetalMarker}) => {

    const[theme, setTheme] = useState();
    const cookieData = Cookie.get("theme");
    console.log(cookieData)

    useEffect(() => {
        if (cookieData) {
            setTheme(cookieData)
        }
    },[cookieData])

    console.log(theme)

    //console.log(fetalMarker)

    //const [value, setValue] = useState(0);
    // useEffect(() => {

    //     let intervalId;
    //     if (fhrData.length > 0 && fourDataPoint == true && fhrData.length < 3600){
    //         console.log('if 1 working')
    //         setValue(0)
    //     } else if (fourDataPoint == true && fhrData.length >= 3600 ) {
    //         console.log('else if 1 working')
    //         intervalId = setInterval(() => {
    //             console.log('interval')
    //             setValue((prevValue) => prevValue + 24);
    //             console.log(value, '1')
    //         }, 6000); // 180000 milliseconds = 3 minutes  
               
    //     } 
    //     if (fhrData.length > 0 && fourDataPoint == false && fhrData.length < 900){
    //         console.log('if 2 working')
    //         setValue(0)
    //     } else if (fourDataPoint == false && fhrData.length > 900){
    //         intervalId = setInterval(() => {
    //             setValue((prevValue) => prevValue + 6);
    //         }, 6000);
            
    //     }
        
    //     Clear the interval when the component unmounts
    //     return () => clearInterval(intervalId);
    // }, [fhrData, fourDataPoint]);

    //console.log(value, fhrData.length)

    if (fhrData.length > 0){
       var startTime = fhrData[0][0]
    }
    
    let fhrHeight = 31
    let ucHeight = 20
    

    if (window.screen.height > 950){
        fhrHeight = 31
        ucHeight = 20
    } else if (window.screen.height > 400 && window.screen.height <= 950){
        fhrHeight = 35
        ucHeight = 22
    }

    const options1 = {
        chart : {
            backgroundColor: theme === "light-theme" ? "rgba(255, 255, 255, 1)" : '#0E0E0E',
            animation: false,
            height: `${fhrHeight}%`
        },
        credits: {
            enabled: false
        },
        title: {
          text: '',
        },
        scrollbar: {
            enabled: true
        },
        tooltip: {
            formatter: function() {
                return  '<b>' + this.series.name +'</b><br/>' +
                    getReadableTime(parseInt(this.key)) + '<br/>' + this.y;
            }
        },
        xAxis: {
            type: 'Time',
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S.%L',
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%e %b',
                week: '%e %b',
                month: '%b \'%y',
                year: '%Y'
            },
            labels: {
                style: {
                    color: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' :'#fff',
                    
                },
                formatter: function () {
                    return getReadableTimeOnly(parseInt(this.value));
                },
            },
            min: startTime,
            max: startTime+ 21 * 60,
            scrollbar: {
                enabled: false
            },
            lineColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' :'#fff',
            lineWidth: 2,
            tickInterval: 180,
            minorTickInterval: 30,
            gridLineColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : 'rgba(255,255,255,0.)', // Change the grid line color
            tickColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.1)`, // Change the major tick color
            minorGridLineColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.3)`,
            title: {
                text: '',
                style: {
                    color: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' :'#fff',
                },
            },
        },
        yAxis: {
            title: {
                text: '',
                style: {
                    color: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.1)`,
                },
            },
            labels: {
                style: {
                    color: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.1)`,
                },
            },
            plotBands: [{
                color: theme === "light-theme" ? 'rgba(249, 255, 246, 1)' : `rgba(255,255,255,0.1)`,
                from: 120,
                to: 160,
                zIndex: -1,
            }],
            lineWidth: 2,
            lineColor:  theme === "light-theme" ? 'rgba(127, 127, 127, 1)' :'#fff',
            tickInterval: 20,
            minorTickInterval: 5,
            min: 50,
            max: 210,
            scrollbar: {
                enabled: false
            },
            startOnTick: false,
            endOnTick: false,
            gridLineColor: theme === "light-theme" ? 'rgba(177, 176, 176, 1)' : 'rgba(255,255,255,0.)', // Change the grid line color
            tickColor: theme === "light-theme" ? 'rgba(131, 130, 130, 1)' : `rgba(255,255,255,0.1)`, // Change the major tick color
            minorGridLineColor: theme === "light-theme" ? 'rgba(177, 176, 176, 1)' : `rgba(255,255,255,0.3)`,
        },
        // legend: {
        //     align: 'right',
        //     verticalAlign: 'bottom',
        //     layout: 'horizontal',
        //     y:-10,
        //     borderWidth: 1,
        //     borderColor: '#000000',
        //     zIndex: 2,
        //     backgroundColor : 'yellow',
        //     floating : true,

        // },
        series: [
            {
                name: 'FHR',
                data: fhrData,
                //lineWidth: lineWidth,
                marker:{
                    //radius : markerRadius,
                    symbol: "circle"
                },
                color: theme === "light-theme" ? 'rgba(28, 21, 153, 1)' : 'rgb(253, 156, 39)'
            },
            {
                name: 'MHR',
                data: mhrData,
                //lineWidth: lineWidth,
                marker:{
                    //radius : markerRadius,
                    symbol: "circle"
                },
                color: theme === "light-theme" ? 'rgba(223, 44, 137, 1)' : 'rgb(239, 55, 151)'
            },
            {
                name: 'FHR2',
                data: fhr2Data,
                //lineWidth: lineWidth,
                marker:{
                    //radius : markerRadius,
                    symbol: "circle"
                },
                color: theme === "light-theme" ? 'rgba(128, 0, 32, 1)' : 'rgb(43, 245, 253)'
            },
            {
                name: 'FMM',
                data: fetalMarker,
                type: 'scatter',
                marker: {
                    radius: 6, // Adjust as needed for visibility
                    symbol: "circle",
                    fillColor: 'rgb(239, 55, 151)', // Use fillColor for better visibility
                },
                color: 'rgb(239, 55, 151)', 
                zIndex: 5 // Ensure that the scatter plot is above other series
            },
        ]
    }       
      
      const options2 = {
        chart : {
            backgroundColor: theme === "light-theme" ? "rgba(255, 255, 255, 1)" : '#0E0E0E',
            animation: false,
            height: `${ucHeight}%`
        },
        credits: {
            enabled: false
        },
        title: {
          text: '',
        },
        tooltip: {
            formatter: function() {
                return  '<b>' + this.series.name +'</b><br/>' +
                    getReadableTime(parseInt(this.key)) + '<br/>' + this.y;
            }
        },
        xAxis: {
            type: 'Time',
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S.%L',
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%e %b',
                week: '%e %b',
                month: '%b \'%y',
                year: '%Y'
            },
            labels: {
                style: {
                    color:  theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.3)`,
                },
                formatter: function () {
                    return getReadableTimeOnly(parseInt(this.value));
                },
            },
            min: startTime,
            max: startTime + 21 * 60,
            scrollbar: {
                enabled: true
            },
            lineColor:  theme === "light-theme" ? 'rgba(183, 183, 183, 1)' : `rgba(255,255,255,0.3)`,
            lineWidth: 2,
            tickInterval: 180,
            minorTickInterval: 30,
            gridLineColor: theme === "light-theme" ? 'rgba(127, 127, 127, 0.5)' : `rgba(255,255,255,0.5)`, // Change the grid line color
            tickColor: theme === "light-theme" ? 'rgba(131, 130, 130, 1)' : `rgba(255,255,255,0.1)`, // Change the major tick color
            minorGridLineColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.3)`,
            title: {
                text: '',
                style: {
                    color: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.3)`,
                },
            },
        },
        yAxis: {
            title: {
                text: '',
                style: {
                    color: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.3)`,
                },
            },
            labels: {
                style: {
                    color: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.3)`,
                },
            },
            plotBands: [{
                color: `rgba(255,255,255,0.1)`,
                from: 120,
                to: 160,
                zIndex: -1,
            }],
            lineWidth: 2,
            lineColor: '#fff',
            tickInterval: 20,
            minorTickInterval: 10,
            min: 0,
            max: 100,
            startOnTick: false,
            endOnTick: false,
            gridLineColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.5)`, // Change the grid line color
            tickColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.3)`, // Change the major tick color
            minorGridLineColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.1)`,
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            layout: 'horizontal',
            y:0,

            //itemMarginTop: itemMargin,
            //itemMarginBottom: itemMargin,
            // itemStyle: {
            //     lineHeight: legendFont.toString() + 'px',
            //     fontSize: legendFont.toString() + 'px',
            // },
            borderWidth: 1,
            borderColor: theme === "light-theme" ? 'rgba(127, 127, 127, 1)' : `rgba(255,255,255,0.3)`,
            zIndex: 2,
            backgroundColor : 'yellow',
            floating : true,

        },
        series: [{
            name: 'UC',
            data: ucData,
            //lineWidth: lineWidth,
            marker:{
                //radius : markerRadius,
                symbol: "circle"
            },
            color: theme === "light-theme" ? 'rgba(223, 44, 137, 1)' : 'rgb(239, 55, 151)'
        }]
      } 
      
      console.log(theme)

  return (
    <div >
        <HighchartsReact
            highcharts={Highcharts}
            options={options1}
            constructorType={'chart'}
            allowChartUpdate={true}
            className={theme === "light-theme" ? "highcharts-light" : "highcharts-dark"} 
        />

        <HighchartsReact
            highcharts={Highcharts}
            options={options2}
            constructorType={'chart'}
            allowChartUpdate={true}
            className={theme === "light-theme" ? "highcharts-light" : "highcharts-dark"} 
        />
    </div>
  )
}

export default Graph

