import React from 'react';
import {useState, useEffect, useMemo} from 'react';
import CaseSheetLayout from '../casesheet/casesheet';
import BasicInfo from './basicInfo/basicInfo';
import ExaminationInfo from './examinationInfo/examinationInfo';
import PatientHistory from './patientHistory/patientHistory';
import DeviceData from './deviceData/deviceData';
import Intrapartum from './intrapartumData/intrapartum';
import DeliveryInfo from './deliveryInfo/deliveryInfo';
import ChildbirthChecklist from './childbirthChecklist/childbirthChecklist';
import PostpartumData from './postpartumData/postpartumData';
import Form from './form/form';
import DischargeInformation from './dischargeInformation/dischargeInformation';
import ReferralDetails from './referralDetails/referralDetails' 
import AlertDetails from './alertDetails/alertDetails';
import PatientInformaton from '../basicVersion/patientInformaton'
import DeviceInformation from '../basicVersion/deviceData'
import NoData from '../common/NoData';
import './viewLayout.scss';
import OsiGraph from '../osi/osiGraph';
import Spo2Graph from '../osi/spo2Graph';
import {useNavigate, useLocation, useParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import { isJson, getReadableTime } from '../../utils/converter';
import urls from '../../utils/url';
import Cookie from 'js-cookie';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
// import L1CasesheetLayout from '../Case-sheet/l1Casesheet';
// import L2CasesheetLayout from '../Case-sheet/l2Casesheet';
// import L3CasesheetLayout from '../Case-sheet/l3Casesheet';

const StyledMenuItem = styled(MenuItem)({
  fontFamily: 'Poppins !important',
  '&:hover': {
    backgroundColor: '#faf6ff', 
  },
  '&.Mui-selected': {
    backgroundColor: '#faf6ff', 
    '&:hover': {
      backgroundColor: '#faf6ff', 
    },
  },
});

const ViewLayout = () => {

  const cookie = Cookie.get('access_token')
  const cookieData = Cookie.get('data');
  const navigate =  useNavigate();
  const {patientID} = useParams();
  const location = useLocation()

  const [currentDate, setCurrentDate] = useState(moment().format('DD/MM/YYYY'));
  const [activeIndex, setActiveIndex] = useState(0);
 // const [selectType, setSelectType] = useState('mother');
  const [caseSheetOpen, setCaseSheetOpen] = useState(false);
  const [data, setData]  = useState(location.state);
  const [accountType, setAccountType] = useState('');
  const [selectedSubTab, setSelectedSubTab] = useState('NST');
  const [keyarCMData, setKeyarCMData] = useState([])
  const [keyarDTData, setKeyarDTData] = useState([])
  const [keyarEchoData, setKeyarEchoData] = useState([])
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [otherDetail, setOtherDetail] = useState([])
  const [basicDetail, setBasicDetail] = useState([])
  const [isPlaying, setIsPlaying] = useState(false)
  const [deviceData, setDeviceData] = useState()
  const [placeDetail, setPlaceDetail] = useState([]);
  // const [casesheetTab, setCasesheetTab] = useState('l1-casesheet');
  const [ isMrn, setIsMrn] = useState(true); // For Malaysia Specific Requirement


  useEffect(() => {
    // Check if data is not available in the location state
    if (!data) {
      navigate('/');
    }
  }, [ data, navigate]);

  useEffect(() => {

    if (cookieData) {
        const parsedData = JSON.parse(cookieData);
        const account_type = parsedData.account_type;
        const algorithmEnabled = parsedData.fetal_distress_algorithm;
        console.log(algorithmEnabled)

        setIsMrn(algorithmEnabled);
        setAccountType(account_type); 
    } 
  },[cookieData])

  // const casesheets = {
  //   'L1 Casesheet' : 'l1-casesheet',
  //   'L2 Casesheet' : 'l2-casesheet',
  //   'L3 Casesheet' : 'l3-casesheet',
  // }
  
  const components = [BasicInfo, ExaminationInfo, PatientHistory,  DeviceData, Intrapartum, DeliveryInfo, PostpartumData ,ChildbirthChecklist, Form, DischargeInformation, AlertDetails, ReferralDetails];
  const ActiveComponent = components[activeIndex];
  const componentItems = ['Basic Information', 'Examination Information','Patient History','Device Data','Intrapartum Data','Delivery Information','Postpartum Data','Childbirth Checklist','Consent Forms','Discharge Information', 'Alert Details','Referral Details'];
  const activeComponentName = componentItems[activeIndex]

  useEffect(() => {
    // console.log(data?.alertindex)
    if(data?.alertIndex){
      // If User is clicking on alert arrow then alerts section will be shown directly
      setActiveIndex(data?.alertIndex);   
    }
  }, []);
 
  const osiComponents = [OsiGraph, Spo2Graph]
  const osiComponentItems = ['OSI','SPO2'];
  const ActiveOSIComponents = osiComponents[activeIndex];
  const activeOSIComponentName = osiComponentItems[activeIndex]

  const basicComponents = [PatientInformaton, DeviceInformation];
  const basicComponentItems = [
    {
      text: 'Patient Information',
      active_image: '/patient-info-icon.svg',
      inactive_image: '/inactive-patient-info.svg',
    },
    {
      text: 'Device Data',
      active_image: '/active-device-data.svg',
      inactive_image: '/inactive-device-data-icon.svg',
    },
  ];
  const ActiveBasicComponents = basicComponents[activeIndex];
  
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);


  const showGraph = (index, data) => {
    setDeviceData(data)
    setActiveTabIndex(index)
    setIsPlaying(false)
  }

  function sortDataByLatestTimestamp(data) {
    if (data && Object.keys(data).length > 0 && data.length > 0 && data != '' && data != null){
      return data?.sort((a, b) => b.data_time - a.data_time);
    }
  }

  useEffect(() => {
  fetchDeviceData()
  },[])

  const handleSubTabSelection = (subTab) => {
    setSelectedSubTab(subTab);
  };

// Merging data of Keyar Echo, Keyar CM and Keyar DT
const mergedData = useMemo(() => [
  ...(keyarDTData || []).map((deviceData) => ({
    data: deviceData,
    data_time: deviceData.data_time,
    device_name: 'Keyar DT',
    active_image: '/active-doppler.svg',
    inactive_image: '/inactive-doppler.svg',
  })),
  ...(keyarCMData || []).map((deviceData) => ({
    data: deviceData,
    data_time: deviceData.data_time,
    device_name: 'Keyar CM',
    active_image: '/active-cm-patch.svg',
    inactive_image: '/inactive-cm-patch.svg',
  })),
  ...(keyarEchoData || []).map((deviceData) => ({
    data: deviceData,
    data_time: deviceData.data_time,
    device_name: 'Keyar Echo',
    active_image: '/active-echo.svg',
    inactive_image: '/inactive-echo.svg',
  })),
], [keyarDTData, keyarCMData, keyarEchoData]);


  useEffect(() => {
    if (mergedData.length > 0) {
      setDeviceData(mergedData[0]);
    }
  }, [mergedData]);

  const fetchDeviceData = async () => {
      await axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=keyar_data,basic_detail,pa_pv_examination,place_details&`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
        }
      }).then((res) => {     
        if(res.data){
            const jsonData = isJson(res.data)
            const keyar_data = jsonData.response[0]?.keyar_data

            const sortedKeyarData = sortDataByLatestTimestamp(keyar_data)

            let cm_data = []
            let dt_data = []
            let echo_data = []
            let undefined_data = []

            if (Object.keys(sortedKeyarData || []).length > 0){
              keyar_data?.forEach(item => {

                if (!item.params == undefined || !item.params == ''){
                  const ucData = item.params.find(data => data.param_type == 'uc');
                  if (Object.keys(ucData?.data_set || [].length > 0)){
                    const ucTimestamps = Object.keys(ucData.data_set || [])?.map(Number)?.sort((a, b) => a - b)
                    var timestampDifference = ucTimestamps[1] - ucTimestamps[0]
                  }
                
                  if ( ucData && ucData.serial_number.includes("JKEC")) {
                      echo_data.push(item);
                  } else if (ucData && (ucData.serial_number.includes('JKDP') || ucData.serial_number.includes('eFM') || timestampDifference < 1100)) {
                      dt_data.push(item);
                  } else if (ucData && (ucData.serial_number.includes('JKKP') || (timestampDifference > 1100 && timestampDifference < 2200))) {
                    cm_data.push(item);
                  } else {
                    undefined_data.push([])
                  }
                }
                // Add more conditions here for other serial number checks
              });
            }
          
            setKeyarCMData(cm_data)
            setKeyarDTData(dt_data)
            setKeyarEchoData(echo_data)
            
            const patient_detail = jsonData.response[0].basic_detail;
            const patient_other_detail = jsonData.response[0].pa_pv_examination;
            const place_detail = jsonData.response[0].place_details;


            setBasicDetail(patient_detail);
            setOtherDetail(patient_other_detail);
            setPlaceDetail(place_detail);
            // setPatientDetail({
            //   name : `${patient_detail.name}`,
            //   age: `${patient_detail.age}`,
            //   patient_id: `${patient_detail.id}`,
            //   ges_period: `${patient_other_detail.gestation_period}`
            // })
            //const keyar_data = latest_keyar_data[latest_keyar_data.length-1]
            // setKeyarData(keyar_data)
            // setStartTime(latest_keyar_data[latest_keyar_data.length-1].data_time)
            
            setLoading(false)
        }
      }).catch((err) => {
        console.log("Graph API failed",err)
        setError(true)
        setLoading(false)
      })
  }

  const openCaseSheet= () => {
    setCaseSheetOpen(true);
  }
  
  const handleReload = () => {
    window.location.reload();
  }
 
  const handleClose = () => {
    navigate(`/dashboard/report`);
  }

  const handleClick = (index) => {
    setActiveIndex(index);
  }

  const handleKeyDown = (e, activeIndex, setActiveIndex, componentsLength, osiComponentsLength) => {
    // Both osi and basic have 2 components if any of the entity have more components then logic will be change accordingly
    if(accountType && accountType.length > 0 && (accountType === 'Daksh SI' || accountType === 'Basic')){
      if (e.key === 'ArrowUp') {
        setActiveIndex((activeIndex - 1 + osiComponentsLength) % (osiComponentsLength));
      } else if (e.key === 'ArrowDown') {
        setActiveIndex((activeIndex + 1 ) % (osiComponentsLength));
      } 
    }
    else{
      if (e.key === 'ArrowUp') {
        setActiveIndex((activeIndex - 1 + componentsLength ) % (componentsLength ));
      } else if (e.key === 'ArrowDown') {
        setActiveIndex((activeIndex + 1) % (componentsLength ));
      } 
    }
  };
  
  useEffect(() => {
    const handleKeyDownEvent = (e) => {
      handleKeyDown(e, activeIndex, setActiveIndex, componentItems.length, osiComponentItems.length, basicComponentItems.length);
    };
    
    window.addEventListener('keydown', handleKeyDownEvent);
    return () => {
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, [activeIndex, componentItems.length, osiComponentItems.length, basicComponentItems.length]);

  useEffect(() => {
    const updateDate = () => {
      setCurrentDate(moment().format('DD/MM/YYYY'));
    };

    // Update the date initially
    updateDate();

    // Set up interval to update the date at the start of each new day (every 24 hours)
    const intervalId = setInterval(updateDate,  1000);

    return () => clearInterval(intervalId); // Cleanup function
  }, []);

  // const handleTabChange = (e) => {
  //   setCasesheetTab(e.target.value);
  // };
  

  // console.log("mrn",data?.mrn)
  // console.log("",data?.patient_id)
  // console.log(isMrn)

  return (
    <>

      {
        accountType && accountType.length > 0 && accountType === 'Daksh SI' ? 
          <div className='view-section'>
            <div className='view-file-header'>

               <div className='patient-info-header'>
                  <img className = "back-arrow" src = "/back_arrow.svg" alt='Back Arrow' onClick = {() => handleClose()} />
                  <div className='update'>
                    <img style={{width:`24px`,height:`24px`}} src="/update.svg" alt="Update Icon"  onClick={() => handleReload()} className='update-icon'/>
                    <p className='update_time'>Last Updated: {moment().format('DD MMM YYYY hh:mm A')}</p>
                  </div>
               </div>

            </div>
            
            <div className='view-section-sidebar'>
              {
                osiComponentItems.map((osiComponentItem, index) => (
                  <div key={index} className={activeIndex === index ? 'active-text' : 'inactive-text'} onClick={() => handleClick(index)}>
                    <p>{osiComponentItem}</p>
                  </div>
                ))
              } 
            </div>

            <div className='scroll-component' >
                  <div className='osi-reports-div'> 
                    <p className='osi-reports'>Reports {`>`} {data?.patient_name} ({data?.patient_id}) {`>`}</p>
                    <p className='osi-name'> {activeOSIComponentName}</p>
                  </div>
                <ActiveOSIComponents patientID={patientID}/>
            </div>
            
          </div>

          : accountType && accountType.length > 0 && accountType === 'Basic' ?

              <div className='view-section'>

                <div className='basic-view-header'>
                  
                  <div className='left-header-section'>
                    <img className = 'back-arrow' src= "/back_arrow.svg" alt='Back Arrow' onClick = {() => handleClose()} />
                    <p className='basic-reports-text'>Reports {`>`} <span className='patient-text'> {data?.patient_name} ({isMrn ? data?.patient_id : data?.mrn})</span> </p>
                  </div>

                  <div className='right-header-section'>
                        <img style={{width:`20px`,height:`20px`, marginLeft: `30px`}} src="/update.svg " alt="Update Icon" onClick={() => handleReload()} className='update-icon'/>
                        <p className='update_time'>Last Updated: {moment().format('DD MMM YYYY hh:mm A')}</p>
                  </div>

                </div>
                
                <div className='view-sidebar-basic'>
                  {
                    basicComponentItems.map((basicComponentItem, index) => (
                      <div>
                        <div key={index} className={activeIndex === index ? 'active-text-basic' : 'inactive-text-basic'} onClick={() => handleClick(index)}>
                          <img src={activeIndex === index ? (basicComponentItem.active_image) : (basicComponentItem.inactive_image)} alt='Text Icon' className='nav-icon'/>
                          <span className='nav-text'>{basicComponentItem.text}</span>
                        </div>
                        {
                          activeIndex === index && basicComponentItem.text === 'Device Data' && 
                            (
                              <div className='side-tab'>
                                <div  className='sub-tabs'>
                                    <div className={`sub-tab ${selectedSubTab === 'NST' ? 'active-device-text' : 'inactive-device-text'}`} onClick={() => handleSubTabSelection('NST')}> NST</div>
                                    <div className={`sub-tab ${selectedSubTab === 'Recordings' ? 'active-device-text' : 'inactive-device-text'}`} onClick={() => handleSubTabSelection('Recordings')} > Recordings</div>
                                </div>
                        
                                {selectedSubTab === 'NST' && mergedData.length > 0 && (
                                  mergedData.map((data, index) => (
                                    (data.device_name === 'Keyar DT' ||  data.device_name === 'Keyar CM') && (
                                      <div key={index} className={`timestamp-div ${activeTabIndex === index ? 'active-timestamp' : 'inactive-timestamp'}`} onClick={() => { showGraph(index, data) }}>
                                        <img src={activeTabIndex === index ? data.active_image : data.inactive_image} alt={data.device_name} />
                                        <p>{getReadableTime(data.data_time)}</p>
                                      </div>
                                    )
                                  ))
                                )}
                        
                                {selectedSubTab === 'Recordings' && mergedData.length > 0 && (
                                  mergedData.map((data, index) => (
                                    ( data.device_name === 'Keyar Echo' && (
                                      <div key={index} className={`timestamp-div ${activeTabIndex === index ? 'active-timestamp' : 'inactive-timestamp'}`} onClick={() => { showGraph(index, data)}}>
                                        <img src={activeTabIndex === index ? data.active_image : data.inactive_image} alt={data.device_name} />
                                        <p>{getReadableTime(data.data_time)}</p>
                                      </div>
                                    ))
                                  ))
                                )}
                  
                              </div>
                            )
                        }
                      </div>
                    ))
                    
                  } 

                </div>

                <div className='scroll-component' >
                  { 
                    ActiveBasicComponents === PatientInformaton ? <PatientInformaton patientID={patientID}/> 
                  :
                    (
                      deviceData && ActiveBasicComponents === DeviceInformation ? 
                        (
                          <DeviceInformation selectedSubTab ={selectedSubTab} patientID={patientID} data={deviceData.data} basicDetail={basicDetail} otherDetail={otherDetail} placeDetail={placeDetail} deviceName={deviceData.device_name} isPlaying={isPlaying} setIsPlaying={setIsPlaying}/> 
                        )
                      :
                        <div style={{paddingTop:`60px`, paddingLeft:`26%`}} ><NoData/></div>
                    )
                }
                  
                </div>
              
              </div>
          
          : caseSheetOpen && accountType && accountType.length > 0 && accountType !== 'Basic' ? 
            // (  casesheetTab === 'l1-casesheet' ? 
            //     <L1CasesheetLayout patientID={patientID} caseSheetOpen={caseSheetOpen} setCaseSheetOpen= {setCaseSheetOpen} />
            //   :
            //   casesheetTab === 'l2-casesheet' ?
            //     <L2CasesheetLayout patientID={patientID} caseSheetOpen={caseSheetOpen} setCaseSheetOpen= {setCaseSheetOpen} />
            //   : 
            //   casesheetTab === 'l3-casesheet' ?
            //     <L3CasesheetLayout patientID={patientID} caseSheetOpen={caseSheetOpen} setCaseSheetOpen= {setCaseSheetOpen} />
            //   : null
            // )
            <CaseSheetLayout patientID={patientID} caseSheetOpen={caseSheetOpen} setCaseSheetOpen= {setCaseSheetOpen}/>
          : 

          // For accountType = 'Premuim'; 
            <div className='view-section'>
              <div className='view-file-header'>
                  
                    <div className='patient-info-header'>
                        <img className = "back-arrow" src= "/back_arrow.svg" alt='Back Arrow' onClick = {() => handleClose()} />
                        <div className='update'>
                          <img style={{width:`20px`,height:`20px`, marginLeft: `30px`, cursor:`pointer`}} src="/update.svg " alt="update" onClick={() => handleReload()} className='update-icon'/>
                          <p className='update_time'>Last Updated: {moment().format('DD MMM YYYY hh:mm A')}</p>
                        </div>
                    </div>

                    {/* <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectType}
                      label="Tab"
                      onChange={handleChange}
                    >
                      <MenuItem value={'mother'}>Mother</MenuItem>
                    </Select> */}
                    {/* <div style={{display:`flex`,gap:`1.5rem`, marginRight:`3rem`, alignItems:`center`, justifyContent:`center`}}>
                      <Select labelId="demo-simple-select-label" id="demo-simple-select"
                          sx={{
                            width: '50%',
                            borderRadius: '100px',
                            border: '0.5px solid #A163F1',
                            height: '3.125rem',
                            background: '#F8F5FF',
                            fontFamily: 'Poppins',
                            '&:hover': { border: 'transparent' },
                            '& .MuiSelect-select': {
                              color: '#A163F1',
                              fontSize: '1rem',
                              fontWeight: '400',
                              mt: '5px',
                              ml: '10px',
                            },
                          }} 
                          value={casesheetTab}
                          onChange={handleTabChange}
                          >
                          {
                            Object.entries(casesheets).map(([key, value]) => (
                              <StyledMenuItem key={value} value={value}> {key} </StyledMenuItem>
                            ))
                          }
                      </Select>
                
                    </div> */}

                    <button className="casesheet-button" onClick={openCaseSheet}> View Case Sheet </button>       

              </div>
              
              <div className='view-section-sidebar'>
                  {
                    componentItems.map((componentItem, index) => (
                      <div key={index} className={activeIndex === index ? 'active-text' : 'inactive-text'} onClick={() => handleClick(index)}>
                        <p>{componentItem}</p>
                      </div>
                    ))
                  }
                  
                  <hr className='horizontal-line'/>

                  <div className='viewfile-footer'>
                    <img src="/janitri-registered-logo.svg"  alt="Janitri Logo" className='janitri-logo'/>
                    {/* <p className='date-footer'>{moment().format('DD/MM/YYYY hh:mm A')}</p> */}
                  </div>
                  
              </div>
              
              <div className='scroll-component'>
                
                  <div className='reports-div'> 
                    <p className='reports'>Reports {`>`} {data?.patient_name} ({isMrn ? data?.patient_id : data?.mrn}) {`>` }</p>
                    <p className='name'> { data?.alerts ? 'Alerts' : activeComponentName}</p>
                  </div>

                  <ActiveComponent patientID={patientID}/>
                  
              </div>

            </div> 
      }

    </>

  )
}

export default ViewLayout;

