import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import urls from '../../../utils/url';
import {useParams , useNavigate, useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Loader from '../../../components/common/loader';
import '../central_monitor_graph/graph.scss'
import { getReadableTimeOnly, isJson} from '../../../utils/converter';
import Graph from '../../../components/graphs/graph';
import BackButton from '../../../components/common/BackButton';
import { duration } from 'moment';
import Cookie from 'js-cookie';


ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend);


const ChartLayout = ({keyar_data, live_data}) => {

  //console.log(live_data)
  const cookie = Cookie.get('access_token')
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation();
  //const { data } = location.state || {};
  //console.log(data)
  const [keyarData, setKeyarData] = useState({})
  const [loading, setLoading] = useState(false)
  const [patientDetail, setPatientDetail] = useState({})
  let [startTime, setStartTime] = useState('')
  const [fhrcritical , setfhrCritical] = useState(false)
  const [fhr2critical , setfhr2Critical] = useState(false)
  const [mhrcritical , setmhrCritical] = useState(false)
  const [fourDataPoint, setFourDataPoint] = useState(false)
  const [fhr, setFhr] = useState('')
  const [mhr, setMhr] = useState('')
  const [fhr2, setFhr2] = useState('')
  const [uc, setUc] = useState('')
  const [keyar, setKeyar] = useState({})
  const [isActive, setIsActive] = useState(true)
  const timestampsPerUpdate = 4; // Number of timestamps to display per update
  const updateInterval = 1000;
  const [isDelayedByOneMin, setIsDelayedByOneMin] = useState(false)
  const [delayInSec, setDelayInSec] = useState(0)
  const [deviceID, setDeviceID] = useState('')
  const [totalMonitoringTimeInSecs, setTotalMonitoringTimeInSecs] = useState('')
  //const [live_data, setLiveData] = useState({})
  const [refetched, setRefetched] = useState(false); // Flag to check if API has been refetched

  const [interpretationsDisable, setInterpretationsDisable] = useState("");
  const[theme, setTheme] = useState();

  const cookieValueString = Cookie.get('data');
  const cookieData = Cookie.get("theme");
  console.log(cookieData)

  useEffect(() => {
      if (cookieData) {
          setTheme(cookieData)
      }
  },[cookieData])

  console.log(theme)


  useEffect(() => {
    if (cookieValueString) {
      const cookieValue = JSON.parse(cookieValueString);
      const accessToken = cookieValue.access_token;
      const algorithm = cookieValue.fetal_distress_algorithm;

      setInterpretationsDisable(algorithm);
      // console.log('Access Token:', accessToken);
      // console.log('Algorithm:', algorithm);
    }
  }, [cookieValueString])


  let fhrData = {}
  let mhrData = {}
  let ucData= {}
  let fhr2Data = {}
  let fmm = []
  
  
  useEffect(() => {
    setLoading(true)
    fetchKeyarData()

  },[])

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log('interval working')
      if (keyarData && keyarData.params) {
        const fhrData = keyarData.params.find(param => param.param_type === 'fhr')?.data_set;
        if (fhrData) {
          const latestTimestamp = Math.max(...Object.keys(fhrData).map(Number));
          //console.log(latestTimestamp, startTime)
          const monitoringTimeInMinutes = (latestTimestamp - (String(startTime).length == 13 ? startTime : startTime*1000)) / (1000*60);
          //console.log(monitoringTimeInMinutes)
          if (monitoringTimeInMinutes > 20 && !refetched) {
            fetchKeyarData(); // Refetch the data
            setRefetched(true); // Set flag to true to avoid repeated fetches
          }
        }
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [startTime, refetched, keyarData]);


  useEffect(() => {
    const keyarDataofPatient = keyar_data.filter((obj) =>  obj.patientID == params.patientID)[0]
    const data = keyarDataofPatient?.keyarData
    
    if(data !== undefined && data.length> 0){
        const fhrValue = data.filter(val => val.param_type == 'fhr')[0].latest_data
        const mhrValue = data.filter(val => val.param_type == 'mhr')[0].latest_data
        const ucValue = data.filter(val => val.param_type == 'uc')[0].latest_data
        const fhr2Value = data.filter(val => val.param_type == 'fhr_two')[0].latest_data
        const ucMetaData = data.filter(val => val.param_type == 'uc')[0].meta_data
        const fhrMetaData = data.filter(val => val.param_type == 'fhr')[0].meta_data
        const serialNumber = data.filter(val => val.param_type == 'uc')[0].serial_number
        setDeviceID(serialNumber)
        const realtimeContractionData = Object.keys(ucMetaData).length > 0 ? {
          contraction: Math.floor(ucMetaData?.contractions),
          duration: Math.floor(ucMetaData?.duration)
        } : {}
        const fetalMarker = fhrMetaData.fetal_movement
        const fmm_data = fetalMarker?.map(obj => [parseInt(obj)/1000, 205]) 
        const sortedFHRData = Object.entries(fhrValue).sort(([a], [b]) => parseInt(a) - parseInt(b));
        const sortedMHRData = Object.entries(mhrValue).sort(([a], [b]) => parseInt(a) - parseInt(b));
        const sortedUCData = Object.entries(ucValue).sort(([a], [b]) => parseInt(a) - parseInt(b));
        const sortedFHR2Data = Object.entries(fhr2Value).sort(([a], [b]) => parseInt(a) - parseInt(b));
        

        let currentIndex = 0;

        const intervalId = setInterval(() => {
            // Determine the slice of timestamps to display
            const fhrSlice = sortedFHRData.slice(currentIndex, currentIndex + timestampsPerUpdate);
            const mhrSlice = sortedMHRData.slice(currentIndex, currentIndex + timestampsPerUpdate);
            const ucSlice = sortedUCData.slice(currentIndex, currentIndex + timestampsPerUpdate);
            const fhr2Slice = sortedFHR2Data.slice(currentIndex, currentIndex + timestampsPerUpdate);
            const temp_delay =  Date.now() - parseInt(ucSlice[ucSlice.length - 1][0])
            const delayInSecs =  Math.floor(temp_delay/1000)
            setDelayInSec(delayInSecs)
            const delay = formatTime(delayInSecs)
            const fhrObject = convertArrayToObject(fhrSlice)
            const fhr2Object = convertArrayToObject(fhr2Slice)
            const mhrObject = convertArrayToObject(mhrSlice)
            const ucObject = convertArrayToObject(ucSlice)

            if (delayInSecs > 60){
              setIsDelayedByOneMin(true)
            } else{
                setIsDelayedByOneMin(false)
            }

            
            setKeyar({
                fhr : fhrSlice.length > 0 && Math.floor(fhrSlice[0][1]),
                mhr: mhrSlice.length > 0 && Math.floor(mhrSlice[0][1]),
                uc: ucSlice.length > 0 && Math.floor(ucSlice[0][1]),
                fhr2: fhr2Slice.length > 0 && Math.floor(fhr2Slice[0][1]),
                delay: delay,
                realtimeContractions: realtimeContractionData,
                fetalMarker: fmm_data,
                fhrDataset : fhrObject,
                fhr2Dataset: fhr2Object,
                mhrDataset: mhrObject,
                ucDataset: ucObject
            })
            currentIndex = (currentIndex + timestampsPerUpdate) % sortedFHRData.length;
        }, updateInterval);
        return () => clearInterval(intervalId);
        
    }      

  }, [keyar_data, params])


useEffect(() => {
    if (Object.keys(keyar).length > 0){
      let fhr_data = keyar.fhr
      if (!fhr_data || fhr_data === undefined || fhr_data === '0.0' || fhr_data === 0 || fhr_data === ''){
          setfhrCritical(false) 
      } else if (fhr_data > 160 || fhr_data < 110){
          setfhrCritical(true)
      } else if (fhr_data <= 160 || fhr_data >= 110){
          setfhrCritical(false)
      }
  

  
      let fhr2_data = keyar.fhr2
      if (!fhr2_data || fhr2_data === undefined || fhr2_data === '0.0' || fhr2_data === 0 || fhr2_data === ''){
          setfhr2Critical(false) 
      } else if (fhr2_data > 160 || fhr2_data < 110){
          setfhr2Critical(true)
      } else if (fhr2_data <= 160 || fhr2_data >= 110){
          setfhr2Critical(false)
      }
  

  
      let mhr_data = keyar.mhr
      if (!mhr_data || mhr_data === undefined || mhr_data === '0.0' || mhr_data === 0 || mhr_data === ''){
          setmhrCritical(false) 
      } else if (mhr_data > 100 || mhr_data < 60){
          setmhrCritical(true)
      } else if (mhr_data <= 100 || mhr_data >= 60){
          setmhrCritical(false)
      }
        
    }


},[keyar, fhrcritical, fhr2critical, mhrcritical])

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${paddedMinutes} min ${paddedSeconds} sec`;
  }
  
  const handleClick = () => {
    navigate("/dashboard/central-monitor")
  }

  const isJson = (data) => {
    try{
        const jsonString = data.replace(/NaN/g, '"NaN"');
        return JSON.parse(jsonString);
    }catch (e){
       return data;
    }
  }

  const fetchKeyarData = async () => {
    await axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${params.patientID}&forms=keyar_data,basic_detail,pa_pv_examination`,
    {
      headers: {
      'Request-ID': 'allow|me', 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${cookie}`
    }
    }).then((res) => {     
      if(res.data){
          const jsonData = isJson(res.data)
          const latest_keyar_data = jsonData.response[0].keyar_data
          //console.log(latest_keyar_data)
          const patient_detail = jsonData.response[0].basic_detail
          const patient_other_detail = jsonData.response[0].pa_pv_examination
          setPatientDetail({
            name : `${patient_detail.name}`,
            age: `${patient_detail.age}`,
            patient_id: `${patient_detail.id}`,
            mrn : `${patient_detail.mrn}`,
            ges_period: `${patient_other_detail.gestation_period}`
          })
          const keyar_data = latest_keyar_data[latest_keyar_data.length-1]
          setKeyarData(keyar_data)
          setStartTime(latest_keyar_data[latest_keyar_data.length-1].data_time)
          
          setLoading(false)
      }
    }).catch((err) => {
      console.log("Graph API failed",err)
      setLoading(false)
    })
  }

 

  const sortTimestamp = (data) => {
    return Object.fromEntries(Object.entries(data).sort((a, b) => a[0] - b[0]));
  }
 
  const getTimefromTimestamp = (data) => {
      return new Date(data*1000).toLocaleTimeString()
  }

  const convertArrayToObject = (array) => {
    const resultObject = {};
    array.forEach(item => {
      const [key, value] = item;
      resultObject[key] = value;
    });
    return resultObject;
  }

  if (keyarData != undefined || keyarData != null){
    if (keyarData.params != undefined || keyarData.params != null ){
      let fhr_data =  keyarData.params.filter(val => val.param_type === 'fhr')[0].data_set
      let mhr_data = keyarData.params.filter(val => val.param_type === 'mhr')[0].data_set
      let uc_data = keyarData.params.filter(val => val.param_type === 'uc')[0].data_set
      let fhr2_data = keyarData.params.filter(val => val.param_type === 'fhr_two')[0].data_set
      let fmm_data = keyarData.params.filter(val => val.param_type === 'fhr')[0].meta_data.fetal_movement


      if(!Object.keys(fhrData || []).includes(Object.keys(keyar.fhrDataset || []))){
        
        
        Object.assign(fhr_data, keyar.fhrDataset)
        // if (Object.keys(fhr_data).length < 900){
          
        // }
        // else if (Object.keys(fhr_data).length >= 900){
        //   const firstkey = Object.keys(fhr_data)[0]
        //   delete fhr_data[firstkey]
        //   Object.assign(fhr_data, realTimefhrData)
        // }
      }
      if(!Object.keys(mhrData || []).includes(Object.keys(keyar.mhrDataset || []))){
        Object.assign(mhr_data, keyar.mhrDataset)
        // if (Object.keys(mhr_data).length < 900){
          
        // }
        // else if (Object.keys(mhr_data).length >= 900){
        //   const firstkey = Object.keys(mhr_data)[0]
        //   delete mhr_data[firstkey]
        //   Object.assign(mhr_data, realTimemhrData)
        // }
      }
      if(!Object.keys(ucData || []).includes(Object.keys(keyar.ucDataset || []))){
        
        //console.log(Object.entries(uc_data), keyar.ucDataset)
        Object.assign(uc_data, keyar.ucDataset)
        // if (Object.keys(uc_data).length < 900){
          
        // }
        // else if (Object.keys(uc_data).length >= 900){
        //   const firstkey = Object.keys(uc_data)[0]
        //   delete uc_data[firstkey]
        //   Object.assign(uc_data, realTimeUCData)
        // }
      }
      if(!Object.keys(fhr2Data || []).includes(Object.keys(keyar.fhr2Dataset || []))){
        Object.assign(fhr2_data, keyar.fhr2Dataset)
        // if (Object.keys(fhr2_data).length < 900){
          
        // }
        // else if (Object.keys(fhr2_data).length >= 900){
        //   const firstkey = Object.keys(fhr2_data)[0]
        //   delete fhr2_data[firstkey]
        //   Object.assign(fhr2_data, realTimefhr2Data)
        // }
      }

      if (!fmm_data.includes(keyar.fetalMarker)){
          fmm_data.push(keyar.fetalMarker)
      }

      
      const sortedfhrData = sortTimestamp(fhr_data);
      const sortedmhrData = sortTimestamp(mhr_data);
      const sortedUCData = sortTimestamp(uc_data);
      const sortedfhr2Data = sortTimestamp(fhr2_data);
      
      

      // if (!Object.keys(sortedfhrData || []).includes(Object.keys(realTimefhrData || []))){
      //   console.log('if working')
      //   console.log(sortedfhrData, realTimefhrData, Object.keys(sortedfhrData).length)
      //   if (Object.keys(realTimefhrData || []).length == 4 && Object.keys(sortedfhrData).length >= 3600){
      //     var removedFhrData = sortedfhrData
      //     console.log('if if working')
      //     for (let i = 0; i < 4; i++) {
      //       console.log('if if for working')
      //       const key = Object.keys(removedFhrData)[i];
      //       console.log(key)
      //       delete removedFhrData[key]
      //       console.log(removedFhrData, Object.keys(removedFhrData).length)
      //     }
      //   }
      // }
   

      fhrData = Object.entries(sortedfhrData).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key), value == 0 ? null : Number(value)]);
      fhr2Data = Object.entries(sortedfhr2Data).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key), value == 0 ? null : Number(value)]);
      mhrData = Object.entries(sortedmhrData).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key), value == 0 ? null : Number(value)]);
      ucData = Object.entries(sortedUCData).map(([key, value]) => [String(key).length == '13' ? Number(key/1000) : Number(key), value == 0 ? null : Number(value)]);
       


      if (fhrData && fhrData != undefined && fhrData != null && fhrData.length > 0){
        let start_time = ''
  
        if (String(startTime).length == 13){
          start_time = startTime
        } else if (String(startTime).length == 10){
          start_time = startTime*1000
        }
                
        const last_time = fhrData[fhrData.length-1][0]*1000
        const timeDifferenceMs = last_time - start_time;
        var totalMonitoringTimeInSec = (Math.floor(timeDifferenceMs/1000))
        // if (totalMonitoringTimeInSec > 1200){
        //   fetchKeyarData()
        // }

        // Convert milliseconds to hours, minutes, and seconds
        const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifferenceMs % (1000 * 60)) / 1000);

        var monitoring_time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
    }
  }

  return (
    <div className={`${theme === "light-theme" ? "chart-light" : "chart-dark"}`}>
      {
        loading ?  
        <Loader/>
        :
        
        <div style={{background: (theme === "light-theme" ? `#fff` : `#0E0E0E`), height:`100vh`}}>
          <div className={`navbar ${theme === "light-theme" ? "navbar-white" : "navbar-dark"}`}>
            <div className='nav'>
              <div onClick={handleClick} className={theme === "light-theme" ? "back-light" : "back-dark"}>
                <BackButton theme={theme}/>
              </div>
              <p className='live-view'>Live View</p>
              <p className='delayed-timer'>Delayed By: - {keyar.delay == undefined ? '--' : keyar.delay}</p>
            </div>
          </div>
          {
            isDelayedByOneMin === true ? 
            <div className='delayedByOneMin-div'>
              <img src="/warning.png" alt="" className='small-warning'/>
              <p className='delayedByOneMin-text'>Data loading is delayed by more than 1 min</p>
            </div>
            :
            <div/>
          }
          <div className='patient-detail'>
            <div className='graph-layout' >
              <div className={`detail ${theme === "light-theme" ? "light-theme" : "dark-theme"}`}>
                <p className='name'>
                  {patientDetail?.name} ({`${interpretationsDisable ? patientDetail?.patient_id : patientDetail?.mrn}`})
                </p>
                <p className='age'>
                  Age : {patientDetail?.age == 'undefined' || patientDetail?.age == null || patientDetail?.age == '' ? '--' : patientDetail?.age}
                </p>
                <p className='ga'>
                  GA: {patientDetail?.ges_period == 'undefined' || patientDetail?.ges_period == null || patientDetail?.ges_period == '' ?  '--' : `${patientDetail?.ges_period} Week` }
                </p>
                <p className='device-id'>
                  Device ID: {deviceID}
                </p>
                <p className='monitoring-time'>
                  Monitoring Time : {monitoring_time}
                </p>
               
              </div>
              
              <Graph startTime={startTime} fhrData={fhrData} fhr2Data={fhr2Data} ucData={ucData} mhrData={mhrData} fetalMarker={keyar.fetalMarker}/>
            </div>
            <div   className={` detail-layout ${theme === "light-theme" ? "detail-layout-light" : "detail-layout-dark"}`} >
              <div className='fhr-fhr2-mhr-card'>
                <p className='monitoring-data'>Monitoring Data</p>
                <hr className='hr'/>
                <div className='monitoring-data-layout'>    
                  <div>
                    <div className='fhr-layout-card'>
                      <div className='name-layout'>
                        <p className='fhr'>FHR</p>
                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                          <img src={theme === "light-theme" ? '/fhr-light.svg' : '/fhr-yellow.png'} alt="FHR Logo" className='imageLogo'/>
                          <img src={theme === "light-theme" ? '/fhr-light1.svg' : '/fhr-yellow1.png'} alt="FHR Logo" className='imageLogo'/>
                        </div>
                      </div>
                      <div className='number-layout'>
                        <p className={fhrcritical ? 'critical-number' : 'number'}>
                        {!keyar?.fhr || keyar?.fhr == '' || keyar?.fhr == 0 ? '--' : keyar?.fhr}
                        </p>
                        <p className='bpm'>BPM</p>
                      </div>
                    </div>
                    <div className='fhr-layout-card'>
                      <div className='name-layout'>
                        <p className='fhr'>FHR2</p>
                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                          <img src={theme === "light-theme" ? '/fhr2-light.svg' : '/fhr2-blue.png'} alt="FHR2 Logo" className='imageLogo'/>
                          <img src={theme === "light-theme" ? '/fhr2-light2.svg' : '/fhr2-blue1.png'} alt="FHR2 Logo" className='imageLogo'/>
                        </div>
                      </div>
                      <div className='number-layout'>
                        <p className={fhr2critical ?'critical-number' : 'number'}>
                        {!keyar?.fhr2 || keyar?.fhr2 == '' || keyar?.fhr2 == 0 ? '--' : keyar?.fhr2}
                        </p>
                        <p className='bpm'>BPM</p>
                      </div>
                    </div>
                    <div className='fhr-layout-card'>
                      <div className='name-layout'>
                        <p className='fhr'>MHR</p>
                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                          <img src={theme === "light-theme" ? '/mhr-light.svg' : '/mhr-pink.png'} alt="MHR Logo" className='imageLogo'  width={25} height={25}/>
                          <img src={theme === "light-theme" ? '/mhr-light2.svg' : '/mhr-pink.png'} alt="MHR Logo" className='imageLogo'/>
                        </div>
                      </div>
                      <div className='number-layout'>
                        <p className={mhrcritical ? 'critical-number' : 'number'}>
                        {!keyar?.mhr || keyar?.mhr == '' || keyar?.mhr == 0 ? '--' : keyar?.mhr}
                        </p>
                        <p className='bpm'>BPM</p>
                      </div> 
                    </div>
                  </div> 
                </div>
              </div>
              <div className='uc-toco-layout'>
                <div className='uc-card'>
                  <div className='uc-logo-layout'> 
                      <p className='uc-text'>UA</p> 
                      <img src={theme === "light-theme" ? '/uc-light.svg' : '/toco.png'} alt="toco Logo" className='imageLogo'/>    
                  </div>
                  <div className='uc-number-layout'>
                    <div className='uc-number'>
                        <p className='number'>
                          {keyar?.realtimeContractions?.contraction == undefined || keyar?.realtimeContractions?.contraction == '' || keyar?.realtimeContractions?.contraction == 0 ? '--' : keyar?.realtimeContractions?.contraction}
                        </p>
                        <p className='small-text'>Contraction</p> 
                        <p className='large-text'>Total Count</p>    
                    </div>
                    <div className='uc-number'>
                        <p className='number'>
                          { keyar?.realtimeContractions?.duration == undefined || keyar?.realtimeContractions?.duration == '' || keyar?.realtimeContractions?.duration == 0 ? '--' : keyar?.realtimeContractions?.duration}
                        </p>
                        <p className='small-text'>Seconds</p> 
                        <p className='large-text'>Avg. Duration</p>     
                    </div>
                  </div>
                  
                </div>
                <div className='toco-card'>
                  <div className='toco-name-layout'>
                    <p className='fhr'>TOCO</p>
                    <img  src={theme === "light-theme" ? '/toco-light.svg' : '/toco.png'} alt="toco Logo" className='imageLogo'/>
                    
                  </div>
                  <div className='toco-number-layout'>
                    <p className={theme === "light-theme" ? "toco-number-light" : "toco-number"}>
                    {!keyar?.uc || keyar?.uc == '' || keyar?.uc == 0 ? '--' : keyar?.uc}
                    </p>
                    <p className='pressure'>Pressure</p>
                  </div>
                </div>
              </div>
              
              
                
              
            </div>
            
          </div>
        </div>
      }
 
    </div>
  )
}

const mapStateToProps = (state) => ({
  keyar_data: state.keyarData.keyarData,
  live_data: state.liveOneSecKeyarData.liveOneSecKeyarData

});

export default connect(mapStateToProps)(ChartLayout)
