import { getContext, getReadableTime, getSentenceCase } from '../utils/converter'
import { saveAs } from 'file-saver';

const _ = require('lodash');
const FileSaver = require('file-saver');

var commonHeaders = [
    {
        "attribute": "facility_name",
        "type": "string",
        "display": "Facility Name"
    },
    {
        "attribute": "patient_name",
        "type": "string",
        "display": "Patient Name"
    },
    {
        "attribute": "patient_id",
        "type": "string",
        "display": "Patient ID"
    },
];

// omitFields();

function omitFields(patientDetailFieldMap){

    var omittedFields = {
        "basic_detail": ['name', 'id', 'place_id'],
        "place_details": [],
        "investigation": [],
        "physical_examination": [],
        "pa_pv_examination": [],
        "admission_note": [],
        "treatment_detail": [],
        "obstetric_histories": [],
        "medical_history": [],
        "family_history": [],
        "delivery_detail": [],
        "child_details": [],
        "alerts": [],
        "events": [],
        "pre_delivery_checklist": []
    };
    for(var key in omittedFields){
        patientDetailFieldMap[key] = _.find(patientDetailFieldMap[key], function(e){
            if(omittedFields[key].indexOf(e['attribute']) != -1){
                return false;
            } else {
                return true;
            }
        });
    }
}

function getHeaderText(text){
    return getSentenceCase(text.replace(/_/g, " "));
}

function getColumnName(num) {
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while(num >= 0) {
        s = String.fromCharCode(num % len + ordA) + s;
        num = Math.floor(num / len) - 1;
    }
    return s.toUpperCase();
}

function setCommonHeaders(patientDetailFieldMap, ep, sheetName){
    var row = 1;
    var column = 0;
    var cellData = {};
    var content = "";
    var rowData = [];
    commonHeaders.map((value,index) => {
        
        content = value['attribute'];
        if(typeof(value['display']) !== 'undefined'){
            content = value['display'];
        }

        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": content
        };
        rowData.push(cellData.content)
        column++;
    });
    // ep.addRow(rowData)
    return {'row': row, 'column': column, 'commonHeaderRow': rowData};
}

function setSheetHeaders(patientDetailFieldMap, ep, sheetName, formType){
    
    var cellData = {};
    var content = "";
    var resp = setCommonHeaders(patientDetailFieldMap, ep, sheetName)
    var row = resp['row'];
    var column = resp['column'];
    var commonHeaderRow = resp['commonHeaderRow']
    var rowData = [];
    rowData = rowData.concat(commonHeaderRow)
    patientDetailFieldMap[formType].map((value,index) => {
        content = getHeaderText(value['attribute']);
        if(typeof(value['display']) !== 'undefined'){
            content = value['display'];
        }
        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": content
        };
        rowData.push(cellData.content)
        column++;
    });
    ep.addRow(rowData)
    row++;

    return {'row': row, 'column': column};
}

function setCommonColumns(ep, sheetName, data, row){

    var column = 0;
    var commonData = [];
    var cellData = {};
    var facility_name = _.get(data, 'place_details.' + data.basic_detail.registration_place_id.toString() + '.name', '-');
    commonData.push(facility_name);
    
    var context = getContext(data, 'basic_detail');
    commonData.push(context['name']);
    commonData.push(context['id']);

    var rowData = []

    commonData.map((value, index) => {
        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": value
        };
        rowData.push(cellData.content)
        column++;
    });

    return {'row': row, 'column': column, 'commonColumnData': rowData};
}

function generateDefaultSheet(patientDetailFieldMap, ep, response, sheet){

    var sheetName = sheet['name'];
    var formType = sheet['form_type'];

    var resp = setSheetHeaders(patientDetailFieldMap, ep, sheetName, formType);

    var row = resp['row'];
    var column = 0;
    var context = {};
    var cellData = {};
    var rowData = []

    response && response.map((patient,index) => {        
        
        resp = setCommonColumns(ep, sheetName, patient, row);
        column = resp['column'];
        var commonColumnData = resp['commonColumnData']
        rowData = rowData.concat(commonColumnData)
        context = getContext(patient, formType);
        patientDetailFieldMap[formType].map((value,index) => {
            cellData = {
                "cell": getColumnName(column) + row.toString(),
                "content": context[value['attribute']]
            };
            rowData.push(cellData.content)
            column++;
        });
        ep.addRow(rowData);
        row++;
        rowData = [];
    });
}

function generateCustomSheet(patientDetailFieldMap, ep, response, sheet, customKey){

    var sheetName = sheet['name'];
    var formType = sheet['form_type'];

    var resp = setSheetHeaders(patientDetailFieldMap, ep, sheetName, formType);
    var row = resp['row'];
    var column = 0;
    var context = {};
    var cellData = {};
    var dataExists = true;

    response.map((patient, index) => { 

        var requiredData = _.get(patient, customKey['data'], false);
        var isArray = Array.isArray(requiredData);
        if(requiredData && requiredData.length > 0 && isArray){
            requiredData.map((rowData, index) => {
                resp = setCommonColumns(ep, sheetName, patient, row);
                column = resp['column'];
                var commonColumnData = resp['commonColumnData']
                var contextData = {};
                contextData[customKey['form']] = rowData;
                context = getContext(contextData, formType);
                var rowData = [];
                rowData = rowData.concat(commonColumnData)
                patientDetailFieldMap[formType].map((value,index) => {
                    cellData = {
                        "cell": getColumnName(column) + row.toString(),
                        "content": context[value['attribute']]
                    };
                    rowData.push(cellData.content)
                    column++;
                });
                ep.addRow(rowData);
                row++;
                rowData=[];
            });
        } else if(!isArray && typeof(requiredData) === 'object'){
            Object.keys(requiredData).forEach(function(key) {
                requiredData[key].map((rowData,index) => {
                    resp = setCommonColumns(ep, sheetName, patient, row);
                    column = resp['column'];
                    var commonRowData = resp['commonRowData']
                    var contextData = {};
                    contextData[customKey['form']] = rowData;
                    context = getContext(contextData, formType);
                    var rowData = [];
                    rowData = rowData.concat(commonRowData)
                    patientDetailFieldMap[formType].map((value,index) => {
                        cellData = {
                            "cell": getColumnName(column) + row.toString(),
                            "content": context[value['attribute']]
                        };
                        rowData.push(cellData.content)
                        column++;
                    });
                    ep.addRow(rowData);
                    row++;
                    rowData=[];
                });
            });
        }
    });
}

function generateEventsSheet(patientDetailFieldMap, ep, response, sheet, customKey){

    var sheetName = sheet['name'];
    var formType = sheet['form_type'];

    var resp = setSheetHeaders(patientDetailFieldMap, ep, sheetName, formType);

    var row = resp['row'];
    var column = 0;
    var context = {};
    var cellData = {};
    var dataExists = true;

    response.map((patient,index) => {

        var requiredData = _.get(patient, 'events', false);
        var isArray = Array.isArray(requiredData);

        if(requiredData && requiredData.length > 0 && isArray){
            requiredData.map((rowData,index) => {

                var referMessages = _.get(rowData, 'refer_message', '');

                if(referMessages != ''){

                    referMessages.map((msgData, msgIndex) => {
                        resp = setCommonColumns(ep, sheetName, patient, row);
                        column = resp['column'];
                        var commonColumnData = resp['commonColumnData']

                        var contextData = {};
                        
                        contextData[customKey['form']] = rowData;
                        context = getContext(contextData, formType);
                        context['refer_message'] = _.get(msgData, 'refer_message', '-');
                        context['refer_type'] = _.get(msgData, 'refer_type', '-');

                        var rowData = []
                        rowData = rowData.concat(commonColumnData)

                        patientDetailFieldMap[formType].map((value,index) => {
                            cellData = {
                                "cell": getColumnName(column) + row.toString(),
                                "content": context[value['attribute']]
                            };
                            rowData.push(cellData.content)
                            column++;
                        });
                        ep.addRow(rowData)
                        row++;
                        rowData = []
                    });

                } else {

                    resp = setCommonColumns(ep, sheetName, patient, row);
                    column = resp['column'];
                    var commonColumnData = resp['commonColumnData']

                    var contextData = {};
                    contextData[customKey['form']] = rowData;
                    context = getContext(contextData, formType);

                    var rowData = []
                    rowData = rowData.concat(commonColumnData)

                    patientDetailFieldMap[formType].map((value,index) => {
                        if(value['attribute'])
                        cellData = {
                            "cell": getColumnName(column) + row.toString(),
                            "content": context[value['attribute']]
                        };
                        rowData.push(cellData.content)
                        column++;
                    });
                    ep.addRow(rowData)
                    row++;
                    rowData = []
                }
            });
        }
    });
}

function generateMotherPostLaborDataSheet(patientDetailFieldMap, ep, response, sheet, customKey){
    var sheetName = sheet['name'];
    var formType = sheet['form_type'];

    var sheetHeaders = [];
    var parameters = [];

    var sheetHeaders = ['Patient ID', 'Patient Name', 'Facility Name', 'Data Time', 'Alive', 'Death Time', 'Death Reason', 'Uterus', 'Diet', 'Pulse', 'Episiotomy Tear', 'Nipples', 'Bleeding', 'Lochia', 'Complications', 'Urine Output', 'BP (Diastolic)', 'BP (Systolic)', 'Breastfeeding', 'Pallor', 'Temperature', 'Breast', 'Family Planning'];

    var parameters = ['mother_alive', 'mother_death_time', 'mother_death_reason', 'mother_uterus', 'mother_diet', 'mother_pulse', 'mother_episiotomy_tear', 'mother_nipples', 'mother_bleeding', 'mother_lochia', 'mother_complications', 'mother_urine_output', 'mother_blood_pressure_diastolic', 'mother_blood_pressure_systolic', 'mother_breast_feeding', 'mother_pallor', 'mother_temperature', 'mother_breast', 'mother_family_planning'];
   
    var column = 0;
    var row = 1;
    var context = {};
    var cellData = {};
    var rowData = []
    sheetHeaders.map((value,index) => {

        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": value
        };
        rowData.push(cellData.content)
        column++;
    });

    ep.addRow(rowData)
    rowData = []

    //Setting the sheet data
    row = 2;
    response.map((patient,index) => {
        
        var paramsByTime = {};

        //Getting each parameters data for the patient - total and count to set average
        patient.post_partum_data.mother.map((datum,index) => {
            if(typeof(paramsByTime[datum['data_time']]) === 'undefined'){
                paramsByTime[datum['data_time']] = {};
            }
            paramsByTime[datum['data_time']][datum['data_type']] = datum['param_1'];
        });

        for(var time in paramsByTime){

            column = 0;

            var patient_id = patient.basic_detail.id;
            var rowData = []
            cellData = {
                "cell": getColumnName(column) + row.toString(),
                "content": patient_id
            };
            rowData.push(cellData.content)
            column++;

            var patient_name = patient.basic_detail.name;
            cellData = {
                "cell": getColumnName(column) + row.toString(),
                "content": patient_name
            };
            rowData.push(cellData.content)
            column++;

            var facility_name = _.get(patient, 'place_details.' + patient.basic_detail.registration_place_id.toString() + '.name', ''); 
            cellData = {
                "cell": getColumnName(column) + row.toString(),
                "content": facility_name
            };
            rowData.push(cellData.content)
            column++;

            cellData = {
                "cell": getColumnName(column) + row.toString(),
                "content": getReadableTime(time)
            };
            rowData.push(cellData.content)
            column++;
            
            parameters.map((value,index) => {
                if(typeof(paramsByTime[time][value]) !== 'undefined' && paramsByTime[time][value] != ''){
                    cellData = {
                        "cell": getColumnName(column) + row.toString(),
                        "content": paramsByTime[time][value]
                    };
                    rowData.push(cellData.content)
                } else {
                    cellData = {
                        "cell": getColumnName(column) + row.toString(),
                        "content": "-"
                    };
                    rowData.push(cellData.content)
                }
                column++;
            });
            ep.addRow(rowData)
            row++;
            rowData = []
        }
    });

}

function generateChildPostLaborDataSheet(patientDetailFieldMap, ep, response, sheet, customKey){
    var sheetName = sheet['name'];
    var formType = sheet['form_type'];

    var sheetHeaders = ['Patient ID', 'Patient Name', 'Facility Name', 'Child ID', 'Data Time', 'Alive', 'Death Time', 'Death Reason', 'Diarrhea', 'Activity', 'Jaundice', 'Breathing', 'Chest Indrawing', 'Sucking', 'Convulsions', 'Stool Passed', 'Skin Pustules', 'Urine Passed', 'Complications', 'Temperature', 'Vomiting', 'Umbilical Cord'];

    var parameters = ['child_alive', 'child_death_time', 'child_death_reason', 'child_diarrhea', 'child_activity', 'child_jaundice', 'child_breathing', 'child_chest_indrawing', 'child_sucking', 'child_convulsions', 'child_stool_passed', 'child_skin_pustules', 'child_urine_passed', 'child_complications', 'child_temperature', 'child_vomiting', 'child_umbilical_cord'];

    var column = 0;
    var row = 1;
    var rowData = []
    var cellData = {};

    sheetHeaders.map((value,index) => {

        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": value
        };
        rowData.push(cellData.content);
        column++;
    });

    ep.addRow(rowData)
    rowData = []

    //Setting the sheet data
    row = 2;
    response.map((patient,index) => {
        
        for(var child_id in patient.post_partum_data.child){

            var paramsByTime = {};

            //Getting each parameters data for the patient - total and count to set average
            patient.post_partum_data.child[child_id].map((datum,index) => {
                if(typeof(paramsByTime[datum['data_time']]) === 'undefined'){
                    paramsByTime[datum['data_time']] = {};
                }
                paramsByTime[datum['data_time']][datum['data_type']] = datum['param_1'];
            });

            for(var time in paramsByTime){

                column = 0;

                var patient_id = patient.basic_detail.id;
                cellData = {
                    "cell": getColumnName(column) + row.toString(),
                    "content": patient_id
                };
                rowData.push(cellData.content);
                column++;

                var patient_name = patient.basic_detail.name;
                cellData = {
                    "cell": getColumnName(column) + row.toString(),
                    "content": patient_name
                };
                rowData.push(cellData.content);
                column++;

                var facility_name = _.get(patient, 'place_details.' + patient.basic_detail.registration_place_id.toString() + '.name', ''); 
                cellData = {
                    "cell": getColumnName(column) + row.toString(),
                    "content": facility_name
                };
                rowData.push(cellData.content);
                column++;

                cellData = {
                    "cell": getColumnName(column) + row.toString(),
                    "content": child_id
                };
                rowData.push(cellData.content);
                column++;

                cellData = {
                    "cell": getColumnName(column) + row.toString(),
                    "content": getReadableTime(time)
                };
                rowData.push(cellData.content);
                column++;
                
                parameters.map((value,index) => {
                    if(typeof(paramsByTime[time][value]) !== 'undefined' && paramsByTime[time][value] != ''){
                        cellData = {
                            "cell": getColumnName(column) + row.toString(),
                            "content": paramsByTime[time][value]
                        };
                        rowData.push(cellData.content);
                    } else {
                        cellData = {
                            "cell": getColumnName(column) + row.toString(),
                            "content": "-"
                        };
                        rowData.push(cellData.content);
                    }
                    column++;
                });
                ep.addRow(rowData)
                row++;
                rowData = []
            }
        }
        
    });
}

function getWarningTime(data_type, active_labor){
    return 0;
}

function generateAnalyticsSheet(patientDetailFieldMap, ep, response, sheet){

    var sheetName = sheet['name'];

    // Setting the sheet headers
    var sheetHeaders = ['Patient ID', 'Patient Name', 'Facility Name', 'Cervix Dilatation', 'Descent of Head', 'BP', 'Pulse', 'Fetal Heart Rate', 'Amniotic Fluid', 'Moulding', 'Contractions', 'Drugs', 'Oxytocin', 'Temperature'];

    var parameters = ['cervix_diameter', 'head_descent', 'bp', 'pulse', 'fetal_heart_rate', 'amniotic_fluid', 'moulding', 'contraction', 'drugs', 'oxytocin', 'temperature'];

    var column = 0;
    var row = 1;
    var rowData = []
    var cellData = {}
    sheetHeaders.map((value,index) => {
        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": value
        };
        rowData.push(cellData.content)
        column++;
    });

    ep.addRow(rowData)
    rowData = []

    //Setting the sheet data
    row = 2;
    response.map((patient,index) => {
        column = 0;
        var total_time_lag = {};
        var counter = {};
        var prev_time = {};
        var time_diff = 0

        var active_labor = false;

        //Getting each parameters data for the patient - total and count to set average
        patient.labor_data.map((datum,index) => {
            var param_index = parameters.indexOf(datum['data_type']);
            if(param_index !== -1){
                if (typeof(total_time_lag[datum['data_type']]) === 'undefined'){
                    total_time_lag[datum['data_type']] = 0;
                    counter[datum['data_type']] = 0;
                } else {
                    time_diff = datum['data_time'] - prev_time[datum['data_type']] - getWarningTime(datum['data_type'], active_labor);
                    total_time_lag[datum['data_type']] = total_time_lag[datum['data_type']] + time_diff;
                    counter[datum['data_type']]++;
                }
                if(datum['data_type'] == 'cervix_diameter' && parseInt(datum['param_1']) >= 4){
                    active_labor = true;
                }
                prev_time[datum['data_type']] = datum['data_time'];
            }
        });

        var patient_id = patient.basic_detail.id;
        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": patient_id
        };
        rowData.push(cellData.content);
        column++;

        var patient_name = patient.basic_detail.name;
        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": patient_name
        };
        rowData.push(cellData.content);
        column++;

        var facility_name = _.get(patient, 'place_details.' + patient.basic_detail.registration_place_id.toString() + '.name', ''); 
        cellData = {
            "cell": getColumnName(column) + row.toString(),
            "content": facility_name
        };
        rowData.push(cellData.content);
        column++;
        
        parameters.map((value,index) => {
            if(typeof(total_time_lag[value]) !== 'undefined' && typeof(counter[value]) !== 'undefined' && counter[value] > 0 && total_time_lag[value] > 0){
                cellData = {
                    "cell": getColumnName(column) + row.toString(),
                    "content": Math.round(total_time_lag[value] / counter[value] * 100) / 100
                };
                rowData.push(cellData.content)
            } else {
                cellData = {
                    "cell": getColumnName(column) + row.toString(),
                    "content": "-"
                };
                rowData.push(cellData.content)
            }
            column++;
        });
        ep.addRow(rowData)
        row++;
        rowData = []
    });
}

export async function downloadReport(patientDetailFieldMap, response){
    // omitFields(patientDetailFieldMap)
    var ep = require('exceljs')

    var sheets = {
        "basic_detail": "Patients",
        "investigation": "Investigations",
        "physical_examination": "Physical Examination",
        "pa_pv_examination": "PA PV Examination",
        "admission_note": "Admission Note",
        "treatment_detail": "Treatment Details",
        "obstetric_histories": "Obstetric History",
        "medical_history": "Medical History",
        "family_history": "Family History",
        "delivery_detail": "Delivery Details",
        "alerts": "Alerts",
        "events": "Events",
        "child_details": "Child Details",
        "pre_delivery_checklist": "Pre Delivery Checklist",
        "labor_data": "Labor Data",
        "mother_post_labor_data": "Mother Post Labor Data",
        "child_post_labor_data": "Child Post Labor Data",
        "analytics": "Analytics"
    };

    const wb = new ep.Workbook()

    Object.keys(sheets).forEach(function(key,ind) {
        var sheet = {'name': sheets[key], 'form_type': key};
        var ws = wb.addWorksheet(sheet['name'])
        switch(key) {
            case 'obstetric_histories':
                generateCustomSheet(patientDetailFieldMap, ws, response, sheet, {'data': 'obstetric_histories', 'form': 'obstetric_histories'});
                break;
            case 'child_details':
                generateCustomSheet(patientDetailFieldMap, ws, response, sheet, {'data': 'delivery_detail.child_details', 'form': 'child_details'});
                break;
            case 'labor_data':
                generateCustomSheet(patientDetailFieldMap, ws, response, sheet, {'data': 'labor_data', 'form': 'labor_data'});
                break;
            case 'alerts':
                generateCustomSheet(patientDetailFieldMap, ws, response, sheet, {'data': 'alerts', 'form': 'alerts'});
                break;
            case 'events':
                generateEventsSheet(patientDetailFieldMap, ws, response, sheet, {'data': 'events', 'form': 'events'});
                break;
            case 'mother_post_labor_data':
                generateMotherPostLaborDataSheet(patientDetailFieldMap, ws, response, sheet, {'data': 'post_partum_data.mother', 'form': 'mother_post_labor_data'});
                break;
            case 'child_post_labor_data':
                generateChildPostLaborDataSheet(patientDetailFieldMap, ws, response, sheet, {'data': 'post_partum_data.child', 'form': 'child_post_labor_data'});
                break;
            case 'analytics':
                generateAnalyticsSheet(patientDetailFieldMap, ws, response, sheet);
                break;
            default:
                generateDefaultSheet(patientDetailFieldMap, ws, response, sheet);
                break;
        }
    })

    const buf = await wb.xlsx.writeBuffer()
    saveAs(new Blob([buf]), 'Daksh_Report.xlsx')
}
