import React,{useRef} from 'react';
import axios from 'axios';
import urls from '../../../utils/url';
import { useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import '../common.scss';
import { isJson,getReadableTime } from '../../../utils/converter';
import Cookie from 'js-cookie';
import moment from 'moment';

const Form = (props) => {

  const cookie = Cookie.get('access_token')
  const { patientID } = props;
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ formDetail, setFormDeatil ] = useState();
  const [ patientName, setPatientName ] = useState();
  const [ patientForm, setPatientForm ] = useState();
  const formRefs = useRef([]);
  const [printComponent, setPrintComponent] = useState(null);

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=consent_details,basic_detail&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setFormDeatil(json_data.response[0].consent_details)
            setPatientName(json_data.response[0].basic_detail.name)
            setLoading(false)
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err)
          setError(true)
          setFormDeatil()
          setLoading(false)
        }
      ) 
  }, [])


  useEffect(() => {
   
      // Create refs only when the component mounts or when form changes
      const newRefs = [];
      for (let i = 0; i < patientForm?.length || 0; i++) {
        // If a ref already exists at this index, reuse it; otherwise, create a new ref
        newRefs[i] = formRefs.current[i] || React.createRef();
      }
      formRefs.current = newRefs;
  
  }, [patientForm]);

  // Manipulation of Form Data
  useEffect(() => {
      let array = [];
     
      if (formDetail) {
          const formData = formDetail.map((form, index) => {
              return form.consent_categories.map((categories, ind) => {
                  array.push({
                      form: {
                          consent_categorie: categories.category_name,
                          signee_name: form.signee_name,
                          signee_guardian_name: form.signee_guardian_name,
                          signee_guardian_relation: form.signee_guardian_relation,
                          signee_age: form.signee_age,
                          signee_address: form.signee_address,
                          signee_patient_relation: form.signee_patient_relation,
                          attendant_signature_file: form.attendant_signature_file,
                          attendant_name: form.attendant_name,
                          signee_signature_file : form.signee_signature_file,
                          form_time: form.form_time,
                      }
                  });
              });
          });
          
          setPatientForm(array)
      }
  }, [formDetail]);


  const handlePrint = async (index) => {

    const printButton = document.getElementById(`print-button-${index}`);
  
    if (printButton) {
      printButton.style.display ='none';
    }
  
    const contentToPrint = formRefs.current[index];

    if (contentToPrint) {
      setPrintComponent(() => contentToPrint);
      await triggerPrint();
      setPrintComponent(null); // Reset the print component after printing
    }
  
    if (printButton) {
      printButton.style.display = 'inline-flex';
    };

  };

  useEffect(() => {
    if (printComponent) {
      triggerPrint();
    }
  }, [printComponent]);

  const triggerPrint = useReactToPrint({
    content: () => printComponent,

  });
  
  const transformText = (text) => {
    const transformedText = text.split('_').map(word => {
      return word.toLowerCase();
    }).join(' ');
  
    return transformedText;

  }

  return (
     
    <>
      { 
        loading ? <div className='loading-div'> <Loading/> </div> 
        : error ? <div className='reload-div'> <Reload/> </div>
        : patientForm && patientForm.length > 0 ? 
            patientForm.map((obj, index) => {
             
              return (
                
                 <div key={index}>
                  
                   <div key={index} className='form-container' id="printableContent" ref={(el) => (formRefs.current[index] = el)}>
                      <div className='form-header'>
                          <p className='form-time'>{moment(getReadableTime(obj.form.form_time)).format('DD/MM/YYYY hh:mm A')}</p>
                          <p className='form-heading'>Consent for {obj.form.consent_categorie}</p>
                          <button className='print'  id={`print-button-${index}`} onClick = { () => handlePrint(index)}>
                              <img src="/print.svg" alt= "Print Form" />
                               <p>Print</p>
                          </button>
                            
                      </div>

                      <div className='form-content'>

                      <p>
                        I <span className='underline'>{obj.form.signee_name}</span> {transformText(obj.form.signee_guardian_relation)}{' '}
                        <span className='underline'>{obj.form.signee_guardian_name}</span> aged{' '}
                        <span className='underline'>{obj.form.signee_age}</span> (years) resident of{' '}
                        <span className='underline'>{obj.form.signee_address}</span> related with{' '}
                        <span className='underline'>{patientName}</span> (pregnant woman) as{' '}
                        <span className='underline'>{obj.form.signee_patient_relation}</span> is giving consent for medical procedures{' '}
                        {obj.form.consent_categorie} without any pressure.

                        <br/>
                        <br/>

                        <div className='form-subcontent'>
                          <li>I have been informed in detail about the probable consequences of this procedure in a language that I understand.</li>
                          <li>I am signing this consent form without any undue pressure and in my complete consciousness.</li>
                          <li>I provide consent to use anaesthetics and all other medications which the healthcare centre may think fit.</li>
                          <li>I will remain present for health check-ups and follow-ups at a nearby hospital after the procedure or operation, as instructed by the healthcare centre. If I fail to do so, I will be solely responsible.</li>
                          <br/>
                          <li>The doctor/provider shall not be held responsible in case of any complication/mishap.</li>
                        </div>
                      </p>

                      </div>

                      <div className='signature-container'>
                            <div className='sign-detail'>
                              <img src={obj.form.signee_signature_file} alt="Patient Signature" />
                              <p>{obj.form.signee_name}</p>
                              <p>Name & signature of patient</p>
                            </div>

                            <div className='sign-detail'>
                              <img src={obj.form.attendant_signature_file} alt="Attendant Signature" />
                              <p>{obj.form.attendant_name}</p>
                              <p>Name & signature of Attendant</p>
                            </div>
                      </div>

                   </div>

                 </div>
              );
            }) 
        : 
        <div className='no-data-div'>
          <img src = "/no_form_recorded.svg" alt = 'No Consent Form Recorded' style={{ marginLeft:`17%`, marginTop:`4rem`}}/>
        </div>
      }
    </>
    
  );
}

export default Form