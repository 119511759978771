import React from 'react'
import '../common/common.scss'

const Loading = ({text}) => {
  
  return (
    <div className='loading-background'>
        <div className='loading-div'>
            <span class="loading"></span>
            <p className='loading-text'>{ !text ? 'Loading please wait....' : text}</p>
        </div>
    </div>
    
  )
}

export default Loading
