import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    patientDetail: []
}

const patientDetailSlice = createSlice({
    name: 'patientDetail',
    initialState,
    reducers: {
        addPatientDetail: ( state, action) => {
            state.patientDetail = action.payload
        }}
})

export const {addPatientDetail} = patientDetailSlice.actions;
export default patientDetailSlice.reducer