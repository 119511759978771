import React from 'react';
import axios from 'axios';
import urls from '../../../utils/url';
import { useState, useEffect } from 'react';
import '../common.scss';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import {TooltipComponent, limitStr, charLimit, isJson} from '../../../utils/converter';
import Cookie from 'js-cookie';

const ChildbirthChecklist = (props) => {

    const { patientID } = props;
    const cookie = Cookie.get('access_token')
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ patientCheckList, setPatientCheckList ] = useState();
    

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=pre_delivery_checklist,delivery_detail&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPatientCheckList(json_data.response[0]);
            setLoading(false)
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err)
          setError(true)
          setPatientCheckList()
          setLoading(false)
        }
      ) 
  }, [])
  
  console.log(patientCheckList)
  return (
 
    <>
        { loading ? <div className='loading-div'><Loading/></div> 
        : error ? <div className='reload-div'><Reload/></div>
        :
        <div className='info-section'>
        
        {/* Pre Delivery Checklist */}
        <>
            <div className='info-header'>
            <p className='heading'>Pre Delivery Checklist</p>
            </div>

            <div className='info-container'>

                <div>
                    <p className='text1'>Spotlight</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.spotlight ? patientCheckList?.pre_delivery_checklist?.spotlight : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Gloves</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.glove ? patientCheckList?.pre_delivery_checklist?.glove : '--'}</p>
                </div>
                    
                <div>
                    <p className='text1'>Soap and Water</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.soap_and_water ? patientCheckList?.pre_delivery_checklist?.soap_and_water : '--'}</p>
                </div>
                    
                <div>
                    <p className='text1'>Mucus Extractor</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.mucus_extractor ? patientCheckList?.pre_delivery_checklist?.mucus_extractor : '--'}</p>
                </div>
                
                <div>
                    <p className='text1'>Oxytocin Syringe</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.oxytocin_syringe ? patientCheckList?.pre_delivery_checklist?.oxytocin_syringe : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Scissors</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.scissor ? patientCheckList?.pre_delivery_checklist?.scissor : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Sanity Pad</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.sanity_pad ? patientCheckList?.pre_delivery_checklist?.sanity_pad : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Towel</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.towel ? patientCheckList?.pre_delivery_checklist?.towel : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Bag and Mask</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.bag_and_mask ? patientCheckList?.pre_delivery_checklist?.bag_and_mask : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Cord Ligature</p>
                    <p className='text2'>{patientCheckList?.pre_delivery_checklist?.cord_ligature ? patientCheckList?.pre_delivery_checklist?.cord_ligature : '--'}</p>
                </div>

            </div>
        </>
        
        {/* Post Delivery Checklist */}
        <>
            <div className='info-header'>
                <p className='heading'>Post Delivery Checklist</p>
            </div>

            <div className='info-container'>

                <div>
                    <p className='text1'>3rd Stage Active Management </p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.third_stage_active_management ? patientCheckList?.delivery_detail?.third_stage_active_management : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Episiotomy Done</p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.episiotomy_done ? patientCheckList?.delivery_detail?.episiotomy_done : '--'}</p>
                </div>
                    
                <div>
                    <p className='text1'>Delayed Cord Clamping</p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.delayed_cord_clamping ? patientCheckList?.delivery_detail?.delayed_cord_clamping: '--'}</p>
                </div>

                <div>
                    <p className='text1'>PPIUCD Counselling</p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.ppiucd_counselling ? patientCheckList?.delivery_detail?.ppiucd_counselling : '--'}</p>
                </div>
                    
                <div>
                    <p className='text1'>Perineum Tear</p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.perineun_tear ? patientCheckList?.delivery_detail?.perineun_tear : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Repair</p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.repair ? patientCheckList?.delivery_detail?.repair : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Placenta Delivered</p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.placenta_delivered ? patientCheckList?.delivery_detail?.placenta_delivered : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Mode of Placenta Delivery</p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.placenta_delivered_observation ? patientCheckList?.delivery_detail?.placenta_delivered_observation : '--'}</p>
                </div>

                <div>
                    <p className='text1'>CCT</p>
                    <p className='text2'>{patientCheckList?.delivery_detail?.completeness_checked ? patientCheckList?.delivery_detail?.completeness_checked : '--'}</p>
                </div>

                <div>
                    <p className='text1'>Family Planning</p>
                    {
                        patientCheckList?.delivery_detail?.family_planning_method && patientCheckList?.delivery_detail?.family_planning_method.length > charLimit ?
                            <TooltipComponent title={`${patientCheckList?.delivery_detail?.family_planning_method}`} content={limitStr(patientCheckList?.delivery_detail?.family_planning_method, charLimit)}/>
                        :
                            <p className='text2'>{patientCheckList?.delivery_detail?.family_planning_method ? patientCheckList?.delivery_detail?.family_planning_method : "- -"}</p>
                    }
                </div>

                <div>
                    <p className='text1'>Other Family Planning</p>
                    {
                        patientCheckList?.delivery_detail?.family_planning_method_other && patientCheckList?.delivery_detail?.family_planning_method_other.length > charLimit ?
                            <TooltipComponent title={`${patientCheckList?.delivery_detail?.family_planning_method_other}`} content={limitStr(patientCheckList?.delivery_detail?.family_planning_method_other, charLimit)}/>
                        :
                            <p className='text2'>{patientCheckList?.delivery_detail?.family_planning_method_other ? patientCheckList?.delivery_detail?.family_planning_method_other : "- -"}</p>
                    }
                </div>


                <div>
                    <p className = 'text1'> Counselling on Danger Sign </p>
                    <p className = 'text2'>{patientCheckList?.delivery_detail?.counselling_on_danger_sign ? patientCheckList?.delivery_detail?.counselling_on_danger_sign : '--'}</p>
                </div>

                <div>
                    <p className = 'text1'> Oxytocin </p>
                    <p className = 'text2'> {patientCheckList?.delivery_detail?.oxytocin ? patientCheckList?.delivery_detail?.oxytocin : '--'} </p>
                </div>

                <div>
                    <p className = 'text1'> Tab Misoprostol </p>
                    <p className = 'text2'> {patientCheckList?.delivery_detail?.misoprostol ? patientCheckList?.delivery_detail?.misoprostol : '--'} </p>
                </div>
                
                <div>
                    <p className = 'text1'> Uterine Massage </p>
                    <p className = 'text2'> { patientCheckList?.delivery_detail?.uterine_massage ? patientCheckList?.delivery_detail?.uterine_massage : '--'} </p>
                </div>

                <div>
                    <p className='text1'>Complications during delivery</p>
                    {
                        patientCheckList?.delivery_detail?.complications && patientCheckList.delivery_detail.complications.length > charLimit ? 
                            <TooltipComponent
                                title={`${patientCheckList?.delivery_detail?.complications}`}
                                content={limitStr(patientCheckList?.delivery_detail?.complications, charLimit)}
                            />
                        : 
                            <p className='text2'>{patientCheckList?.delivery_detail?.complications ? patientCheckList.delivery_detail.complications : "--"}</p>
                    }
                </div>



            </div>
        </>

        {/* Child Details */}
        {patientCheckList?.delivery_detail?.child_details && patientCheckList?.delivery_detail?.child_details.length > 0 ?
            patientCheckList?.delivery_detail?.child_details.map((obj,index) => { 
                return (
                    <div>

                        <div className='info-header'>
                            <p className='heading'>Child {index+1}</p>
                        </div>

                        <div className='info-container'>

                            <div>
                                <p className='text1'>Baby Cry</p>
                                <p className='text2'>{obj?.baby_cry ? obj.baby_cry : '--'}</p>
                            </div>

                            <div>
                                <p className='text1'>Baby Needs Resuscitation</p>
                                <p className='text2'>{obj?.baby_need_resuscitation ? obj.baby_need_resuscitation : '--'}</p>
                            </div>
                                
                            <div>
                                <p className='text1'>Oxygen Given</p>
                                <p className='text2'>{obj?.oxygen_given ? obj.oxygen_given : '--'}</p>
                            </div>

                            <div>
                                <p className='text1'>Mucous Sucker Used</p>
                                <p className='text2'>{obj?.oxygen_given ? obj.oxygen_given : '--'}</p>
                            </div>

                            <div>
                                <p className='text1'>Breastfeeding Within 1 hour</p>
                                <p className='text2'>{obj?.breastfeeding_within_one_hour ? obj.breastfeeding_within_one_hour : '--'}</p>
                            </div>

                            <div>
                                <p className='text1'>Breastfeeding Time</p>
                                <p className='text2'>{obj?.breastfeeding_time ? obj.breastfeeding_time : '--'}</p>
                            </div>
                                
                            <div>
                                <p className='text1'>PPTCT Treatment Given</p>
                                <p className='text2'>{obj?.pptct_treatment_given ? patientCheckList?.delivery_detail?.child_details[0].pptct_treatment_given : '--'}</p>
                            </div>

                            <div>
                                <p className='text1'>Congenital Anomaly</p>
                                {obj?.congenital_anomaly && obj?.congenital_anomaly.length > charLimit ?
                                    <TooltipComponent title={`${obj.congenital_anomaly}`} content={limitStr(obj.congenital_anomaly, charLimit)} />
                                    :
                                    <p className='text2'>{obj?.congenital_anomaly ? obj.congenital_anomaly : "--"}</p>
                                }
                            </div>

                            <div>
                                <p className='text1'>Immunization</p>
                                {obj?.immunization && obj?.immunization.length > charLimit ?
                                    <TooltipComponent title={`${obj.immunization}`} content={limitStr(obj.immunization, charLimit)} />
                                    :
                                    <p className='text2'>{obj?.immunization ? obj.immunization : "--"}</p>
                                }
                            </div>

                            <div>
                                <p className='text1'>APGAR Score</p>
                                <p className='text2'>{obj?.apgar_score ? obj.apgar_score : '--'}</p>
                            </div>
                                
                            <div>
                                <p className='text1'>Length Of Chord</p>
                                <p className='text2'>{obj?.chord_length ? obj.chord_length : '--'}</p>
                            </div>

                            <div>
                                <p className='text1'>Complications</p>
                                {
                                    obj?.complications && obj.complications.length > charLimit ? 
                                        <TooltipComponent
                                            title={`${obj?.complications}`}
                                            content={limitStr(obj.complications, charLimit)}
                                        />
                                        : 
                                        <p className='text2'>{obj?.complications ? obj.complications : "--"}</p>
                                }
                            </div>

                            <div>
                                <p className='text1'>Other Congenital Anomaly</p>
                                {
                                    obj?.congenital_anomaly_other && obj.congenital_anomaly_other.length > charLimit ? 
                                        <TooltipComponent
                                            title={`${obj?.congenital_anomaly_other}`}
                                            content={limitStr(obj.congenital_anomaly_other, charLimit)}
                                        />
                                    : 
                                        <p className='text2'>{obj?.congenital_anomaly_other ? obj.congenital_anomaly_other : "--"}</p>
                                }
                            </div>

                        </div>
                    </div> 
                )}) 
            :  <></>}
        
        </div>
        }
    </>

  )
}

export default ChildbirthChecklist