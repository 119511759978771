import React from 'react'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const LocalDatePicker = ({value, setValue,open, onOpen, minDate}) => {

    //const [value , setValue] = useState(dayjs(dayjs().add(1, 'year').$d))

    
  return (
    <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker sx={{'.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding: '10px', fontFamily: 'Poppins, sans-serif',}}} 
            value={value} 
            onChange={((newValue) => setValue(newValue))} 
            defaultValue={null} 
            maxDate={dayjs()} 
            minDate = {minDate}
            open={open} 
            onOpen={onOpen} 
            />  
        </LocalizationProvider>
    </div>
  )
}

export default LocalDatePicker