import './App.css';
import React,{useEffect, useState} from 'react';
import useOneSignal from '../src/components/onesignal/useOneSignal'
import {RouterProvider,} from "react-router-dom";
import {router} from '../src/router/index';
import Cookie from 'js-cookie';

function App() {

  const [theme, setTheme] = useState(() => {
    return Cookie.get("theme") || "dark-theme"; // Use default if not set
  });

  useEffect(() => {
    const savedTheme = Cookie.get("theme");
    if (!savedTheme) {
      Cookie.set("theme", "dark-theme", 
      { 
        expires: 365, 
        path: '/' 
      });
      setTheme("dark-theme");
    }
  }, []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    if ('serviceWorker' in navigator) {
      // Register a service worker hosted at the root of the
      // site using the default scope.
      navigator.serviceWorker.register('OneSignalSDKWorker.js').then(function(registration) {
        console.log('Service worker registration succeeded:', registration);
      }, /*catch*/ function(error) {
        console.log('Service worker registration failed:', error);
      });
      navigator.serviceWorker.register('OneSignalSDKUpdaterWorker.js').then(function(registration) {
        console.log('Service worker registration succeeded:', registration);
      }, /*catch*/ function(error) {
        console.log('Service worker registration failed:', error);
      });
    } else {
      
      console.log('Service workers are not supported.');
    }
  }, [])

  useOneSignal();

  return (
    <RouterProvider router={router} />
  );
}

export default App;
