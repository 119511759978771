import React,{useEffect, useState, useRef} from 'react'
import '../common.scss';
import { dataEntries } from '../../../utils/sheetConstants';
import { getReadableTime, getLaborDataValue, isJson, limitStr,TooltipComponent, charLimit } from '../../../utils/converter'
import axios from 'axios';
import urls from '../../../utils/url';
import IntrapartumGraph from './IntrapartumGraph';
import NoData from '../../common/NoData';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import Cookie from 'js-cookie';
import * as htmlToImage from 'html-to-image';
import jsPDF from "jspdf"

const Intrapartum = (props) => {

  const cookie = Cookie.get('access_token')
  const { patientID } = props;
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false);
  const [ patientData, setPatientData ] = useState({})
  const [ firstCervixTime, setFirstCervixTime ] = useState(0)
  const [ plotPartograph, setPlotPartograph ] = useState(false)
  const [ activeText, setActiveText ] = useState('Partograph');
  const [ intrapartumParams, setIntrapartumParams] = useState([]) // To store data for Labour Data Entry after data manipulation 

  const partographRef = useRef(null);

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=labor_data,user_details,basic_detail,events,place_details,admission_note&`,
      {
        headers: {
        'Request-ID': 'allow|me', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookie}`
      }
      }).then((res) => {
        if(res.data){
          const json_data = isJson(res.data)
          const response =  json_data.response[0]
          setPatientData(response)
          const new_data = response.labor_data.map(obj => ({...obj, data_time: obj.data_time *= 1000 }))
          const sorted_data = new_data.sort((a, b) => (a.data_time > b.data_time) ? 1 : -1);
          
          setIntrapartumParams(sorted_data)
          sorted_data.filter(obj => {
            if (obj.data_type === 'cervix_diameter' && parseInt(obj.param_1) >= 5 && firstCervixTime == 0){
              // If Cervix Diameter is greater than and equal to 5 and first Cervix Time is 0 then only partograph will be generated.
              setPlotPartograph(true)
            }
          })
          setLoading(false) 
          
        }
      }).catch((err) => {
        console.log("Error in Fetching Data",err)
        setError(true)
        setPatientData({})
        setIntrapartumParams([])
        setPlotPartograph(false)
        setLoading(false)
      }
    )
  },[])

  const handleToggle = (text) => {
    setActiveText(text);
  };

//   const handleDownload = () => {
//     const partograph = partographRef.current;
//     const printableContainer = document.querySelector('.printable-only-div');

//     if (partograph && printableContainer) {
//         printableContainer.style.display = 'block';

//         // Convert the partograph to an image
//         htmlToImage.toCanvas(partograph)
//             .then((canvas) => {
//                 const dataUrl = canvas.toDataURL('image/png');
//                 const pdf = new jsPDF('p', 'mm', 'a4');               // 'p' for portrait mode
//                 const pageWidth = 210; // A4 portrait width in mm
//                 const pageHeight = 280; // A4 portrait height in mm
//                 const margin = 10; // 10mm margin on all sides
//                 const imgWidth = pageWidth - 2 * margin; // Adjust image width for margins
//                 const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale image height proportionally

//                 let heightLeft = imgHeight;
//                 let position = margin;

//                 // Add image to the first page
//                 pdf.addImage(dataUrl, 'PNG', margin, position, imgWidth, imgHeight);
//                 heightLeft -= (pageHeight - 2 * margin); // Reduce by page height minus margins

//                 // While content still needs to be added
//                 while (heightLeft > 0) {
//                     position -= (imgHeight - heightLeft); // Adjust the position for the new page
//                     pdf.addPage();
//                     pdf.addImage(dataUrl, 'PNG', margin, position, imgWidth, imgHeight);
//                     heightLeft -= (pageHeight - 2 * margin);
//                     heightLeft += 10 // Reduce by page height minus margins
//                 }

//                 // Open PDF in a new window for printing
//                 window.open(pdf.output('bloburl'), '_blank');

//                 // Reset the display property to 'none' after printing
//                 printableContainer.style.display = 'none';
//             })
//             .catch((error) => {
//                 console.error('Error generating PDF:', error);
//                 // Reset the display property to 'none' in case of an error
//                 printableContainer.style.display = 'none';
//             });
//     } else {
//         console.error('Partograph element or intrapartum-info-container not found');
//     }
// }

  const handleDownload = () => {
      const partograph = partographRef.current;
      const printableContainer = document.querySelector('.printable-only-div');

      if (partograph && printableContainer) {
          printableContainer.style.display = 'block';

          // Convert the partograph to an image
          htmlToImage.toCanvas(partograph)
              .then((canvas) => {
                  const dataUrl = canvas.toDataURL('image/png');
                  const pdf = new jsPDF('p', 'mm', 'a4');    // 'p' for portrait mode
                  pdf.addImage(dataUrl, 'PNG', 0, 0, 200, 290);
                  window.open(pdf.output('bloburl'), '_self');
                  printableContainer.style.display = 'none';
              })
              .catch((error) => {
                  console.error('Error generating PDF:', error);
                  printableContainer.style.display = 'none';
              });
      } else {
          console.error('Partograph element or intrapartum-info-container not found');
      }
  }

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
        e.preventDefault();
        alert('Please click on Download button to download the Partograph');
      }
  };
    
  useEffect(() => {
    const handleKeyDownEvent = (e) => {
      handleKeyDown(e);
    };

    window.addEventListener('keydown', handleKeyDownEvent);

    return () => {
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, []);

  return (
    <>
    { 
      loading ? ( <div className='loading-div'><Loading/></div>) 
      : error ? ( <div className='reload-div'><Reload/></div>) 
      : (
        <>
          <div className='toggle-header info-section'>
    
              <div className='toggle-button'>
                  <p className={activeText === 'Partograph' ? 'active-toggle-text' : 'inactive-toggle-text'} onClick={() => handleToggle('Partograph')}> Partograph </p>
                  <hr className='vertical-line'/>
                  <p className={activeText === 'labor-data' ? 'active-toggle-text' : 'inactive-toggle-text'} onClick={() => handleToggle('labor-data')}> Labor Data Entry </p>
              </div>

              { 
                plotPartograph ?
                    <div className='download-partograph' onClick={handleDownload}>
                      <button className='download-partograph-button'>Download Partograph</button> 
                    </div>
                  : 
                    <></>
              } 
                            
          </div>

          <div className='info-section'>
          
            { 
              activeText === 'Partograph' ?
                (
                  plotPartograph ? 
                    (

                       <div ref={partographRef} id="partograph-container" style={{marginTop:`1.5rem`, paddingBottom:`1.5rem`}}>
                          
                          <div className='printable-only-div'>

                            <h3 style={{textAlign: `center`, textTransform:`capitalize`, fontWeight:`bold`}}> {patientData?.place_details[patientData?.basic_detail?.registration_place_id].name} -  SIMPLIFIED PARTOGRAPH </h3>

                            <div className='intrapartum-info-container' >

                                <div className='intrapartum-info-section'>
                                    <p className='text1'>Patient's Name</p>
                                    <p className='text2 breakableText'>{patientData?.basic_detail?.name ? patientData?.basic_detail?.name: "- -"} </p>
                                </div>

                                <div className='intrapartum-info-section'>
                                    <p className='text1'>GPAL</p>
                                    {patientData?.admission_note && patientData.admission_note.gravida !== null && patientData.admission_note.para !== null && patientData.admission_note.abortion !== null && patientData.admission_note.living_children !== null ?
                                        <p className='text2 breakableText'>
                                            G{<><sub>{patientData.admission_note.gravida}</sub></>}
                                            P{<><sub>{patientData.admission_note.para}</sub></>}
                                            A{<><sub>{patientData.admission_note.abortion}</sub></>}
                                            L{<><sub>{patientData.admission_note.living_children}</sub></>}
                                        </p>
                                        :
                                        '-'
                                    }
                                </div>

                                <div className='intrapartum-info-section'>
                                    <p className='text1'>IPD Registration Number</p>
                                    <p className='text2 breakableText'>{patientData?.basic_detail?.ipd_registration_number ? patientData?.basic_detail?.ipd_registration_number : "- -"}</p>
                                </div>

                                <div className='intrapartum-info-section'>
                                    <p className='text1'>Admission Time</p>
                                    <p className='text2 breakableText'>{patientData?.basic_detail?.register_time ? getReadableTime(patientData?.basic_detail?.register_time) : "- -"}</p>
                                </div>
                                    
                                <div className='intrapartum-info-section'>
                                    <p className='text1'>Membrane Rupture Time</p>
                                    <p className='text2 breakableText'>{ (patientData?.events.find(obj => obj.event === "membrane_ruptured") !== undefined) ? getReadableTime(patientData.events.find(obj => obj.event === "membrane_ruptured").event_time) : '--' }</p> 
                                </div> 

                                <div className='intrapartum-info-section'>
                                    <p className='text1'>OPD Registration Number</p>
                                    <p className='text2 breakableText'>{patientData?.basic_detail?.opd_registration_number ? patientData?.basic_detail?.opd_registration_number: "- -"}</p>
                                </div>
                            </div>

                          </div>
                          
                           <IntrapartumGraph patientID={patientID} />

                       </div>
                    ) 
                    : 
                    <>
                      <img src = "/no_partograph_generated.svg" alt = 'No Partograph Generated' className = 'no-recorded-data' />
                    </>
                )
                : null
            }
      
            {
              activeText === 'labor-data' ? (
                <>
                  {  
                    loading ? ( <div className='loading-div'><Loading/></div>)
                    : error ? ( <div className='reload-div'><Reload/></div>) 
                    : intrapartumParams && intrapartumParams.length > 0 ? 
                    (
                      <div>
                        
                        <table style={{ width: '90%', paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '2rem', marginLeft: '2.5rem', marginRight: '2rem', paddingBottom: '2rem' }}>
                          <thead>
                            <tr className = "table-layout header" >
                              <td className = "header-text">Time</td>
                              <td className = "header-text">Parameter Name</td>
                              <td className = "header-text">Parameter Value</td>
                              <td className = "header-text">Data Entered By</td>
                            </tr>
                          </thead>

                          <tbody>
                            {intrapartumParams.map((row, ind) => (
                              <tr key={ind} className = "table-layout" >
                                <td className = "table-text">{ getReadableTime(row.data_time) }</td>
                                <td className = "table-text">{ dataEntries[row.data_type] }</td>
                                <td className = "table-text">{ getLaborDataValue(row) }</td>
                                <td className = "table-text">
                                {patientData.user_details[patientData.basic_detail.registration_worker_id]?.first_name && patientData.user_details[patientData.basic_detail.registration_worker_id]?.first_name.length > charLimit?
                                    <TooltipComponent title={`${patientData.user_details[patientData.basic_detail.registration_worker_id]?.first_name}`} content={limitStr(patientData.user_details[patientData.basic_detail.registration_worker_id]?.first_name, charLimit)} />
                                  :
                                    <p className='text2'>{patientData.user_details[patientData.basic_detail.registration_worker_id]?.first_name ? patientData.user_details[patientData.basic_detail.registration_worker_id]?.first_name : "- -"}</p>
                                }
                                </td>
                              </tr>
                            ))}
                          </tbody>

                        </table>
                      </div>
                    ) 
                    : <NoData/>
                  }
                </>
              ) : null
            }
          </div>
        </>
      )
    }
    </>
  
  )
}

export default Intrapartum