import React from 'react';
import { useState, useEffect } from 'react';
import '../common.scss';
import axios from 'axios';
import urls from '../../../utils/url';
import {limitStr, charLimit, isJson, TooltipComponent } from '../../../utils/converter';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import Cookie from 'js-cookie';

const ExaminationInfo = (props) => {

  const cookie = Cookie.get('access_token')
  const { patientID } = props;
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ patientExaminationInfo, setPatientExaminationInfo ] = useState({});

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=investigation,physical_examination,pa_pv_examination&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPatientExaminationInfo(json_data.response[0])
            setLoading(false)
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err)
          setError(true)
          setPatientExaminationInfo({})
          setLoading(false)
        }
      ) 
  }, [])



  return (
    
    <>
    { 
        loading ? <div className='loading-div'><Loading/></div> 
        :
        error ? <div className='reload-div'><Reload/></div>
        :
        <div className='info-section'>
        
          {/* Physical Examination */}
          <>
            <div className='info-header'>
              <p className='heading'> Physical Examination </p>
            </div>

            <div className='info-container'>
              <div>
                  <p className='text1'>Height(cms)</p>
                  <p className='text2'>{patientExaminationInfo?.physical_examination?.height ? patientExaminationInfo?.physical_examination?.height : "- -"} </p>
              </div>

              <div>
                  <p className='text1'>Weight(kgs)</p>
                  <p className='text2'>{patientExaminationInfo?.physical_examination?.weight ? patientExaminationInfo?.physical_examination?.weight : "- -"}</p>
              </div>
                  
              <div>
                  <p className='text1'>Pallor</p>
                  <p className='text2'>{patientExaminationInfo?.physical_examination?.pallor ? patientExaminationInfo?.physical_examination?.pallor : "- -"}</p>
              </div>

              <div>
                  <p className='text1'>Jaundice</p>
                  <p className='text2'>{patientExaminationInfo?.physical_examination?.jaundice ? patientExaminationInfo?.physical_examination?.jaundice : "- -"}</p>
              </div>
                  
              <div>
                  <p className='text1'>Pedal Edema</p>
                  <p className='text2'>{patientExaminationInfo?.physical_examination?.pedal_edema ? patientExaminationInfo?.physical_examination?.pedal_edema : "- -"}</p>
              </div>
            </div>
          </>
          
          {/* PA PV Examination */}
          <>

            <div className='info-header'>
              <p className='heading'>PA/PV Examinations</p>
            </div>
            
            <div className='info-container'>
              <div>
                  <p className='text1'>Presentation</p>
                  <p className='text2'>{patientExaminationInfo?.pa_pv_examination?.presentation ? patientExaminationInfo?.pa_pv_examination?.presentation : "- -"}</p>
              </div>

              <div>
                  <p className='text1'>Presentation Part</p>
                  <p className='text2'>{patientExaminationInfo?.pa_pv_examination?.presenting_part ? patientExaminationInfo?.pa_pv_examination?.presenting_part : "- -"}</p>
              </div>
                  
              <div>
                <p className='text1'>Other Presenting Part</p>
                {patientExaminationInfo?.pa_pv_examination?.presenting_part_other && patientExaminationInfo?.pa_pv_examination?.presenting_part_other.length > charLimit ?
                  <TooltipComponent title={`${patientExaminationInfo?.pa_pv_examination?.presenting_part_other}`} content={limitStr(patientExaminationInfo?.pa_pv_examination?.presenting_part_other, charLimit)} />
                  :
                  <p className='text2'>{patientExaminationInfo?.pa_pv_examination?.presenting_part_other ? patientExaminationInfo.pa_pv_examination.presenting_part_other : "- -"}</p>
                }
              </div>


              <div>
                  <p className='text1'>Gestation type</p>
                  <p className='text2'>{patientExaminationInfo?.pa_pv_examination?.gestation_type ? patientExaminationInfo?.pa_pv_examination?.gestation_type : "- -"}</p>
              </div>

            <div>
                <p className='text1'>Gestation Period(weeks)</p>
                <p className='text2'>{patientExaminationInfo?.pa_pv_examination?.gestation_period ? patientExaminationInfo?.pa_pv_examination?.gestation_period : "- -"}</p>
            </div>

              <div>
                  <p className='text1'>Fundal Height</p>
                  <p className='text2'>{patientExaminationInfo?.pa_pv_examination?.fundal_height ? patientExaminationInfo?.pa_pv_examination?.fundal_height : "- -"}</p>
              </div>
                  
              <div>
                  <p className='text1'>Fetal Movements</p>
                  <p className='text2'>{patientExaminationInfo?.pa_pv_examination?.fetal_movements ? patientExaminationInfo?.pa_pv_examination?.fetal_movements : "- -"}</p>
              </div>

              <div>
                  <p className='text1'>Pelvis Adequacy </p>
                  <p className='text2'>{patientExaminationInfo?.pa_pv_examination?.pelvic_adequacy ? patientExaminationInfo?.pa_pv_examination?.pelvic_adequacy : "- -"}</p>
              </div>
            </div>
            
          </>
          
          {/* Investigatiion */}
          <>
            <div className='info-header'>
              <p className='heading'>Investigations</p>
            </div>
            
            <div className='info-container'>
              <div>
                  <p className='text1'>Blood Group & Rh</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.blood_group ? patientExaminationInfo?.investigation?.blood_group : "- -"}</p>
              </div>

              <div>
                  <p className='text1'>Haemoglobin(g/dl)</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.haemoglobin ? patientExaminationInfo?.investigation?.haemoglobin : "- -"}</p>
              </div>
                  
              <div>
                  <p className='text1'>VDRL/RPR</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.vdrl_rpr ? patientExaminationInfo?.investigation?.vdrl_rpr : "- -"}</p>
              </div>

              <div>
                  <p className='text1'>HBsAg</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.hbs_ag ? patientExaminationInfo?.investigation?.hbs_ag : "- -"}</p>
              </div>

            <div>
                <p className='text1'>Urine Albumin</p>
                <p className='text2'>{patientExaminationInfo?.investigation?.urine_albumin ? patientExaminationInfo?.investigation?.urine_albumin : "- -"}</p>
            </div>

              <div>
                  <p className='text1'>Urine Sugar</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.urine_sugar ? patientExaminationInfo?.investigation?.urine_sugar : "- -"}</p>
              </div>
                  
              <div>
                  <p className='text1'>RBS</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.rbs ? patientExaminationInfo?.investigation?.rbs : "- -"}</p>
              </div>

            <div>
                <p className='text1'>HIV</p>
                <p className='text2'>{patientExaminationInfo?.investigation?.hiv ? patientExaminationInfo?.investigation?.hiv : "- -"}</p>
            </div>

              <div>
                  <p className='text1'>Syphilis</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.syphilis ? patientExaminationInfo?.investigation?.syphilis : "- -"}</p>
              </div>
                  
              <div>
                  <p className='text1'>Malaria</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.malaria ? patientExaminationInfo?.investigation?.malaria : "- -"}</p>
              </div>

              <div>
                  <p className='text1'>Anti D</p>
                  <p className='text2'>{patientExaminationInfo?.investigation?.anti_d ? patientExaminationInfo?.investigation?.anti_d : "- -"}</p>
              </div>

              <div>
                <p className='text1'>Staff Nurse Feedback</p>
                {patientExaminationInfo?.investigation?.staff_nurse_feedback && patientExaminationInfo?.investigation?.staff_nurse_feedback.length > charLimit ? 
                  <TooltipComponent title={`${patientExaminationInfo?.investigation?.staff_nurse_feedback}`} content={limitStr(patientExaminationInfo?.investigation?.staff_nurse_feedback, charLimit)} />
                  :
                  <p className='text2'>{patientExaminationInfo?.investigation?.staff_nurse_feedback ? patientExaminationInfo.investigation.staff_nurse_feedback : "- -"}</p>
                }
              </div>

            </div>
          </>

        </div> 
    }
   </>
  )
}

export default ExaminationInfo