import React from 'react'
import KeyarGraph from '../viewFiles/deviceData/keyarGraph';

const DeviceInformation = (props) => {

  const {patientID, data, basicDetail, otherDetail, deviceName,  isPlaying, setIsPlaying, selectedSubTab, placeDetail} = props;
  //console.log(data, placeDetail)

  return (
    
    <div style={{ marginLeft:`22%`, width:`75%`}} >
      <KeyarGraph placeDetail={placeDetail} selectedSubTab={selectedSubTab} data={data} basicDetail={basicDetail} otherDetail={otherDetail} deviceName={deviceName} isPlaying={isPlaying} setIsPlaying={setIsPlaying}/> 
    </div>
  )
}

export default DeviceInformation