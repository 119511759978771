import React,{useState} from 'react'
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../common/common.scss'
import { styled } from '@mui/material/styles';


// You can adjust the styles by using the `styled` function
const StyledInputLabel = styled(InputLabel)({
  color: '#171717',
  fontSize: '16px',
  fontWeight: '500',
  marginTop: '20px',
  marginBottom: '10px',
  fontFamily: 'Poppins',
});

const StyledSelect = styled(Select)({
  '& .MuiPaper-root': {
    backgroundColor: '#faf6ff', // This will change the background color of the dropdown
  },
  borderRadius: '4px',
  border: '0.5px solid #BFBFBF',
  background: '#fff',
  width: '150px', // You can adjust the width as needed
  '& .MuiSelect-select': {
    //background: '#faf6ff',
    height: '10px', // This might be too small, adjust as needed
    padding: '10px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: 'Poppins !important',
  // '&:hover': {
  //   backgroundColor: '#faf6ff', // for hover state
  // },
  '&.Mui-selected': {
    backgroundColor: '#faf6ff', // for selected item
    '&:hover': {
      backgroundColor: '#faf6ff', // to maintain the same color on hover for a selected item
    },
  },
  //background: '#faf6ff',
  // Add other styles if needed
});


const Filter = ({ heading, options, selectedValue, onFilterChange, }) => {


  const handleChange = (event) => {
    
    const newValue = event.target.value;
    onFilterChange(heading, newValue); // Call the handler passed from the parent component
  }
  
  return (
    <div className='filter'>
      <StyledInputLabel variant="standard" className='input-label'> {heading} </StyledInputLabel>
      <StyledSelect labelId="demo-simple-select-label" id="demo-simple-select" 
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null, // This is important to prevent the Menu from using the Select input as the anchor
        }}
        value={selectedValue} className='custom-select' onChange={handleChange}>
      {options.map((option, index) => (
        <StyledMenuItem key={index} value={option}>
          {option}
        </StyledMenuItem>
      ))}
      </StyledSelect>
     
    </div>
  )
}

export default Filter;