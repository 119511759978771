import React from 'react';
import axios from 'axios';
import urls from '../../../utils/url';
import { useState, useEffect } from 'react';
import '../common.scss';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import { getReadableDateOnly, getReadableTimeOnly, transformText, formatDate , isJson} from '../../../utils/converter';
import Cookie from 'js-cookie';
import { useParams } from 'react-router-dom';

const AlertDetails = (props) => {

  const cookie = Cookie.get('access_token');
  const { patientID } = props;

  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ patientAlerts, setPatientAlerts ] = useState();

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=alerts&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPatientAlerts(json_data.response[0].alerts)
            setLoading(false)
            
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err)
          setError(true)
          setPatientAlerts()
          setLoading(false)
        }
      ) 
  }, [])

//To Remove Placenta Alerts 
 const filterAlerts = patientAlerts && patientAlerts.length !== undefined  ? patientAlerts.filter((alert,index)=> alert.alert_type !== "Placenta_Complication" && alert.alert_type !== "PLACENTA_DELIVERED" ) : [];

  return (
    <>
    {
      loading ? <div className='loading-div'><Loading/></div> 
      : error ? <div className='reload-div'><Reload/></div>
      : filterAlerts && filterAlerts.length > 0  && filterAlerts.length !== undefined  ? 
        <div className='info-section'>
            <div className='alert-section' >
                <div className='alert-heading header-background'>
                  <p className='header-text'>Alert</p>
                  <p className='header-text'>Date</p>
                  <p className='header-text'>Time</p>
                </div>

                {filterAlerts.map((obj, index) =>{
                  return (
                    <>
                   
                        <div className='alert-heading' key={index}>
                          <p className='data-text'>{transformText(obj.alert_type)}</p>
                          <p className='data-text'>{formatDate(getReadableDateOnly(obj.data_time * 1000))}</p>
                          <p className='data-text'>{getReadableTimeOnly(obj.data_time)}</p>
                        </div>
                      
                    </>
                )})}
            </div>
        
        </div>
      : 
        <div className='no-data-div' >
          <img src = "/no_alerts_recorded.png" alt = 'No Alerts Recorded' style={{width:`65%`, marginLeft:`17%`, marginTop:`2rem`}} />
        </div>
    }
    </>
  )
}

export default AlertDetails