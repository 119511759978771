import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    liveOneSecKeyarData: []
}

const liveOneSecKeyarDataSlice = createSlice({
    name: 'liveOneSecKeyarData',
    initialState,
    reducers: {
        addliveOneSecKeyarData: ( state, action) => {
            state.liveOneSecKeyarData = action.payload
        }
    }
})

export const {addliveOneSecKeyarData} = liveOneSecKeyarDataSlice.actions;
export default liveOneSecKeyarDataSlice.reducer