import React,{useEffect, useState} from 'react';
import { Scatter } from 'react-chartjs-2';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsHeatmap from "highcharts/modules/heatmap";
import PatientHistoryCard from '../../osi/patientHistoryCard';
import './devicedata.scss'

HighchartsHeatmap(Highcharts);

const SIGraph = ({data, deviceName}) => {
  
  console.log(data)

  const [siData, setSiData] = useState([]);
  const [threshold, setThreshold] = useState();
  const [firstTime, setFirstTime] = useState();
  const [tickPositions, setTickPositions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);


  var chartMarginLeft = 70;
  var chartMarginRight = 40;
  var chartMarginTop = 60;
  var chartMarginBottom = 60;
  var itemMargin = 2;


function getTickPositions(data_time) {
    // maximum 24 hours
    var positions = [];
   
    // 30 minutes difference between 2 tick positions
    for(var i = 0; i <= 48; i++){
        positions.push(data_time + (i*1800*1000));
    }
    return positions;
}

function getStep(){
  var width = window.screen.width;
  var step = 1 + parseInt((1280 - width));
  if(step < 1 ){
      step = 1;
  }
  return step;
}


useEffect(()=>{

    Highcharts.setOptions({
        global: {
          useUTC: false
        }
    });

    if (data && data.length > 0) {
        const firstTickPosition = data[0].data_time;
        const thresholdValue = data[0].threshold;
        setThreshold(thresholdValue)
        setFirstTime(firstTickPosition);
        setTickPositions(getTickPositions(firstTickPosition));
    }
   
    let osi_values = []; 
    if(data && data.length > 0){
        osi_values = data.map(entry => [entry.data_time, entry.osi_value]);

        console.log(osi_values)
        setSiData(osi_values)
    }

},[data])

const osiGraph = {
    chart: {
        type:'line',
        marginLeft: chartMarginLeft,
        marginRight:chartMarginRight,
        marginTop: chartMarginTop,
        marginBottom:chartMarginBottom,
        scrollablePlotArea: {
          minWidth: 2500,
          scrollPositionX: -1
        },
        borderRadius:12,// graph border radius
        height: '60%', // height of chart
  
        
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
          align: 'right',
          alignTo: 'spacingBox'
      }
    },
  
    title: {
      text: null
    },
  
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M', // Format for hours and minutes
        },
        scrollbar: {
          enabled: true  // Enable scrollbar
        },
        title: {
          text: null
        },
        min: tickPositions[0],
        max: tickPositions.slice(-1).pop(),
        tickPositions: tickPositions,
        labels: {
            formatter: function () {
              return Highcharts.dateFormat('%H:%M ', this.value);
            },
            //step: getStep(),
        },
        gridLineColor: 'rgba(0, 0, 0, 0.1)',
        gridLineWidth: 1.1,
        gridLineDashStyle: 'Dash',
        lineColor: '#A163F1',
        lineWidth: 1,
    },
  
    yAxis: {
        title: {
          text: null
        },
        tickInterval: 10,
        gridLineColor: 'rgba(0, 0, 0, 0.1)',
        gridLineWidth: 1.1,
        gridLineDashStyle: 'Dash',
        lineColor: '#A163F1',
        lineWidth: 1,
        min: 0,
        max: 2,
        plotLines: [
            {
                color: 'red',
                width: 1,
                value: threshold,
                dashStyle: 'Dash'
            },
          
        ],
        tickPositioner: function () {
            var positions = [],
            i = 0;
            while(i <= 2){
                positions.push(i);
                i+=0.2;
            }
            const roundedNumber = positions.map(obj => parseFloat(obj.toFixed(2)))
            return roundedNumber;
        }
    },
    
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      itemMarginTop: itemMargin,
      itemMarginBottom: itemMargin,
      zIndex: 20,
      backgroundColor : '#FFFFFF',
      floating : true,
    },
  
    series: [
      {
        name: 'OSI Value',
        data: siData ? siData.map(([x, y],index) => ({
          x,
          y,
          marker: {
            fillColor: y > threshold ? 'red' : 'green',
          },
        })) : [],
        lineWidth: 1.2,
        color: 'black', // Set the default line color to black
        marker: {
          enabled: true,
          radius: 5, // Set the radius of the marker
          
        },
      }
    ],
  }

  const handleClick = (index) => {
    setActiveIndex(index);
  };


  return (
     <div>

         {
            siData && siData.length > 0 &&
            
                <div className='si-graph-section' >

                    <div className='si-graph-container'>
                        
                        <div className='graph-header'>
                            <div className='graph-info'>
                                <img src = '/threshold.svg' alt='Threshold' />
                                <div style={{paddingTop:`0.8rem`}}>
                                <p className='axis-text'>X-axis = <span className='axis-value'>Time</span></p>
                                <p className='axis-text'>Y-axis = <span className='axis-value'>OSI</span></p>
                                </div>
                            </div>
                        </div>
                        
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={osiGraph}
                            constructorType={'chart'}
                            allowChartUpdate={true}  
                        />  

                    </div> 

                    <div className='si-card-section'>
                        <p className='patient-text'> Patient History </p>
                        <hr className='hr-line'/>
                    
                        {
                            data && data.length > 0 ? data.map((object,index) => {
                                return (
                                    <div key={index} className={activeIndex === index ? 'si-active-card' : 'si-inactive-card'} onClick={() => handleClick(index)} >
                                        <PatientHistoryCard si= {true} activeIndex={activeIndex} index={index} osi_value={object.osi_value} systolic_bp={object.systolic_bp} pulse_rate={object.pulse_rate}> </PatientHistoryCard>
                                    </div>
                                )}) 
                            : 
                            <></>
                        }
                    </div>

                </div>
        }

     </div>
  )
}

export default SIGraph