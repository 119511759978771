import React from 'react';
import { useState, useEffect } from 'react';
import '../common.scss';
import axios from 'axios';
import urls from '../../../utils/url';
import {limitStr, charLimit, isJson, TooltipComponent} from '../../../utils/converter';
import Loading from '../../common/loading';
import Reload from '../../common/Reload';
import Cookie from 'js-cookie';

const PatientHistory = (props) => {

  const cookie = Cookie.get('access_token')
  const { patientID } = props;
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ patientMedicalHistory, setPatientMedicalHistory ] = useState({});
  

  useEffect(() => {
    setLoading(true)
    axios.get(`${urls.BETA_URL}/nurse/patients/?patient_ids=${patientID}&forms=medical_history,family_history,obstetric_histories&`,
        {
          headers: {
          'Request-ID': 'allow|me', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookie}`
        }
        }).then((res) => {
          if(res.data){
            const json_data = isJson(res.data)
            setPatientMedicalHistory(json_data.response[0]);
            setLoading(false)
          }
        }).catch((err) => {
          console.log("Error in Fetching Data",err)
          setError(true)
          setPatientMedicalHistory({})
          setLoading(false)
        }
      ) 
  }, [])

  return (

   <>
    { loading ? <div className='loading-div'><Loading/></div> 
      : error ? <div className='reload-div'><Reload/></div>
      :
      <div className='info-section'>

        {/* Patient Medical History */}
        <>
          <div className='info-header'>
            <p className='heading'>Patient Medical History</p>
          </div>

          <div className='info-container'>
            <div>
                <p className='text1'>Hypertension</p>
                <p className='text2'>{patientMedicalHistory?.medical_history?.hypertension ?patientMedicalHistory?.medical_history?.hypertension : "- -"}</p>
            </div>

            <div>
                <p className='text1'>Diabetes Mellitus</p>
                <p className='text2'>{patientMedicalHistory?.medical_history?.diabetese_mellitus ? patientMedicalHistory?.medical_history?.diabetese_mellitus : "- -"}</p>
            </div>

            <div>
                <p className='text1'>Tuberculosis</p>
                <p className='text2'>{patientMedicalHistory?.medical_history?.tuberculosis ? patientMedicalHistory?.medical_history?.tuberculosis : "- -"}</p>
            </div>
                
            <div>
                <p className='text1'>Bronchial Asthma</p>
                <p className='text2'>{patientMedicalHistory?.medical_history?.bronchial_asthma ? patientMedicalHistory?.medical_history?.bronchial_asthma : "- -"}</p>
            </div>

            <div>
                <p className='text1'>Heart Disease</p>
                <p className='text2'>{patientMedicalHistory?.medical_history?.heart_disease ? patientMedicalHistory?.medical_history?.heart_disease : "- -"}</p>
            </div>
                
            <div>
              <p className='text1'>Surgery</p>
              {patientMedicalHistory?.medical_history?.surgery_detail && patientMedicalHistory?.medical_history?.surgery_detail.length > charLimit ?
                <TooltipComponent title={`${patientMedicalHistory?.medical_history?.surgery_detail}`} content={limitStr(patientMedicalHistory?.medical_history?.surgery_detail, charLimit)} />
                :
                <p className='text2'>{patientMedicalHistory?.medical_history?.surgery_detail ? patientMedicalHistory.medical_history.surgery_detail : "- -"}</p>
              }
            </div>

            <div>
              <p className='text1'>Drug Allergy</p>
              {patientMedicalHistory?.medical_history?.drug_allergy_details && patientMedicalHistory?.medical_history?.drug_allergy_details.length > charLimit ?
                <TooltipComponent title={`${patientMedicalHistory?.medical_history?.drug_allergy_details}`} content={limitStr(patientMedicalHistory?.medical_history?.drug_allergy_details, charLimit)} />
                :
                <p className='text2'>{patientMedicalHistory?.medical_history?.drug_allergy_details ? patientMedicalHistory.medical_history.drug_allergy_details : "- -"}</p>
              }
            </div>

            <div>
              <p className='text1'>Contraceptive</p>
              {patientMedicalHistory?.medical_history?.contraceptive && patientMedicalHistory?.medical_history?.contraceptive.length > charLimit ?
                <TooltipComponent title={`${patientMedicalHistory?.medical_history?.contraceptive}`} content={limitStr(patientMedicalHistory?.medical_history?.contraceptive, charLimit)} />
                :
                <p className='text2'>{patientMedicalHistory?.medical_history?.contraceptive ? patientMedicalHistory.medical_history.contraceptive : "- -"}</p>
              }
            </div>

            <div>
              <p className='text1'>Other Medical Conditions</p>
              {patientMedicalHistory?.medical_history?.other_medical_condition_details && patientMedicalHistory?.medical_history?.other_medical_condition_details.length > charLimit ?
                <TooltipComponent title={`${patientMedicalHistory?.medical_history?.other_medical_condition_details}`} content={limitStr(patientMedicalHistory?.medical_history?.other_medical_condition_details, charLimit)}/>
                :
                <p className='text2'>{patientMedicalHistory?.medical_history?.other_medical_condition_details ? patientMedicalHistory.medical_history.other_medical_condition_details : "- -"}</p>
              }
            </div>


        </div>

        </>
        
        {/* Patient Family History */}
        <>
          <div className='info-header'>
            <p className='heading'>Family History</p>
          </div>
          
          <div className='info-container'>
            <div>
                <p className='text1'>Hypertension</p>
                <p className='text2'>{patientMedicalHistory?.family_history?.hypertension ? patientMedicalHistory?.family_history?.hypertension : "- -"}</p>
            </div>

            <div>
                <p className='text1'>Diabetes Mellitus</p>
                <p className='text2'>{patientMedicalHistory?.family_history?.diabetese_mellitus ? patientMedicalHistory?.family_history?.diabetese_mellitus : "- -"}</p>
            </div>
                
            <div>
                <p className='text1'>Tuberculosis</p>
                <p className='text2'>{patientMedicalHistory?.family_history?.tuberculosis ? patientMedicalHistory?.family_history?.tuberculosis : "- -"}</p>
            </div>

            <div>
                <p className='text1'>Asthma</p>
                <p className='text2'>{patientMedicalHistory?.family_history?.asthma ? patientMedicalHistory?.family_history?.asthma : "- -"}</p>
            </div>
                
            <div>
                <p className='text1'>Twins</p>
                <p className='text2'>{patientMedicalHistory?.family_history?.twins ? patientMedicalHistory?.family_history.twins : "- -"}</p>
            </div>

            <div>
              <p className='text1'>Other Medical Conditions</p>
              {
                patientMedicalHistory?.family_history?.other_medical_condition_details && patientMedicalHistory?.family_history?.other_medical_condition_details.length > charLimit ? 
                  <TooltipComponent title={patientMedicalHistory?.family_history?.other_medical_condition_details} content={limitStr(patientMedicalHistory?.family_history?.other_medical_condition_details, charLimit)}/>
                  :
                  <p className='text2'>{patientMedicalHistory?.family_history?.other_medical_condition_details ? patientMedicalHistory.family_history.other_medical_condition_details :  '- -'}</p>
              }
            </div>

          </div>
        </>
        
        {/* Obstetric History */}
        <>
         
          { patientMedicalHistory?.obstetric_histories && patientMedicalHistory?.obstetric_histories.length > 0 ? 
            patientMedicalHistory?.obstetric_histories.map((object, index) => {
              return (
                  <div>
                     
                      <div className='info-header'>
                        <p className='heading'>Obstetric History - Child {index+1}</p>
                      </div>
                    
                      <div className='info-container'>
                          <div>
                              <p className = 'text1'> Pregnancy Year </p>
                              <p className = 'text2'> {object?.pregnancy_year ? object?.pregnancy_year : "- -"} </p>
                          </div>

                          <div>
                              <p className = 'text1'> Place of Delivery </p>
                              <p className = 'text2'> {object?.delivery_place? object?.delivery_place : "- -"} </p>
                          </div>

                          <div>
                              <p className = 'text1' > Mode of Delivery </p>
                              <p className = 'text2' > {object?.delivery_mode ? object?.delivery_mode : "- -"} </p>
                          </div>

                          <div>
                              <p className = 'text1'> Outcome of Delivery </p>
                              <p className = 'text2'> {object?.delivery_outcome ? object?.delivery_outcome : "- -"} </p>
                          </div>

                          <div>
                            <p className='text1'>Pregnancy Complications</p>
                            <p className='text2'>{object?.pregnancy_complication ? object.pregnancy_complication : "- -"}</p>
                          </div>

                          <div>
                            <p className='text1'>Notes</p>
                            {object?.notes && object?.notes.length > charLimit ?
                              <TooltipComponent title={`${object?.notes}`} content={limitStr(object?.notes, charLimit)} />
                              :
                              <p className='text2'>{object?.notes ? object.notes : "- -"}</p>
                            }
                          </div>

                      </div>

                  </div>
              )
          })
          : 
          <>
           <div className='info-header'>
             <p className='heading'>Obstetric History</p>
           </div> 
           <p style={{display:`flex`, alignItems:`center`, justifyContent:`center`, marginTop:`2rem`, fontSize:`19px`, marginBottom:`2rem`}}>There is no Obstetric History</p>
          </>
          }
        </>

      </div>
    }
   </>
  )
}

export default PatientHistory